import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, putReq } from "../api-services";

export const getOffDayDetail = createAsyncThunk(
  "merchants/businesses/off_days",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/off_days?startDate=${payload.startDate}&endDate=${payload.endDate}`,
    );
    return response;
  },
);

export const updateOffDay = createAsyncThunk(
  "merchants/businesses/off_days/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/off_days`,
      payload,
    );
    return response;
  },
);

const offDaySlice = createSlice({
  name: "offDay",
  initialState: {
    getOffDayDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateOffDayObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getOffDayDetail.pending]: (state) => {
      state.getOffDayDetailObj.status = "pending";
    },
    [getOffDayDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getOffDayDetailObj.status = "succeeded";
      state.getOffDayDetailObj.data = data;
      state.getOffDayDetailObj.successMessage = message;
    },
    [getOffDayDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getOffDayDetailObj.status = "failed";
      state.getOffDayDetailObj.errorMessage = message;
    },
    [updateOffDay.pending]: (state) => {
      state.updateOffDayObj.status = "pending";
    },
    [updateOffDay.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateOffDayObj.status = "succeeded";
      state.updateOffDayObj.data = data;
      state.updateOffDayObj.successMessage = message;
    },
    [updateOffDay.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateOffDayObj.status = "failed";
      state.updateOffDayObj.errorMessage = message;
    },
  },
});

export default offDaySlice.reducer;

// state
export const offDaySelector = (state) => state.offDay;
