import DownloadIcon from "@mui/icons-material/Download";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import {
  eventResponseSelector,
  getEventResponsesCSV,
} from "../../../services/event/response/response.slice.services";

export default function ResponsesTableRow({ response }) {
  const { values } = useFormikContext();
  const csvRef = useRef();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const { getEventResponsesCSVObj } = useSelector(eventResponseSelector);

  const downloadResponseCSV = () => {
    const userIds = values.userIds.length !== 0 ? values.userIds.map((item) => item.id) : null;

    dispatch(
      getEventResponsesCSV({
        eventId: response.id,
        userIds,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        csvRef.current.link.click();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <>
      <TableRow
        key={response.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "100px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {response.id}
          </Text>
        </TableCell>

        <TableCell>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {response.title}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {response.totalResponses}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <CSVLink
            data={getEventResponsesCSVObj.data ? getEventResponsesCSVObj.data : []}
            filename={`${response.title}_responses.csv`}
            target="_blank"
            ref={csvRef}
          />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={() => downloadResponseCSV()}>
              <DownloadIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

ResponsesTableRow.propTypes = {
  response: PropTypes.shape({
    id: PropTypes.number,
    totalResponses: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};
