import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import Text from "../../../components/text.component";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function RenewalDayDetailLoader() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));

  const renderFieldLoader = (label) => (
    <>
      {isSmallScreen && (
        <LabelContainer>
          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
        </LabelContainer>
      )}

      <FormContainer>
        {!isSmallScreen && (
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
          </LabelContainer>
        )}
        <Box sx={{ display: "flex", flex: 1 }}>
          <Box sx={{ width: "100%" }}>
            <SkeletonLoader height="41.56px" width="100%" />
          </Box>
        </Box>
      </FormContainer>
    </>
  );

  return (
    <>
      <Text variant="screenLabel">Profile</Text>
      <Grid container sx={{ paddingY: "20px" }} spacing={3}>
        <Grid item xs={12}>
          {renderFieldLoader("RenewalDays")}
        </Grid>
      </Grid>
    </>
  );
}
