import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq, putReq, delReq } from "../../api-services";

export const getEventQuestions = createAsyncThunk(
  "merchants/events/questions/list",
  async (payload) => {
    const { eventId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${eventId}/questions`,
    );
    return response;
  },
);

export const getEventSpecificQuestion = createAsyncThunk(
  "merchants/events/questions/{question_id}",
  async (payload) => {
    const { eventId, questionId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${eventId}/questions/${questionId}`,
    );
    return response;
  },
);

export const publishEventQuestions = createAsyncThunk(
  "merchants/events/questions/publish",
  async (payload) => {
    const { eventId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${eventId}/questions/publish`,
    );
    return response;
  },
);

export const createEventQuestions = createAsyncThunk(
  "merchants/events/questions/create",
  async (payload) => {
    const { eventId, items } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${eventId}/questions`,
      { items },
    );
    return response;
  },
);

export const updateEventQuestion = createAsyncThunk(
  "merchants/events/questions/update",
  async (payload) => {
    const { eventId, questionId, question, responses, openEnded } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${eventId}/questions/${questionId}`,
      {
        question,
        responses,
        open_ended: openEnded,
      },
    );
    return response;
  },
);

export const deleteEventQuestion = createAsyncThunk(
  "merchants/events/questions/delete",
  async (payload) => {
    const { eventId, questionId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${eventId}/questions/${questionId}`,
    );
    return response;
  },
);

const eventQuestionSlice = createSlice({
  name: "eventQuestion",
  initialState: {
    getEventQuestionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventSpecificQuestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    publishEventQuestionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createEventQuestionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateEventQuestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteEventQuestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    setReduxStoreEventQuestions: (state, action) => {
      const { payload } = action;
      state.reduxStoreEventQuestions = payload;
    },
  },
  extraReducers: {
    [getEventQuestions.pending]: (state) => {
      state.getEventQuestionsObj.status = "pending";
    },
    [getEventQuestions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventQuestionsObj.status = "succeeded";
      state.getEventQuestionsObj.data = data;
      state.getEventQuestionsObj.successMessage = message;
    },
    [getEventQuestions.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventQuestionsObj.status = "failed";
      state.getEventQuestionsObj.errorMessage = message;
    },
    [getEventSpecificQuestion.pending]: (state) => {
      state.getEventSpecificQuestionObj.status = "pending";
    },
    [getEventSpecificQuestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventSpecificQuestionObj.status = "succeeded";
      state.getEventSpecificQuestionObj.data = data;
      state.getEventSpecificQuestionObj.successMessage = message;
    },
    [getEventSpecificQuestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventSpecificQuestionObj.status = "failed";
      state.getEventSpecificQuestionObj.errorMessage = message;
    },
    [publishEventQuestions.pending]: (state) => {
      state.publishEventQuestionsObj.status = "pending";
    },
    [publishEventQuestions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.publishEventQuestionsObj.status = "succeeded";
      state.publishEventQuestionsObj.data = data;
      state.publishEventQuestionsObj.successMessage = message;
    },
    [publishEventQuestions.rejected]: (state, action) => {
      const { message } = action.error;

      state.publishEventQuestionsObj.status = "failed";
      state.publishEventQuestionsObj.errorMessage = message;
    },
    [createEventQuestions.pending]: (state) => {
      state.createEventQuestionsObj.status = "pending";
    },
    [createEventQuestions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createEventQuestionsObj.status = "succeeded";
      state.createEventQuestionsObj.data = data;
      state.createEventQuestionsObj.successMessage = message;
    },
    [createEventQuestions.rejected]: (state, action) => {
      const { message } = action.error;

      state.createEventQuestionsObj.status = "failed";
      state.createEventQuestionsObj.errorMessage = message;
    },
    [updateEventQuestion.pending]: (state) => {
      state.updateEventQuestionObj.status = "pending";
    },
    [updateEventQuestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateEventQuestionObj.status = "succeeded";
      state.updateEventQuestionObj.data = data;
      state.updateEventQuestionObj.successMessage = message;
    },
    [updateEventQuestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateEventQuestionObj.status = "failed";
      state.updateEventQuestionObj.errorMessage = message;
    },
    [deleteEventQuestion.pending]: (state) => {
      state.deleteEventQuestionObj.status = "pending";
    },
    [deleteEventQuestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteEventQuestionObj.status = "succeeded";
      state.deleteEventQuestionObj.data = data;
      state.deleteEventQuestionObj.successMessage = message;
    },
    [deleteEventQuestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteEventQuestionObj.status = "failed";
      state.deleteEventQuestionObj.errorMessage = message;
    },
  },
});

export default eventQuestionSlice.reducer;

// state
export const eventQuestionSelector = (state) => state.eventQuestion;

export const { setReduxStoreEventQuestions } = eventQuestionSlice.actions;
