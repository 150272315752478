import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import Text from "../text.component";

function FormFieldDropdown({ name, width, isTransparent, itemList, placeholder }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values } = useFormikContext();
  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <FormControl
      sx={{
        width,
        "& .MuiOutlinedInput-input": {
          padding: "10px",
        },
      }}
    >
      <Select
        onBlur={() => setFieldTouched(name)}
        value={values[name] === null ? "" : values[name]}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
        error={showError}
        displayEmpty
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        }}
      >
        <MenuItem value="" disabled>
          <Text type="GreyColor">{placeholder}</Text>
        </MenuItem>
        {itemList.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error={showError}>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

FormFieldDropdown.defaultProps = {
  width: "100%",
  isTransparent: false,
  placeholder: "",
};

FormFieldDropdown.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
};

export default FormFieldDropdown;
