import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import {
  profileSelector,
  updateProfileDetail,
} from "../../../services/profile/profile.slice.services";
import BankDetailLoader from "../loader/bank-detail-loader.component";
import BankDetailEditForm from "./bank-detail-edit-form.component";
import BankNameEditForm from "./bank-name-edit-form.component";

const bankNameValidationSchema = Yup.object().shape({
  bankName: Yup.string().required().label("Bank Name"),
  updateType: Yup.string().required(),
});

const accountNoValidationSchema = Yup.object().shape({
  accountNo: Yup.number().required().label("Account Number"),
  updateType: Yup.string().required(),
});

const accountHolderNameValidationSchema = Yup.object().shape({
  accountHolderName: Yup.string().required().label("Account holder name"),
  updateType: Yup.string().required(),
});

export default function BankDetail() {
  const { getProfileDetailObj } = useSelector(profileSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [bankNameEditing, setBankNameEditing] = useState(false);
  const [accountNoEditing, setAccountNoEditing] = useState(false);
  const [accountHolderNameEditing, setAccountHolderNameEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onUpdateDetail = (values) => {
    setIsLoading(true);
    dispatch(updateProfileDetail(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (values.updateType === "bankName") {
          setBankNameEditing(false);
        }
        if (values.updateType === "accountNo") {
          setAccountNoEditing(false);
        }
        if (values.updateType === "accountHolderName") {
          setAccountHolderNameEditing(false);
        }
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (getProfileDetailObj.status === "succeeded") {
      return (
        <>
          <Text variant="screenLabel"> Bank Details</Text>
          <Grid container sx={{ paddingY: "20px" }} spacing={3}>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "bankName",
                  bankName: getProfileDetailObj.data.bankDetails.name,
                }}
                validationSchema={bankNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <BankNameEditForm
                  name="bankName"
                  placeholder="Enter bank name"
                  label="Bank Name"
                  isEditing={bankNameEditing}
                  setIsEditing={setBankNameEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "accountNo",
                  accountNo: getProfileDetailObj.data.bankDetails.accountNo,
                }}
                validationSchema={accountNoValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <BankDetailEditForm
                  name="accountNo"
                  placeholder="Enter account number"
                  label="Account Number"
                  isEditing={accountNoEditing}
                  setIsEditing={setAccountNoEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "accountHolderName",
                  accountHolderName: getProfileDetailObj.data.bankDetails.accountHolderName,
                }}
                validationSchema={accountHolderNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <BankDetailEditForm
                  name="accountHolderName"
                  placeholder="Enter account holder name"
                  label="Account Holder Name"
                  isEditing={accountHolderNameEditing}
                  setIsEditing={setAccountHolderNameEditing}
                />
              </Form>
            </Grid>
          </Grid>
        </>
      );
    }
    return <BankDetailLoader />;
  };

  return (
    <Grid item xs={12}>
      <BackdropLoading isLoading={isLoading} />
      {renderContent()}
    </Grid>
  );
}
