import { Box, Button, Grid, styled, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  businessSelector,
  getBusinessAmenities,
  getBusinessDetail,
  removeBusinessImage,
  uploadBusinessImage,
} from "../../../services/business/business.slice.services";
import BusinessEditSideMenu from "../components/business-edit-side-menu.component";
import EditBusinessDetailForm from "../components/edit-business-detail-form.component";
import EditImageForm from "../components/edit-image-form.component";

const imageValidationSchema = Yup.object().shape({
  image: Yup.string().required().label("Image Path"),
});

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.primary,
    height: "3px",
  },
  borderBottom: `1px solid ${theme.palette.colors.brand.secondary}`,
  width: "100%",
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.secondary,
  },
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

export default function BusinessEditScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const location = useLocation();
  const businessId = new URLSearchParams(location.search).get("businessId");
  const editType = new URLSearchParams(location.search).get("type");
  const { getBusinessDetailObj } = useSelector(businessSelector);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getTab = () => {
    if (editType === "detail") {
      return 1;
    }

    return 0;
  };

  const handleChangeTab = (event, newValue) => {
    if (newValue === 0) {
      history.push({
        pathname: routes.BUSINESS_EDIT,
        search: `type=image&businessId=${businessId}`,
      });
    }
    if (newValue === 1) {
      history.push({
        pathname: routes.BUSINESS_EDIT,
        search: `type=detail&businessId=${businessId}`,
      });
    }
  };

  const onUploadImage = (values) => {
    setIsLoading(true);
    dispatch(uploadBusinessImage(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onRemoveImage = (imageId) => {
    if (getBusinessDetailObj.data.images.length === 1) {
      createSnackBar({
        message: "Must have at least one image",
        type: "error",
      });
      return;
    }
    setIsLoading(true);
    dispatch(removeBusinessImage({ businessId, imageId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getBusinessAmenities()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });

    dispatch(getBusinessDetail(businessId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <BackdropLoading isLoading={isLoading} />
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text variant="screenLabel">Edit Business</Text>
          <Button
            onClick={() => history.push(routes.BUSINESS_LIST)}
            sx={{
              textTransform: "none",
              padding: "0px",
              ":hover": { backgroundColor: "transparent" },
            }}
          >
            <Text>Back</Text>
          </Button>
        </SpaceBetweenBox>
      </Grid>
      {isSmallScreen ? (
        <AntTabs variant="scrollable" value={getTab()} onChange={handleChangeTab}>
          <AntTab label={<Text>Image</Text>} />
          <AntTab label={<Text>Business Details</Text>} />
        </AntTabs>
      ) : (
        <Grid item sx={{ width: "300px" }}>
          <BusinessEditSideMenu />
        </Grid>
      )}

      <Grid item sx={{ display: "flex", flex: 1 }}>
        <WhiteBgCardContainer>
          {editType === "image" && (
            <Form
              validationSchema={imageValidationSchema}
              initialValues={{ image: "", businessId }}
              onSubmit={onUploadImage}
            >
              <EditImageForm name="image" onRemoveImage={onRemoveImage} />
            </Form>
          )}
          {editType === "detail" && <EditBusinessDetailForm />}
        </WhiteBgCardContainer>
      </Grid>
    </Grid>
  );
}
