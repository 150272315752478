import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function UserEventsDetailTableRow({ item }) {
  return (
    <>
      <TableRow key={item.id}>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {item.location.name}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {item.date}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {item.time}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {item.event.title}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {item.promoCode ? item.promoCode : "-"}
          </Text>
        </TableCell>
        <TableCell>
          <Text
            variant="body2"
            type="TableText"
            noWrap={true}
            sx={{ textTransform: "capitalize", textAlign: "center" }}
          >
            {item.status}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

UserEventsDetailTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    time: PropTypes.string,
    date: PropTypes.string,
    event: PropTypes.shape({
      title: PropTypes.string,
    }),
    location: PropTypes.shape({
      name: PropTypes.string,
    }),
    promoCode: PropTypes.string,
  }).isRequired,
};
