import React from "react";
import { Box, Button, Grid, styled, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";

const ButtonContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "10px",
  color: theme.palette.colors.brand.primary,
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  textTransform: "none",
  width: "100%",
  overflow: "hidden",
  padding: "0px",
  ":hover": {
    backgroundColor: "none",
  },
}));

export default function BusinessEditSideMenu() {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const businessId = new URLSearchParams(location.search).get("businessId");
  const editType = new URLSearchParams(location.search).get("type");

  return (
    <WhiteBgCardContainer>
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <StyledButton
            onClick={() =>
              history.push({
                pathname: routes.BUSINESS_EDIT,
                search: `type=image&businessId=${businessId}`,
              })
            }
          >
            <ButtonContainer
              sx={{
                backgroundColor:
                  editType === "image"
                    ? theme.palette.colors.bg.primary
                    : theme.palette.colors.bg.secondary,
              }}
            >
              <Text
                sx={{ fontSize: theme.fonts.fontSizes.size20 }}
                type={editType === "image" ? "BrandColor" : null}
              >
                Images
              </Text>
            </ButtonContainer>
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <StyledButton
            onClick={() =>
              history.push({
                pathname: routes.BUSINESS_EDIT,
                search: `type=detail&businessId=${businessId}`,
              })
            }
          >
            <ButtonContainer
              sx={{
                backgroundColor:
                  editType === "detail"
                    ? theme.palette.colors.bg.primary
                    : theme.palette.colors.bg.secondary,
              }}
            >
              <Text
                type={editType === "detail" ? "BrandColor" : null}
                sx={{ fontSize: theme.fonts.fontSizes.size20 }}
              >
                Business Details
              </Text>
            </ButtonContainer>
          </StyledButton>
        </Grid>
      </Grid>
    </WhiteBgCardContainer>
  );
}
