import { List, ListItem, TableCell, TableRow, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function UserClassesDetailTableRow({ userClass }) {
  const theme = useTheme();
  return (
    <>
      <TableRow
        key={userClass.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell>
          <Text
            variant="body2"
            type="TableText"
            noWrap={true}
            sx={{ textTransform: "capitalize", textAlign: "center" }}
          >
            {userClass.type.replaceAll("_", " ")}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {userClass.location.name}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {userClass.date}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {userClass.time}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {userClass.session?.class.category.label
              ? userClass.session?.class.category.label
              : "-"}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {userClass.session?.class.name ? userClass.session?.class.name : "-"}
          </Text>
        </TableCell>
        <TableCell>
          <List sx={{ listStyleType: "disc", padding: 0, pl: 3, textAlign: "center" }}>
            {userClass.type.includes("merchant_class_session") &&
            userClass.session?.trainers?.length > 0 ? (
              userClass.session?.trainers.map((trainer) => (
                <ListItem
                  key={trainer.id}
                  sx={{
                    display: "list-item",
                    padding: 0,
                    paddingBottom: "5px",
                    color: theme.palette.colors.text.table,
                  }}
                >
                  {`${trainer.firstName} ${trainer.lastName}`}
                </ListItem>
              ))
            ) : (
              <Text variant="body2" type="TableText" noWrap={true}>
                -
              </Text>
            )}
          </List>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {userClass.promoCode ? userClass.promoCode : "-"}
          </Text>
        </TableCell>
        <TableCell>
          <Text
            variant="body2"
            type="TableText"
            noWrap={true}
            sx={{ textTransform: "capitalize", textAlign: "center" }}
          >
            {userClass.status}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

UserClassesDetailTableRow.propTypes = {
  userClass: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    date: PropTypes.string,
    status: PropTypes.string,
    time: PropTypes.string,
    promoCode: PropTypes.string,
    location: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      lat: PropTypes.string,
      long: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    session: PropTypes.shape({
      trainers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          imagePath: PropTypes.string,
        }),
      ),
      class: PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.shape({
          label: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};
