import React from "react";
import PropTypes from "prop-types";
import { Box, FormControl, MenuItem, Select, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";
import FormPhoneNumber from "../../../components/forms/form-phone-number.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import FormAddressAutoComplete from "../../../components/forms/form-address-auto-complete.component";
import FormFieldDropdown from "../../../components/forms/form-field-dropdown.component";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";
import FormSelect from "./form-select.component";
import FormCheckBox from "../../../components/forms/form-check-box.component";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function FormFieldLabel({ name, label, placeholder, itemList, disabled }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const { values, setFieldValue } = useFormikContext();

  const renderField = () => {
    if (label === "Category") {
      return <FormFieldDropdown name={name} placeholder={placeholder} itemList={itemList} />;
    }
    if (label === "Hourly Booking") {
      return <FormSelect name={name} placeholder={placeholder} itemList={itemList} />;
    }
    if (label === "About Us") {
      return <FormFieldTextMultiline name={name} placeholder={placeholder} rows={5} />;
    }
    if (label === "Address") {
      return (
        <FormAddressAutoComplete
          name={name}
          placeholder={placeholder}
          latName="locationLat"
          longName="locationLong"
          cityName="city"
          stateName="state"
          postCodeName="postcode"
          countryName="country"
        />
      );
    }
    if (label === "Contact Number") {
      return <FormPhoneNumber name={name} placeholder={placeholder} />;
    }

    if (label === "Amenity" || label === "Tags") {
      return <FormMultipleSelect name={name} placeholder={placeholder} itemList={itemList} />;
    }

    if (label === "Status") {
      return (
        <FormControl sx={{ minWidth: "120px" }} size="small">
          <Select
            sx={{
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
              backgroundColor: theme.palette.colors.bg.primary,
            }}
            disabled={values[name] === "rejected_admin" || disabled}
            value={values[name]}
            onChange={(e) => setFieldValue(name, e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {values[name] === "rejected_admin" && (
              <MenuItem disabled value="rejected_admin">
                <Text type="GreyColor">Rejected</Text>
              </MenuItem>
            )}
            <MenuItem value="publish">
              <Text>Publish</Text>
            </MenuItem>
            <MenuItem value="draft">
              <Text>Draft</Text>
            </MenuItem>
          </Select>
        </FormControl>
      );
    }

    if (label === "Exclusive Referrals") {
      return <FormCheckBox name={name} />;
    }

    return <FormFieldText name={name} placeholder={placeholder} disabled={disabled} />;
  };

  return (
    <>
      {isSmallScreen && (
        <LabelContainer>
          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
        </LabelContainer>
      )}

      <FormContainer>
        {!isSmallScreen && (
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
          </LabelContainer>
        )}
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Box sx={{ width: "100%" }}>{renderField()}</Box>
        </Box>
      </FormContainer>
    </>
  );
}

FormFieldLabel.defaultProps = {
  itemList: [],
  disabled: false,
};

FormFieldLabel.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.shape({})),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
