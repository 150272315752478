import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";

import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  createPromoCode,
  promoCodeSelector,
} from "../../../services/promo-code/promo-code.slice.services";
import FormFieldLabel from "../../business/components/form-field-label.component";
import BusinessListAutoComplete from "../components/business-list-autocomplete.component";
import ClassListAutoComplete from "../components/class-list-autocomplete.component";
import FormSelect from "../components/form-select.component";
import FormTimePicker from "../components/form-time-picker.component";
import PackagePlansListAutoComplete from "../components/package-plans-list-autocomplete.component";
import SubscriptionPlansListAutoComplete from "../components/subscription-plans-list-autocomplete.component";
import UserListPhoneNumber from "../components/user-list-phone-number.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function PromoCodeCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { createPromoCodeObj } = useSelector(promoCodeSelector);
  const [selectedType, setSelectedType] = useState("");
  const [isUserSpecific, setIsUserSpecific] = useState(false);
  const [isBusinessSpecific, setIsBusinessSpecific] = useState(false);
  const [isSubscriptionPlanSpecific, setIsSubscriptionPlanSpecific] = useState(false);
  const [isPackagePlanSpecific, setIsPackagePlanSpecific] = useState(false);
  const [isClassSpecific, setIsClassSpecific] = useState(false);

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .nullable()
      .label("Code")
      .length(6, "Code must have exactly 6 alphanumeric character"),
    title: Yup.string().required().label("Title"),
    description: Yup.string().required().label("Description"),
    startDate: Yup.date().required().nullable().label("Start Date"),
    startTime: Yup.date()
      .required()
      .nullable()
      .label("Start Time")
      .when("startDate", {
        is: (startDate) => startDate,
        then: Yup.date()
          .required()
          .nullable()
          .label("Start Time")
          .when("startDate", {
            is: (startDate) => format(startDate, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd"),
            then: Yup.date()
              .required()
              .nullable()
              .label("Start Time")
              .min(new Date(), "Time cannot be in the past"),
          }),
      }),

    endDate: Yup.date()
      .label("End Date")
      .min(Yup.ref("startDate"), "End date can't be earlier than start date")
      .required("End date is required")
      .nullable(),
    endTime: Yup.date()
      .label("End Time")
      .required()
      .when(["startDate", "endDate"], (startDate, endDate) => {
        try {
          if (format(endDate, "yyyy-MM-dd") === format(startDate, "yyyy-MM-dd")) {
            return Yup.date()
              .label("End Time")
              .min(
                Yup.ref("startTime"),
                "End time can't be earlier than start time on the same day",
              )
              .required()
              .nullable();
          }
          return Yup.date().label("End Time").required().nullable();
        } catch (err) {
          return Yup.date().label("End Time").required().nullable();
        }
      }),
    type: Yup.string().label("Type").required(),
    discount: Yup.number()
      .required()
      .when("type", {
        is: "percentage",
        then: Yup.number().integer().min(0).max(100).required(),
      })
      .when("type", {
        is: "fixed amount",
        then: Yup.number().min(1).required(),
      })
      .label("Discount"),
    maxCapAmount: Yup.number()
      .label("Maximum Cap Amount")
      .when("type", {
        is: "percentage",
        then: Yup.number().min(0).required(),
      }),
    maxNoClaims: Yup.number().min(0).required().label("Max number of claims"),
    maxNoClaimsPerUser: Yup.number().min(0).required().label("Max number of claims per user"),
    minSpentAmount: Yup.number().min(0).required().label("Minimum spent amount"),
    userSpecific: Yup.boolean().label("Users Specific").required(),
    businessSpecific: Yup.boolean().label("Business Specific").required(),
    hourlyBookingSpecific: Yup.boolean().label("Hourly Booking Specific").required(),
    subscriptionPlanSpecific: Yup.boolean().label("Subscription Plan Specific").required(),
    packagePlanSpecific: Yup.boolean().label("Package Plan Specific").required(),
    classSpecific: Yup.boolean().label("Fitness Class Specific").required(),
    usersList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("userSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific User List"),
    businessesList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("businessSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific Business List"),

    subscriptionPlansList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("subscriptionPlanSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific Subscription Plan List"),

    packagePlansList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("packagePlanSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific Package Plan List"),

    classesList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("classSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific Fitness Class List"),
  });
  const yesNoOption = [
    { id: 1, label: "No", value: false },
    { id: 2, label: "Yes", value: true },
  ];

  const onCreatePromoCode = (values) => {
    const startAt = `${format(values.startDate, "yyyy-MM-dd")} ${format(
      values.startTime,
      "HH:mm:00",
    )}`;
    const endAt = `${format(values.endDate, "yyyy-MM-dd")} ${format(values.endTime, "HH:mm:00")}`;
    if (!isUserSpecific) {
      values.userSpecific = false;
      values.usersList = [];
    } else {
      values.userSpecific = true;
      values.usersList = values.usersList.map((item) => item.id);
    }
    if (!isBusinessSpecific) {
      values.businessesList = [];
    } else {
      values.businessesList = values.businessesList.map((item) => item.id);
    }
    if (!isSubscriptionPlanSpecific) {
      values.subscriptionPlansList = [];
    } else {
      values.subscriptionPlansList = values.subscriptionPlansList.map((item) => item.id);
    }
    if (!isPackagePlanSpecific) {
      values.packagePlansList = [];
    } else {
      values.packagePlansList = values.packagePlansList.map((item) => item.id);
    }
    if (!isClassSpecific) {
      values.classesList = [];
    } else {
      values.classesList = values.classesList.map((item) => item.id);
    }
    if (selectedType === "fixed amount") values.maxCapAmount = values.discount;
    setIsLoading(true);
    dispatch(createPromoCode({ startAt, endAt, ...values })).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.PROMO_CODES_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      <Formik
        validationSchema={validationSchema}
        onSubmit={onCreatePromoCode}
        initialValues={{
          description: "",
          discount: "",
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null,
          maxCapAmount: "",
          maxNoClaims: "",
          maxNoClaimsPerUser: "",
          minSpentAmount: "",
          title: "",
          type: "",
          userSpecific: false,
          usersList: [],
          businessSpecific: false,
          businessesList: [],
          hourlyBookingSpecific: false,
          subscriptionPlanSpecific: false,
          subscriptionPlansList: [],
          packagePlanSpecific: false,
          packagePlansList: [],
          classSpecific: false,
          classesList: [],
        }}
      >
        <Form>
          <Grid
            container
            spacing={5}
            sx={{
              padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
              paddingY: theme.dimensions.ScreenPaddingY,
            }}
          >
            <Grid item xs={12}>
              <SpaceBetweenBox>
                <Text variant="screenLabel">Create Promo Code</Text>
                <Button
                  onClick={() => history.push(routes.PROMO_CODES_LIST)}
                  sx={{
                    textTransform: "none",
                    padding: "0px",
                    ":hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Text>Back</Text>
                </Button>
              </SpaceBetweenBox>
            </Grid>

            <Grid item xs={12}>
              <WhiteBgCardContainer>
                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={12}>
                    <Text variant="screenLabel">Promo Code</Text>
                  </Grid>
                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                          Code (Optional)
                        </Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Code (Optional)
                          </Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormFieldTextWithCounter
                            maxLength={6}
                            name="code"
                            placeholder="Autogenerated if left empty"
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <FormFieldLabel
                      name="title"
                      placeholder="Enter Promo Code title"
                      label="Promo Code Title"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Description</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Description</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormFieldTextMultiline
                            placeholder="Enter promo code description"
                            name="description"
                            rows={5}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <Text variant="screenLabel">Promo Period</Text>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Date</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Date</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormDatePicker name="startDate" width="100%" maxDays={null} />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Date</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Date</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormDatePicker name="endDate" width="100%" maxDays={null} />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormTimePicker name="startTime" width="100%" />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormTimePicker name="endTime" width="100%" />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <Text variant="screenLabel">Promo Details</Text>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Type</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Type</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormSelect
                            itemList={[
                              { label: "percentage", id: "1", value: "percentage" },
                              { label: "fixed amount", id: "2", value: "fixed amount" },
                            ]}
                            name="type"
                            placeholder="Select Type"
                            setFormVariable={setSelectedType}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                          Discount {selectedType === "percentage" && "%"}
                        </Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Discount {selectedType === "percentage" && "%"}
                          </Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          {selectedType === "percentage" ? (
                            <FormFieldText name="discount" placeholder="Percentage" type="number" />
                          ) : (
                            <FormPrice placeholder="00.00" name="discount" />
                          )}
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                          Max Cap Amount
                        </Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Max Cap Amount
                          </Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormPrice
                            placeholder="00.00"
                            name="maxCapAmount"
                            disabled={selectedType === "fixed amount"}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                          Max No. Claims
                        </Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Max No. Claims
                          </Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormFieldText
                            name="maxNoClaims"
                            placeholder="Max no of claims"
                            type="number"
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                          Max No. Claims Per User
                        </Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Max No. Claims Per User
                          </Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormFieldText
                            name="maxNoClaimsPerUser"
                            placeholder="Max no of claims per user"
                            type="number"
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                          Min Spent Amount
                        </Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Min Spent Amount
                          </Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormPrice placeholder="Minimum spent amount" name="minSpentAmount" />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={3}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Hourly Booking Specific
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Hourly Booking Specific
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelect
                              itemList={yesNoOption}
                              name="hourlyBookingSpecific"
                              placeholder="For specific hourly booking"
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={3}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            User Specific
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              User Specific
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelect
                              itemList={yesNoOption}
                              name="userSpecific"
                              placeholder="For specific user"
                              setFormVariable={(value) => setIsUserSpecific(value)}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={9}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>User List</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>User List</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <UserListPhoneNumber
                              name="usersList"
                              placeholder="User's phone number"
                              disabled={!isUserSpecific}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={3}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Business Specific
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Business Specific
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelect
                              itemList={yesNoOption}
                              name="businessSpecific"
                              placeholder="For specific business"
                              setFormVariable={(value) => setIsBusinessSpecific(value)}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={9}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Business List
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Business List
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <BusinessListAutoComplete
                              name="businessesList"
                              placeholder="Businesses"
                              disabled={!isBusinessSpecific}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={3}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Subscription Plan Specific
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Subscription Plan Specific
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelect
                              itemList={yesNoOption}
                              name="subscriptionPlanSpecific"
                              placeholder="For specific subscription plan"
                              setFormVariable={(value) => setIsSubscriptionPlanSpecific(value)}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={9}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Subscription Plan List
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Subscription Plan List
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <SubscriptionPlansListAutoComplete
                              name="subscriptionPlansList"
                              placeholder="Subscription Plans"
                              disabled={!isSubscriptionPlanSpecific}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={3}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Package Plan Specific
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Package Plan Specific
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelect
                              itemList={yesNoOption}
                              name="packagePlanSpecific"
                              placeholder="For specific package plan"
                              setFormVariable={(value) => setIsPackagePlanSpecific(value)}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={9}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Package Plan List
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Package Plan List
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <PackagePlansListAutoComplete
                              name="packagePlansList"
                              placeholder="Package Plans"
                              disabled={!isPackagePlanSpecific}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={3}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Fitness Class Specific
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Fitness Class Specific
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelect
                              itemList={yesNoOption}
                              name="classSpecific"
                              placeholder="For specific fitness class"
                              setFormVariable={(value) => setIsClassSpecific(value)}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={9}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Fitness Class List
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Fitness Class List
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <ClassListAutoComplete
                              name="classesList"
                              placeholder="Fitness Classes"
                              disabled={!isClassSpecific}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </WhiteBgCardContainer>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
              <FormSubmitButton width="200px" isLoading={createPromoCodeObj.status === "pending"}>
                <Text type="WhiteColor">Create</Text>
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Grid>
  );
}
