import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { getStaff } from "../../../services/staff/staff-slice.service";
import StaffDetail from "../components/staff-detail.component";

export default function StaffEditScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const location = useLocation();
  const staffId = new URLSearchParams(location.search).get("staffId");
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    dispatch(getStaff({ staffId })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <Grid item sx={{ display: "flex", flex: 1 }}>
        <StaffDetail />
      </Grid>
    </Grid>
  );
}
