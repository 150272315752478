import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Box, Drawer, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PaddedView from "../../components/padded-view.component";
import Spacer from "../../components/spacer.component";
import Text from "../../components/text.component";
import KatchLogo from "../../images/katch-logo.png";
import routes from "../../navigation/routes";
import { profileSelector } from "../../services/profile/profile.slice.services";
import DrawerItems from "./drawer-items.component";
import { isMerchantStaff } from "../utils";
import { profileStaffSelector } from "../../services/staff/profile/profile.slice.services";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: "40px",
  width: "40px",
  color: theme.palette.colors.brand.primary,
}));

const AppBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: theme.dimensions.PCMaxWidth,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: theme.dimensions.MobilePadding,
  justifyContent: "space-between",
}));

function MobileDrawer() {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down("900") && theme.breakpoints.up("sm"));
  const { getProfileDetailObj } = useSelector(profileSelector);
  const { getStaffProfileDetailObj } = useSelector(profileStaffSelector);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <AppBarContainer>
      <Link to={{ pathname: routes.HOME }} style={{ height: "35px" }}>
        <img src={KatchLogo} height="35px" alt="katch-logo" />
      </Link>
      <Box>
        <StyledIconButton
          onClick={() => {
            handleDrawerToggle();
          }}
          size="large"
        >
          <MenuIcon />
        </StyledIconButton>
        <Drawer
          anchor="right"
          variant="temporary"
          open={openDrawer}
          onClose={handleDrawerToggle}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: isTablet ? "35%" : "80%",
              minWidth: "300px",
              backgroundColor: theme.palette.colors.bg.white,
            },
          }}
        >
          <Box
            sx={{
              height: "200px",
              background: `linear-gradient(to bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "24px",
            }}
          >
            {isMerchantStaff() ? (
              <Avatar
                sx={{ width: "60px", height: "60px" }}
                src={
                  getStaffProfileDetailObj.status === "succeeded"
                    ? getStaffProfileDetailObj.data.imagePath
                    : null
                }
              />
            ) : (
              <Avatar
                sx={{ width: "60px", height: "60px" }}
                src={
                  getProfileDetailObj.status === "succeeded" ? getProfileDetailObj.data.logo : null
                }
              />
            )}

            <Spacer size="m" position="left" />
            <Text
              type="WhiteColor"
              sx={{
                fontWeight: theme.fonts.fontWeights.bold,
                fontSize: theme.fonts.fontSizes.size16,
              }}
            >
              {isMerchantStaff()
                ? `Welcome, ${
                    getStaffProfileDetailObj.status === "succeeded"
                      ? getStaffProfileDetailObj.data.businessName
                      : "Engage Merchant Staff"
                  }`
                : `Welcome, ${
                    getProfileDetailObj.status === "succeeded"
                      ? getProfileDetailObj.data.businessName
                      : "Engage Merchant"
                  }`}
            </Text>
          </Box>

          <PaddedView multiples={3}>
            <DrawerItems openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
          </PaddedView>
        </Drawer>
      </Box>
    </AppBarContainer>
  );
}

export default MobileDrawer;
