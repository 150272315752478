import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  eventCategorySelector,
  getEventCategoryList,
} from "../../../services/event/category/category.slice.services";
import {
  deleteEvent,
  eventSelector,
  searchEvent,
} from "../../../services/event/event.slice.services";
import BusinessListAutoComplete from "../components/business-list-autocomplete.component";
import EventTableRow from "../components/event-table-row.component";
import SearchBar from "../components/search-bar.component";
import EventTableRowLoader from "../loader/event-table-row-loader.component";
import TableSort from "../../../components/table/table-sort.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  businesses: Yup.array().label("Businesses").nullable(),
  categories: Yup.array().label("Categories").nullable(),
});

export default function EventListScreen() {
  const theme = useTheme();
  const formRef = useRef();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [businesses, setBusinesses] = useState(null);
  const [categories, setCategories] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const { searchEventObj, deleteEventObj } = useSelector(eventSelector);
  const { getEventCategoryListObj } = useSelector(eventCategorySelector);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedEventId, setSelectedEventId] = useState("");
  const columnMapping = {
    "Event Title": "title",
    "Business Name": "business.name",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);
  const getProcessedCategoryList = () => {
    const itemList = [];
    getEventCategoryListObj.data.map((item) =>
      itemList.push({
        label: item.label,
        id: item.id,
        value: item.id,
      }),
    );
    return itemList;
  };

  const onRefreshEventList = (newPage, keyword, businessIds, categoryIds) => {
    setIsLoading(true);
    setPage(newPage);
    setQ(keyword);
    setBusinesses(businessIds);
    setCategories(categoryIds);
    dispatch(
      searchEvent({
        page: newPage,
        q: keyword,
        businessIds,
        categoryIds,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });

    setIsLoading(false);
  };

  const onHandleDelete = (eventId) => {
    setSelectedEventId(eventId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteEvent({ eventId: selectedEventId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshEventList(page, q, businesses, categories);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const submitFilter = (values) => {
    const businessIds = values.businesses.map((item) => item.id);
    const categoryIds = values.categories;
    onRefreshEventList(1, values.q, businessIds, categoryIds);
  };

  const onChangeSearch = (keyword) => {
    onRefreshEventList(1, keyword, businesses, categories);
  };

  const onPageChange = (e, newPage) => {
    onRefreshEventList(newPage, q, businesses, categories);
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const getProcessedRecord = () => {
    let records = null;

    if (searchEventObj.data && searchEventObj.status === "succeeded") {
      records = { ...searchEventObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (searchEventObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (searchEventObj?.data?.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <TableEmptyBox>
                <Text type="TableText">No Events</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <EventTableRow detail={item} key={item.id} onHandleDelete={onHandleDelete} />
      ));
    }
    return <EventTableRowLoader />;
  };

  useEffect(() => {
    onRefreshEventList(page, q);
    dispatch(getEventCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  if (getEventCategoryListObj.status === "succeeded")
    return (
      <Formik
        innerRef={formRef}
        initialValues={{
          q: q || "",
          businesses: [],
          categories: [],
        }}
        onSubmit={submitFilter}
        validationSchema={validationSchema}
      >
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
            paddingY: theme.dimensions.ScreenPaddingY,
          }}
        >
          <Grid item xs={12}>
            <Text variant="screenLabel">Event List</Text>
          </Grid>
          <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
            <WhiteBgCardContainer>
              <BackdropLoading isLoading={isLoading} />

              <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={1.45}>
                            <Text>Search</Text>
                          </Grid>
                          <Grid item xs={10.55}>
                            <SearchBar
                              placeholder="Search by event title"
                              name="q"
                              searchKeyword={q}
                              setSearchKeyword={onChangeSearch}
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={isSmallScreen ? 12 : 6}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Businesses</Text>
                          </Grid>

                          <Grid item xs={9}>
                            <BusinessListAutoComplete name="businesses" placeholder="Businesses" />
                          </Grid>
                        </FormContainer>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : 6}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Categories</Text>
                          </Grid>
                          <Grid item xs={9}>
                            <FormMultipleSelect
                              itemList={getProcessedCategoryList()}
                              name="categories"
                              placeholder="Categories"
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={2}>
                        <FormSubmitButton>
                          <Text type="WhiteColor">Search</Text>
                        </FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>

              <DeleteConfirmationModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title="Remove Event"
                label="Are you sure you wish to remove this event?"
                isLoading={deleteEventObj.status === "pending"}
                onConfirmClicked={onConfirmDelete}
              />
              <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                <Grid item xs={12}>
                  <TableWrapper>
                    <Table>
                      <TableHeader
                        headerCells={["Event Title", "Business Name", "Category", "Action"]}
                        sortColumn={sortColumn}
                        sortOrder={sortOrder}
                        onSortChange={onSortChange}
                        columnMapping={columnMapping}
                        nonSortableColumns={["Category"]}
                      />
                      <TableBody>{renderTableBody()}</TableBody>
                    </Table>
                  </TableWrapper>
                </Grid>
                <Grid item xs={12}>
                  <CustomFooter>
                    <Pagination
                      sx={{
                        "&& .Mui-selected": {
                          backgroundColor: theme.palette.colors.brand.primary,
                          color: theme.palette.colors.text.white,
                        },
                      }}
                      page={page}
                      shape="rounded"
                      onChange={onPageChange}
                      count={searchEventObj?.data?.pagination.totalPages || totalPages}
                      variant="outlined"
                    />
                  </CustomFooter>
                </Grid>
              </Grid>
            </WhiteBgCardContainer>
          </Grid>
        </Grid>
      </Formik>
    );
}
