import { Box, FormHelperText, useTheme } from "@mui/material";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

dayjs.extend(customParseFormat);

export default function FormTimePicker({
  name,
  disabledHours = [],
  setEndTimeAnHourLater,
  isTransparent,
  width,
  isDisabled = false,
}) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const onChange = (time) => {
    setFieldValue(name, time);
    if (setEndTimeAnHourLater) setFieldValue("endTime", dayjs(time).add(1, "hour"));
  };

  const disabledDateTime = () => ({
    disabledHours: () => disabledHours,
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width }}>
      <TimePicker
        name={name}
        value={values[name]}
        onChange={onChange}
        use12Hours
        format="h a"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        // eslint-disable-next-line react/jsx-no-bind
        disabledTime={disabledDateTime}
        style={{
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
          padding: "10px",
          borderRadius: theme.shape.borderRadius[0],
        }}
        disabled={isDisabled}
        showNow={false}
        allowClear={false}
      />
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
    </Box>
  );
}
FormTimePicker.defaultProps = {
  width: null,
  disabledHours: [],
  isTransparent: false,
  isDisabled: false,
  setEndTimeAnHourLater: false,
};

FormTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  disabledHours: PropTypes.instanceOf(Array),
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
  isDisabled: PropTypes.bool,
  setEndTimeAnHourLater: PropTypes.bool,
};
