import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function PaddedView({ children, multiples, ...props }) {
  return (
    <Box sx={{ padding: multiples * 1, flex: 1 }} {...props}>
      {children}
    </Box>
  );
}

PaddedView.defaultProps = {
  multiples: 1,
  children: null,
};

PaddedView.propTypes = {
  children: PropTypes.node,
  multiples: PropTypes.number,
};

export default PaddedView;
