import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Text from "../../../components/text.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import Form from "../../../components/forms/form.component";
import FormFieldLabel from "../components/form-field-label.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import CreateBusinessImageList from "../components/create-business-image-list.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import {
  businessSelector,
  createBusiness,
  getBusinessAmenities,
} from "../../../services/business/business.slice.services";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import routes from "../../../navigation/routes";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  tags: Yup.array().of(Yup.string()).required().label("Tags"),
  description: Yup.string().required().label("About Us"),
  location: Yup.string().required().label("Address"),
  locationLat: Yup.string().required().label("Address"),
  locationLong: Yup.string().required().label("Address"),
  city: Yup.string().label("Address"),
  state: Yup.string().label("Address"),
  postcode: Yup.string().label("Address"),
  country: Yup.string().label("Address"),
  contactNo: Yup.string()
    .required()
    .label("Phone number")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{8}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid.",
    ),
  amenitiesId: Yup.array().of(Yup.number()).label("Amenities"),
  images: Yup.array().of(Yup.object().shape({})).min(1).label("Images"),
  blockHourlyBooking: Yup.boolean().required().label("Hourly Booking"),
  email: Yup.string().nullable().email().label("Email"),
  exclusiveReferrals: Yup.boolean().required().label("Exclusive Referrals"),
});

export default function BusinessCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getBusinessAmenitiesObj } = useSelector(businessSelector);

  const onCreateBusiness = (values) => {
    values.blockHourlyBooking = values.blockHourlyBooking === "true";
    setIsLoading(true);
    dispatch(createBusiness(values)).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.BUSINESS_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getBusinessAmenities()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  if (getBusinessAmenitiesObj.status === "succeeded")
    return (
      <Grid
        container
        spacing={5}
        sx={{
          padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        <BackdropLoading isLoading={isLoading} />
        <Form
          validationSchema={validationSchema}
          onSubmit={onCreateBusiness}
          initialValues={{
            name: "",
            tags: [],
            description: "",
            location: "",
            locationLat: "",
            locationLong: "",
            city: "",
            state: "",
            postcode: "",
            amenitiesId: [],
            images: [],
            contactNo: "",
            blockHourlyBooking: "",
            email: "",
            exclusiveReferrals: false,
          }}
        >
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Text variant="screenLabel">Create Business</Text>
              <Button
                onClick={() => history.goBack()}
                sx={{
                  textTransform: "none",
                  padding: "0px",
                  ":hover": { backgroundColor: "transparent" },
                }}
              >
                <Text>Back</Text>
              </Button>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={12}>
            <WhiteBgCardContainer>
              <Grid container spacing={isMobile ? 1 : 3}>
                <Grid item xs={12}>
                  <Text variant="screenLabel">Business Details</Text>
                </Grid>
                <Grid item xs={12}>
                  <FormFieldLabel
                    name="name"
                    placeholder="Enter business name"
                    label="Business Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldLabel
                    name="tags"
                    label="Tags"
                    placeholder="Tags"
                    itemList={[
                      { label: "Fitness", value: "fitness" },
                      { label: "Health", value: "health" },
                      { label: "Wellness", value: "wellness" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldLabel
                    name="contactNo"
                    placeholder="Enter business contact number"
                    label="Contact Number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldLabel
                    name="email"
                    placeholder="Enter business email (Optional)"
                    label="Email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldLabel
                    name="description"
                    placeholder="Brief description about your business"
                    label="About Us"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldLabel
                    name="location"
                    placeholder="Enter business address"
                    label="Address"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldLabel
                    label="Amenity"
                    name="amenitiesId"
                    placeholder="Select amenities"
                    itemList={getBusinessAmenitiesObj.data.map((item) => ({
                      value: item.id,
                      label: item.amenity.replace("_", " "),
                    }))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldLabel
                    name="exclusiveReferrals"
                    label="Exclusive Referrals"
                    placeholder="Exclusive Referrals"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldLabel
                    name="blockHourlyBooking"
                    label="Hourly Booking"
                    placeholder="Hourly Booking"
                    itemList={[
                      { label: "Blocked", id: 1, value: "true" },
                      { label: "Not blocked", id: 2, value: "false" },
                    ]}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CreateBusinessImageList name="images" />
                </Grid>
              </Grid>
            </WhiteBgCardContainer>
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
            <FormSubmitButton width="200px">
              <Text type="WhiteColor">Save</Text>
            </FormSubmitButton>
          </Grid>
        </Form>
      </Grid>
    );
}
