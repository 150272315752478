import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  styled,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import Text from "../../../components/text.component";
import AppointmentEditModal from "./appointment-edit-modal.component";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const editValidationSchema = Yup.object().shape({
  startDate: Yup.date().label("Start date").nullable().required(),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startDate", {
      is: (startDate) => startDate,
      then: Yup.date()
        .min(Yup.ref("startDate"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
});

export default function AppointmentTableRow({ appointment, onHandleCancel, onUpdateAppointment }) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  return (
    <>
      <Form
        initialValues={{
          appointmentId: appointment.id,
          startDate: moment(appointment.start_at).toDate(),
          endDate: moment(appointment.end_at).toDate(),
          startTime: dayjs(appointment.start_at),
          endTime: dayjs(appointment.end_at),
        }}
        validationSchema={editValidationSchema}
        onSubmit={onUpdateAppointment}
      >
        <AppointmentEditModal showModal={showEditModal} setShowModal={setShowEditModal} />
        <TableRow
          key={appointment.id}
          sx={{
            "& .MuiTableCell-root": {
              borderBottom: "no-set",
            },
          }}
        >
          <TableCell sx={{ width: "200px" }}>
            <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
              {appointment.user.name}
            </Text>
          </TableCell>

          <TableCell sx={{ width: "200px" }}>
            <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
              {appointment.user.phone}
            </Text>
          </TableCell>

          <TableCell sx={{ width: "200px" }}>
            <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
              {appointment.category_type}
            </Text>
          </TableCell>

          <TableCell sx={{ width: "200px" }}>
            <Text
              variant="body2"
              type="TableText"
              sx={{ textAlign: "center", textTransform: "capitalize" }}
            >
              {appointment.status.replaceAll("_", " ")}
            </Text>
          </TableCell>
          <TableCell sx={{ width: "200px" }}>
            <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
              {appointment.start_at}
            </Text>
          </TableCell>
          <TableCell sx={{ width: "200px" }}>
            <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
              {appointment.end_at}
            </Text>
          </TableCell>
          <TableCell sx={{ width: "200px" }}>
            <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
              {appointment.business.name}
            </Text>
          </TableCell>

          <TableCell sx={{ width: "200px" }}>
            <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
              {appointment.requestedPackage.code}
            </Text>
          </TableCell>

          <TableCell sx={{ width: "100px" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton onClick={handleOpenMenu}>
                <MoreHorizIcon sx={{ color: theme.palette.colors.text.table }} />
              </IconButton>
              <StyledMenu
                sx={{ mt: "20px" }}
                id="menu-appbar"
                anchorEl={showMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(showMenu)}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  key={1}
                  onClick={() => {
                    setShowEditModal(true);
                    handleCloseMenu();
                  }}
                  disabled={!appointment.editable}
                >
                  <Text textAlign="center">Edit</Text>
                </MenuItem>
                <MenuItem
                  key={2}
                  onClick={() => {
                    onHandleCancel(appointment.id);
                    handleCloseMenu();
                  }}
                  disabled={!appointment.cancellable}
                >
                  <Text textAlign="center">Cancel</Text>
                </MenuItem>
              </StyledMenu>
            </Box>
          </TableCell>
        </TableRow>
      </Form>
    </>
  );
}

AppointmentTableRow.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    category_type: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    editable: PropTypes.bool,
    cancellable: PropTypes.bool,
    user: PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.string,
    }),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    requestedPackage: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
  onHandleCancel: PropTypes.func.isRequired,
  onUpdateAppointment: PropTypes.func.isRequired,
};
