import { Box, styled } from "@mui/material";
import React from "react";
import Routes from "./navigation/index";

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
}));

function App() {
  return (
    <MainContainer>
      <Routes />
    </MainContainer>
  );
}

export default App;
