import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormFieldText from "../../../components/forms/form-field-text.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import {
  checkAttendance,
  eventBookingSelector,
} from "../../../services/event/booking/booking.slice.services";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  padding: "30px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxWidth: isMobile ? "" : "1100px",
  width: isMobile ? "100%" : "800px",
  height: isMobile ? "100%" : "250px",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  overflowY: "auto",
}));

const validationSchema = Yup.object().shape({
  q: Yup.string().required().label("Keyword"),
});

export default function CheckAttendanceModal({ onPageChange, sessionId, showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("760"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { checkAttendanceObj } = useSelector(eventBookingSelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  const onSubmit = (values) => {
    dispatch(checkAttendance({ ...values, isGuest: values.q.startsWith("GUEST-") })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          onPageChange(null, 1);
          setShowModal(false);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Form
          initialValues={{ sessionId, q: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Grid container>
            <Grid item container xs={12} position="relative" alignItems="center">
              <Grid item xs={12}>
                <Text variant="h5" textAlign="center">
                  Scan with QR code
                </Text>
                <Spacer size="m" />
              </Grid>
              <Grid container item justifyContent="flex-end" position="absolute">
                <IconButton onClick={handleCancel}>
                  <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container item xs={12} display="flex" alignItems="center" spacing={2}>
              <Grid item xs={isMobile ? 12 : 2.3}>
                <Text>User Public Id / Phone:</Text>
              </Grid>
              <Grid item xs={isMobile ? 12 : 9.7}>
                <FormFieldText
                  name="q"
                  placeholder="Please scan user's QR code / type in phone number"
                  autoFocus={true}
                />
              </Grid>
            </Grid>
            <Grid container item display="flex" justifyContent="end">
              <Grid item xs={isMobile ? 12 : 2}>
                <Spacer />
                <FormSubmitButton isLoading={checkAttendanceObj.status === "pending"}>
                  <Text type="WhiteColor">Check</Text>
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </ModalBox>
    </Modal>
  );
}

CheckAttendanceModal.propTypes = {
  sessionId: PropTypes.number.isRequired,
  showModal: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
