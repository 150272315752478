import { TableCell, TableRow } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import React from "react";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";
import Text from "../../../components/text.component";

export default function UserDetailTableRowLoader({ col }) {
  return (
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      {[...Array(col)].map(() => (
        <TableCell key={uuidv4()}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            <SkeletonLoader width="100%" />
          </Text>
        </TableCell>
      ))}
    </TableRow>
  );
}

UserDetailTableRowLoader.defaultProps = {
  col: 6,
};

UserDetailTableRowLoader.propTypes = {
  col: PropTypes.number,
};
