import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import HTMLRenderer from "react-html-renderer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import FormFieldLabel from "../../business/components/form-field-label.component";

import FormTextEditor from "../../../components/forms/form-text-editor.component";
import Form2 from "../../../components/forms/form.component";
import {
  eventCategorySelector,
  getEventCategoryList,
} from "../../../services/event/category/category.slice.services";
import {
  eventSelector,
  getEventDetail,
  updateEvent,
} from "../../../services/event/event.slice.services";
import {
  addEventImage,
  reOrderEventImage,
  removeEventImage,
} from "../../../services/event/image/image.slice.services";
import {
  eventQuestionSelector,
  getEventQuestions,
  setReduxStoreEventQuestions,
} from "../../../services/event/question/question.slice.services";
import BusinessListAutoCompleteSingleSelect from "../components/business-list-autocomplete-single-select.component";
import CategoryMultipleSelect from "../components/category-multiple-select.component";
import EditImageForm from "../components/edit-image-form.component";
import EventQuestionAdd from "../components/event-question-add.component";
import EventQuestion from "../components/event-question.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const validationSchema = Yup.object().shape({
  business: Yup.object().shape().nullable().required().label("Business"),
  title: Yup.string()
    .required()
    .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
    .label("Title"),
  categoriesId: Yup.array().min(1).nullable().required().label("Categories"),
});

const validationSchemaHighlights = Yup.object().shape({
  highlights: Yup.string().required().label("Highlights"),
});

const validationSchemaSummary = Yup.object().shape({
  summary: Yup.string().required().label("Summary"),
});

const validationSchemaImage = Yup.object().shape({
  image: Yup.string().required().label("Image"),
});

const eventQuestionUpdateValidationSchema = Yup.object().shape({
  eventQuestion: Yup.string().required().label("Event Question"),
  open_ended: Yup.boolean().required().label("Open Ended"),
  eventQuestionResponses: Yup.array().nullable().label("Event Question Responses"),
});

export default function EventEditScreen() {
  const theme = useTheme();
  const location = useLocation();
  const selectedEventId = new URLSearchParams(location.search).get("eventId");
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getEventDetailObj } = useSelector(eventSelector);
  const { getEventCategoryListObj } = useSelector(eventCategorySelector);
  const { reduxStoreEventQuestions } = useSelector(eventQuestionSelector);
  const [allowEditDetail, setAllowEditDetail] = useState(false);
  const [allowEditHighlights, setAllowEditHighlights] = useState(false);
  const [allowEditSummary, setAllowEditSummary] = useState(false);
  const [allowEditImage, setAllowEditImage] = useState(false);
  const [allowEditQuestion, setAllowEditQuestion] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(setReduxStoreEventQuestions([]));
    dispatch(getEventCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(getEventDetail({ eventId: selectedEventId })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(getEventQuestions({ eventId: selectedEventId })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        dispatch(setReduxStoreEventQuestions(payload.data.items));
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onUpdateEventDetail = (values) => {
    const businessId = values.business.id;
    setIsLoading(true);
    dispatch(
      updateEvent({
        eventId: selectedEventId,
        params: "details",
        businessId,
        ...values,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setAllowEditDetail(false);
  };

  const onUpdateEventHighlights = (values) => {
    setIsLoading(true);
    dispatch(
      updateEvent({
        eventId: selectedEventId,
        params: "highlights",
        ...values,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        dispatch(getEventDetail({ eventId: selectedEventId }));
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setAllowEditHighlights(false);
  };

  const onUpdateEventSumary = (values) => {
    setIsLoading(true);
    dispatch(
      updateEvent({
        eventId: selectedEventId,
        params: "summary",
        ...values,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        dispatch(getEventDetail({ eventId: selectedEventId }));
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setAllowEditSummary(false);
  };

  const onAddEventImage = (values) => {
    setIsLoading(true);
    dispatch(addEventImage({ eventId: selectedEventId, ...values })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          dispatch(getEventDetail({ eventId: selectedEventId }));
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const imageEventHandle = (values) => {
    setIsLoading(true);
    if (values.type === "remove") {
      dispatch(removeEventImage({ eventId: selectedEventId, ...values })).then(
        ({ meta, error, payload }) => {
          setIsLoading(false);
          if (meta.requestStatus === "fulfilled") {
            createSnackBar({
              message: payload.message,
              type: "success",
              open: true,
            });
            dispatch(getEventDetail({ eventId: selectedEventId }));
          }
          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: error.message,
              type: "error",
              open: true,
            });
          }
        },
      );
      return;
    }
    dispatch(reOrderEventImage({ eventId: selectedEventId, ...values })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          dispatch(getEventDetail({ eventId: selectedEventId }));
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  if (getEventDetailObj.status === "succeeded" && getEventCategoryListObj.status === "succeeded")
    return (
      <Grid>
        <BackdropLoading isLoading={isLoading} />

        <>
          <Formik
            validationSchema={validationSchemaImage}
            onSubmit={onAddEventImage}
            initialValues={{
              image: "",
            }}
          >
            <Form>
              <Grid
                container
                spacing={5}
                sx={{
                  paddingX: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
                  paddingTop: theme.dimensions.ScreenPaddingY,
                }}
              >
                <Grid item xs={12}>
                  <SpaceBetweenBox>
                    <Text variant="screenLabel">Edit Event</Text>
                    <Button
                      onClick={() => history.push(routes.EVENT_LIST)}
                      sx={{
                        textTransform: "none",
                        padding: "0px",
                        ":hover": { backgroundColor: "transparent" },
                      }}
                    >
                      <Text>Back</Text>
                    </Button>
                  </SpaceBetweenBox>
                </Grid>
                <Grid item xs={12}>
                  <WhiteBgCardContainer>
                    <Grid container spacing={isMobile ? 1 : 3}>
                      <Grid item xs={4} sm={2}>
                        <Text variant="screenLabel">Image</Text>
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        {allowEditImage ? (
                          <IconButton size="small" onClick={() => setAllowEditImage(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        ) : (
                          <>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setAllowEditImage(true);
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <FormContainer>
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <EditImageForm
                                name="image"
                                imageEventHandle={imageEventHandle}
                                allowEditImage={allowEditImage}
                              />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </WhiteBgCardContainer>
                </Grid>
              </Grid>
            </Form>
          </Formik>

          <Formik
            validationSchema={validationSchema}
            onSubmit={onUpdateEventDetail}
            initialValues={{
              business: getEventDetailObj.data.business,
              title: getEventDetailObj.data.title,
              categoriesId: getEventDetailObj.data.category.map((item) => item.id),
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Plan</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditDetail ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditDetail(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditDetail(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Business
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Business
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <BusinessListAutoCompleteSingleSelect
                                  name="business"
                                  placeholder="Business"
                                  disabled={!allowEditDetail}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={12}>
                          <FormFieldLabel
                            name="title"
                            placeholder="Enter event title"
                            label="Title"
                            disabled={!allowEditDetail}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Categories
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Categories
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <CategoryMultipleSelect
                                  name="categoriesId"
                                  placeholder="Choose categories"
                                  itemList={getEventCategoryListObj.data}
                                  disabled={!allowEditDetail}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Formik
            validationSchema={validationSchemaHighlights}
            onSubmit={onUpdateEventHighlights}
            initialValues={{
              highlights: getEventDetailObj.data.highlights,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={3}>
                          <Text variant="screenLabel">Highlights</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditHighlights ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => setAllowEditHighlights(false)}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditHighlights(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <FormContainer>
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                {allowEditHighlights ? (
                                  <Box
                                    sx={{
                                      padding: "16.5px 14px",
                                      borderRadius: "4px",
                                      border: `1px solid ${theme.palette.colors.ui.border}`,
                                    }}
                                  >
                                    <FormTextEditor
                                      name="highlights"
                                      width="100%"
                                      placeholder="Enter highlights"
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      padding: "16.5px 14px",
                                      borderRadius: "4px",
                                      border: `1px solid ${theme.palette.colors.ui.border}`,
                                      backgroundColor: theme.palette.colors.bg.primary,
                                    }}
                                  >
                                    <HTMLRenderer
                                      html={
                                        getEventDetailObj.data.highlights ||
                                        '<span style="color: #AAAAAA">Enter highlights</span>'
                                      }
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Formik
            validationSchema={validationSchemaSummary}
            onSubmit={onUpdateEventSumary}
            initialValues={{
              summary: getEventDetailObj.data.summary,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={3}>
                          <Text variant="screenLabel">Summary</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditSummary ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditSummary(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditSummary(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <FormContainer>
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                {allowEditSummary ? (
                                  <Box
                                    sx={{
                                      padding: "16.5px 14px",
                                      borderRadius: "4px",
                                      border: `1px solid ${theme.palette.colors.ui.border}`,
                                    }}
                                  >
                                    <FormTextEditor
                                      name="summary"
                                      width="100%"
                                      placeholder="Enter summary"
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      padding: "16.5px 14px",
                                      borderRadius: "4px",
                                      border: `1px solid ${theme.palette.colors.ui.border}`,
                                      backgroundColor: theme.palette.colors.bg.primary,
                                    }}
                                  >
                                    <HTMLRenderer
                                      html={
                                        getEventDetailObj.data.summary ||
                                        '<span style="color: #AAAAAA">Enter summary</span>'
                                      }
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Grid
            container
            spacing={5}
            sx={{
              paddingX: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
            }}
          >
            <Grid item xs={12}>
              <WhiteBgCardContainer>
                <Grid container spacing={isMobile ? 1 : 3}>
                  <EventQuestionAdd
                    selectedEventId={selectedEventId}
                    allowEditQuestion={allowEditQuestion}
                    setAllowEditQuestion={setAllowEditQuestion}
                  />

                  {reduxStoreEventQuestions.length > 0 && (
                    <>
                      {reduxStoreEventQuestions.map((item) => (
                        <Form2
                          validationSchema={eventQuestionUpdateValidationSchema}
                          onSubmit={() => {}}
                          initialValues={{
                            questionId: item.id,
                            eventQuestion: item.question,
                            openEnded: item.open_ended,
                            eventQuestionResponses: item.responses.map(
                              (response) => response.option,
                            ),
                          }}
                          key={item.id}
                        >
                          <EventQuestion
                            key={item.id}
                            item={item}
                            eventId={selectedEventId}
                            allowEditDetail={allowEditQuestion}
                          />
                        </Form2>
                      ))}
                    </>
                  )}
                </Grid>
              </WhiteBgCardContainer>
            </Grid>
          </Grid>
        </>
      </Grid>
    );
}
