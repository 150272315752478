import React, { useEffect, useState } from "react";
import { Button, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Text from "../text.component";

function ResendCountdownButton({ onClickButton, variant }) {
  const theme = useTheme();
  const [countDown, setCountDown] = useState(60);

  useEffect(() => {
    let interval = 0;
    let timer = 0;
    if (countDown > 0) {
      timer = setTimeout(() => {
        interval = setInterval(() => {
          setCountDown((prev) => {
            if (prev === 1) clearInterval(interval);
            return prev - 1;
          });
        });
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [countDown]);

  return (
    <Button
      disabled={countDown > 0}
      onClick={() => {
        setCountDown(60);
        onClickButton();
      }}
      sx={{ textTransform: "none", ":hover": { backgroundColor: "transparent" } }}
    >
      <Text
        variant={variant}
        sx={{
          color:
            countDown > 0 ? theme.palette.colors.text.disabled : theme.palette.colors.brand.primary,
        }}
      >
        {countDown > 0 ? `Resend Code in ${countDown}` : "Resend Code"}
      </Text>
    </Button>
  );
}

ResendCountdownButton.defaultProps = {
  variant: "body2",
};

ResendCountdownButton.propTypes = {
  variant: PropTypes.string,
  onClickButton: PropTypes.func.isRequired,
};

export default ResendCountdownButton;
