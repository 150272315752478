import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Text from "../../../components/text.component";
import AddDetailConfirmationModal from "./add-detail-confirmation-modal.component";

export default function PackageDetailAdd({ selectedPlanId, allowEditDetail, setAllowEditDetail }) {
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <>
      <Grid item xs={4} sm={2}>
        <Text variant="screenLabel">Detail</Text>
      </Grid>
      <Grid item xs={4} sm={2}>
        {allowEditDetail ? (
          <>
            <IconButton size="small" onClick={() => setAllowEditDetail(false)}>
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={() => setShowAddModal(true)}>
              <AddIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              size="small"
              onClick={() => {
                setAllowEditDetail(true);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </Grid>
      <AddDetailConfirmationModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        title="Add Detail"
        selectedPlanId={selectedPlanId}
      />
    </>
  );
}

PackageDetailAdd.propTypes = {
  selectedPlanId: PropTypes.number.isRequired,
  allowEditDetail: PropTypes.bool.isRequired,
  setAllowEditDetail: PropTypes.func.isRequired,
};
