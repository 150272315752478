import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function HourlyBookingTableRow({ record }) {
  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {record.user.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.user.phone}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.business.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "150px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM${record.booking.price}`}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.booking.startDate}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.booking.endDate}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
            type={record.booking.status === "active" ? "BrandColor" : "YellowColor"}
          >
            {record.booking.status}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

HourlyBookingTableRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    booking: PropTypes.shape({
      status: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      price: PropTypes.string,
    }),
  }).isRequired,
};
