import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

export default function PromoCodeTableRow({ record }) {
  const [collapse, setCollapse] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const onHandleEdit = () => {
    history.push({
      pathname: routes.PROMO_CODES_EDIT,
      search: `promoCodeId=${record.id}`,
    });
  };

  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "0px",
          },
        }}
      >
        <TableCell width="25px">
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.id}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.title}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.code}
          </Text>
        </TableCell>
        <TableCell width="100px">
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.type === "percentage" ? `${record.discount}%` : `RM ${record.discount}`}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center", minWidth: "140px" }}>
            {record.startAt}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center", minWidth: "140px" }}>
            {record.endAt}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.redeemedCount}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={onHandleEdit}>
              <EditIcon sx={{ color: theme.palette.colors.text.table }} />
            </IconButton>
            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Text>Promo Code Details</Text>
            </Box>
            <Spacer />
            <Table size="small">
              <TableHeader
                headerCells={[
                  "Type",
                  "Max cap amount",
                  "Max no. claims",
                  "Max no. claims per user",
                  "Users specific",
                ]}
                nonSortableColumns={[
                  "Type",
                  "Max cap amount",
                  "Max no. claims",
                  "Max no. claims per user",
                  "Users specific",
                ]}
              />
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Text
                      variant="body2"
                      noWrap={true}
                      sx={{ textAlign: "center", textTransform: "capitalize" }}
                    >
                      {record.type.replace("_", " ")}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {`RM ${record.maxCapAmount}`}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.maxNoClaims}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.maxNoClaimsPerUser}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.userSpecific ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table size="small">
              <TableHeader
                headerCells={[
                  "Merchants specific",
                  "Business specific",
                  "Hourly Booking specific",
                  "Subscription Plan specific",
                  "Package Plan specific",
                  "Fitness Class specific",
                ]}
                nonSortableColumns={[
                  "Merchants specific",
                  "Business specific",
                  "Hourly Booking specific",
                  "Subscription Plan specific",
                  "Package Plan specific",
                  "Fitness Class specific",
                ]}
              />
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.merchantSpecific ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.businessSpecific ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.hourlyBookingSpecific ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.subscriptionPlanSpecific ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.packagePlanSpecific ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.classSpecific ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

PromoCodeTableRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    code: PropTypes.string,
    discount: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    type: PropTypes.string,
    maxCapAmount: PropTypes.string,
    minSpentAmount: PropTypes.string,
    maxNoClaims: PropTypes.number,
    maxNoClaimsPerUser: PropTypes.number,
    userSpecific: PropTypes.bool,
    merchantSpecific: PropTypes.bool,
    businessSpecific: PropTypes.bool,
    hourlyBookingSpecific: PropTypes.bool,
    subscriptionPlanSpecific: PropTypes.bool,
    packagePlanSpecific: PropTypes.bool,
    classSpecific: PropTypes.bool,
    redeemedCount: PropTypes.number,
  }).isRequired,
};
