import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api-services";

export const getTotalVisitors = createAsyncThunk("merchants/footfall/visitors", async (payload) => {
  const { startAt, endAt } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/footfalls/visitors/?${
      startAt ? "startAt=".concat(startAt) : ""
    }${endAt ? "&endAt=".concat(endAt) : ""}`,
  );
  return response;
});

export const getTotalSessions = createAsyncThunk("merchants/footfall/sessions", async (payload) => {
  const { startAt, endAt } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/footfalls/sessions/?${
      startAt ? "startAt=".concat(startAt) : ""
    }${endAt ? "&endAt=".concat(endAt) : ""}`,
  );
  return response;
});

export const getEntryLogs = createAsyncThunk("merchants/footfall/logs", async (payload) => {
  const { startAt, endAt, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/footfalls/logs/?${
      startAt ? "startAt=".concat(startAt) : ""
    }${endAt ? "&endAt=".concat(endAt) : ""}&page=${page}`,
  );
  return response;
});

export const getEntrySummary = createAsyncThunk("merchants/footfall/index", async (payload) => {
  const { startAt, endAt } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/footfalls/?${
      startAt ? "startAt=".concat(startAt) : ""
    }${endAt ? "&endAt=".concat(endAt) : ""}`,
  );
  return response;
});

export const createEntries = createAsyncThunk("merchants/entries", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/entries`,
    payload,
  );
  return response;
});

const footfallSlice = createSlice({
  name: "footfall",
  initialState: {
    getTotalVisitorsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getTotalSessionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEntryLogsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEntrySummaryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createEntriesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getTotalVisitors.pending]: (state) => {
      state.getTotalVisitorsObj.status = "pending";
    },
    [getTotalVisitors.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getTotalVisitorsObj.status = "succeeded";
      state.getTotalVisitorsObj.data = data;
      state.getTotalVisitorsObj.successMessage = message;
    },
    [getTotalVisitors.rejected]: (state, action) => {
      const { message } = action.error;

      state.getTotalVisitorsObj.status = "failed";
      state.getTotalVisitorsObj.errorMessage = message;
    },
    [getTotalSessions.pending]: (state) => {
      state.getTotalSessionsObj.status = "pending";
    },
    [getTotalSessions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getTotalSessionsObj.status = "succeeded";
      state.getTotalSessionsObj.data = data;
      state.getTotalSessionsObj.successMessage = message;
    },
    [getTotalSessions.rejected]: (state, action) => {
      const { message } = action.error;

      state.getTotalSessionsObj.status = "failed";
      state.getTotalSessionsObj.errorMessage = message;
    },
    [getEntryLogs.pending]: (state) => {
      state.getEntryLogsObj.status = "pending";
    },
    [getEntryLogs.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEntryLogsObj.status = "succeeded";
      state.getEntryLogsObj.data = data;
      state.getEntryLogsObj.successMessage = message;
    },
    [getEntryLogs.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEntryLogsObj.status = "failed";
      state.getEntryLogsObj.errorMessage = message;
    },
    [getEntrySummary.pending]: (state) => {
      state.getEntrySummaryObj.status = "pending";
    },
    [getEntrySummary.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEntrySummaryObj.status = "succeeded";
      state.getEntrySummaryObj.data = data;
      state.getEntrySummaryObj.successMessage = message;
    },
    [getEntrySummary.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEntrySummaryObj.status = "failed";
      state.getEntrySummaryObj.errorMessage = message;
    },
    [createEntries.pending]: (state) => {
      state.createEntriesObj.status = "pending";
    },
    [createEntries.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createEntriesObj.status = "succeeded";
      state.createEntriesObj.data = data;
      state.createEntriesObj.successMessage = message;
    },
    [createEntries.rejected]: (state, action) => {
      const { message } = action.error;

      state.createEntriesObj.status = "failed";
      state.createEntriesObj.errorMessage = message;
    },
  },
});

export default footfallSlice.reducer;

// state
export const footfallSelector = (state) => state.footfall;
