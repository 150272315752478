import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function LineChart({ datasets, labels, yLegend }) {
  const noRevenue = !datasets
    .find((datum) => datum.label === "Total")
    .data.some((revenue) => revenue !== "0.00");

  return (
    <Line
      options={{
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            max: noRevenue ? 50 : undefined,
            ticks: { stepSize: noRevenue ? 10 : undefined },
            beginAtZero: true,
            display: true,
            title: {
              display: true,
              text: yLegend,
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      }}
      data={{
        labels,
        datasets,
      }}
    />
  );
}

LineChart.propTypes = {
  yLegend: PropTypes.string.isRequired,
  datasets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};
