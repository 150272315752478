import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Menu,
  styled,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: "41px",
    maxHeight: "300px",
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: theme.palette.colors.brand.secondary,
      },
    },
  },
  " .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const ButtonBox = styled(Button)(({ theme }) => ({
  width: "100%",
  textTransform: "none",
  border: `1px solid ${theme.palette.colors.ui.border}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: theme.palette.colors.bg.secondary,
  ":hover": {
    backgroundColor: theme.palette.colors.bg.secondary,
  },
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
});

function CheckBoxFilter({ itemList, name, label }) {
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext();
  const [showMenu, setShowMenu] = useState(null);

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const handleCheckboxChange = (data) => {
    const isChecked = values[name].some((checkedCheckbox) => checkedCheckbox === data.label);
    if (isChecked) {
      setFieldValue(
        name,
        values[name].filter((checkedCheckbox) => checkedCheckbox !== data.label),
      );
    } else {
      setFieldValue(name, values[name].concat(data.label));
    }
  };

  return (
    <>
      <ButtonBox disableRipple onClick={handleOpenMenu}>
        <CenterRowBox>
          {values[name].length <= 0 ? (
            <Text
              type={values[name].length <= 0 ? "GreyColor" : null}
              sx={{
                paddingLeft: "5px",
                paddingRight: "15px",
                textAlign: "start",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                textTransform: "capitalize",
              }}
            >
              {label}
            </Text>
          ) : (
            <Grid container sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {values[name].map((item) => (
                <Grid key={item}>
                  <Chip label={item.replace("_", " ")} sx={{ textTransform: "capitalize" }} />
                </Grid>
              ))}
            </Grid>
          )}
          <Spacer size="s" position="left" />
          {showMenu ? (
            <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.text.secondary }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.text.secondary }} />
          )}
        </CenterRowBox>
      </ButtonBox>
      <StyledMenu
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={showMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(showMenu)}
        onClose={handleCloseMenu}
      >
        <FormControl sx={{ padding: "10px" }}>
          {itemList.map((item) => (
            <FormControlLabel
              key={item.id}
              label={item.label}
              control={
                <Checkbox
                  disableRipple
                  sx={{
                    color: theme.palette.colors.brand.primary,
                    "&.Mui-checked": {
                      color: theme.palette.colors.brand.primary,
                    },
                  }}
                  onChange={() => handleCheckboxChange(item)}
                  checked={values[name].some(
                    (checkedCheckbox) =>
                      (checkedCheckbox === "rejected_admin" && item.label === "Rejected") ||
                      checkedCheckbox === item.label,
                  )}
                />
              }
            />
          ))}
        </FormControl>
      </StyledMenu>
    </>
  );
}

CheckBoxFilter.propTypes = {
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckBoxFilter;
