import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  getPackagePurchasesCSV,
  packageSelector,
  searchPackagePurchases,
} from "../../../services/package/package-slice.service";
import CheckBoxFilter from "../components/checkbox-fiter.component";
import PackagePurchasesTableRow from "../components/package-purchases-table-row.component";
import SearchBar from "../components/search-bar.component";
import PackageTableRowLoader from "../loader/package-table-row-loader.component";
import TableSort from "../../../components/table/table-sort.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  statuses: Yup.array().label("Status").of(Yup.string()).required().min(1),
});

const STATUSLIST = [
  { id: "1", label: "Ongoing", value: "ongoing" },
  { id: "2", label: "Completed", value: "completed" },
];

export default function PackagePurchasesListScreen() {
  const theme = useTheme();
  const formRef = useRef();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [statuses, setStatuses] = useState(["ongoing", "completed"]);
  const [page, setPage] = useState(1);
  const { searchPackagePurchasesObj, getPackagePurchasesCSVObj } = useSelector(packageSelector);
  const [totalPages, setTotalPages] = useState(1);
  const columnMapping = {
    Plan: "plan.title",
    Name: "user.name",
    Phone: "user.phone",
    Code: "code",
    Price: "price",
    "Sessions claimed": "sessions.claimedCount",
    "No. of sessions": "sessions.noOfSessions",
    "Date Created": "createdAt",
    Status: "status",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshPackageList = (newPage, keyword, status) => {
    setIsLoading(true);
    setPage(newPage);
    setQ(keyword);
    setStatuses(status);
    dispatch(searchPackagePurchases({ q: keyword, page: newPage, statuses: status })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setTotalPages(payload.data.pagination.totalPages);
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );

    dispatch(getPackagePurchasesCSV({ q: keyword, statuses: status }));
    setIsLoading(false);
  };

  const submitFilter = (values) => {
    onRefreshPackageList(1, values.q, values.statuses);
  };

  const onChangeSearch = (keyword) => {
    onRefreshPackageList(1, keyword, statuses);
  };

  const onPageChange = (e, newPage) => {
    onRefreshPackageList(newPage, q, statuses);
  };

  useEffect(() => {
    onRefreshPackageList(page, q, statuses);
    dispatch(getPackagePurchasesCSV({ q, statuses }));
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (searchPackagePurchasesObj.data && searchPackagePurchasesObj.status === "succeeded") {
      records = { ...searchPackagePurchasesObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (searchPackagePurchasesObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (searchPackagePurchasesObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <TableEmptyBox>
                <Text type="TableText">No Packages</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <PackagePurchasesTableRow packagePlan={item} key={item.id} />
      ));
    }
    return <PackageTableRowLoader />;
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        statuses,
        q: q || "",
      }}
      onSubmit={submitFilter}
      validationSchema={validationSchema}
    >
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        <Grid item xs={12}>
          <Text variant="screenLabel">Purchased Package Plans</Text>
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
          <WhiteBgCardContainer>
            <BackdropLoading isLoading={isLoading} />
            <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
              <Grid item container xs={12} spacing={3} sx={{ justifyContent: "flex-start" }}>
                <Grid item xs={8}>
                  <SearchBar
                    placeholder="Search here"
                    name="q"
                    searchKeyword={q}
                    setSearchKeyword={onChangeSearch}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CheckBoxFilter itemList={STATUSLIST} label="Status" name="statuses" />
                </Grid>
              </Grid>
              <Spacer size="l" />
              <Grid item container xs={12} spacing={3} sx={{ justifyContent: "flex-end" }}>
                <Grid item xs={12} sm={4} md={3}>
                  {getPackagePurchasesCSVObj.status === "succeeded" ? (
                    <CSVLink
                      data={getPackagePurchasesCSVObj.data}
                      filename="merchant_packages_purchases.csv"
                      style={{ textDecoration: "none" }}
                    >
                      <CtaBorderButton disabled={getPackagePurchasesCSVObj.status !== "succeeded"}>
                        <Text
                          sx={{
                            whiteSpace: "nowrap",
                            color:
                              getPackagePurchasesCSVObj.status === "succeeded"
                                ? theme.palette.colors.brand.primary
                                : theme.palette.colors.text.disabled,
                          }}
                        >
                          Download CSV
                        </Text>
                      </CtaBorderButton>
                    </CSVLink>
                  ) : (
                    <>
                      <CtaBorderButton disabled={true}>
                        <Text
                          sx={{
                            color: theme.palette.colors.text.disabled,
                          }}
                        >
                          Download CSV
                        </Text>
                      </CtaBorderButton>
                    </>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormSubmitButton>
                    <Text type="WhiteColor">Search</Text>
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </SearchContainer>

            <Grid container sx={{ paddingY: "20px" }} spacing={3}>
              <Grid item xs={12}>
                <TableWrapper>
                  <Table>
                    <TableHeader
                      headerCells={[
                        "Plan",
                        "Name",
                        "Phone",
                        "Code",
                        "Price",
                        "Sessions claimed",
                        "No. of sessions",
                        "Date Created",
                        "Status",
                        "Action",
                      ]}
                      sortColumn={sortColumn}
                      sortOrder={sortOrder}
                      onSortChange={onSortChange}
                      columnMapping={columnMapping}
                    />
                    <TableBody>{renderTableBody()}</TableBody>
                  </Table>
                </TableWrapper>
              </Grid>
              <Grid item xs={12}>
                <CustomFooter>
                  <Pagination
                    sx={{
                      "&& .Mui-selected": {
                        backgroundColor: theme.palette.colors.brand.primary,
                        color: theme.palette.colors.text.white,
                      },
                    }}
                    page={page}
                    shape="rounded"
                    onChange={onPageChange}
                    count={searchPackagePurchasesObj?.data?.pagination.totalPages || totalPages}
                    variant="outlined"
                  />
                </CustomFooter>
              </Grid>
            </Grid>
          </WhiteBgCardContainer>
        </Grid>
      </Grid>
    </Formik>
  );
}
