import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function UserSubscriptionsDetailTableRow({ subscription }) {
  return (
    <>
      <TableRow
        key={subscription.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {subscription.plan.title}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {subscription.plan.shortDescription}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {subscription.start_at ? subscription.start_at : "-"}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {subscription.end_at ? subscription.end_at : "-"}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {subscription.plan.numberOfDays}
          </Text>
        </TableCell>

        <TableCell>
          <Text
            variant="body2"
            type="TableText"
            noWrap={true}
            sx={{ textTransform: "capitalize", textAlign: "center" }}
          >
            {subscription.status.replaceAll("_", " ")}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

UserSubscriptionsDetailTableRow.propTypes = {
  subscription: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    plan: PropTypes.shape({
      title: PropTypes.string,
      shortDescription: PropTypes.string,
      numberOfDays: PropTypes.number,
    }),
  }).isRequired,
};
