import {
  Box,
  FormHelperText,
  Popper,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import {
  businessSelector,
  getBusinessAutoCompleteSuggestion,
} from "../../../services/business/business.slice.services";

function CustomPopper(props) {
  return <Popper {...props} placement="bottom" />;
}

export default function BusinessListAutoCompleteSingleSelect({
  name,
  placeholder,
  disabled,
  isWhiteBg,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getBusinessAutoCompleteSuggestionObj } = useSelector(businessSelector);
  const createSnackBar = useContext(SnackbarContext);
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [planKeyword, setPlanKeyword] = useState("");
  const [loadingMoreResults, setLoadingMoreResults] = useState(false);

  const StyledTextField = styled(TextField)(() => ({
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "100%",
    "& .MuiOutlinedInput-root": {
      padding: 4,
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      backgroundColor: isWhiteBg
        ? theme.palette.colors.bg.secondary
        : theme.palette.colors.bg.primary,
    },
  }));

  const listOnChange = (event, value) => {
    setFieldValue(name, value);
  };

  const loadMoreResults = () => {
    let newPage = page;

    if (newPage < totalPage) {
      setLoadingMoreResults(true);
      const newOptions = options;
      setOptions([...newOptions, { id: "loading", option: "loading" }]);
      newPage += 1;
      setPage(newPage);
      dispatch(getBusinessAutoCompleteSuggestion({ keyword: planKeyword, page: newPage })).then(
        ({ meta, error, payload }) => {
          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: `Failed to get business auto complete suggestion! ${error.message}`,
              type: "error",
              open: true,
            });
          }
          if (meta.requestStatus === "fulfilled") {
            setLoadingMoreResults(false);
            setOptions([...newOptions, ...payload.data.items]);
          }
        },
      );
    }
  };

  const onSearchKeywordChange = (keyword) => {
    setPage(1);
    setOptions([]);

    dispatch(getBusinessAutoCompleteSuggestion({ keyword, page: 1 })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: `Failed to get user auto complete suggestion! ${error.message}`,
            type: "error",
            open: true,
          });
        }
        if (meta.requestStatus === "fulfilled") {
          setTotalPage(payload.data.pagination.totalPages);
          setOptions(payload.data.items);
        }
      },
    );
  };

  useEffect(() => {
    onSearchKeywordChange("");
  }, []);

  return (
    <>
      <Autocomplete
        filterOptions={(x) => x}
        loading={options.length === 0}
        loadingText={
          getBusinessAutoCompleteSuggestionObj.status === "succeeded" ? "No options" : "Loading..."
        }
        ListboxProps={{
          role: "list-box",
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              !loadingMoreResults &&
              listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
            ) {
              loadMoreResults();
            }
          },
        }}
        PopperComponent={CustomPopper}
        disabled={disabled}
        freeSolo
        onChange={listOnChange}
        options={options}
        getOptionDisabled={(option) => option.option === "loading"}
        value={values[name]}
        getOptionLabel={(option) => option.option || option.name}
        renderOption={(props, option) => (
          <Box key={option.id} {...props}>
            {option.option === "loading" ? (
              <Typography sx={{ color: theme.palette.colors.text.secondary }}>
                Loading...
              </Typography>
            ) : (
              <Typography sx={{ color: theme.palette.colors.text.primary }}>
                {option.option}
              </Typography>
            )}
          </Box>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <DebounceInput
            {...params}
            debounceTimeout={500}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            element={StyledTextField}
            placeholder={placeholder}
            variant="outlined"
            onChange={(e) => {
              setPlanKeyword(e.target.value);
              onSearchKeywordChange(e.target.value);
            }}
          />
        )}
      />
      {showError && (
        <FormHelperText error sx={{ margin: "3px 14px 0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
}

BusinessListAutoCompleteSingleSelect.defaultProps = {
  disabled: false,
  isWhiteBg: false,
};

BusinessListAutoCompleteSingleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isWhiteBg: PropTypes.bool,
};
