import React, { useState } from "react";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "@nick4fake/react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box, FormHelperText, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

function FormTextEditor({ name, placeholder, width }) {
  const theme = useTheme();
  const { setFieldValue, touched, errors, setFieldTouched, values } = useFormikContext();
  let state = null;

  if (values[name] !== null && Object.keys(values[name]).length > 0) {
    const blocksFromHTML = htmlToDraft(values[name]);
    const { contentBlocks, entityMap } = blocksFromHTML;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    state = EditorState.createWithContent(contentState);
  }

  const [editorState, setEditorState] = useState(
    values[name] !== null && Object.keys(values[name]).length === 0
      ? EditorState.createEmpty()
      : state,
  );

  const onChangeFieldText = (e) => {
    setEditorState(e);
    setFieldValue(
      name,
      e.getCurrentContent().hasText() ? draftToHtml(convertToRaw(e.getCurrentContent())) : "",
    );
  };
  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box sx={{ width }}>
      <Editor
        editorState={editorState}
        editorStyle={{
          border: `1px solid ${
            showError ? theme.palette.colors.ui.errorBox : theme.palette.colors.ui.border
          }`,
          paddingLeft: "10px",
          paddingRight: "10px",
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          minHeight: "200px",
          backgroundColor: theme.palette.colors.bg.primary,
        }}
        onBlur={() => setFieldTouched(name)}
        placeholder={placeholder}
        onEditorStateChange={onChangeFieldText}
      />
      {showError && (
        <FormHelperText
          sx={{
            color: theme.palette.colors.text.error,
            margin: "3px 14px 0px 14px",
          }}
        >
          {errors[name]}
        </FormHelperText>
      )}
    </Box>
  );
}

FormTextEditor.propTypes = {
  width: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default FormTextEditor;
