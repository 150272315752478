import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Text from "../../../components/text.component";
import { eventSelector } from "../../../services/event/event.slice.services";

const CoverPhotoLabelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  backgroundColor: "rgba(0,0,0,0.5)",
  top: "15px",
  left: "10px",
  paddingLeft: "5px",
  paddingRight: "5px",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  top: "10px",
  right: "10px",
  backgroundColor: theme.palette.colors.brand.white,
  width: "30px",
  height: "30px",
  ":hover": { backgroundColor: theme.palette.colors.brand.white },
}));

const StyledMoreHorizIcon = styled(MoreHorizIcon)(({ theme }) => ({
  fontSize: "25px",
  color: theme.palette.colors.brand.secondary,
}));

export default function EventImage({ image, index, imageEventHandle, allowEditImage }) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);
  const { getEventDetailObj } = useSelector(eventSelector);

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const renderMenu = () => (
    <StyledMenu
      sx={{ mt: "30px" }}
      anchorEl={showMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(showMenu)}
      onClose={handleCloseMenu}
    >
      {index !== 0 && index !== getEventDetailObj.data.images.length - 1 && (
        <MenuItem
          key={uuidv4()}
          onClick={() => {
            imageEventHandle({ imageId: image.id, type: "backward" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Move Backward</Text>
        </MenuItem>
      )}

      {index !== 0 && index !== 1 && (
        <MenuItem
          key={uuidv4()}
          onClick={() => {
            imageEventHandle({ imageId: image.id, type: "forward" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Move Foward</Text>
        </MenuItem>
      )}
      {index !== 0 && (
        <MenuItem
          key={uuidv4()}
          onClick={() => {
            imageEventHandle({ imageId: image.id, type: "cover" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Set as Cover Photo</Text>
        </MenuItem>
      )}
      <MenuItem
        key={4}
        onClick={() => {
          imageEventHandle({ imageId: image.id, type: "remove" });
          handleCloseMenu();
        }}
      >
        <Text textAlign="center">Delete</Text>
      </MenuItem>
    </StyledMenu>
  );

  return (
    <Box sx={{ position: "relative" }}>
      <img
        src={image.imagePath}
        alt="event-img"
        style={{
          width: "100%",
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
        }}
      />
      {allowEditImage && (
        <StyledButtonIcon
          onClick={(event) => {
            handleOpenMenu(event);
          }}
        >
          <StyledMoreHorizIcon />
        </StyledButtonIcon>
      )}
      {renderMenu()}
      {index === 0 && (
        <CoverPhotoLabelContainer>
          <Text type="WhiteColor">Cover Photo</Text>
        </CoverPhotoLabelContainer>
      )}
    </Box>
  );
}

EventImage.propTypes = {
  imageEventHandle: PropTypes.func.isRequired,
  image: PropTypes.shape({
    imagePath: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  allowEditImage: PropTypes.bool.isRequired,
};
