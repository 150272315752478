import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import PublishConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import {
  createEventQuestions,
  eventQuestionSelector,
  publishEventQuestions,
  setReduxStoreEventQuestions,
} from "../../../services/event/question/question.slice.services";
import AddQuestionConfirmationModal from "./add-question-confirmation-modal.component";

const eventQuestionAddValidationSchema = Yup.object().shape({
  eventQuestion: Yup.string().required().label("Event Question"),
  open_ended: Yup.boolean().required().label("Open Ended"),
  eventQuestionResponses: Yup.array().nullable().label("Event Question Responses"),
});

export default function EventQuestionAdd({
  selectedEventId,
  allowEditQuestion,
  setAllowEditQuestion,
}) {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const { getEventQuestionsObj, publishEventQuestionsObj } = useSelector(eventQuestionSelector);

  const onCreateEventQuestion = (values, { resetForm }) => {
    dispatch(
      createEventQuestions({
        eventId: selectedEventId,
        items: [
          {
            question: values.eventQuestion,
            open_ended: values.eventQuestionResponses.length === 0,
            responses: values.eventQuestionResponses.map((responses) => ({ option: responses })),
          },
        ],
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowAddModal(false);
        dispatch(setReduxStoreEventQuestions(payload.data.items));
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        resetForm();
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const handlePublishQuestions = () => {
    setIsLoading(true);
    dispatch(
      publishEventQuestions({
        eventId: selectedEventId,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        setIsPublished(true);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setShowPublishModal(false);
    setIsLoading(false);
  };
  return (
    <>
      <Grid item xs={4}>
        <Text variant="screenLabel">Question and Responses</Text>
      </Grid>
      {!getEventQuestionsObj?.data?.event?.publishQuestions && !isPublished && (
        <Grid item xs={4} sm={2}>
          {allowEditQuestion ? (
            <>
              <IconButton size="small" onClick={() => setAllowEditQuestion(false)}>
                <CheckIcon fontSize="small" />
              </IconButton>

              <IconButton size="small" onClick={() => setShowAddModal(true)}>
                <AddIcon fontSize="small" />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                onClick={() => {
                  setAllowEditQuestion(true);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </Grid>
      )}

      {!getEventQuestionsObj?.data?.event?.publishQuestions && !isPublished && (
        <Grid item xs={4} sm={6} textAlign="end">
          <CtaButton onClickButton={() => setShowPublishModal(true)} width="150px">
            Publish Questions
          </CtaButton>
        </Grid>
      )}

      <Form
        validationSchema={eventQuestionAddValidationSchema}
        onSubmit={onCreateEventQuestion}
        initialValues={{
          eventQuestion: "",
          open_ended: true,
          eventQuestionResponses: [],
        }}
      >
        <AddQuestionConfirmationModal
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          title="Add Question and Responses"
        />
      </Form>
      <PublishConfirmationModal
        showModal={showPublishModal}
        setShowModal={setShowPublishModal}
        title="Publish Questions and Responses"
        label="You will no longer be able to edit/delete these questions and responses or create new ones after publishing. Please confirm below to continue."
        isLoading={publishEventQuestionsObj.status === "pending"}
        onConfirmClicked={handlePublishQuestions}
      />
      <BackdropLoading isLoading={isLoading} />
    </>
  );
}

EventQuestionAdd.propTypes = {
  selectedEventId: PropTypes.string.isRequired,
  allowEditQuestion: PropTypes.bool.isRequired,
  setAllowEditQuestion: PropTypes.func.isRequired,
};
