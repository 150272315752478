import React from "react";
import { Box, Grid } from "@mui/material";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";
import Text from "../../../components/text.component";

export default function BusinessDetailLoader() {
  return (
    <>
      <Grid item xs={12}>
        <Text variant="screenLabel">Business Details</Text>
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoader width="100%" height="41.65px" />
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoader width="100%" height="41.65px" />
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoader width="100%" height="100px" />
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoader width="100%" height="41.65px" />
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoader width="100%" height="41.65px" />
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoader width="100%" height="41.65px" />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
          <SkeletonLoader width="125px" height="41.65px" />
        </Box>
      </Grid>
    </>
  );
}
