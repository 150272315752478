import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, patchReq, postReq, putReq } from "../api-services";

export const getRenewalDay = createAsyncThunk(
  "merchants/additional_fees/renewal_days",
  async () => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/additional_fees/renewal_days`,
    );
    return response;
  },
);

export const updateRenewalDay = createAsyncThunk(
  "merchants/additional_fees/update/renewal_days",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/additional_fees/renewal_days`,
      payload,
    );
    return response;
  },
);

export const searchAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/index",
  async (payload) => {
    const { q, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/additional_fees?${
        q ? "&q=".concat(q) : ""
      }&page=${page}`,
    );
    return response;
  },
);

export const createAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/additional_fees`,
      payload,
    );
    return response;
  },
);

export const deleteAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/delete/{fee_id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/additional_fees/${payload.feeId}`,
    );
    return response;
  },
);

export const getAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/get/{fee_id}",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/additional_fees/${payload.feeId}`,
    );
    return response;
  },
);

export const updateAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/update/{fee_id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/additional_fees/${payload.feeId}`,
      payload,
    );
    return response;
  },
);

export const updateAdditionalFeesStatus = createAsyncThunk(
  "merchants/additional_fees/update/{fee_id}/status",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/additional_fees/${payload.feeId}/status`,
      payload,
    );
    return response;
  },
);

const additionalFeesSlice = createSlice({
  name: "additionalFees",
  initialState: {
    getRenewalDayObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateRenewalDayObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateAdditionalFeesStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getRenewalDay.pending]: (state) => {
      state.getRenewalDayObj.status = "pending";
    },
    [getRenewalDay.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getRenewalDayObj.status = "succeeded";
      state.getRenewalDayObj.data = data;
      state.getRenewalDayObj.successMessage = message;
    },
    [getRenewalDay.rejected]: (state, action) => {
      const { message } = action.error;

      state.getRenewalDayObj.status = "failed";
      state.getRenewalDayObj.errorMessage = message;
    },
    [updateRenewalDay.pending]: (state) => {
      state.updateRenewalDayObj.status = "pending";
    },
    [updateRenewalDay.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateRenewalDayObj.status = "succeeded";
      state.updateRenewalDayObj.data = data;
      state.updateRenewalDayObj.successMessage = message;
    },
    [updateRenewalDay.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateRenewalDayObj.status = "failed";
      state.updateRenewalDayObj.errorMessage = message;
    },
    [searchAdditionalFees.pending]: (state) => {
      state.searchAdditionalFeesObj.status = "pending";
    },
    [searchAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchAdditionalFeesObj.status = "succeeded";
      state.searchAdditionalFeesObj.data = data;
      state.searchAdditionalFeesObj.successMessage = message;
    },
    [searchAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchAdditionalFeesObj.status = "failed";
      state.searchAdditionalFeesObj.errorMessage = message;
    },
    [createAdditionalFees.pending]: (state) => {
      state.createAdditionalFeesObj.status = "pending";
    },
    [createAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createAdditionalFeesObj.status = "succeeded";
      state.createAdditionalFeesObj.data = data;
      state.createAdditionalFeesObj.successMessage = message;
    },
    [createAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.createAdditionalFeesObj.status = "failed";
      state.createAdditionalFeesObj.errorMessage = message;
    },
    [deleteAdditionalFees.pending]: (state) => {
      state.deleteAdditionalFeesObj.status = "pending";
    },
    [deleteAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteAdditionalFeesObj.status = "succeeded";
      state.deleteAdditionalFeesObj.data = data;
      state.deleteAdditionalFeesObj.successMessage = message;
    },
    [deleteAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteAdditionalFeesObj.status = "failed";
      state.deleteAdditionalFeesObj.errorMessage = message;
    },
    [getAdditionalFees.pending]: (state) => {
      state.getAdditionalFeesObj.status = "pending";
    },
    [getAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAdditionalFeesObj.status = "succeeded";
      state.getAdditionalFeesObj.data = data;
      state.getAdditionalFeesObj.successMessage = message;
    },
    [getAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAdditionalFeesObj.status = "failed";
      state.getAdditionalFeesObj.errorMessage = message;
    },
    [updateAdditionalFees.pending]: (state) => {
      state.updateAdditionalFeesObj.status = "pending";
    },
    [updateAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateAdditionalFeesObj.status = "succeeded";
      state.updateAdditionalFeesObj.data = data;
      state.updateAdditionalFeesObj.successMessage = message;
    },
    [updateAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateAdditionalFeesObj.status = "failed";
      state.updateAdditionalFeesObj.errorMessage = message;
    },
    [updateAdditionalFeesStatus.pending]: (state) => {
      state.updateAdditionalFeesStatusObj.status = "pending";
    },
    [updateAdditionalFeesStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateAdditionalFeesStatusObj.status = "succeeded";
      state.updateAdditionalFeesStatusObj.data = data;
      state.updateAdditionalFeesStatusObj.successMessage = message;
    },
    [updateAdditionalFeesStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateAdditionalFeesStatusObj.status = "failed";
      state.updateAdditionalFeesStatusObj.errorMessage = message;
    },
  },
});

export default additionalFeesSlice.reducer;

// state
export const additionalFeesSelector = (state) => state.additionalFees;
