import {
  Box,
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  appointmentSelector,
  createAppointment,
  getAppointmentsSuggestion,
} from "../../../services/appointment/appointment-slice.service";
import FormSelect from "../components/form-select.component";
import StaffListAutoComplete from "../components/staff-autocomplete-single-select.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const validationSchema = Yup.object().shape({
  userId: Yup.number().nullable().required().label("User"),
  packageId: Yup.number().nullable().required().label("Package"),
  businessId: Yup.number().nullable().required().label("Business"),
  startDate: Yup.date().required().label("Start date"),
  endDate: Yup.date()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't be earlier than start date")
    .required("End date is required")
    .nullable(),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
});

const validationFitnessSchema = Yup.object().shape({
  userId: Yup.number().nullable().required().label("User"),
  packageId: Yup.number().nullable().required().label("Package"),
  businessId: Yup.number().nullable().required().label("Business"),
  startDate: Yup.date().required().label("Start date"),
  endDate: Yup.date()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't be earlier than start date")
    .required("End date is required")
    .nullable(),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  staff: Yup.object().nullable().required().shape({
    id: Yup.string().required(),
  }),
});

export default function AppointmentCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryType, setCategoryType] = useState("wellness");
  const [userList, setUserList] = useState([]);
  const { getAppointmentsSuggestionObj } = useSelector(appointmentSelector);

  const onCreateAppointment = (values, { resetForm }) => {
    const startAt = `${format(values.startDate, "yyyy-MM-dd")} ${dayjs(values.startTime).format(
      "HH:mm:00",
    )}`;
    const endAt = `${format(values.endDate, "yyyy-MM-dd")} ${dayjs(values.endTime).format(
      "HH:mm:00",
    )}`;

    const payloadParams = {
      userId: values.userId,
      packageId: values.packageId,
      businessId: values.businessId,
      startAt,
      endAt,
    };
    const payloadFitnessParams = {
      ...payloadParams,
      staffId: values.staff?.id,
    };

    setIsLoading(true);
    dispatch(
      createAppointment(categoryType === "fitness" ? payloadFitnessParams : payloadParams),
    ).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.APPOINTMENT_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getAppointmentsSuggestion({ packageCategoryType: categoryType })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        if (meta.requestStatus === "fulfilled") {
          setUserList(
            payload.data.items
              .map((item) => item.user)
              .filter((user, index, array) => array.findIndex((u) => u.id === user.id) === index),
          );
        }
      },
    );
  }, [categoryType]);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <BackdropLoading isLoading={isLoading} />
      <Formik
        validationSchema={categoryType === "fitness" ? validationFitnessSchema : validationSchema}
        onSubmit={onCreateAppointment}
        initialValues={{
          userId: null,
          packageId: null,
          businessId: null,
          startDate: new Date(),
          endDate: new Date(),
          startTime: dayjs(),
          endTime: dayjs().add(1, "hour"),
          staff: null,
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            <Grid item xs={12}>
              <SpaceBetweenBox>
                <Text variant="screenLabel">Create Appointment</Text>
                <Button
                  onClick={() => history.goBack()}
                  sx={{
                    textTransform: "none",
                    padding: "0px",
                    ":hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Text>Back</Text>
                </Button>
              </SpaceBetweenBox>
            </Grid>
            <Grid item xs={12}>
              <WhiteBgCardContainer>
                <Grid item xs={isMobile ? 12 : 4}>
                  <ToggleButtonGroup
                    value={categoryType}
                    exclusive
                    onChange={(e) => {
                      setCategoryType(e.target.value);
                      setFieldValue("userId", "");
                      setFieldValue("packageId", "");
                      setFieldValue("businessId", "");
                      setFieldValue("staff", null);
                    }}
                  >
                    <ToggleButton value="wellness" sx={{ whiteSpace: "nowrap" }}>
                      Wellness
                    </ToggleButton>
                    <ToggleButton value="health" sx={{ whiteSpace: "nowrap" }}>
                      Health
                    </ToggleButton>
                    <ToggleButton value="fitness" sx={{ whiteSpace: "nowrap" }}>
                      Fitness
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Spacer />
                </Grid>
                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={12}>
                    <Text variant="screenLabel">Appointment Details</Text>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>User</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>User</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormSelect
                            itemList={userList?.map((user) => ({
                              id: user.id,
                              label: user.name,
                              value: user.id,
                            }))}
                            name="userId"
                            placeholder="Select user"
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Package</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Package</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormSelect
                            itemList={
                              values.userId
                                ? getAppointmentsSuggestionObj.data.items
                                    ?.filter((item) => item.user.id === values.userId)
                                    ?.map((item) => ({
                                      id: item.package.id,
                                      value: item.package.id,
                                      label: item.plan.title,
                                    }))
                                : []
                            }
                            name="packageId"
                            placeholder="Select User First"
                            disabled={!values.userId}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormSelect
                            itemList={
                              values.packageId
                                ? getAppointmentsSuggestionObj.data.items
                                    ?.filter(
                                      (item) =>
                                        item.user.id === values.userId &&
                                        item.package.id === values.packageId,
                                    )
                                    ?.flatMap((item) => item.plan.businesses)
                                    ?.map((business) => ({
                                      id: business.id,
                                      value: business.id,
                                      label: business.name,
                                    }))
                                : []
                            }
                            name="businessId"
                            placeholder="Select User & Package First"
                            disabled={!values.userId || !values.packageId}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Date</Text>
                      </LabelContainer>
                    )}

                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Date</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormDatePicker
                            name="startDate"
                            width="100%"
                            setEndDateAsSame
                            maxDays={null}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                      </LabelContainer>
                    )}

                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormTimePicker name="startTime" width="100%" setEndTimeAnHourLater />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Date</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Date</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormDatePicker name="endDate" width="100%" maxDays={null} />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormTimePicker name="endTime" width="100%" />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>
                </Grid>

                {categoryType === "fitness" && (
                  <Box sx={{ marginTop: isMobile ? "8px" : "24px" }}>
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Staff</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Staff</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <StaffListAutoComplete name="staff" placeholder="Add staff" />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Box>
                )}
              </WhiteBgCardContainer>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
              <FormSubmitButton width="200px">
                <Text type="WhiteColor">Create</Text>
              </FormSubmitButton>
            </Grid>
          </>
        )}
      </Formik>
    </Grid>
  );
}
