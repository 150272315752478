import {
  Box,
  Button,
  Grid,
  Modal,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  createHourlyTimeslot,
  deleteHourlyTimeslot,
  getHourlyTimeslotList,
  getTakenHourlyTimeslotList,
  hourlyTimeslotSelector,
} from "../../../services/hourly-timeslot/hourly-timeslot.slice.service";
import HourlyTimeslotTableRow from "../components/hourly-timeslot-table-row.component";
import HourlyTimeslotTableRowLoader from "../loader/hourly-timeslot-table-row-loader.component";
import TableSort from "../../../components/table/table-sort.component";
import Spacer from "../../../components/spacer.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormCheckBox from "../../../components/forms/form-check-box.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormTimePicker from "../components/form-hour-picker.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

export default function HourlyTimeslotListScreen() {
  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddTimeslot, setOpenAddTimeslot] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTimeslotId, setSelectedTimeslotId] = useState("");
  const { getHourlyTimeslotListObj } = useSelector(hourlyTimeslotSelector);
  const { getTakenHourlyTimeslotListObj, deleteHourlyTimeslotObj } = useSelector(hourlyTimeslotSelector);

  const columnMapping = {
    "Start Time": "startTime",
    "End Time": "endTime",
    Capacity: "capacity",
    Price: "price",
    "Exclusive Price": "exclusivePrice",
    Unlimited: "unlimited",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshHourlyTimeslotList = (newPage) => {
    setPage(newPage);
    dispatch(getHourlyTimeslotList({ page: newPage })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onGetTakenHourlyTimeslotList = () => {
    dispatch(getTakenHourlyTimeslotList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    onRefreshHourlyTimeslotList(newPage);
  };

  useEffect(() => {
    onRefreshHourlyTimeslotList(page);
    onGetTakenHourlyTimeslotList();
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getHourNum = (val) => val?.split(":")[0] || "";

  const disabledHours = (records) => records?.map((r) => Number(getHourNum(r)));

  const getProcessedRecord = () => {
    let records = null;

    if (getHourlyTimeslotListObj.data && getHourlyTimeslotListObj.status === "succeeded") {
      records = { ...getHourlyTimeslotListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const onHandleDelete = (timeslotId) => {
    setSelectedTimeslotId(timeslotId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteHourlyTimeslot({ timeslotId: selectedTimeslotId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshHourlyTimeslotList(1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderTableBody = () => {
    if (getHourlyTimeslotListObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getHourlyTimeslotListObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={4}>
              <TableEmptyBox>
                <Text type="TableText">No timeslots</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <HourlyTimeslotTableRow timeslot={item} key={item.id} onHandleDelete={onHandleDelete} />
      ));
    }
    return <HourlyTimeslotTableRowLoader />;
  };

  const renderStartTimePicker = () => {
    if (
      getTakenHourlyTimeslotListObj.status === "succeeded" &&
      getTakenHourlyTimeslotListObj.data
    ) {
      return (
        <FormTimePicker
          name="startTime"
          setEndTimeAnHourLater={true}
          disabledHours={disabledHours([...getTakenHourlyTimeslotListObj?.data]) || []}
        />
      );
    }
    if (getTakenHourlyTimeslotListObj.status === "failed") {
      return (
        <FormTimePicker
          name="startTime"
          setEndTimeAnHourLater={true}
          disabledHours={[]}
          isDisabled={true}
        />
      );
    }
    return <></>;
  };

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().nullable().required().label("Start time"),
    endTime: Yup.string().nullable().required().label("End time"),
    capacity: Yup.string().required(),
    price: Yup.number().min(0).typeError("Price is required").label("Price"),
    exclusivePrice: Yup.number().min(0).nullable().required().label("Exclusive Price"),
  });

  const onCreateTimeslot = (values, { resetForm }) => {
    const startTime = `${dayjs(values.startTime).format("HH:00:00")}`;
    const endTime = `${dayjs(values.endTime).format("HH:00:00")}`;

    if (startTime >= endTime) {
      formRef.current.setFieldError("endTime", "End time must be later than start time");
      return;
    }

    const payloadParams = {
      startTime,
      endTime,
      amountCents: values.price * 100,
      exclusiveReferralAmountCents: values.exclusivePrice * 100,
      capacity: values.unlimited ? 0 : values.capacity,
      unlimited: values.unlimited,
    };

    setIsLoading(true);

    dispatch(createHourlyTimeslot(payloadParams)).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        setOpenAddTimeslot(false);
        onRefreshHourlyTimeslotList(1);
        onGetTakenHourlyTimeslotList();
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <BackdropLoading isLoading={isLoading} />

      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Hourly Timeslot"
        label="Are you sure you wish to remove this timeslot?"
        isLoading={deleteHourlyTimeslotObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />

      <Grid item xs={9}>
        <Text variant="screenLabel">Hourly Timeslot List</Text>
      </Grid>
      <Grid item xs={3}>
        <Button
          onClick={() => setOpenAddTimeslot(true)}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.colors.brand.primary,
            width: 40,
            height: 40,
            borderRadius: "100%",
            marginLeft: "auto",
            marginRight: "6px",
            display: "flex",
            minWidth: 0,
          }}
        >
          <AddIcon fontSize="small" />
        </Button>
      </Grid>
      <Formik
        innerRef={formRef}
        initialValues={{
          capacity: 10,
          unlimited: false,
          price: null,
          exclusivePrice: null,
          startTime: "",
          endTime: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onCreateTimeslot}
      >
        {({ values }) => (
          <Modal
            open={openAddTimeslot}
            onClose={() => setOpenAddTimeslot(false)}
            aria-labelledby="add timeslot"
          >
            <ModalBox sx={{ padding: isMobile ? "20px" : "30px", width: "90%", maxWidth: "400px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Add Timeslot
              </Typography>
              <Spacer size="m" />
              <Grid container columnSpacing={3} rowSpacing={1}>
                <Grid item xs={12}>
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                  <Spacer />
                  {renderStartTimePicker()}
                  <Spacer size="m" />

                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                  <Spacer />
                  <FormTimePicker name="endTime" />
                  <Spacer size="m" />

                  {!values.unlimited && (
                    <>
                      <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Capacity</Text>
                      <Spacer />
                      <FormFieldText
                        name="capacity"
                        placeholder="0"
                        label="Capacity"
                        type="number"
                      />
                      <Spacer size="m" />
                    </>
                  )}

                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                  <Spacer />
                  <FormPrice placeholder="00.00" name="price" />
                  <Spacer size="m" />

                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Exclusive Price</Text>
                  <Spacer />
                  <FormPrice placeholder="00.00" name="exclusivePrice" />
                  <Spacer size="m" />

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormCheckBox name="unlimited" />
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold, marginLeft: "-12px" }}>
                      Unlimited
                    </Text>
                  </Box>
                  <Spacer size="l" />

                  <FormSubmitButton width="200px">
                    <Text type="WhiteColor">Submit</Text>
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </ModalBox>
          </Modal>
        )}
      </Formik>

      <Grid item xs={12}>
        <TableWrapper>
          <Table>
            <TableHeader
              headerCells={[
                "Start Time",
                "End Time",
                "Capacity",
                "Price",
                "Exclusive Price",
                "Unlimited",
                "Action",
              ]}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
              columnMapping={columnMapping}
            />
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      <Grid item xs={12}>
        <CustomFooter>
          <Pagination
            sx={{
              "&& .Mui-selected": {
                backgroundColor: theme.palette.colors.brand.primary,
                color: theme.palette.colors.text.white,
              },
            }}
            page={page}
            shape="rounded"
            onChange={onPageChange}
            count={getHourlyTimeslotListObj?.data?.pagination.totalPages || totalPages}
            variant="outlined"
          />
        </CustomFooter>
      </Grid>
    </Grid>
  );
}
