import React from "react";
import PropTypes from "prop-types";
import { Box, InputAdornment, styled, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Text from "../../../components/text.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import Spacer from "../../../components/spacer.component";
import { profileSelector } from "../../../services/profile/profile.slice.services";
import routes from "../../../navigation/routes";
import CtaButton from "../../../components/button/cta-button.component";
import { profileStaffSelector } from "../../../services/staff/profile/profile.slice.services";
import { isMerchantStaff } from "../../../infrastructure/utils";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const DisabledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.white,
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
}));

const SpacebetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
});

export default function ProfileAccountDetailEditForm({
  name,
  label,
  placeholder,
  isEditing,
  setIsEditing,
  allowEdit,
}) {
  const { handleSubmit, values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const { getProfileDetailObj, updateProfileDetailObj } = useSelector(profileSelector);
  const { getStaffProfileDetailObj, updateStaffProfileDetailObj } =
    useSelector(profileStaffSelector);
  const history = useHistory();

  const pushToVerify = () => {
    history.push({
      pathname: routes.EMAIL_VERIFICATION,
      state: {
        email: isMerchantStaff()
          ? getStaffProfileDetailObj.data.email
          : getProfileDetailObj.data.email,
      },
    });
  };

  const renderButton = () => {
    if (isEditing) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {isSmallScreen && (
            <>
              <CtaButton
                width={isSmallScreen ? null : "70px"}
                padding="8px"
                onClickButton={() => handleSubmit()}
                isLoading={
                  updateProfileDetailObj.status === "pending" ||
                  updateStaffProfileDetailObj.status === "pending"
                }
              >
                <Text type="WhiteColor">Save</Text>
              </CtaButton>
              <Spacer position="left" size="s" />
            </>
          )}
          <CtaButton
            width={isSmallScreen ? null : "70px"}
            padding="8px"
            onClickButton={() => {
              if (!isMerchantStaff()) {
                setFieldValue(name, getProfileDetailObj.data[name]);
              }
              if (isMerchantStaff()) {
                setFieldValue(name, getStaffProfileDetailObj.data[name]);
              }

              setIsEditing(false);
            }}
            isLoading={
              updateProfileDetailObj.status === "pending" ||
              updateStaffProfileDetailObj.status === "pending"
            }
          >
            <Text type="WhiteColor">Cancel</Text>
          </CtaButton>
        </Box>
      );
    }
    return (
      <CtaBorderButton
        width={isSmallScreen ? null : "70px"}
        padding="8px"
        onClickButton={() => setIsEditing(true)}
      >
        <Text type="BrandColor">Edit</Text>
      </CtaBorderButton>
    );
  };

  return (
    <>
      {isSmallScreen && (
        <SpacebetweenRowBox>
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
          </LabelContainer>
          {allowEdit && renderButton()}
        </SpacebetweenRowBox>
      )}

      <FormContainer>
        {!isSmallScreen && (
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
          </LabelContainer>
        )}
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Box sx={{ width: "100%" }}>
            {isEditing ? (
              <FormFieldText isDisableEnter name={name} placeholder={placeholder} width="100%" />
            ) : (
              <DisabledTextField
                value={values[name] === null ? "" : values[name]}
                disabled
                variant="outlined"
                InputProps={{
                  endAdornment: name.toUpperCase().includes("EMAIL") &&
                    allowEdit &&
                    !getProfileDetailObj?.data?.emailVerified && (
                      <InputAdornment position="end">
                        <CtaBorderButton padding="3px" onClickButton={pushToVerify}>
                          <Text type="BrandColor">Verify</Text>
                        </CtaBorderButton>
                      </InputAdornment>
                    ),
                }}
              />
            )}
          </Box>
          {isEditing && !isSmallScreen && (
            <>
              <Spacer position="top" size="m" />
              <CtaButton
                width={isSmallScreen ? null : "70px"}
                padding="8px"
                onClickButton={() => handleSubmit()}
                isLoading={
                  updateProfileDetailObj.status === "pending" ||
                  updateStaffProfileDetailObj.status === "pending"
                }
              >
                <Text type="WhiteColor">Save</Text>
              </CtaButton>
            </>
          )}
        </Box>

        {!isSmallScreen && (
          <>
            <Spacer position="left" size="m" />
            {allowEdit ? renderButton() : <Box sx={{ width: "70px" }} />}
          </>
        )}
      </FormContainer>
    </>
  );
}

ProfileAccountDetailEditForm.defaultProps = {
  allowEdit: true,
};

ProfileAccountDetailEditForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  allowEdit: PropTypes.bool,
};
