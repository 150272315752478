import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useHistory } from "react-router-dom";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function PackageTableRow({ packagePlan, onHandleDelete }) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const history = useHistory();

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const handleOpenDetail = () => {
    setShowDetail(!showDetail);
  };

  const onToggleDelete = () => {
    onHandleDelete(packagePlan.id);
    handleCloseMenu();
  };

  const onHandleEdit = () => {
    history.push({
      pathname: routes.PACKAGE_EDIT,
      search: `${packagePlan.id}`,
      state: packagePlan,
    });
    handleCloseMenu();
  };

  const onHandleReport = () => {
    history.push({
      pathname: routes.PACKAGE_REPORT,
      search: `${packagePlan.id}`,
      state: packagePlan,
    });
    handleCloseMenu();
  };

  const getStatusColor = (status) => {
    if (status === "inactive") {
      return "RedColor";
    }
    if (status === "pending") {
      return "YellowColor";
    }
    return "BrandColor";
  };

  return (
    <>
      <TableRow
        key={packagePlan.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {packagePlan.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM${packagePlan.price}`}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {packagePlan.noOfSessions}
          </Text>
        </TableCell>

        <TableCell>
          <img src={packagePlan.imagePath} alt="package" width={70} />
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {packagePlan.dateMotified}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            sx={{ textAlign: "center" }}
            type={getStatusColor(packagePlan.status)}
          >
            {packagePlan.status.charAt(0).toUpperCase() + packagePlan.status.slice(1)}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon sx={{ color: theme.palette.colors.text.table }} />
            </IconButton>
            <StyledMenu
              sx={{ mt: "20px" }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem key={1} onClick={onHandleEdit}>
                <Text textAlign="center">Edit</Text>
              </MenuItem>

              {packagePlan.deletable && (
                <MenuItem key={2} onClick={onToggleDelete}>
                  <Text textAlign="center">Remove</Text>
                </MenuItem>
              )}
              <MenuItem key={3} onClick={onHandleReport}>
                <Text textAlign="center">Report</Text>
              </MenuItem>
            </StyledMenu>
            <IconButton onClick={handleOpenDetail}>
              {showDetail ? (
                <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.text.table }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.text.table }} />
              )}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {showDetail && (
        <>
          <TableRow>
            <TableCell colSpan={9}>
              <Text marginLeft="2%">Items</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            {packagePlan.items.length === 0 ? (
              <TableCell colSpan={9}>
                <Box>
                  <Text type="TableText" marginLeft="2%">
                    No items
                  </Text>
                  <Spacer />
                </Box>
              </TableCell>
            ) : (
              <TableCell colSpan={9}>
                {packagePlan.items.map((item) => (
                  <Box key={item.id}>
                    <Text type="TableText" marginLeft="2%">
                      {item.name}
                    </Text>
                    <Spacer />
                  </Box>
                ))}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell colSpan={7}>
              <Text marginLeft="2%">Businesses Name</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7}>
              {packagePlan.businesses.map((business) => (
                <div key={business.name}>
                  <Text type="TableText" marginLeft="2%">
                    {business.name}
                  </Text>
                  <Spacer />
                </div>
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7}>
              <Text marginLeft="2%">Category</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7}>
              {packagePlan.category.items.map((item) => (
                <Box key={item.name}>
                  <Text marginLeft="2%" type="TableText">
                    {item.name.charAt(0) + item.name.slice(1).toLowerCase()}
                  </Text>
                  <Spacer />
                </Box>
              ))}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

PackageTableRow.propTypes = {
  onHandleDelete: PropTypes.func.isRequired,
  packagePlan: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        start_at: PropTypes.string,
        end_at: PropTypes.string,
      }),
    ),
    noOfSessions: PropTypes.number,
    imagePath: PropTypes.string,
    dateMotified: PropTypes.string,
    status: PropTypes.string,
    deletable: PropTypes.bool,
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    category: PropTypes.shape({
      model: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
};
