import React, { useState, useContext } from "react";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import { Box, Grid, IconButton, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import {
  deletePackageDetail,
  updatePackageDetail,
} from "../../../services/package/package-slice.service";

const DetailTextField = styled(Field)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius[0],
  padding: "10px",
  font: "inherit",
  border: "2px solid #D0D0D0",
  backgroundColor: `${theme.palette.colors.bg.primary}`,
}));

const PackageDetail = ({ detail, planId, allowEditDetail }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const [tempDetail, setTempDetail] = useState(detail.description);
  const [editing, setEditing] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleChange = (e) => {
    setEditing(true);
    setTempDetail(e.target.value);
  };

  const handleSubmit = () => {
    setEditing(false);
    dispatch(updatePackageDetail({ planId, detailId: detail.id, description: tempDetail })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const handleDelete = () => {
    setDeleted(true);
    dispatch(deletePackageDetail({ planId, detailId: detail.id })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  return (
    <>
      {!deleted && (
        <Grid container item>
          <Grid item xs={allowEditDetail ? 10 : 12}>
            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <DetailTextField
                placeholder="Enter package details"
                type="text"
                value={tempDetail}
                name="package-detail"
                onChange={handleChange}
                disabled={!allowEditDetail}
                style={{ color: !allowEditDetail && theme.palette.colors.text.disabled }}
                sx={{
                  "&:hover": {
                    border: allowEditDetail && `2px solid black`,
                  },
                }}
              />
            </Box>
          </Grid>
          {allowEditDetail && (
            <>
              <Grid item xs={1}>
                <IconButton onClick={handleSubmit} disabled={!editing}>
                  <CheckCircleIcon
                    style={{ color: editing && theme.palette.colors.brand.primary }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={handleDelete}>
                  <RemoveCircleIcon style={{ color: theme.palette.colors.ui.errorBox }} />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

PackageDetail.propTypes = {
  detail: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  planId: PropTypes.number.isRequired,
  allowEditDetail: PropTypes.bool.isRequired,
};

export default PackageDetail;
