import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { DebounceInput } from "react-debounce-input";
import { TextField, Box, FormHelperText } from "@mui/material";

function EmailCheckerField({ name, placeholder, errorMessage }) {
  const theme = useTheme();
  const { setFieldValue, errors, touched, values, submitForm, setFieldTouched } =
    useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      setFieldValue(name, e.target.value);
      setTimeout(() => {
        submitForm();
      }, 50);
    }
  };

  const onChangeText = (e) => {
    setFieldValue(name, e.target.value);
    setTimeout(() => {
      submitForm();
    }, 50);
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: theme.palette.colors.bg.secondary,
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        },
      }}
    >
      <DebounceInput
        onBlur={() => setFieldTouched(name)}
        variant="outlined"
        debounceTimeout={500}
        onChange={onChangeText}
        element={TextField}
        value={values.name}
        onKeyDown={keyPress}
        InputProps={{
          placeholder,
          error: showError || errorMessage !== "",
        }}
      />
      {(showError || errorMessage) && (
        <FormHelperText
          sx={{
            color: theme.palette.colors.text.white,
            backgroundColor: theme.palette.colors.ui.errorBox,
            padding: "10px",
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
          }}
        >
          {errors[name] || errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
}

EmailCheckerField.defaultProps = {
  errorMessage: "",
};

EmailCheckerField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default EmailCheckerField;
