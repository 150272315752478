import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { putReq } from "../../api-services";

export const updateStaffPermission = createAsyncThunk(
  "merchants/staffs/permissions/{staff_id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/${payload.staffId}/permissions`,
      payload,
    );
    return response;
  },
);

const permissionStaffSlice = createSlice({
  name: "permissionStaff",
  initialState: {
    updateStaffPermissionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [updateStaffPermission.pending]: (state) => {
      state.updateStaffPermissionObj.status = "pending";
    },
    [updateStaffPermission.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateStaffPermissionObj.status = "succeeded";
      state.updateStaffPermissionObj.data = data;
      state.updateStaffPermissionObj.successMessage = message;
    },
    [updateStaffPermission.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateStaffPermissionObj.status = "failed";
      state.updateStaffPermissionObj.errorMessage = message;
    },
  },
});

export default permissionStaffSlice.reducer;

// state
export const permissionStaffSelector = (state) => state.permissionStaff;
