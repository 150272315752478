import { Box, Grid, Pagination, styled, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  businessSelector,
  searchBusiness,
} from "../../../services/business/business.slice.services";
import BusinessItem from "../components/business-item.component";
import StatesFilter from "../components/checkbox-filter.component";
import SearchBar from "../components/search-bar.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const STATELIST = [
  { label: "Johor", id: "1", value: "johor" },
  { label: "Kedah", id: "2", value: "kedah" },
  { label: "Kelantan", id: "3", value: "kelantan" },
  { label: "Wilayah Persekutuan Kuala Lumpur", id: "4", value: "wilayah persekutuan kuala lumpur" },
  { label: "Melaka", id: "5", value: "melaka" },
  { label: "Negeri Sembilan", id: "6", value: "negeri sembilan" },
  { label: "Pahang", id: "7", value: "pahang" },
  { label: "Penang", id: "8", value: "penang" },
  { label: "Perak", id: "9", value: "perak" },
  { label: "Perlis", id: "10", value: "perlis" },
  { label: "Putrajaya", id: "11", value: "putrajaya" },
  { label: "Sabah", id: "12", value: "sabah" },
  { label: "Sarawak", id: "13", value: "sarawak" },
  { label: "Selangor", id: "14", value: "selangor" },
  { label: "Terengganu", id: "15", value: "terengganu" },
  { label: "Labuan", id: "16", value: "labuan" },
];

const STATUSLIST = [
  { label: "Publish", id: "1", value: "publish" },
  { label: "Draft", id: "2", value: "draft" },
  { label: "Rejected", id: "3", value: "rejected_admin" },
];

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  states: Yup.array().of(Yup.string()).nullable(),
  statuses: Yup.array().of(Yup.string()).nullable(),
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

export default function UserBusinessAccessScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("860"));
  const isShowThreeItems = useMediaQuery(theme.breakpoints.down("1150"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const formRef = useRef();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const location = useLocation();
  const states = new URLSearchParams(location.search).get("states");
  const statuses = new URLSearchParams(location.search).get("status") || null;
  const q = new URLSearchParams(location.search).get("q") || "";
  const { searchBusinessObj } = useSelector(businessSelector);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    dispatch(searchBusiness({ search: q, states, statuses: statuses?.toLowerCase(), page })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setTotalPages(payload.data.pagination.totalPages);
        }
        if (meta.requestStatus === "rejected") {
          setTotalPages(1);
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  }, [location.search, page]);

  const onPageChange = (e, newPage) => {
    setPage(newPage);
  };

  const clearFilter = () => {
    formRef.current.setFieldValue("q", "");
    formRef.current.setFieldValue("states", []);
    formRef.current.setFieldValue("statuses", []);
    history.push({ pathname: routes.USER_BUSINESS_ACCESS });
  };

  const submitFilter = (values) => {
    let search = "";
    if (values.states.length > 0) {
      search += `&states=${values.states.toString()}`;
    }
    if (values.statuses.length > 0) {
      search += `&status=${values.statuses.toString().replace("Rejected", "rejected_admin")}`;
    }
    if (values.q) {
      search += `&q=${values.q}`;
    }
    history.push({ pathname: routes.USER_BUSINESS_ACCESS, search: `${search}&page=${page}` });
  };

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    if (isShowThreeItems) {
      return 4;
    }
    return 3;
  };

  const renderBusinessList = () => {
    if (searchBusinessObj.status === "succeeded") {
      if (searchBusinessObj.data.items.length <= 0) {
        return (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "200px",
                alignItems: "center",
              }}
            >
              <Text>No Items</Text>
            </Box>
          </Grid>
        );
      }
      return searchBusinessObj.data.items.map((item) => (
        <Grid item xs={getItemToShow()} key={item.id}>
          <BusinessItem business={item} />
        </Grid>
      ));
    }

    return (
      <>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="280px" />
        </Grid>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="280px" />
        </Grid>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="280px" />
        </Grid>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="280px" />
        </Grid>
      </>
    );
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        states: states?.split(",") || [],
        statuses: statuses?.split(",") || [],
        q: q || "",
      }}
      onSubmit={submitFilter}
      validationSchema={validationSchema}
    >
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        <Grid item xs={12}>
          <Text variant="screenLabel">Business Access</Text>
        </Grid>

        <WhiteBgCardContainer>
          <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px", marginTop: "10px" }}>
            <Grid item container spacing={3}>
              <Grid item xs={12} sm={4}>
                <SearchBar placeholder="Search by title" name="q" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StatesFilter itemList={STATELIST} label="States" name="states" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StatesFilter itemList={STATUSLIST} label="Status" name="statuses" />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                  <Grid item xs={12} sm={2}>
                    <FormSubmitButton whiteBg>
                      <Text type="BrandColor">Search</Text>
                    </FormSubmitButton>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <CtaButton whiteBg onClickButton={clearFilter}>
                      <Text type="BrandColor">Clear All</Text>
                    </CtaButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SearchContainer>

          <Spacer size="m" />
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {renderBusinessList()}
              <Grid item xs={12}>
                <CustomFooter>
                  <Pagination
                    shape="rounded"
                    sx={{
                      "&& .Mui-selected": {
                        backgroundColor: theme.palette.colors.brand.primary,
                        color: theme.palette.colors.text.white,
                      },
                    }}
                    page={parseInt(page, 10)}
                    onChange={onPageChange}
                    count={totalPages}
                    variant="outlined"
                  />
                </CustomFooter>
              </Grid>
            </Grid>
          </Grid>
        </WhiteBgCardContainer>
      </Grid>
    </Formik>
  );
}
