import React from "react";
import { Box, Button, styled, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPhoneNumber from "../../../components/forms/form-phone-number.component";

const ButtonContainer = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  borderRadius: theme.shape.borderRadius[1],
  overflow: "hidden",
}));

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export default function OtpTypeForm() {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();

  return (
    <MainContainer>
      <Text type="GreyColor" sx={{ textAlign: "center" }}>{`Please enter your ${
        values.type === "email" ? "email address." : "phone number."
      }`}</Text>
      <Spacer size="m" position="top" />
      <ButtonContainer>
        <Button
          onClick={() => {
            setFieldValue("type", "email");
            setFieldValue("phone", "");
          }}
          sx={{
            textTransform: "none",
            width: "50%",
            backgroundColor:
              values.type === "email"
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.button.idle,
            ":hover": {
              backgroundColor:
                values.type === "email"
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.button.idle,
            },
          }}
        >
          <Text type={values.type === "email" ? "WhiteColor" : "GreyColor"}>Email</Text>
        </Button>
        <Button
          onClick={() => {
            setFieldValue("type", "phone");
            setFieldValue("email", "");
          }}
          sx={{
            padding: "10px",
            textTransform: "none",
            width: "50%",
            backgroundColor:
              values.type === "phone"
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.button.idle,
            ":hover": {
              backgroundColor:
                values.type === "phone"
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.button.idle,
            },
          }}
        >
          <Text type={values.type === "phone" ? "WhiteColor" : "GreyColor"}>Phone</Text>
        </Button>
      </ButtonContainer>
      <Spacer size="m" position="top" />
      {values.type === "email" ? (
        <FormFieldText name="email" placeholder="Enter your email" />
      ) : (
        <FormPhoneNumber name="phone" placeholder="Enter your phone" />
      )}
      <Spacer size="m" position="top" />
    </MainContainer>
  );
}
