import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CtaButton from "../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import SearchBar from "../../../components/table/search-bar.component";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  deletePackage,
  packageSelector,
  searchPackage,
} from "../../../services/package/package-slice.service";
import PackageTableRow from "../components/package-table-row.component";
import PackageTableRowLoader from "../loader/package-table-row-loader.component";
import TableSort from "../../../components/table/table-sort.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
  padding: "20px",
}));

export default function PackageListScreen() {
  const theme = useTheme();
  const history = useHistory();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const { searchPackageObj, deletePackageObj } = useSelector(packageSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const columnMapping = {
    Name: "name",
    Price: "price",
    "No. of sessions": "noOfSessions",
    "Date Modified": "dateMotified",
    Status: "status",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshPackageList = (newPage, keyword) => {
    setPage(newPage);
    setQ(keyword);
    dispatch(searchPackage({ q: keyword, page: newPage })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeSearch = (keyword) => {
    onRefreshPackageList(1, keyword);
  };

  const onPageChange = (e, newPage) => {
    onRefreshPackageList(newPage, q);
  };

  const onHandleDelete = (planId) => {
    setSelectedPlanId(planId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deletePackage({ planId: selectedPlanId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshPackageList(page, q);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onRefreshPackageList(page, q);
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (searchPackageObj.data && searchPackageObj.status === "succeeded") {
      records = { ...searchPackageObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (searchPackageObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (searchPackageObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <TableEmptyBox>
                <Text type="TableText">No Packages</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <PackageTableRow packagePlan={item} key={item.id} onHandleDelete={onHandleDelete} />
      ));
    }
    return <PackageTableRowLoader />;
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Package"
        label="Are you sure you wish to remove this package?"
        isLoading={deletePackageObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Grid item xs={12}>
        <Text variant="screenLabel">Package Plans</Text>
      </Grid>
      <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
        <WhiteBgCardContainer>
          <BackdropLoading isLoading={isLoading} />

          <SearchContainer>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <SearchBar
                    searchKeyword={q}
                    setSearchKeyword={onChangeSearch}
                    placeholder="Search here"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CtaButton onClickButton={() => history.push(routes.PACKAGE_CREATE)}>
                    <Text type="WhiteColor">Create Package Plan</Text>
                  </CtaButton>
                </Grid>
              </Grid>
            </Grid>
          </SearchContainer>

          <Grid container sx={{ paddingY: "20px" }} spacing={3}>
            <Grid item xs={12}>
              <TableWrapper>
                <Table>
                  <TableHeader
                    headerCells={[
                      "Name",
                      "Price",
                      "No. of sessions",
                      "Image",
                      "Date Modified",
                      "Status",
                      "Action",
                    ]}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={onSortChange}
                    columnMapping={columnMapping}
                    nonSortableColumns={["Image"]}
                  />
                  <TableBody>{renderTableBody()}</TableBody>
                </Table>
              </TableWrapper>
            </Grid>
            <Grid item xs={12}>
              <CustomFooter>
                <Pagination
                  sx={{
                    "&& .Mui-selected": {
                      backgroundColor: theme.palette.colors.brand.primary,
                      color: theme.palette.colors.text.white,
                    },
                  }}
                  page={page}
                  shape="rounded"
                  onChange={onPageChange}
                  count={searchPackageObj?.data?.pagination.totalPages || totalPages}
                  variant="outlined"
                />
              </CustomFooter>
            </Grid>
          </Grid>
        </WhiteBgCardContainer>
      </Grid>
    </Grid>
  );
}
