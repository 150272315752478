import InfoIcon from "@mui/icons-material/Info";
import { Box, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import DialogModal from "../../../components/notification/dialog-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import {
  getCurrentStatement,
  statementSelector,
} from "../../../services/statement/statement-slice.service";
import StatementCard from "../components/statement-card.component";

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StatusContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  paddingTop: "5px",
  paddingBottom: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "125px",
}));

const InfoButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  color: theme.palette.colors.ui.blue,
}));

export default function StatementCurrentListScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [showModal, setShowModal] = useState(false);
  const { getCurrentStatementObj } = useSelector(statementSelector);

  useEffect(() => {
    dispatch(getCurrentStatement()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Box
      width="100%"
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <Grid container spacing={3}>
        <DialogModal
          showModal={showModal}
          setShowModal={setShowModal}
          title="Payments made through bank accounts take up to 3-5 business days."
          buttonText="OK"
          onConfirm={() => setShowModal(false)}
        />
        <Grid item xs={12}>
          <Text variant="screenLabel">Current Statements</Text>
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
          <WhiteBgCardContainer>
            {getCurrentStatementObj.status === "succeeded" && (
              <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                {getCurrentStatementObj.data.rejected.length === 0 &&
                  getCurrentStatementObj.data.open.length === 0 &&
                  getCurrentStatementObj.data.pending.length === 0 && (
                    <Grid item xs={12}>
                      <CenterRowBox sx={{ justifyContent: "center" }}>
                        <Text>Currently no statement</Text>
                      </CenterRowBox>
                    </Grid>
                  )}
                {getCurrentStatementObj.data.rejected.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <StatusContainer sx={{ backgroundColor: theme.palette.colors.ui.redDot }}>
                          <Text type="WhiteColor">Rejected</Text>
                        </StatusContainer>
                      </Grid>
                      {getCurrentStatementObj.data.rejected.map((item) => (
                        <Grid item xs={12} key={item.statementId}>
                          <StatementCard
                            id={item.statementId}
                            price={item.amount}
                            status="rejected"
                            reason={item.rejectedReason}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                {getCurrentStatementObj.data.open.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <StatusContainer sx={{ backgroundColor: theme.palette.colors.ui.greenDot }}>
                          <Text type="WhiteColor">Open</Text>
                        </StatusContainer>
                      </Grid>
                      {getCurrentStatementObj.data.open.map((item) => (
                        <Grid item xs={12} key={item.statementId}>
                          <StatementCard id={item.statementId} price={item.amount} status="open" />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                {getCurrentStatementObj.data.pending.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <CenterRowBox>
                          <StatusContainer sx={{ backgroundColor: theme.palette.colors.ui.blue }}>
                            <Text type="WhiteColor">To be paid</Text>
                          </StatusContainer>
                          <Spacer size="s" position="left" />
                          <InfoButton onClick={() => setShowModal(true)}>
                            <InfoIcon />
                          </InfoButton>
                        </CenterRowBox>
                      </Grid>
                      {getCurrentStatementObj.data.pending.map((item) => (
                        <Grid item xs={12} key={item.statementId}>
                          <StatementCard
                            id={item.statementId}
                            price={item.amount}
                            status="pending"
                            date={item.nextTransferDate}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </WhiteBgCardContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
