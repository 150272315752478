import { Box, Drawer, Link, styled, useTheme } from "@mui/material";
import React from "react";
import Spacer from "../../components/spacer.component";
import KatchLogo from "../../images/katch-logo.png";
import routes from "../../navigation/routes";
import DrawerItems from "./drawer-items.component";

const CenterBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  paddingLeft: "30px",
});

function DesktopDrawer() {
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={true}
      elevation={1}
      sx={{
        width: "200px",
        "& .MuiDrawer-paper": {
          width: "200px",
          backgroundColor: theme.palette.colors.bg.white,
        },
      }}
    >
      <Spacer size="xl" />
      <CenterBox>
        <Link href={routes.HOME} style={{ height: "35px" }}>
          <img src={KatchLogo} alt="logo" height="35px" />
        </Link>
      </CenterBox>
      <Spacer size="xxl" />
      <DrawerItems />
    </Drawer>
  );
}

export default DesktopDrawer;
