import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import SearchBar from "../../../components/table/search-bar.component";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import { getUsers, sendUserList, userSelector } from "../../../services/user/user-slice.service";
import UserTableRow from "../components/user-table-row.component";
import UserTableRowLoader from "../loader/user-table-row-loader.component";
import TableSort from "../../../components/table/table-sort.component";
import CtaButton from "../../../components/button/cta-button.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SpacebetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export default function UserListScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const { getUsersObj } = useSelector(userSelector);
  const [totalPages, setTotalPages] = useState(1);
  const columnMapping = {
    "Full Name": "user.firstName",
    "Phone Number": "user.phoneNumber",
    Email: "user.email",
    "Total Hourly Booking": "user.purchaseSummary.totalHourBooking",
    "Total Fitness Class Session": "user.purchaseSummary.totalFitnessClassSession",
    "Total Package": "user.purchaseSummary.totalPackage",
    "Total Subscription": "user.purchaseSummary.totalSubscription",
    "Last Purchased": "user.purchaseSummary.lastPurchaseAt",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshUserList = (newPage, keyword) => {
    setPage(newPage);
    setQ(keyword);
    dispatch(getUsers({ page: newPage, q: keyword })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeSearch = (keyword) => {
    onRefreshUserList(1, keyword);
  };

  const onPageChange = (e, newPage) => {
    onRefreshUserList(newPage, q);
  };

  useEffect(() => {
    onRefreshUserList(page, q);
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getUsersObj.data && getUsersObj.status === "succeeded") {
      records = { ...getUsersObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const handleDownloadUserList = () => {
    dispatch(sendUserList()).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderTableBody = () => {
    if (getUsersObj.status === "succeeded") {
      const records = getProcessedRecord();

      if (getUsersObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={9}>
              <TableEmptyBox>
                <Text type="TableText">No items</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => <UserTableRow user={item} key={item.user.id} />);
    }
    return <UserTableRowLoader />;
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <Grid item xs={12}>
        <SpacebetweenBox>
          <Text variant="screenLabel">User List</Text>
          <CtaButton onClickButton={handleDownloadUserList}>
            <Text type="WhiteColor">Download User List</Text>
          </CtaButton>
        </SpacebetweenBox>
      </Grid>

      <Grid item xs={12}>
        <SearchBar
          searchKeyword={q}
          setSearchKeyword={onChangeSearch}
          placeholder="Search here"
          isTransparent
          width="300px"
        />
      </Grid>

      <Grid item xs={12}>
        <TableWrapper>
          <Table>
            <TableHeader
              headerCells={[
                "Full Name",
                "Phone Number",
                "Email",
                "Total Hourly Booking",
                "Total Fitness Class Session",
                "Total Package",
                "Total Subscription",
                "Last Purchased",
                "Action",
              ]}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
              columnMapping={columnMapping}
            />
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      <Grid item xs={12}>
        <CustomFooter>
          <Pagination
            sx={{
              "&& .Mui-selected": {
                backgroundColor: theme.palette.colors.brand.primary,
                color: theme.palette.colors.text.white,
              },
            }}
            page={page}
            shape="rounded"
            onChange={onPageChange}
            count={getUsersObj?.data?.pagination.totalPages || totalPages}
            variant="outlined"
          />
        </CustomFooter>
      </Grid>
    </Grid>
  );
}
