import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../../api-services";

export const getUserEvents = createAsyncThunk(
  "merchants/users/{user_Id}/events",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/users/${payload.userId}/events?page=${payload.page}`,
    );
    return response;
  },
);

const eventUserSlice = createSlice({
  name: "eventUser",
  initialState: {
    getUserEventsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getUserEvents.pending]: (state) => {
      state.getUserEventsObj.status = "pending";
    },
    [getUserEvents.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserEventsObj.status = "succeeded";
      state.getUserEventsObj.data = data;
      state.getUserEventsObj.successMessage = message;
    },
    [getUserEvents.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserEventsObj.status = "failed";
      state.getUserEventsObj.errorMessage = message;
    },
  },
});

export default eventUserSlice.reducer;

// state
export const eventUserSelector = (state) => state.eventUser;
