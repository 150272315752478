export const PCMaxWidth = "1200px";

export const heightWithoutAppBar = "89vh";

export const PCPadding = "15px";

export const MobilePadding = "15px";

export const ScreenPaddingY = "50px";

export const tabletWidth = "920";
