import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api-services";

export const getTotalRevenueWithCSV = createAsyncThunk("revenue/index", async (payload) => {
  const { startAt, endAt } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/revenues?startAt=${startAt}&endAt=${endAt}`,
  );
  return response;
});

export const getFitnessClassesRevenueWithCSV = createAsyncThunk(
  "revenue/fitness_classes",
  async (payload) => {
    const { startAt, endAt } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/revenues/fitness_classes?startAt=${startAt}&endAt=${endAt}`,
    );
    return response;
  },
);

export const getHourlyBookingsRevenueWithCSV = createAsyncThunk(
  "revenue/hourly_bookings",
  async (payload) => {
    const { startAt, endAt } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/revenues/hourly_bookings?startAt=${startAt}&endAt=${endAt}`,
    );
    return response;
  },
);

export const getSubscriptionsRevenueWithCSV = createAsyncThunk(
  "revenue/subscriptions",
  async (payload) => {
    const { startAt, endAt } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/revenues/subscriptions?startAt=${startAt}&endAt=${endAt}`,
    );
    return response;
  },
);

export const getPackagesRevenueWithCSV = createAsyncThunk("revenue/packages", async (payload) => {
  const { startAt, endAt } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/revenues/packages?startAt=${startAt}&endAt=${endAt}`,
  );
  return response;
});

export const getEventsRevenueWithCSV = createAsyncThunk("revenue/events", async (payload) => {
  const { startAt, endAt } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/revenues/events?startAt=${startAt}&endAt=${endAt}`,
  );
  return response;
});

const revenueSummarySlice = createSlice({
  name: "revenueSummary",
  initialState: {
    getTotalRevenueWithCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getFitnessClassesRevenueWithCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getHourlyBookingsRevenueWithCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSubscriptionsRevenueWithCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPackagesRevenueWithCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventsRevenueWithCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getTotalRevenueWithCSV.pending]: (state) => {
      state.getTotalRevenueWithCSVObj.status = "pending";
    },
    [getTotalRevenueWithCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getTotalRevenueWithCSVObj.status = "succeeded";
      state.getTotalRevenueWithCSVObj.data = data;
      state.getTotalRevenueWithCSVObj.successMessage = message;
    },
    [getTotalRevenueWithCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getTotalRevenueWithCSVObj.status = "failed";
      state.getTotalRevenueWithCSVObj.errorMessage = message;
    },
    [getFitnessClassesRevenueWithCSV.pending]: (state) => {
      state.getFitnessClassesRevenueWithCSVObj.status = "pending";
    },
    [getFitnessClassesRevenueWithCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFitnessClassesRevenueWithCSVObj.status = "succeeded";
      state.getFitnessClassesRevenueWithCSVObj.data = data;
      state.getFitnessClassesRevenueWithCSVObj.successMessage = message;
    },
    [getFitnessClassesRevenueWithCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFitnessClassesRevenueWithCSVObj.status = "failed";
      state.getFitnessClassesRevenueWithCSVObj.errorMessage = message;
    },
    [getHourlyBookingsRevenueWithCSV.pending]: (state) => {
      state.getHourlyBookingsRevenueWithCSVObj.status = "pending";
    },
    [getHourlyBookingsRevenueWithCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getHourlyBookingsRevenueWithCSVObj.status = "succeeded";
      state.getHourlyBookingsRevenueWithCSVObj.data = data;
      state.getHourlyBookingsRevenueWithCSVObj.successMessage = message;
    },
    [getHourlyBookingsRevenueWithCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getHourlyBookingsRevenueWithCSVObj.status = "failed";
      state.getHourlyBookingsRevenueWithCSVObj.errorMessage = message;
    },
    [getSubscriptionsRevenueWithCSV.pending]: (state) => {
      state.getSubscriptionsRevenueWithCSVObj.status = "pending";
    },
    [getSubscriptionsRevenueWithCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubscriptionsRevenueWithCSVObj.status = "succeeded";
      state.getSubscriptionsRevenueWithCSVObj.data = data;
      state.getSubscriptionsRevenueWithCSVObj.successMessage = message;
    },
    [getSubscriptionsRevenueWithCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubscriptionsRevenueWithCSVObj.status = "failed";
      state.getSubscriptionsRevenueWithCSVObj.errorMessage = message;
    },
    [getPackagesRevenueWithCSV.pending]: (state) => {
      state.getPackagesRevenueWithCSVObj.status = "pending";
    },
    [getPackagesRevenueWithCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPackagesRevenueWithCSVObj.status = "succeeded";
      state.getPackagesRevenueWithCSVObj.data = data;
      state.getPackagesRevenueWithCSVObj.successMessage = message;
    },
    [getPackagesRevenueWithCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPackagesRevenueWithCSVObj.status = "failed";
      state.getPackagesRevenueWithCSVObj.errorMessage = message;
    },
    [getEventsRevenueWithCSV.pending]: (state) => {
      state.getEventsRevenueWithCSVObj.status = "pending";
    },
    [getEventsRevenueWithCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventsRevenueWithCSVObj.status = "succeeded";
      state.getEventsRevenueWithCSVObj.data = data;
      state.getEventsRevenueWithCSVObj.successMessage = message;
    },
    [getEventsRevenueWithCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventsRevenueWithCSVObj.status = "failed";
      state.getEventsRevenueWithCSVObj.errorMessage = message;
    },
  },
});

export default revenueSummarySlice.reducer;

export const revenueSummarySelector = (state) => state.revenueSummary;
