import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField } from "@mui/material";

function FormFieldTextMultiline({ name, placeholder, rows, width, isTransparent, disabled }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values } = useFormikContext();

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <TextField
      sx={{
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
        width,
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        },
      }}
      rows={rows}
      multiline
      error={showError}
      variant="outlined"
      helperText={showError ? errors[name] : null}
      onBlur={() => setFieldTouched(name)}
      name={name}
      type={name}
      placeholder={placeholder}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
      value={values[name] === null ? "" : values[name]}
      disabled={disabled}
    />
  );
}

FormFieldTextMultiline.defaultProps = {
  rows: 1,
  width: "100%",
  placeholder: "",
  isTransparent: false,
  disabled: false,
};

FormFieldTextMultiline.propTypes = {
  rows: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FormFieldTextMultiline;
