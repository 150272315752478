import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import Text from "../../../components/text.component";
import ScheduleOffDateModal from "./schedule-off-date-modal.component";

const editValidationSchema = Yup.object().shape({
  date: Yup.date().label("Date").nullable().required(),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
});

export default function ScheduleOffDateTableRow({ offDate, onHandleDelete, onUpdateOffDay }) {
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <Form
        initialValues={{
          offDateId: offDate.id,
          date: moment(offDate.date).toDate(),
          startTime: dayjs(offDate.startTime, "h:mma"),
          endTime: dayjs(offDate.endTime, "h:mma"),
        }}
        validationSchema={editValidationSchema}
        onSubmit={onUpdateOffDay}
      >
        <ScheduleOffDateModal showModal={showEditModal} setShowModal={setShowEditModal} />
      </Form>
      <TableRow
        key={offDate.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "100px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {offDate.id}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {offDate.date}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {offDate.startTime}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {offDate.endTime}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box display="flex">
            <IconButton aria-label="expand row" size="small" onClick={() => setShowEditModal(true)}>
              <EditIcon />
            </IconButton>

            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onHandleDelete(offDate.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

ScheduleOffDateTableRow.propTypes = {
  offDate: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired,
  onHandleDelete: PropTypes.func.isRequired,
  onUpdateOffDay: PropTypes.func.isRequired,
};
