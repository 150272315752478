import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import CtaButton from "../../../components/button/cta-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import {
  deleteProfileLogo,
  profileSelector,
} from "../../../services/profile/profile.slice.services";
import ProfileLogoCropper from "./profile-logo-cropper.component";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const SpacebetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
});

export default function ProfileLogoEditForm({ name, label, isEditing, setIsEditing, allowEdit }) {
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [showCropper, setShowCropper] = useState(false);

  const { handleSubmit, values, setFieldValue, setFieldError, setFieldTouched, errors } =
    useFormikContext();
  const showError = typeof errors[name] === "string";
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const { getProfileDetailObj, updateProfileDetailObj, deleteProfileLogoObj } =
    useSelector(profileSelector);

  const handleRemoveImage = () => {
    dispatch(deleteProfileLogo()).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        setFieldValue(name, "");
        setFieldError(name, "");
      }
    });
  };

  const renderButton = () => {
    if (isEditing) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {isSmallScreen && values.image && (
            <>
              <CtaButton
                width={isSmallScreen ? null : "70px"}
                padding="8px"
                onClickButton={() => handleSubmit()}
                isLoading={updateProfileDetailObj.status === "pending"}
              >
                <Text type="WhiteColor">Save</Text>
              </CtaButton>
              <Spacer position="left" size="s" />
            </>
          )}

          {values.image && (
            <>
              <CtaBorderButton
                width={isSmallScreen ? null : "70px"}
                padding="8px"
                onClickButton={() => handleRemoveImage()}
                isLoading={deleteProfileLogoObj.status === "pending"}
              >
                <Text type="BrandColor">Delete</Text>
              </CtaBorderButton>
              <Spacer position="left" size="s" />
            </>
          )}
          <CtaButton
            width={isSmallScreen ? null : "70px"}
            padding="8px"
            onClickButton={() => {
              setFieldValue(name, getProfileDetailObj.data[name]);
              setIsEditing(false);
            }}
            isLoading={updateProfileDetailObj.status === "pending"}
          >
            <Text type="WhiteColor">Cancel</Text>
          </CtaButton>
        </Box>
      );
    }
    return (
      <CtaBorderButton
        width={isSmallScreen ? null : "70px"}
        padding="8px"
        onClickButton={() => setIsEditing(true)}
      >
        <Text type="BrandColor">Edit</Text>
      </CtaBorderButton>
    );
  };

  return (
    <>
      {isSmallScreen && (
        <SpacebetweenRowBox>
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
          </LabelContainer>
          {allowEdit && renderButton()}
        </SpacebetweenRowBox>
      )}

      <FormContainer>
        {!isSmallScreen && (
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
          </LabelContainer>
        )}
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Box sx={{ width: "100%" }}>
            {values.image ? (
              <Grid item>
                <Box
                  sx={{
                    position: "relative",
                    width: "250px",
                    "& .MuiIconButton-root": {
                      "&:hover": {
                        backgroundColor: theme.palette.colors.brand.primary,
                      },
                    },
                  }}
                >
                  <img
                    src={values.image}
                    alt="banner"
                    style={{
                      height: "250px",
                      width: "250px",
                      borderRadius: theme.shape.borderRadius[1],
                      backgroundSize: "100% 100%",
                    }}
                  />
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Button
                    disabled={!isEditing}
                    onClick={() => {
                      setFieldError(name, "");
                    }}
                    onBlur={() => setFieldTouched(name)}
                    component="label"
                    sx={{
                      backgroundColor: "transparent",
                      borderTopLeftRadius: theme.shape.borderRadius[1],
                      borderTopRightRadius: theme.shape.borderRadius[1],
                      borderBottomLeftRadius: theme.shape.borderRadius[1],
                      borderBottomRightRadius: theme.shape.borderRadius[1],
                      border: `2px dashed ${theme.palette.colors.ui.disabled}`,
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: "250px",
                        width: "250px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: theme.palette.colors.ui.disabled,
                      }}
                    >
                      {isEditing && <AddCircleOutlineRoundedIcon />}
                    </Box>
                    <input
                      type="file"
                      name={name}
                      hidden
                      accept="image/*"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      onBlur={() => setFieldTouched(name)}
                      onChange={(e) => {
                        const { files } = e.target;
                        if (files) {
                          const myFile = files[0]; // single file upload only
                          const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);

                          if (myFile && allowedFileType) {
                            setImage(URL.createObjectURL(myFile));
                            setShowCropper(true);
                          } else {
                            if (!allowedFileType) {
                              setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
                            }
                            setImage("");
                          }
                        }
                      }}
                    />
                  </Button>
                </Grid>
                {showError && errors[name] && <Text type="RedColor">{errors[name]}</Text>}
              </>
            )}
          </Box>
          {isEditing && !isSmallScreen && values.image && (
            <>
              <Spacer position="top" size="m" />
              <CtaButton
                width={isSmallScreen ? null : "70px"}
                padding="8px"
                onClickButton={() => handleSubmit()}
                isLoading={updateProfileDetailObj.status === "pending"}
              >
                <Text type="WhiteColor">Save</Text>
              </CtaButton>
            </>
          )}
        </Box>

        {!isSmallScreen && (
          <>
            <Spacer position="left" size="m" />
            {allowEdit && renderButton()}
          </>
        )}
      </FormContainer>
      <ProfileLogoCropper
        name={name}
        setIsShowModal={setShowCropper}
        isShowModal={showCropper}
        imageFile={image}
      />
    </>
  );
}

ProfileLogoEditForm.defaultProps = {
  allowEdit: true,
};

ProfileLogoEditForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  allowEdit: PropTypes.bool,
};
