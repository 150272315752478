import { Box, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import { authStaffSelector, staffLogin } from "../../../services/staff/auth/auth.slice.services";
import MiniCheckBox from "../components/mini-checkbox.component";
import SideImage from "../components/side-image.component";

const CenteredBox = styled(Box)({
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
});

const FormBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
}));

const CardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  width: "90%",
  backgroundColor: theme.palette.colors.bg.secondary,
  display: "flex",
  flexDirection: "row",
}));

const PaddedBox = styled(Box)({
  flexDirection: "column",
  justifyContent: "space-between",
  display: "flex",
  height: "100%",
});

function LoginStaffScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { staffLoginObj } = useSelector(authStaffSelector);
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.dimensions.tabletWidth));
  const history = useHistory();

  const onSubmitForm = (values) => {
    if (values.isRemember === "true") {
      localStorage.setItem("isRemember", "true");
      localStorage.setItem("engageMerchantEmail", values.email);
    } else {
      localStorage.removeItem("isRemember");
      localStorage.removeItem("engageMerchantEmail");
    }
    dispatch(staffLogin(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.HOME);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Form
      initialValues={{
        email: localStorage.getItem("engageMerchantEmail") || "",
        password: "",
        isRemember: localStorage.getItem("isRemember") || "false",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
    >
      <CenteredBox>
        <CardContainer sx={{ maxWidth: isTablet ? "400px" : "900px" }}>
          <FormBox sx={{ width: isTablet ? "100%" : "50%" }}>
            <PaddedBox
              sx={{
                paddingTop: isTablet ? "25px" : "50px",
                paddingBottom: isTablet ? "25px" : "50px",
                paddingLeft: isTablet ? "35px" : "70px",
                paddingRight: isTablet ? "35px" : "70px",
              }}
            >
              <Box>
                <Text
                  type="WhiteColor"
                  variant="h4"
                  sx={{ fontWeight: theme.fonts.fontWeights.bold }}
                >
                  Sign In as Staff
                </Text>
                <Spacer position="top" size="s" />
                <Text type="WhiteColor">Welcome back! Please enter your details.</Text>
                <Spacer position="top" size="l" />

                <Text type="WhiteColor" sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  Email
                </Text>
                <Spacer position="top" size="xs" />
                <FormFieldText name="email" placeholder="Enter your email" isAuthScreen />

                <Spacer position="top" size="m" />

                <Text type="WhiteColor" sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  Password
                </Text>
                <Spacer position="top" size="xs" />
                <FormFieldText name="password" placeholder="Enter your password" isAuthScreen />
                <Spacer position="top" size="xs" />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "-5px",
                  }}
                >
                  <MiniCheckBox label="Remember email" name="isRemember" />
                  <Link href={routes.FORGOT_PASSWORD_STAFF} underline="none">
                    <Text
                      type="WhiteColor"
                      sx={{
                        fontWeight: theme.fonts.fontWeights.bold,
                        fontSize: theme.fonts.fontSizes.size12,
                      }}
                    >
                      Forgot Password
                    </Text>
                  </Link>
                </Box>
              </Box>
              <Box>
                <FormSubmitButton whiteBg isLoading={staffLoginObj.status === "pending"}>
                  <Text type="BrandColor">Sign in</Text>
                </FormSubmitButton>
                <Spacer position="top" size="s" />
                <Text
                  type="WhiteColor"
                  sx={{ fontSize: theme.fonts.fontSizes.size12, textAlign: "center" }}
                >
                  {"Signing in as an owner? "}
                  <Link
                    style={{ fontWeight: theme.fonts.fontWeights.bold, color: "white" }}
                    href={routes.LOGIN}
                    underline="none"
                  >
                    Click Here
                  </Link>
                </Text>
              </Box>
            </PaddedBox>
          </FormBox>
          {!isTablet && <SideImage />}
        </CardContainer>
      </CenteredBox>
    </Form>
  );
}

export default LoginStaffScreen;
