import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  deleteClass,
  fitnessClassSelector,
  getClassCategoryList,
  searchClass,
  updateClass,
} from "../../../services/fitness/class.slice.services";
import BusinessListAutoComplete from "../components/business-list-autocomplete.component";
import ClassTableRow from "../components/class-table-row.component";
import SearchBar from "../components/search-bar.component";
import ClassTableRowLoader from "../loader/class-table-row-loader.component";
import Form from "../../../components/forms/form.component";
import ClassEditModal from "../components/class-edit-modal.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import TableSort from "../../../components/table/table-sort.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  businesses: Yup.array().label("Businesses").nullable(),
  categories: Yup.array().label("Categories").nullable(),
});

const editValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
    .label("Name"),
  categoryId: Yup.string().required().label("Category"),
});

export default function FitnessClassListScreen() {
  const theme = useTheme();
  const formRef = useRef();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [businesses, setBusinesses] = useState(null);
  const [categories, setCategories] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const { searchClassObj, getClassCategoryListObj, deleteClassObj } =
    useSelector(fitnessClassSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedClassId, setSelectedClassId] = useState("");
  const columnMapping = {
    "Class Name": "name",
    "Business Name": "business.name",
    Category: "category.label",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const getProcessedCategoryList = () => {
    const itemList = [];
    getClassCategoryListObj.data.map((item) =>
      itemList.push({
        label: item.label,
        id: item.categoryId,
        value: item.categoryId,
      }),
    );
    return itemList;
  };

  const onRefreshFitnessClassList = (newPage, keyword, businessIds, categoryIds) => {
    setIsLoading(true);
    setPage(newPage);
    setQ(keyword);
    setBusinesses(businessIds);
    setCategories(categoryIds);
    dispatch(
      searchClass({
        page: newPage,
        q: keyword,
        businessIds,
        categoryIds,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });

    setIsLoading(false);
  };

  const onHandleDelete = (classId) => {
    setSelectedClassId(classId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteClass({ classId: selectedClassId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshFitnessClassList(page, q, businesses, categories);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const submitFilter = (values) => {
    const businessIds = values.businesses.map((item) => item.id);
    const categoryIds = values.categories;
    onRefreshFitnessClassList(1, values.q, businessIds, categoryIds);
  };

  const onChangeSearch = (keyword) => {
    onRefreshFitnessClassList(1, keyword, businesses, categories);
  };

  const onPageChange = (e, newPage) => {
    onRefreshFitnessClassList(newPage, q, businesses, categories);
  };

  const onUpdateClass = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(updateClass(values)).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        onRefreshFitnessClassList(page, q, businesses, categories);
        setShowEditModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onRefreshFitnessClassList(page, q);
    dispatch(getClassCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const getProcessedRecord = () => {
    let records = null;

    if (searchClassObj.data && searchClassObj.status === "succeeded") {
      records = { ...searchClassObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (searchClassObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (searchClassObj?.data?.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <TableEmptyBox>
                <Text type="TableText">No Classes</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <ClassTableRow
          detail={item}
          key={item.id}
          setShowEditModal={setShowEditModal}
          onHandleDelete={onHandleDelete}
        />
      ));
    }
    return <ClassTableRowLoader />;
  };

  if (getClassCategoryListObj.status === "succeeded")
    return (
      <Formik
        innerRef={formRef}
        initialValues={{
          q: q || "",
          businesses: [],
          categories: [],
        }}
        onSubmit={submitFilter}
        validationSchema={validationSchema}
      >
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
            paddingY: theme.dimensions.ScreenPaddingY,
          }}
        >
          <Grid item xs={12}>
            <Text variant="screenLabel">Fitness Class List</Text>
          </Grid>
          <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
            <WhiteBgCardContainer>
              <BackdropLoading isLoading={isLoading} />

              <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={1.45}>
                            <Text>Search</Text>
                          </Grid>
                          <Grid item xs={10.55}>
                            <SearchBar
                              placeholder="Search by class name"
                              name="q"
                              searchKeyword={q}
                              setSearchKeyword={onChangeSearch}
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={isSmallScreen ? 12 : 6}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Businesses</Text>
                          </Grid>

                          <Grid item xs={9}>
                            <BusinessListAutoComplete name="businesses" placeholder="Businesses" />
                          </Grid>
                        </FormContainer>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : 6}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Categories</Text>
                          </Grid>
                          <Grid item xs={9}>
                            <FormMultipleSelect
                              itemList={getProcessedCategoryList()}
                              name="categories"
                              placeholder="Categories"
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={2}>
                        <FormSubmitButton>
                          <Text type="WhiteColor">Search</Text>
                        </FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>

              <Form
                initialValues={{ name: "", categoryId: "" }}
                validationSchema={editValidationSchema}
                onSubmit={onUpdateClass}
              >
                <ClassEditModal
                  showModal={showEditModal}
                  setShowModal={setShowEditModal}
                  itemList={getProcessedCategoryList()}
                />
                <DeleteConfirmationModal
                  showModal={showDeleteModal}
                  setShowModal={setShowDeleteModal}
                  title="Confirmation"
                  label="Proceeding with the removal of this class will also remove the sessions created under this class."
                  isLoading={deleteClassObj.status === "pending"}
                  onConfirmClicked={onConfirmDelete}
                />
                <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                  <Grid item xs={12}>
                    <TableWrapper>
                      <Table>
                        <TableHeader
                          headerCells={["Class Name", "Business Name", "Category", "Action"]}
                          sortColumn={sortColumn}
                          sortOrder={sortOrder}
                          onSortChange={onSortChange}
                          columnMapping={columnMapping}
                        />
                        <TableBody>{renderTableBody()}</TableBody>
                      </Table>
                    </TableWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomFooter>
                      <Pagination
                        sx={{
                          "&& .Mui-selected": {
                            backgroundColor: theme.palette.colors.brand.primary,
                            color: theme.palette.colors.text.white,
                          },
                        }}
                        page={page}
                        shape="rounded"
                        onChange={onPageChange}
                        count={searchClassObj?.data?.pagination.totalPages || totalPages}
                        variant="outlined"
                      />
                    </CustomFooter>
                  </Grid>
                </Grid>
              </Form>
            </WhiteBgCardContainer>
          </Grid>
        </Grid>
      </Formik>
    );
}
