import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import SpecificListAutoComplete from "../../features/hourly-booking/components/specific-list-autocomplete.component";
import {
  businessAccessSelector,
  checkUserBusinessAccess,
  resetBusinesAccessInfo,
} from "../../services/business-access/business-access.slice.servies";
import FormFieldText from "../forms/form-field-text.component";
import FormSubmitButton from "../forms/form-submit-button.component";
import Form from "../forms/form.component";
import SkeletonLoader from "../notification/skeleton-loader.component";
import { SnackbarContext } from "../notification/snackbar.context";
import Spacer from "../spacer.component";
import Text from "../text.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  padding: "30px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxWidth: isMobile ? "" : "1100px",
  width: isMobile ? "100%" : "90%",
  height: isMobile ? "100%" : "92%",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  overflowY: "auto",
}));

const validationSchema = Yup.object().shape({
  businessesId: Yup.array().label("Businesses").nullable(),
  productType: Yup.string().required(),
  userPublicId: Yup.string().required(),
});

export default function CheckBusinessAccessModal({ showModal, setShowModal, productType }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("760"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { checkUserBusinessAccessObj } = useSelector(businessAccessSelector);

  const handleCancel = () => {
    setShowModal(false);
    dispatch(resetBusinesAccessInfo());
  };

  const onSubmit = (values) => {
    const payloadValues = { ...values };
    payloadValues.businessesId = values.businessesId.map((item) => item.id).join(",");
    dispatch(checkUserBusinessAccess(payloadValues)).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (checkUserBusinessAccessObj.status === "succeeded") {
      return (
        <Grid container display="flex" justifyContent="center" sx={{ marginTop: "10px" }}>
          <Grid item xs={isMobile ? 6 : 1.5}>
            <img
              alt="userImage"
              src={checkUserBusinessAccessObj.data.user.imagePath}
              style={{
                objectFit: "contain",
                width: "100%",
                borderRadius: theme.shape.borderRadius[1],
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Text variant="h6" sx={{ textAlign: "center" }}>
              {checkUserBusinessAccessObj.data.user.name}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text variant="h6">Accesses:</Text>
            <Spacer />
          </Grid>
          {checkUserBusinessAccessObj.data.items.map((item, index) => (
            <Grid item container marginBottom="10px" key={uuidv4()} spacing={1}>
              <Grid item xs={12}>
                <Text>
                  {index + 1}. <span style={{ fontWeight: "bold" }}>{item.name}</span>
                </Text>
              </Grid>
              <Grid item xs={12} marginLeft="20px">
                <Grid container>
                  <Grid item>
                    <Text>Current:</Text>
                  </Grid>
                  {item.accesses.current.length > 0 ? (
                    <List sx={{ listStyleType: "disc", padding: 0, pl: 3 }}>
                      {item.accesses.current.map((access) => (
                        <ListItem
                          key={uuidv4()}
                          sx={{ display: "list-item", padding: 0, paddingBottom: "5px" }}
                        >
                          {access.item}: {access.startAt} - {access.endAt}
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Grid item sx={{ pl: 1 }}>
                      <Text>No Access</Text>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} marginLeft="20px">
                <Grid container>
                  <Grid item>
                    <Text>Upcoming:</Text>
                  </Grid>
                  {item.accesses.upcoming.length > 0 ? (
                    <List sx={{ listStyleType: "disc", padding: 0, pl: 3 }}>
                      {item.accesses.upcoming.map((access) => (
                        <ListItem
                          key={uuidv4()}
                          sx={{ display: "list-item", padding: 0, paddingBottom: "5px" }}
                        >
                          {access.item}: {access.startAt} - {access.endAt}
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Grid item sx={{ pl: 1 }}>
                      <Text>No Access</Text>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      );
    }
    if (checkUserBusinessAccessObj.status === "pending") {
      return (
        <>
          <Spacer />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
        </>
      );
    }

    return false;
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Form
          initialValues={{ businessesId: [], productType, userPublicId: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Grid container spacing={2}>
            <Grid item container xs={12} position="relative" alignItems="center">
              <Grid item xs={12}>
                <Text variant="h5" textAlign="center">
                  Scan with QR code
                </Text>
              </Grid>
              <Grid container item justifyContent="flex-end" position="absolute">
                <IconButton onClick={handleCancel}>
                  <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container item xs={12} display="flex" alignItems="center" spacing={2}>
              <Grid item xs={isMobile ? 12 : 1.5}>
                <Text>User Public Id:</Text>
              </Grid>
              <Grid item xs={isMobile ? 12 : 10.5}>
                <FormFieldText
                  name="userPublicId"
                  placeholder="Please scan user's QR code"
                  autoFocus={true}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} display="flex" alignItems="center" spacing={2}>
              <Grid item xs={isMobile ? 12 : 1.5}>
                <Text>Businesses:</Text>
              </Grid>
              <Grid item xs={isMobile ? 12 : 10.5}>
                <SpecificListAutoComplete name="businessesId" placeholder="Businesses" isWhiteBg />
              </Grid>
            </Grid>
            <Grid container item display="flex" justifyContent="end">
              <Grid item xs={isMobile ? 12 : 2}>
                <FormSubmitButton isLoading={checkUserBusinessAccessObj.status === "pending"}>
                  <Text type="WhiteColor">Check</Text>
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
        {renderContent()}
      </ModalBox>
    </Modal>
  );
}

CheckBusinessAccessModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  productType: PropTypes.string.isRequired,
};
