import React from "react";
import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormFieldLabel from "./form-field-label.component";
import { fitnessClassSelector } from "../../../services/fitness/class.slice.services";
import CtaBorderButton from "../../../components/button/cta-border-button.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

export default function ClassEditModal({ showModal, setShowModal, itemList }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm } = useFormikContext();
  const { updateClassObj } = useSelector(fitnessClassSelector);

  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "600px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text variant="screenLabel">Edit Class</Text>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="m" position="top" />
            <FormFieldLabel name="name" placeholder="Enter class name" label="Class Name" />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              name="categoryId"
              placeholder="Select category"
              label="Category"
              itemList={itemList}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <CtaBorderButton
                onClickButton={() => setShowModal(false)}
                width="100px"
                isLoading={updateClassObj.status === "pending"}
              >
                <Text type="BrandColor">Cancel</Text>
              </CtaBorderButton>
              <Spacer size="m" position="left" />
              <FormSubmitButton width="100px" isLoading={updateClassObj.status === "pending"}>
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

ClassEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
};
