import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";
import {
  checkUserBookingsPackageAvailabilities,
  fitnessBookingSelector,
} from "../../../services/fitness/booking/booking.slice.service";

export default function PackageListUserAvailabilitySingleSelect({
  name,
  placeholder,
  width,
  isTransparent,
}) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const showError = touched[name] && typeof errors[name] === "string";
  const { checkUserBookingsPackageAvailabilitiesObj } = useSelector(fitnessBookingSelector);

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  useEffect(() => {
    if (values.classId && values.userPhoneNumber)
      dispatch(
        checkUserBookingsPackageAvailabilities({
          classId: values.classId.id,
          userPhoneNumber: values.userPhoneNumber.phone,
        }),
      );
    if (!values.classId || !values.userPhoneNumber) setFieldValue(name, "");
  }, [values.classId, values.userPhoneNumber]);

  return (
    <FormControl
      sx={{
        width,
        "& .MuiOutlinedInput-input": {
          padding: "10px",
        },
      }}
    >
      <Select
        onBlur={() => setFieldTouched(name)}
        value={values[name] === null ? "" : values[name]}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
        error={showError}
        displayEmpty
        disabled={!values.classId || !values.userPhoneNumber}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        }}
      >
        <MenuItem value="" disabled>
          <Text type="GreyColor">{placeholder}</Text>
        </MenuItem>
        {checkUserBookingsPackageAvailabilitiesObj.status === "succeeded" &&
          checkUserBookingsPackageAvailabilitiesObj.data.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.plan.title} (Claimed: {item.sessions.claimedCount}/{item.sessions.noOfSessions})
            </MenuItem>
          ))}
      </Select>
      {showError && <FormHelperText error={showError}>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

PackageListUserAvailabilitySingleSelect.defaultProps = {
  width: "100%",
  isTransparent: false,
};

PackageListUserAvailabilitySingleSelect.propTypes = {
  width: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
};
