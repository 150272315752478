import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import { createEvent } from "../../../services/event/event.slice.services";
import BusinessListAutoCompleteSingleSelect from "../components/business-list-autocomplete-single-select.component";
import FormFieldLabel from "../components/form-field-label.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required()
    .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
    .label("Title"),
  businesses: Yup.object().required().label("Business").nullable(),
});

export default function EventCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);

  const onCreateEvent = (values, { resetForm }) => {
    const businessId = values.businesses.id;
    setIsLoading(true);
    dispatch(createEvent({ businessId, ...values })).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.EVENT_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <BackdropLoading isLoading={isLoading} />
      <Form
        validationSchema={validationSchema}
        onSubmit={onCreateEvent}
        initialValues={{
          title: "",
          businesses: null,
        }}
      >
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Text variant="screenLabel">Create Event</Text>
            <Button
              onClick={() => history.goBack()}
              sx={{
                textTransform: "none",
                padding: "0px",
                ":hover": { backgroundColor: "transparent" },
              }}
            >
              <Text>Back</Text>
            </Button>
          </SpaceBetweenBox>
        </Grid>
        <Grid item xs={12}>
          <WhiteBgCardContainer>
            <Grid container spacing={isMobile ? 1 : 3}>
              <Grid item xs={12}>
                <Text variant="screenLabel">Event Details</Text>
              </Grid>
              <Grid item xs={12}>
                <FormFieldLabel name="title" placeholder="Enter event title" label="Title" />
              </Grid>
              <Grid item xs={12}>
                {isMobile && (
                  <LabelContainer>
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Businesses</Text>
                  </LabelContainer>
                )}
                <FormContainer>
                  {!isMobile && (
                    <LabelContainer>
                      <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Businesses</Text>
                    </LabelContainer>
                  )}
                  <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <Box sx={{ width: "100%" }}>
                      <BusinessListAutoCompleteSingleSelect
                        name="businesses"
                        placeholder="Businesses"
                      />
                    </Box>
                  </Box>
                </FormContainer>
              </Grid>
            </Grid>
          </WhiteBgCardContainer>
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
          <FormSubmitButton width="200px">
            <Text type="WhiteColor">Create</Text>
          </FormSubmitButton>
        </Grid>
      </Form>
    </Grid>
  );
}
