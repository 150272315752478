import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, List, ListItem, TableCell, TableRow, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function EventTableRow({ detail, onHandleDelete }) {
  const theme = useTheme();
  const history = useHistory();

  return (
    <>
      <TableRow
        key={detail.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {detail.title}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {detail.business.name}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <List sx={{ listStyleType: "disc", padding: 0, pl: 3 }}>
            {detail.category.length > 0 ? (
              detail.category?.map((item) => (
                <ListItem
                  key={item.id}
                  sx={{
                    display: "list-item",
                    padding: 0,
                    paddingBottom: "5px",
                    color: theme.palette.colors.text.table,
                    textAlign: "center",
                  }}
                >
                  {item.label}
                </ListItem>
              ))
            ) : (
              <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
                -
              </Text>
            )}
          </List>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() =>
                history.push({
                  pathname: `/event/edit`,
                  search: `eventId=${detail.id}`,
                  state: detail,
                })
              }
            >
              <EditIcon />
            </IconButton>

            <IconButton
              onClick={() => onHandleDelete(detail.id)}
              disabled={detail.deletable === false}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

EventTableRow.propTypes = {
  detail: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    category: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    created_at: PropTypes.string,
    deletable: PropTypes.bool,
  }).isRequired,
  onHandleDelete: PropTypes.func.isRequired,
};
