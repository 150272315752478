import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxWidth: "1100px",
  width: "90%",
  maxHeight: "90%",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const TopBarContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  justifyContent: "space-between",
});

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  ":hover": { backgroundColor: "transparent" },
  color: theme.palette.colors.text.primary,
  padding: "0px",
}));

const SessionContainer = styled(Box)(({ theme }) => ({
  padding: "15px",
  height: "100%",
  backgroundColor: theme.palette.colors.brand.secondary,
  borderRadius: theme.shape.borderRadius[0],
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export default function CalendarSessionModal({
  showModal,
  setShowModal,
  events,
  selectedDate,
  onClickSession,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("760"));

  const handleCancel = () => {
    setShowModal(false);
  };

  const sessionType = (type) => {
    let sessionTypeName = "";
    switch (type) {
      case "merchant_class_session":
        sessionTypeName = "Class";
        break;
      case "merchant_event_session":
        sessionTypeName = "Event";
        break;
      case "merchant_appointment":
        sessionTypeName = "Appointment";
        break;
      default:
        sessionTypeName = "";
    }
    return sessionTypeName;
  };

  const renderContent = () => {
    if (events && events.length > 0) {
      return (
        <Grid container spacing={1}>
          {events.map((item) => (
            <Grid
              item
              xs={isMobile ? 12 : 4}
              key={uuidv4()}
              sx={{ cursor: item.session.currentCapacity > 0 && "pointer" }}
              onClick={() => {
                if (item.session.currentCapacity > 0) {
                  onClickSession(item);
                }
              }}
            >
              <SessionContainer>
                <Text fontSize="1.1rem" fontWeight="bold">{sessionType(item.type)}</Text>
                <SpaceBetweenRowBox sx={{ width: "100%" }}>
                  <Text>Title:</Text>
                  <Text fontWeight="bold">{item.session.identifier}</Text>
                </SpaceBetweenRowBox>
                <SpaceBetweenRowBox sx={{ width: "100%" }}>
                  <Text>Timeslot:</Text>
                  <Text fontWeight="bold">
                    {`${format(new Date(item.session.startAt), "hh:mm a")} - ${format(
                      new Date(item.session.endAt),
                      "hh:mm a",
                    )}`}
                  </Text>
                </SpaceBetweenRowBox>
                <SpaceBetweenRowBox sx={{ width: "100%" }}>
                  <Text>Capacity:</Text>
                  <Text fontWeight="bold">
                    {item.session.currentCapacity} /{" "}
                    {item.session.maxCapacity === 0 ? "∞" : item.session.maxCapacity}
                  </Text>
                </SpaceBetweenRowBox>
                {item.session.currentCapacity > 0 && (
                  <SpaceBetweenRowBox sx={{ width: "100%" }}>
                    <Text />
                    <Text sx={{ textDecoration: "underline" }}>View Attendees</Text>
                  </SpaceBetweenRowBox>
                )}
              </SessionContainer>
            </Grid>
          ))}
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "130px",
            alignItems: "center",
          }}
        >
          <Text>No Items</Text>
        </Box>
      </Grid>
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ padding: isMobile ? "20px" : "40px" }}>
        <TopBarContainer>
          <Text variant="screenLabel">Sessions</Text>
          <CloseIconButton onClick={handleCancel}>
            <CloseIcon
              sx={{
                stroke: theme.palette.colors.text.primary,
                strokeWidth: 2,
              }}
            />
          </CloseIconButton>
        </TopBarContainer>
        <Spacer position="top" size="l" />
        <Text variant="h6" fontWeight={600}>
          {format(new Date(selectedDate), "dd MMM yyyy, eee")}
        </Text>
        <Spacer position="top" size="l" />
        <Box
          sx={{
            height: isMobile ? "60%" : "400px",
            overflowY: "auto",
            width: "100%",
          }}
        >
          {renderContent()}
        </Box>
      </ModalBox>
    </Modal>
  );
}

CalendarSessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onClickSession: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      session: PropTypes.shape({
        id: PropTypes.number,
        identifier: PropTypes.string,
        currentCapacity: PropTypes.number,
        maxCapacity: PropTypes.number,
        startAt: PropTypes.string,
        endAt: PropTypes.string,
      }),
      bookings: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          user: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
    }),
  ).isRequired,
};
