import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../../api-services";

export const getEventCategoryList = createAsyncThunk("merchants/events/category/list", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/categories`,
  );
  return response;
});

const eventCategorySlice = createSlice({
  name: "eventCategory",
  initialState: {
    getEventCategoryListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getEventCategoryList.pending]: (state) => {
      state.getEventCategoryListObj.status = "pending";
    },
    [getEventCategoryList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventCategoryListObj.status = "succeeded";
      state.getEventCategoryListObj.data = data;
      state.getEventCategoryListObj.successMessage = message;
    },
    [getEventCategoryList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventCategoryListObj.status = "failed";
      state.getEventCategoryListObj.errorMessage = message;
    },
  },
});

export default eventCategorySlice.reducer;

// state
export const eventCategorySelector = (state) => state.eventCategory;
