import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import { footfallSelector, getEntryLogs } from "../../../services/footfall/footfall-slice.service";
import EntryLogTableRowLoader from "../loaders/entry-log-table-row-loader.component";
import EntryLogTableRow from "./entry-log-table-row.component";

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "500px",
  alignItems: "center",
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

function EntryLogTable() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { getEntryLogsObj } = useSelector(footfallSelector);
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    dispatch(
      getEntryLogs({
        startAt: format(new Date(), "yyyy-MM-dd 00:00:00"),
        endAt: format(new Date(), "yyyy-MM-dd 23:59:59"),
        page: 1,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(
      getEntryLogs({
        startAt: format(new Date(), "yyyy-MM-dd 00:00:00"),
        endAt: format(new Date(), "yyyy-MM-dd 23:59:59"),
        page: newPage,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderTableBody = () => {
    if (getEntryLogsObj.status === "succeeded") {
      if (getEntryLogsObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={5}>
              <TableEmptyBox>
                <Text type="TableText">No Entry</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return getEntryLogsObj.data.items.map((item, index) => (
        <EntryLogTableRow record={item} key={uuidv4()} index={index} />
      ));
    }
    return <EntryLogTableRowLoader />;
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader>
          <TableHeader
            headerCells={["No", "Username", "Business", "Entry At", "Valid Entry"]}
            nonSortableColumns={["No", "Username", "Business", "Entry At", "Valid Entry"]}
          />
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <CustomFooter>
          <Pagination
            sx={{
              "&& .Mui-selected": {
                backgroundColor: theme.palette.colors.brand.primary,
                color: theme.palette.colors.text.white,
              },
            }}
            page={page}
            shape="rounded"
            onChange={onPageChange}
            count={getEntryLogsObj?.data?.pagination.totalPages || totalPages}
            variant="outlined"
          />
        </CustomFooter>
      </Grid>
    </>
  );
}

export default EntryLogTable;
