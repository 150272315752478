import { Box, Button, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import KatchLogo from "../../../images/katch-logo.png";
import routes from "../../../navigation/routes";
import {
  authStaffSelector,
  staffForgotPassword,
} from "../../../services/staff/auth/auth.slice.services";
import OtpTypeForm from "../components/otp-type-form.component";

const MainContainer = styled(Box)({
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  width: "90%",
  maxWidth: "450px",
  backgroundColor: theme.palette.colors.bg.secondary,
  display: "flex",
  flexDirection: "row",
}));

const CenteredBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  email: Yup.string().email().label("Email").nullable().when("type", {
    is: "email",
    then: Yup.string().email().required(),
  }),
  phone: Yup.string()
    .label("Phone number")
    .nullable()
    .when("type", {
      is: "phone",
      then: Yup.string()
        .required()
        .label("Phone number")
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{8}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          "Phone number is not valid.",
        ),
    }),
});

const TextButton = styled(Button)({
  textTransform: "none",
  ":hover": { backgroundColor: "transparent" },
});

export default function ForgotPasswordStaffScreen() {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { staffForgotPasswordObj } = useSelector(authStaffSelector);

  const onConfirmSubmitForm = (values) => {
    const typeInfo = values.type === "email" ? values.email : values.phone;
    dispatch(staffForgotPassword({ typeInfo, type: values.type })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          history.push({
            pathname: routes.RESET_PASSWORD_STAFF,
            state: { type: values.type, typeInfo },
          });
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const onBack = () => {
    history.push(routes.LOGIN_STAFF);
  };

  return (
    <Form
      initialValues={{ phone: "", email: "", type: "email" }}
      validationSchema={validationSchema}
      onSubmit={onConfirmSubmitForm}
    >
      <MainContainer>
        <CardContainer>
          <CenteredBox sx={{ padding: isMobile ? "20px" : "50px" }}>
            <img src={KatchLogo} width="150px" alt="katch-logo" />
            <Spacer position="top" size="m" />
            <Text
              sx={{
                fontSize: theme.fonts.fontSizes.size30,
                fontWeight: theme.fonts.fontWeights.bold,
              }}
            >
              Forgot password?
            </Text>
            <Spacer size="s" position="top" />
            <OtpTypeForm />
            <Box sx={{ width: "100%" }}>
              <FormSubmitButton isLoading={staffForgotPasswordObj.status === "pending"}>
                <Text type="WhiteColor">Confirm</Text>
              </FormSubmitButton>
            </Box>
            <Spacer size="m" position="top" />

            <TextButton onClick={onBack}>
              <Text>Back</Text>
            </TextButton>
          </CenteredBox>
        </CardContainer>
      </MainContainer>
    </Form>
  );
}
