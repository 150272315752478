import React from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import Text from "../../../components/text.component";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";

export default function SubscriptionTableRowLoader() {
  return (
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>
      <TableCell sx={{ width: "150px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>
      <TableCell sx={{ width: "100px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SkeletonLoader width="30px" height="30px" borderRadius={30} />
        </Box>
      </TableCell>
      <TableCell sx={{ width: "350px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>

      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>

      <TableCell sx={{ width: "100px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SkeletonLoader width="30px" height="30px" borderRadius={30} />
        </Box>
      </TableCell>
    </TableRow>
  );
}
