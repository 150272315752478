import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq, setAxiosToken } from "../../api-services";

export const staffLogin = createAsyncThunk("auth/merchants/staffs/login", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/login`,
    payload,
  );
  return response;
});

export const staffResendVerificationCode = createAsyncThunk(
  "auth/merchants/staffs/resend_verification_code",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/resend_verification_code`,
      payload,
    );
    return response;
  },
);

export const staffLogout = createAsyncThunk("auth/merchants/staffs/logout", async () => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/logout`);
  return response;
});

export const staffForgotPassword = createAsyncThunk(
  "auth/merchants/staffs/forgot_password",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/forgot_password`,
      payload,
    );
    return response;
  },
);

export const staffResetPassword = createAsyncThunk(
  "auth/merchants/staffs/reset_password",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/reset_password`,
      payload,
    );
    return response;
  },
);

const authStaffSlice = createSlice({
  name: "authStaff",
  initialState: {
    user: null,
    staffLoginObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },

    staffResendVerificationCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },

    staffLogoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    staffForgotPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    staffResetPasswordObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
  },
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("engageMerchantAuthToken");
      localStorage.removeItem("userDetail");
      localStorage.removeItem("loginType");
    },
  },
  extraReducers: {
    [staffLogin.pending]: (state) => {
      state.staffLoginObj.status = "pending";
    },
    [staffLogin.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.staffLoginObj.status = "succeeded";
      state.staffLoginObj.data = data;
      state.user = data;
      localStorage.setItem("engageMerchantAuthToken", data.authToken);
      localStorage.setItem("userDetail", JSON.stringify(data.user));
      localStorage.setItem("loginType", "staff");
      setAxiosToken(data.authToken);
      state.staffLoginObj.successMessage = message;
    },
    [staffLogin.rejected]: (state, action) => {
      const { message } = action.error;

      localStorage.removeItem("engageMerchantAuthToken");
      localStorage.removeItem("userDetail");
      state.staffLoginObj.status = "failed";
      state.staffLoginObj.errorMessage = message;
    },

    [staffResendVerificationCode.pending]: (state) => {
      state.staffResendVerificationCodeObj.status = "pending";
    },
    [staffResendVerificationCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.staffResendVerificationCodeObj.status = "succeeded";
      state.staffResendVerificationCodeObj.data = data;
      state.staffResendVerificationCodeObj.successMessage = message;
    },
    [staffResendVerificationCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.staffResendVerificationCodeObj.status = "failed";
      state.staffResendVerificationCodeObj.errorMessage = message;
    },

    [staffLogout.pending]: (state) => {
      state.staffLogoutObj.status = "pending";
    },
    [staffLogout.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.staffLogoutObj.status = "succeeded";
      state.staffLogoutObj.data = data;
      state.staffLogoutObj.successMessage = message;

      state.user = null;
      state.staffLoginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("engageMerchantAuthToken");
      localStorage.removeItem("userDetail");
      localStorage.removeItem("loginType");
    },
    [staffLogout.rejected]: (state, action) => {
      const { message } = action.error;

      state.staffLogoutObj.status = "failed";
      state.staffLogoutObj.errorMessage = message;
    },
    [staffForgotPassword.pending]: (state) => {
      state.staffForgotPasswordObj.status = "pending";
    },
    [staffForgotPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.staffForgotPasswordObj.status = "succeeded";
      state.staffForgotPasswordObj.data = data;
      state.staffForgotPasswordObj.successMessage = message;
      localStorage.removeItem("engageMerchantAuthToken");
      localStorage.removeItem("userDetail");
      localStorage.removeItem("loginType");
    },
    [staffForgotPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.staffForgotPasswordObj.status = "failed";
      state.staffForgotPasswordObj.errorMessage = message;
    },
    [staffResetPassword.pending]: (state) => {
      state.staffResetPasswordObj.status = "pending";
    },
    [staffResetPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.staffResetPasswordObj.status = "succeeded";
      state.staffResetPasswordObj.data = data;
      state.staffResetPasswordObj.successMessage = message;
      localStorage.removeItem("engageMerchantAuthToken");
      localStorage.removeItem("userDetail");
      localStorage.removeItem("loginType");
    },
    [staffResetPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.staffResetPasswordObj.status = "failed";
      state.staffResetPasswordObj.errorMessage = message;
    },
  },
});

export default authStaffSlice.reducer;

// state
export const authStaffSelector = (state) => state.authStaff;

export const { resetUser } = authStaffSlice.actions;
