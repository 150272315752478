import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  fitnessBookingSelector,
  getFitnessBookingList,
} from "../../../services/fitness/booking/booking.slice.service";
import AttendeeTableRowLoader from "../loader/attendee-table-row-loader.component";
import AttendeeTableRow from "./attendee-table-row.component";
import { getEventBookingList } from "../../../services/event/booking/booking.slice.services";
import { getAppointments } from "../../../services/appointment/appointment-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxWidth: "1100px",
  width: isMobile ? "90%" : "500px",
  maxHeight: "90%",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const TopBarContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  justifyContent: "space-between",
});

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  ":hover": { backgroundColor: "transparent" },
  color: theme.palette.colors.text.primary,
  padding: "0px",
}));

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function SessionAttendeesModal({ showModal, setShowModal, selectedSession }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("760"));
  const dispatch = useDispatch();
  const { getFitnessBookingListObj } = useSelector(fitnessBookingSelector);
  const [page, setPage] = useState(1);
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [attendees, setAttendees] = useState(null);

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (selectedSession.type === "merchant_class_session") {
      dispatch(
        getFitnessBookingList({
          statuses: "active,expired,merchant_request",
          sessionIds: selectedSession.session.id,
          page: 1,
        }),
      ).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setAttendees(payload.data);
        }

        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }

    if (selectedSession.type === "merchant_event_session") {
      dispatch(
        getEventBookingList({
          statuses: "active,expired",
          sessionIds: selectedSession.session.id,
          page: 1,
        }),
      ).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setAttendees(payload.data);
        }

        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }

    if (selectedSession.type === "merchant_appointment") {
      dispatch(
        getAppointments({
          statuses: "active,expired",
          sessionIds: selectedSession.session.id,
          page: 1,
        }),
      ).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setAttendees(payload.data);
        }

        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  }, [selectedSession]);

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(
      getFitnessBookingList({
        statuses: "active,expired,merchant_request",
        sessionIds: selectedSession.session.id,
        page: newPage,
      }),
    );
  };

  const renderAttendeesTable = () => {
    if (isLoading) {
      return <AttendeeTableRowLoader />;
    }

    if (attendees && attendees.items && attendees.items.length > 0) {
      return attendees.items.map((item) => {
        let user = {};
        if (selectedSession.type === "merchant_appointment") {
          user = item.user;
        } else {
          user = item.session.user;
        }
        return <AttendeeTableRow id={item.id} user={user} key={item.id} />;
      });
    }

    return (
      <TableRow>
        <TableCell colSpan={3}>
          <TableEmptyBox>
            <Text type="TableText">No Attendees</Text>
          </TableEmptyBox>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ padding: isMobile ? "20px" : "40px" }} isMobile={isMobile}>
        <TopBarContainer>
          <Text variant="screenLabel">{selectedSession.session.identifier}</Text>
          <CloseIconButton onClick={handleCancel}>
            <CloseIcon
              sx={{
                stroke: theme.palette.colors.text.primary,
                strokeWidth: 2,
              }}
            />
          </CloseIconButton>
        </TopBarContainer>
        <Spacer position="top" size="l" />
        <Grid container>
          <Text fontWeight="bold">
            Timeslot:{" "}
            {`${format(new Date(selectedSession.session.startAt), "hh:mm a")} - ${format(
              new Date(selectedSession.session.endAt),
              "hh:mm a",
            )}`}
          </Text>
        </Grid>
        <Grid container>
          <Text fontWeight="bold">
            Capacity: {selectedSession.session.currentCapacity} /{" "}
            {selectedSession.session.maxCapacity === 0 ? "∞" : selectedSession.session.maxCapacity}
          </Text>
        </Grid>
        <Spacer position="top" size="l" />
        <Box
          sx={{
            height: isMobile ? "60%" : "400px",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <Grid item xs={12}>
            <TableWrapper>
              <Table>
                <TableHeader
                  headerCells={["Name", "Phone"]}
                  nonSortableColumns={["Name", "Phone"]}
                />
                <TableBody>{renderAttendeesTable()}</TableBody>
              </Table>
            </TableWrapper>
          </Grid>
          <Grid item xs={12}>
            <CustomFooter>
              <Pagination
                sx={{
                  "&& .Mui-selected": {
                    backgroundColor: theme.palette.colors.brand.primary,
                    color: theme.palette.colors.text.white,
                  },
                }}
                page={page}
                shape="rounded"
                onChange={onPageChange}
                count={getFitnessBookingListObj?.data?.pagination.totalPages || 1}
                variant="outlined"
              />
            </CustomFooter>
          </Grid>
        </Box>
      </ModalBox>
    </Modal>
  );
}

SessionAttendeesModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  selectedSession: PropTypes.shape({
    type: PropTypes.string,
    session: PropTypes.shape({
      id: PropTypes.number,
      identifier: PropTypes.string,
      currentCapacity: PropTypes.number,
      maxCapacity: PropTypes.number,
      startAt: PropTypes.string,
      endAt: PropTypes.string,
    }),
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        user: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
};
