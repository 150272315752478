import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { CSVLink } from "react-csv";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  getSubscriberCSV,
  searchSubscriber,
  subscriptionSelector,
} from "../../../services/subscription/subscription-slice.service";
import CheckBoxFilter from "../components/checkbox-fiter.component";
import SearchBar from "../components/search-bar.component";
import SpecificListAutoComplete from "../components/specific-list-autocomplete.component";
import SubscriberTableRow from "../components/subscriber-table-row.component";
import SubscriberTableRowLoader from "../loader/subscriber-table-row-loader.component";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import CheckBusinessAccessModal from "../../../components/modal/check-business-access-modal.component";
import TableSort from "../../../components/table/table-sort.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const STATUSLIST = [
  { id: "1", label: "Active", value: "active" },
  { id: "2", label: "Pending Activation", value: "pending_activation" },
  { id: "3", label: "Expired", value: "expired" },
  { id: "4", label: "Payment Failed", value: "payment_failed" },
];

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  businesses: Yup.array().label("Businesses").nullable(),
  statuses: Yup.array().label("Status").of(Yup.string()).required().min(1),
  startDate: Yup.date().label("Start date").nullable(),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startDate", {
      is: (startDate) => startDate,
      then: Yup.date()
        .min(Yup.ref("startDate"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
});

export default function SubscriberListScreen() {
  const theme = useTheme();
  const formRef = useRef();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [statuses, setStatuses] = useState(["active", "pending_activation"]);
  const [businesses, setBusinesses] = useState(null);
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [page, setPage] = useState(1);
  const { searchSubscriberObj, getSubscriberCSVObj } = useSelector(subscriptionSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const columnMapping = {
    Name: "user.name",
    Phone: "user.phone",
    "Subscription Title": "plan.title",
    Price: "price",
    "Start At": "start_at",
    "End At": "end_at",
    Status: "status",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshSubscriberList = (newPage, keyword, status, businessesId, startDate, endDate) => {
    setIsLoading(true);
    setPage(newPage);
    setQ(keyword);
    setStatuses(status);
    setBusinesses(businessesId);
    setStartAt(startDate);
    setEndAt(endDate);
    dispatch(
      searchSubscriber({
        page: newPage,
        q: keyword,
        statuses: status,
        businessesId,
        startAt: startDate && moment(startDate).format("Y-MM-DD hh:mm:ss"),
        endAt: endDate && moment(endDate).format("Y-MM-DD hh:mm:ss"),
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(
      getSubscriberCSV({
        q: keyword,
        statuses: status,
        businessesId,
        startAt: startDate && moment(startDate).format("Y-MM-DD hh:mm:ss"),
        endAt: endDate && moment(endDate).format("Y-MM-DD hh:mm:ss"),
      }),
    );
    setIsLoading(false);
  };

  const submitFilter = (values) => {
    const businessesId = values.businesses.map((item) => item.id);
    onRefreshSubscriberList(
      1,
      values.q,
      values.statuses,
      businessesId,
      values.startDate,
      values.endDate,
    );
  };

  const onChangeSearch = (keyword) => {
    onRefreshSubscriberList(1, keyword, statuses, businesses, startAt, endAt);
  };

  const onPageChange = (e, newPage) => {
    onRefreshSubscriberList(newPage, q, statuses, businesses, startAt, endAt);
  };

  useEffect(() => {
    onRefreshSubscriberList(page, q, statuses);
    dispatch(getSubscriberCSV({ q, statuses }));
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (searchSubscriberObj.data && searchSubscriberObj.status === "succeeded") {
      records = { ...searchSubscriberObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (searchSubscriberObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (searchSubscriberObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <TableEmptyBox>
                <Text type="TableText">No Subscribers</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => <SubscriberTableRow subscriber={item} key={item.id} />);
    }
    return <SubscriberTableRowLoader />;
  };

  return (
    <>
      <CheckBusinessAccessModal
        showModal={showModal}
        setShowModal={setShowModal}
        productType="subscription"
      />
      <Formik
        innerRef={formRef}
        initialValues={{
          statuses,
          q: q || "",
          businesses: [],
          startDate: null,
          endDate: null,
        }}
        onSubmit={submitFilter}
        validationSchema={validationSchema}
      >
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
            paddingY: theme.dimensions.ScreenPaddingY,
          }}
        >
          <Grid item xs={12}>
            <Text variant="screenLabel">Subscribers</Text>
          </Grid>
          <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
            <WhiteBgCardContainer>
              <BackdropLoading isLoading={isLoading} />

              <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Search</Text>
                          </Grid>
                          <Grid item xs={9}>
                            <SearchBar
                              placeholder="Search by user details"
                              name="q"
                              searchKeyword={q}
                              setSearchKeyword={onChangeSearch}
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Businesses</Text>
                          </Grid>

                          <Grid item xs={9}>
                            <SpecificListAutoComplete
                              name="businesses"
                              placeholder="Businesses"
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Status</Text>
                          </Grid>
                          <Grid item xs={9}>
                            <CheckBoxFilter itemList={STATUSLIST} label="Status" name="statuses" />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Start date</Text>
                          </Grid>
                          <Grid item xs={9}>
                            <FormDatePicker
                              name="startDate"
                              width="100%"
                              isWhiteBg
                              maxDays={null}
                              minDays={false}
                              showClear={true}
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>End date</Text>
                          </Grid>

                          <Grid item xs={9}>
                            <FormDatePicker
                              name="endDate"
                              width="100%"
                              isWhiteBg
                              maxDays={null}
                              minDays={false}
                              showClear={true}
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={4}>
                        <CtaBorderButton onClickButton={() => setShowModal(true)}>
                          <Text type="BrandColor">Check Access</Text>
                        </CtaBorderButton>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormSubmitButton>
                          <Text type="WhiteColor">Search</Text>
                        </FormSubmitButton>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        {getSubscriberCSVObj.status === "succeeded" ? (
                          <CSVLink
                            data={getSubscriberCSVObj.data}
                            filename={`merchant_subscribers${
                              startAt
                                ? `_${moment(startAt).format("Y-MM-DD")}-${moment(endAt).format(
                                    "Y-MM-DD",
                                  )}`
                                : ""
                            }.csv`}
                            style={{ textDecoration: "none" }}
                          >
                            <CtaBorderButton disabled={getSubscriberCSVObj.status !== "succeeded"}>
                              <Text
                                sx={{
                                  color:
                                    getSubscriberCSVObj.status === "succeeded"
                                      ? theme.palette.colors.brand.primary
                                      : theme.palette.colors.text.disabled,
                                }}
                              >
                                Download CSV
                              </Text>
                            </CtaBorderButton>
                          </CSVLink>
                        ) : (
                          <>
                            <CtaBorderButton disabled={true}>
                              <Text
                                sx={{
                                  color: theme.palette.colors.text.disabled,
                                }}
                              >
                                Download CSV
                              </Text>
                            </CtaBorderButton>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>

              <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                <Grid item xs={12}>
                  <TableWrapper>
                    <Table>
                      <TableHeader
                        headerCells={[
                          "Name",
                          "Phone",
                          "Subscription Title",
                          "Price",
                          "Start At",
                          "End At",
                          "Status",
                          "Action",
                        ]}
                        sortColumn={sortColumn}
                        sortOrder={sortOrder}
                        onSortChange={onSortChange}
                        columnMapping={columnMapping}
                      />
                      <TableBody>{renderTableBody()}</TableBody>
                    </Table>
                  </TableWrapper>
                </Grid>
                <Grid item xs={12}>
                  <CustomFooter>
                    <Pagination
                      sx={{
                        "&& .Mui-selected": {
                          backgroundColor: theme.palette.colors.brand.primary,
                          color: theme.palette.colors.text.white,
                        },
                      }}
                      page={page}
                      shape="rounded"
                      onChange={onPageChange}
                      count={searchSubscriberObj?.data?.pagination.totalPages || totalPages}
                      variant="outlined"
                    />
                  </CustomFooter>
                </Grid>
              </Grid>
            </WhiteBgCardContainer>
          </Grid>
        </Grid>
      </Formik>
    </>
  );
}
