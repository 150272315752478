import React from "react";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

function FormPhoneNumber({ name, placeholder, isAuthScreen, isTransparent }) {
  const theme = useTheme();
  const { setFieldValue, errors, setFieldTouched, touched, values } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <>
      <PhoneInput
        placeholder={placeholder}
        specialLabel=""
        value={values[name]}
        isValid={() => !showError}
        country="my"
        onBlur={() => setFieldTouched(name)}
        onChange={(e) => setFieldValue(name, `+${e}`)}
        onlyCountries={["my"]}
        inputStyle={{
          paddingTop: "10px",
          paddingBottom: "10px",
          width: "100%",
          borderRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        }}
      />
      {showError &&
        (isAuthScreen ? (
          <FormHelperText
            sx={{
              color: theme.palette.colors.text.white,
              backgroundColor: theme.palette.colors.ui.errorBox,
              padding: "10px",
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
            }}
          >
            {errors[name]}
          </FormHelperText>
        ) : (
          <FormHelperText
            sx={{
              color: theme.palette.colors.text.error,
              margin: "3px 14px 0px 14px",
            }}
          >
            {errors[name]}
          </FormHelperText>
        ))}
    </>
  );
}

FormPhoneNumber.defaultProps = {
  placeholder: "",
  isAuthScreen: false,
  isTransparent: false,
};

FormPhoneNumber.propTypes = {
  isAuthScreen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isTransparent: PropTypes.bool,
};

export default FormPhoneNumber;
