import React from "react";
import PropTypes from "prop-types";
import { Box, styled, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import Text from "../../../components/text.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import Spacer from "../../../components/spacer.component";
import CtaButton from "../../../components/button/cta-button.component";
import FormPhoneNumber from "../../../components/forms/form-phone-number.component";
import FormSelect from "../../../components/forms/form-select.component";
import FormDobPicker from "../../../components/forms/form-dob-picker.component";
import { staffSelector } from "../../../services/staff/staff-slice.service";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const DisabledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.white,
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
}));

const SpacebetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
});

export default function StaffDetailEditForm({ name, label, placeholder, isEditing, setIsEditing }) {
  const { handleSubmit, values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const { getStaffObj, updateStaffObj } = useSelector(staffSelector);

  const renderButton = () => {
    if (isEditing) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {isSmallScreen && (
            <>
              <CtaButton
                width={isSmallScreen ? null : "70px"}
                padding="8px"
                onClickButton={() => handleSubmit()}
                isLoading={updateStaffObj.status === "pending"}
              >
                <Text type="WhiteColor">Save</Text>
              </CtaButton>
              <Spacer position="left" size="s" />
            </>
          )}
          <CtaButton
            width={isSmallScreen ? null : "70px"}
            padding="8px"
            onClickButton={() => {
              setFieldValue(name, getStaffObj.data[name] === null ? "" : getStaffObj.data[name]);
              setIsEditing(false);
            }}
            isLoading={updateStaffObj.status === "pending"}
          >
            <Text type="WhiteColor">Cancel</Text>
          </CtaButton>
        </Box>
      );
    }
    return (
      <CtaBorderButton
        width={isSmallScreen ? null : "70px"}
        padding="8px"
        onClickButton={() => setIsEditing(true)}
      >
        <Text type="BrandColor">Edit</Text>
      </CtaBorderButton>
    );
  };

  const renderField = () => {
    if (name === "phone") {
      return <FormPhoneNumber name={name} placeholder={placeholder} />;
    }

    if (name === "gender") {
      return (
        <FormSelect
          itemList={[
            { label: "male", id: "1" },
            { label: "female", id: "2" },
          ]}
          name={name}
          placeholder={placeholder}
        />
      );
    }

    if (name === "permissions") {
      return (
        <FormMultipleSelect
          itemList={[
            {
              id: 1,
              label: "Finance Read",
              value: "ms-finance-read",
            },
            {
              id: 2,
              label: "Finance Write",
              value: "ms-finance-write",
            },
            {
              id: 3,
              label: "General Read",
              value: "ms-general-read",
            },
            {
              id: 4,
              label: "General Write",
              value: "ms-general-write",
            },
            {
              id: 5,
              label: "Staff Read",
              value: "ms-staff-read",
            },
            {
              id: 6,
              label: "Staff Write",
              value: "ms-staff-write",
            },
            {
              id: 7,
              label: "Operation Read",
              value: "ms-operation-read",
            },
            {
              id: 8,
              label: "Operation Write",
              value: "ms-operation-write",
            },
            {
              id: 9,
              label: "Trainer Read",
              value: "ms-trainer-read",
            },
            {
              id: 10,
              label: "Trainer Write",
              value: "ms-trainer-write",
            },
          ]}
          name={name}
          placeholder={placeholder}
        />
      );
    }

    if (name === "dob") {
      return <FormDobPicker name={name} />;
    }

    return <FormFieldText isDisableEnter name={name} placeholder={placeholder} width="100%" />;
  };

  const getValue = () => {
    if (name === "gender" && values[name] === "male") {
      return "Male";
    }
    if (name === "gender" && values[name] === "female") {
      return "Female";
    }
    if (name === "gender" && !values[name]) {
      return "Select gender";
    }

    if (name === "dob" && !values[name]) {
      return "Select date";
    }

    if (name === "dob" && values[name]) {
      return format(new Date(values[name]), "dd/MM/yyyy");
    }

    if (name === "permissions" && values[name]) {
      if (values[name].length === 0) {
        return "Select permissions";
      }
      const modifiedPermissions = [];
      values[name].forEach((permission) => {
        const modifiedString = permission.replace("ms-", "").replace("-", " ");
        modifiedPermissions.push(modifiedString);
      });
      return modifiedPermissions;
    }

    return values[name];
  };

  return (
    <>
      {isSmallScreen && (
        <SpacebetweenRowBox>
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
          </LabelContainer>
          {renderButton()}
        </SpacebetweenRowBox>
      )}

      <FormContainer>
        {!isSmallScreen && (
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
          </LabelContainer>
        )}
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Box sx={{ width: "100%" }}>
            {isEditing ? (
              renderField()
            ) : (
              <DisabledTextField value={getValue()} disabled variant="outlined" />
            )}
          </Box>
          {isEditing && !isSmallScreen && (
            <>
              <Spacer position="top" size="m" />
              <CtaButton
                width={isSmallScreen ? null : "70px"}
                padding="8px"
                onClickButton={() => handleSubmit()}
                isLoading={updateStaffObj.status === "pending"}
              >
                <Text type="WhiteColor">Save</Text>
              </CtaButton>
            </>
          )}
        </Box>

        {!isSmallScreen && (
          <>
            <Spacer position="left" size="m" />
            {renderButton()}
          </>
        )}
      </FormContainer>
    </>
  );
}

StaffDetailEditForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
};
