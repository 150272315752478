import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { statementSelector } from "../../../services/statement/statement-slice.service";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.ui.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  width: "90%",
  height: "90%",
  maxWidth: "1000px",
  overflow: "hidden",
}));

const PaddingBox = styled(Box)(() => ({
  paddingLeft: "40px",
  paddingRight: "40px",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
}));

export default function DisputeLogModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getStatementDetailObj } = useSelector(statementSelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  const renderContent = () => {
    if (getStatementDetailObj.status === "succeeded") {
      return (
        <Box sx={{ width: "100%", height: "100%" }}>
          <PaddingBox>
            <SpaceBetweenRowBox>
              <Box sx={{ width: "24px" }} />
              <Text variant="h5" sx={{ textAlign: "center", overflowWrap: "anywhere" }}>
                Dispute Log
              </Text>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </SpaceBetweenRowBox>
          </PaddingBox>
          <Spacer size="l" position="top" />

          <SeperateLine />

          <Spacer size="l" position="top" />

          {getStatementDetailObj.data.items.statement.disputeLog.map((item) => (
            <Grid item xs={12} key={item.id} sx={{ padding: "10px", paddingTop: 0 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box>
                    <Text>{item.rejectReason}</Text>
                    <Spacer size="s" position="top" />
                    <Text type="GreyColor">by {item.employee}</Text>
                    <Text type="GreyColor">{item.createdAt}</Text>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <SeperateLine />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Box>
      );
    }
    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <PaddingBox>
          <SpaceBetweenRowBox>
            <Box sx={{ width: "24px" }} />
            <Text variant="h5" sx={{ textAlign: "center", overflowWrap: "anywhere" }}>
              Dispute Log
            </Text>
            <CloseIconButton onClick={handleCancel}>
              <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
            </CloseIconButton>
          </SpaceBetweenRowBox>
        </PaddingBox>
        <Spacer size="l" position="top" />

        <SeperateLine />

        <Spacer size="l" position="top" />

        <Grid item xs={12} sx={{ padding: "10px", paddingTop: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <Text>
                  <Skeleton height="30px" />
                </Text>
                <Text type="GreyColor">
                  <Skeleton width="150px" height="30px" />
                </Text>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ paddingY: isMobile ? "40px" : "30px" }}>{renderContent()}</ModalBox>
    </Modal>
  );
}

DisputeLogModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
