import React, { useState, useContext } from "react";
import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import CtaButton from "../../../components/button/cta-button.component";
import {
  createPackageDetail,
  packageSelector,
  setReduxStorePackageDetails,
} from "../../../services/package/package-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: "50px",
  outline: "none",
}));

const DetailTextField = styled(Field)`
  border-radius: ${({ theme }) => theme.shape.borderRadius[0]}px;
  padding: 10px;
  font: inherit;
  border: 2px solid #d0d0d0;
  background-color: ${({ theme }) => theme.palette.colors.bg.primary};
  &:hover {
    border: 2px solid black;
  }
`;

export default function AddDetailConfirmationModal({
  title,
  showModal,
  setShowModal,
  selectedPlanId,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [tempDetail, setTempDetail] = useState("");
  const dispatch = useDispatch();
  const { reduxStorePackageDetails } = useSelector(packageSelector);
  const createSnackBar = useContext(SnackbarContext);

  const handleCancel = () => {
    setShowModal(false);
    setTempDetail("");
  };

  const handleChange = (e) => {
    setTempDetail(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(createPackageDetail({ planId: selectedPlanId, description: tempDetail })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          setShowModal(false);
          dispatch(
            setReduxStorePackageDetails([
              ...reduxStorePackageDetails,
              { id: payload.data.id, description: payload.data.description },
            ]),
          );
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
    setTempDetail("");
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "350px" : "500px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Text
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: theme.fonts.fontWeights.bold,
              }}
            >
              {title}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <DetailTextField
                placeholder="Enter package details"
                type="text"
                value={tempDetail}
                name="package-detail"
                onChange={handleChange}
                component="textarea"
                rows={4}
                resize="none"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "45%" }}>
                <CtaBorderButton onClickButton={handleCancel}>
                  <Text type="BrandColor">Cancel</Text>
                </CtaBorderButton>
              </Box>
              <Box sx={{ width: "45%" }}>
                <CtaButton onClickButton={handleSubmit}>
                  <Text type="WhiteColor">Confirm</Text>
                </CtaButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AddDetailConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  selectedPlanId: PropTypes.number.isRequired,
};
