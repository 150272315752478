import { Snackbar, Stack } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { createContext, forwardRef, useCallback, useState } from "react";
import PropTypes from "prop-types";

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export const SnackbarContext = createContext();

function SnackbarProvider({ children }) {
  const [snackBar, setSnackBar] = useState({ message: null, type: null, open: false });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({ message: null, type: snackBar.type, open: false });
  };

  const createSnackBar = useCallback((i) => {
    setSnackBar({ open: true, ...i });
  }, []);

  return (
    <SnackbarContext.Provider value={createSnackBar}>
      {children}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={snackBar.open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={snackBar.type} sx={{ width: "100%" }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </SnackbarContext.Provider>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SnackbarProvider;
