import { styled, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    borderRadius: theme.shape.borderRadius[0],
    border: "1px solid black",
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export default function FormMultipleToggleButton({ itemList, name, width, size }) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext("");
  const showError = touched[name] && typeof errors[name] === "string";
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setFieldValue(name, newValue);
  };

  return (
    <FormControl
      sx={{
        width,
        display: "flex",
        // alignItems: "center",
        wrap: "wrap",
      }}
    >
      <StyledToggleButtonGroup
        size={size}
        onBlur={() => setFieldTouched(name)}
        value={values[name]}
        onChange={handleChange}
      >
        {itemList.map((item) => (
          <ToggleButton
            value={item.value}
            key={item.id}
            sx={{
              "&.Mui-selected, &.Mui-selected:hover": {
                color: "white",
                backgroundColor: theme.palette.colors.brand.primary,
              },
              color: "black",
            }}
          >
            {item.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
      {showError && (
        <FormHelperText error sx={{ marginLeft: "0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormMultipleToggleButton.defaultProps = {
  width: "100%",
  size: "large",
};

FormMultipleToggleButton.propTypes = {
  width: PropTypes.string,
  itemList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
};
