import React, { useContext } from "react";
import * as Yup from "yup";
import { Box, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { authSelector, register } from "../../../services/auth/auth.slice.services";
import SideImage from "../components/side-image.component";
import FormPhoneNumber from "../../../components/forms/form-phone-number.component";
import routes from "../../../navigation/routes";
import EmailForm from "../components/email-form.component";

const CenteredBox = styled(Box)({
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "20px",
  paddingBottom: "20px",
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password and confirm password must be same.")
    .label("Password confirmation"),
  phone: Yup.string()
    .required()
    .label("Phone number")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{8}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid.",
    ),
});

const FormBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
  width: "50%",
}));

const CardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  width: "90%",
  backgroundColor: theme.palette.colors.bg.secondary,
  display: "flex",
  flexDirection: "row",
}));

const PaddedBox = styled(Box)({
  flexDirection: "column",
  justifyContent: "space-between",
  display: "flex",
  height: "100%",
});

function SignUpScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { registerObj } = useSelector(authSelector);
  const history = useHistory();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.dimensions.tabletWidth));

  const onSubmitForm = (values) => {
    dispatch(register(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push({
          pathname: routes.PHONE_VERIFICATION,
          state: { phone: values.phone, isNavigateToMerchantDetail: true },
        });
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Form
      initialValues={{
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
    >
      <CenteredBox>
        <CardContainer sx={{ maxWidth: isTablet ? "400px" : "900px" }}>
          <FormBox sx={{ width: isTablet ? "100%" : "50%" }}>
            <PaddedBox
              sx={{
                paddingTop: isTablet ? "25px" : "50px",
                paddingBottom: isTablet ? "25px" : "50px",
                paddingLeft: isTablet ? "35px" : "70px",
                paddingRight: isTablet ? "35px" : "70px",
              }}
            >
              <Box>
                <Text
                  type="WhiteColor"
                  variant="h4"
                  sx={{ fontWeight: theme.fonts.fontWeights.bold }}
                >
                  Sign Up
                </Text>
                <Spacer position="top" size="s" />
                <Text type="WhiteColor">{`Let's get started.`}</Text>
                <Spacer position="top" size="l" />

                <Text type="WhiteColor" sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  Email
                </Text>
                <Spacer position="top" size="xs" />
                <EmailForm />

                <Spacer position="top" size="m" />

                <Text type="WhiteColor" sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  Phone Number
                </Text>
                <Spacer position="top" size="xs" />
                <FormPhoneNumber name="phone" placeholder="Enter your phone number" isAuthScreen />

                <Spacer position="top" size="m" />

                <Text type="WhiteColor" sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  Password
                </Text>
                <Spacer position="top" size="xs" />
                <FormFieldText name="password" placeholder="Enter your password" isAuthScreen />

                <Spacer position="top" size="m" />

                <Text type="WhiteColor" sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  Confirm Password
                </Text>
                <Spacer position="top" size="xs" />
                <FormFieldText
                  name="confirmPassword"
                  type="password"
                  placeholder="Enter your confirm password"
                  isAuthScreen
                />
              </Box>
              <Box>
                <Spacer position="top" size="xl" />
                <FormSubmitButton whiteBg isLoading={registerObj.status === "pending"}>
                  <Text type="BrandColor">Sign Up</Text>
                </FormSubmitButton>
                <Spacer position="top" size="s" />
                <Text
                  type="WhiteColor"
                  sx={{ fontSize: theme.fonts.fontSizes.size12, textAlign: "center" }}
                >
                  {"Already have an account? "}
                  <Link
                    style={{ fontWeight: theme.fonts.fontWeights.bold, color: "white" }}
                    href={routes.LOGIN}
                    underline="none"
                  >
                    Sign in
                  </Link>
                </Text>
              </Box>
            </PaddedBox>
          </FormBox>
          {!isTablet && <SideImage />}
        </CardContainer>
      </CenteredBox>
    </Form>
  );
}

export default SignUpScreen;
