import React from "react";
import { Box, Button, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Loader from "react-js-loader";

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[1],
    borderTopRightRadius: theme.shape.borderRadius[1],
    borderBottomLeftRadius: theme.shape.borderRadius[1],
    borderBottomRightRadius: theme.shape.borderRadius[1],
    backgroundColor: "transparent",
    textTransform: "none",
    border: `1px solid ${theme.palette.colors.brand.primary}`,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function CtaBorderButton({ children, onClickButton, isLoading, width, padding, disabled }) {
  const theme = useTheme();
  return (
    <ButtonContainer
      sx={{
        "& .MuiButton-root": {
          padding,
        },
      }}
    >
      {isLoading ? (
        <Button
          variant="contained"
          disabled
          sx={{
            ":disabled": { backgroundColor: "transparent" },

            width,
          }}
        >
          <Box
            sx={{ height: "23px", justifyContent: "center", display: "flex", alignItems: "center" }}
          >
            <Loader type="bubble-loop" bgColor={theme.palette.colors.brand.primary} size={30} />
          </Box>
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={onClickButton}
          disabled={isLoading || disabled}
          sx={{ width, boxShadow: 0 }}
        >
          {children}
        </Button>
      )}
    </ButtonContainer>
  );
}

CtaBorderButton.defaultProps = {
  width: "100%",
  isLoading: false,
  onClickButton: null,
  padding: "10px",
  disabled: false,
};

CtaBorderButton.propTypes = {
  disabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  padding: PropTypes.string,
};

export default CtaBorderButton;
