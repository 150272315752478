import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { FormHelperText, Popper, styled, TextField, Typography, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { searchUserPhoneNumber, userSelector } from "../../../services/user/user-slice.service";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.bg.secondary,
  },
}));
function CustomPopper(props) {
  return <Popper {...props} placement="bottom" />;
}

export default function UserListPhoneNumber({ name, placeholder, disabled }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { searchUserPhoneNumberObj } = useSelector(userSelector);
  const createSnackBar = useContext(SnackbarContext);
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const [options, setOptions] = useState([]);

  const listOnChange = (event, value) => {
    setFieldValue(name, typeof value === "string" ? value.split(",") : value);
  };

  const onSearchKeywordChange = (keyword) => {
    setOptions([]);

    dispatch(searchUserPhoneNumber({ phone: keyword })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get user from this phone number! ${error.message}`,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setOptions([payload.data]);
      }
    });
  };

  return (
    <>
      <Autocomplete
        filterOptions={(x) => x}
        loading={options.length === 0}
        loadingText={searchUserPhoneNumberObj.status === "pending" ? "Loading..." : "No options"}
        PopperComponent={CustomPopper}
        disabled={disabled}
        multiple
        disableCloseOnSelect
        onChange={listOnChange}
        options={options}
        getOptionDisabled={(option) => option.option === "loading"}
        value={values[name]}
        getOptionLabel={(option) => option.option || option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li key={option.id} {...props}>
            {option.option === "loading" ? (
              <Typography sx={{ color: theme.palette.colors.text.secondary }}>
                Loading...
              </Typography>
            ) : (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </>
            )}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <DebounceInput
            {...params}
            debounceTimeout={500}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            element={StyledTextField}
            type="number"
            placeholder={placeholder}
            variant="outlined"
            onChange={(e) => {
              onSearchKeywordChange(e.target.value);
            }}
          />
        )}
      />
      {showError && (
        <FormHelperText error sx={{ margin: "3px 14px 0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
}

UserListPhoneNumber.defaultProps = {
  disabled: false,
};

UserListPhoneNumber.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
