import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import Text from "../text.component";

const BANKLIST = [
  { label: "Affin Bank", value: "Affin Bank" },
  { label: "Agro Bank", value: "Agro Bank" },
  { label: "Al-Rajhi Malaysia", value: "Al-Rajhi Malaysia" },
  { label: "Alliance Bank Malaysia Berhad", value: "Alliance Bank Malaysia Berhad" },
  { label: "AmBank", value: "AmBank" },
  { label: "Bank Islam Malaysia", value: "Bank Islam Malaysia" },
  { label: "Bank Muamalat Malaysia Berhad", value: "Bank Muamalat Malaysia Berhad" },
  { label: "Bank Rakyat", value: "Bank Rakyat" },
  { label: "Bank Simpanan Nasional (BSN)", value: "Bank Simpanan Nasional (BSN)" },
  { label: "CIMB Bank", value: "CIMB Bank" },
  { label: "Citibank", value: "Citibank" },
  { label: "Co-op Bank Pertama", value: "Co-op Bank Pertama" },
  { label: "Hong Leong Bank", value: "Hong Leong Bank" },
  { label: "HSBC Bank Malaysia", value: "HSBC Bank Malaysia" },
  { label: "Maybank", value: "Maybank" },
  { label: "MBSB Bank", value: "MBSB Bank" },
  { label: "OCBC Bank", value: "OCBC Bank" },
  { label: "Public Bank", value: "Public Bank" },
  { label: "RHB Bank", value: "RHB Bank" },
  { label: "Standard Chartered Bank", value: "Standard Chartered Bank" },
  { label: "UOB Malaysia", value: "UOB Malaysia" },
];

function FormFieldBankDropdown({ name, width, isTransparent }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values } = useFormikContext();
  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <FormControl
      sx={{
        width,
        "& .MuiOutlinedInput-input": {
          padding: "10px",
        },
      }}
    >
      <Select
        onBlur={() => setFieldTouched(name)}
        value={values[name] === null ? "" : values[name]}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
        error={showError}
        displayEmpty
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        }}
      >
        <MenuItem value="" disabled>
          <Text type="GreyColor">Bank Name</Text>
        </MenuItem>
        {BANKLIST.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error={showError}>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

FormFieldBankDropdown.defaultProps = {
  width: "100%",
  isTransparent: false,
};

FormFieldBankDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
};

export default FormFieldBankDropdown;
