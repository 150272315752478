import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { Field, FieldArray, Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { packageSelector, searchPackage } from "../../../services/package/package-slice.service";
import {
  scheduleSelector,
  showSchedule,
  updateSchedule,
  updateScheduleSlots,
} from "../../../services/schedule/schedule-slice.service";
import FormSelect from "../components/form-select.component";
import ScheduleEditOffDate from "../components/schedule-edit-off-date.component";
import routes from "../../../navigation/routes";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const validationSchemaDetails = Yup.object().shape({
  title: Yup.string().nullable().required().label("Title"),
  categoryType: Yup.string().nullable().required().label("Category"),
  planId: Yup.number().nullable().required().label("Package"),
  businessId: Yup.number().nullable().required().label("Business"),
});

const validationSchemaTimeslots = Yup.object().shape({
  slots: Yup.array(
    Yup.object()
      .shape({
        day: Yup.string()
          .required("Day is required when operational hours are custom")
          .nullable()
          .label("Day"),
        startTime: Yup.string()
          .nullable()
          .required()
          .label("Start time")
          .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
            if (value) {
              const selectedTimeFormatted = moment
                .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
                .utcOffset(8 * 60);
              const selectedTimeWithoutDate = moment(
                selectedTimeFormatted.format("HH:mm:ss"),
                "HH:mm:ss",
              );
              const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
              const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
              return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
            }
            return true;
          }),
        endTime: Yup.string()
          .nullable()
          .required()
          .label("End time")
          .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
            if (value) {
              const selectedTimeFormatted = moment
                .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
                .utcOffset(8 * 60);
              const selectedTimeWithoutDate = moment(
                selectedTimeFormatted.format("HH:mm:ss"),
                "HH:mm:ss",
              );
              const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
              const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
              return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
            }
            return true;
          }),
      })
      .required("Must have at least 1"),
  )
    .min(1)
    .label("Timeslots"),
});

export default function ScheduleEditScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedScheduleId = new URLSearchParams(location.search).get("scheduleId");
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryType, setCategoryType] = useState(location.state.categoryType);
  const { searchPackageObj } = useSelector(packageSelector);
  const { showScheduleObj } = useSelector(scheduleSelector);
  const [allowEditDetails, setAllowEditDetails] = useState(false);
  const [allowEditTimeslots, setAllowEditTimeslots] = useState(false);
  const hoursDisabled = [0, 1, 2, 3, 24];
  const dayList = [
    { id: 0, value: "sunday", label: "Sun" },
    { id: 1, value: "monday", label: "Mon" },
    { id: 2, value: "tuesday", label: "Tue" },
    { id: 3, value: "wednesday", label: "Wed" },
    { id: 4, value: "thursday", label: "Thu" },
    { id: 5, value: "friday", label: "Fri" },
    { id: 6, value: "saturday", label: "Sat" },
  ];

  const disabledDateTime = () => ({
    disabledHours: () => hoursDisabled,
    disabledMinutes: (hour) => {
      if (hour === 23) {
        // For hour 23, only have minutes till 30
        return Array.from({ length: 29 }, (_, index) => index + 31);
      }
      return [];
    },
  });

  const onUpdateScheduleDetails = (values) => {
    const payloadParams = {
      title: values.title,
      categoryType: values.categoryType,
      planId: values.planId,
      businessId: values.businessId,
    };
    setIsLoading(true);
    dispatch(updateSchedule({ scheduleId: selectedScheduleId, ...payloadParams })).then(
      ({ meta, payload, error }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setAllowEditDetails(false);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const onUpdateScheduleTimeslots = (values) => {
    const slotsFormatted = values.slots.map((slot) => ({
      day: slot.day,
      startTime: dayjs(slot.startTime).format("HH:mm:00"),
      endTime: dayjs(slot.endTime).format("HH:mm:00"),
    }));

    setIsLoading(true);
    dispatch(updateScheduleSlots({ scheduleId: selectedScheduleId, slots: slotsFormatted })).then(
      ({ meta, payload, error }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setAllowEditTimeslots(false);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  useEffect(() => {
    dispatch(searchPackage({ q: "", page: 1, categoryTypes: categoryType })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  }, [categoryType]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(showSchedule({ scheduleId: selectedScheduleId })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  if (showScheduleObj.status === "succeeded")
    return (
      <Grid
        container
        spacing={3}
        sx={{
          padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        <BackdropLoading isLoading={isLoading} />
        <Formik
          validationSchema={validationSchemaDetails}
          onSubmit={onUpdateScheduleDetails}
          initialValues={{
            title: showScheduleObj.data.title,
            categoryType: showScheduleObj.data.categoryType,
            planId: showScheduleObj.data.packagePlans[0].id,
            businessId: showScheduleObj.data.business.id,
          }}
        >
          {({ values, handleSubmit, dirty }) => (
            <>
              <Grid item xs={12}>
                <SpaceBetweenBox>
                  <Text variant="screenLabel">Edit Schedule</Text>
                  <Button
                    onClick={() => history.push(routes.SCHEDULE_LIST)}
                    sx={{
                      textTransform: "none",
                      padding: "0px",
                      ":hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Text>Back</Text>
                  </Button>
                </SpaceBetweenBox>
              </Grid>
              <Grid item xs={12}>
                <WhiteBgCardContainer>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <Grid item xs={4} sm={2}>
                      <Text variant="screenLabel">Details</Text>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      {allowEditDetails ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEditDetails(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEditDetails(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Title</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Title</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormFieldText
                              name="title"
                              placeholder="Title"
                              disabled={!allowEditDetails}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Category</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Category</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelect
                              itemList={[
                                { id: 1, value: "wellness", label: "Wellness" },
                                { id: 2, value: "health", label: "Health" },
                              ]}
                              name="categoryType"
                              placeholder="Select Category"
                              disabled={!allowEditDetails}
                              setCategoryType={setCategoryType}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Package</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Package</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelect
                              itemList={
                                searchPackageObj.data?.items
                                  ? searchPackageObj.data?.items?.map((item) => ({
                                      id: item.id,
                                      value: item.id,
                                      label: item.name,
                                    }))
                                  : []
                              }
                              name="planId"
                              placeholder="Select Category First"
                              disabled={!allowEditDetails || !values.categoryType}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelect
                              itemList={
                                values.planId && searchPackageObj.status === "succeeded"
                                  ? searchPackageObj.data?.items
                                      ?.filter((item) => item.id === values.planId)
                                      ?.flatMap((item) => item.businesses)
                                      ?.map((business) => ({
                                        id: business.id,
                                        value: business.id,
                                        label: business.name,
                                      }))
                                  : []
                              }
                              name="businessId"
                              placeholder="Select Package First"
                              disabled={!values.planId || !allowEditDetails}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Grid>
                </WhiteBgCardContainer>
              </Grid>
            </>
          )}
        </Formik>

        <Formik
          validationSchema={validationSchemaTimeslots}
          onSubmit={onUpdateScheduleTimeslots}
          initialValues={{
            slots: showScheduleObj.data?.slots.map((item) => ({
              day: item.day,
              startTime: dayjs(item.startTime, "h:mmA"),
              endTime: dayjs(item.endTime, "h:mmA"),
            })),
          }}
        >
          {({ values, setFieldValue, errors, handleSubmit, dirty }) => (
            <>
              <Grid item xs={12}>
                <WhiteBgCardContainer>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <FieldArray name="slots">
                      {({ push, remove }) => (
                        <>
                          <Grid item xs={12} marginTop="20px">
                            <SpaceBetweenBox>
                              <Box display="flex" alignItems="center">
                                <Text variant="screenLabel">Timeslots</Text>
                                <Spacer position="left" size="xl" />
                                {allowEditTimeslots ? (
                                  <>
                                    <IconButton
                                      size="small"
                                      onClick={handleSubmit}
                                      disabled={!dirty}
                                    >
                                      <CheckIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      onClick={() => setAllowEditTimeslots(false)}
                                    >
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </>
                                ) : (
                                  <>
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setAllowEditTimeslots(true);
                                      }}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </>
                                )}
                              </Box>

                              {allowEditTimeslots && (
                                <Box>
                                  <CtaButton
                                    onClickButton={() =>
                                      push({ day: "", startTime: "", endTime: "" })
                                    }
                                  >
                                    Add
                                  </CtaButton>
                                </Box>
                              )}
                            </SpaceBetweenBox>
                          </Grid>

                          {values.slots.map((_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Grid container item key={index}>
                              <Grid
                                item
                                container
                                xs={allowEditTimeslots ? 11 : 11.5}
                                columnSpacing={isMobile ? 0 : 3}
                                bgcolor={theme.palette.colors.bg.grey}
                                padding="15px 10px"
                                margin="1px"
                                borderRadius="5px"
                              >
                                <Grid item xs={isMobile ? 12 : 2}>
                                  {isMobile && (
                                    <LabelContainer>
                                      <Text fontWeight="bold">Day</Text>
                                    </LabelContainer>
                                  )}
                                  <FormContainer>
                                    {!isMobile && (
                                      <LabelContainer>
                                        <Text fontWeight="bold">Day</Text>
                                      </LabelContainer>
                                    )}
                                    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                      <Field
                                        component={Select}
                                        name={`slots[${index}].day`}
                                        disabled={!allowEditTimeslots}
                                        value={_.day}
                                        onChange={(event) =>
                                          setFieldValue(`slots[${index}].day`, event.target.value)
                                        }
                                        inputProps={{ "aria-label": "Without label" }}
                                        displayEmpty
                                        defaultValue=""
                                        sx={{
                                          borderTopLeftRadius: theme.shape.borderRadius[0],
                                          borderTopRightRadius: theme.shape.borderRadius[0],
                                          borderBottomLeftRadius: theme.shape.borderRadius[0],
                                          borderBottomRightRadius: theme.shape.borderRadius[0],
                                          backgroundColor: theme.palette.colors.bg.primary,
                                        }}
                                      >
                                        <MenuItem value="" disabled>
                                          <Text type="GreyColor">Day</Text>
                                        </MenuItem>
                                        {dayList.map((item) => (
                                          <MenuItem key={item.id} value={item.value}>
                                            {item.label}
                                          </MenuItem>
                                        ))}
                                      </Field>

                                      {errors.slots && (
                                        <FormHelperText error sx={{ marginLeft: "10px" }}>
                                          {errors?.slots[index]?.day}
                                        </FormHelperText>
                                      )}
                                    </Box>
                                  </FormContainer>
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 5}>
                                  {isMobile && (
                                    <LabelContainer>
                                      <Text fontWeight="bold">Start Time</Text>
                                    </LabelContainer>
                                  )}
                                  <FormContainer>
                                    {!isMobile && (
                                      <LabelContainer>
                                        <Text fontWeight="bold">Start Time</Text>
                                      </LabelContainer>
                                    )}
                                    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                      <Field
                                        component={TimePicker}
                                        name={`slots[${index}].startTime`}
                                        disabledTime={disabledDateTime}
                                        use12Hours
                                        disabled={!allowEditTimeslots}
                                        format="h:mm A"
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        value={_.startTime}
                                        onChange={(time) => {
                                          setFieldValue(`slots[${index}].startTime`, time);
                                        }}
                                        style={{
                                          backgroundColor: theme.palette.colors.bg.primary,
                                          padding: "10px",
                                          borderRadius: theme.shape.borderRadius[0],
                                        }}
                                      />

                                      {errors.slots && (
                                        <FormHelperText error sx={{ marginLeft: "10px" }}>
                                          {errors?.slots[index]?.startTime}
                                        </FormHelperText>
                                      )}
                                    </Box>
                                  </FormContainer>
                                </Grid>

                                <Grid item xs={isMobile ? 12 : 5}>
                                  {isMobile && (
                                    <LabelContainer>
                                      <Text fontWeight="bold">End Time</Text>
                                    </LabelContainer>
                                  )}
                                  <FormContainer>
                                    {!isMobile && (
                                      <LabelContainer>
                                        <Text fontWeight="bold">End Time</Text>
                                      </LabelContainer>
                                    )}
                                    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                      <Field
                                        component={TimePicker}
                                        name={`slots[${index}].endTime`}
                                        disabledTime={disabledDateTime}
                                        use12Hours
                                        disabled={!allowEditTimeslots}
                                        format="h:mm A"
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        value={_.endTime}
                                        onChange={(time) => {
                                          setFieldValue(`slots[${index}].endTime`, time);
                                        }}
                                        style={{
                                          backgroundColor: theme.palette.colors.bg.primary,
                                          padding: "10px",
                                          borderRadius: theme.shape.borderRadius[0],
                                        }}
                                      />

                                      {errors.slots && (
                                        <FormHelperText error sx={{ marginLeft: "10px" }}>
                                          {errors?.slots[index]?.endTime}
                                        </FormHelperText>
                                      )}
                                    </Box>
                                  </FormContainer>
                                </Grid>
                              </Grid>

                              {allowEditTimeslots && (
                                <Grid item xs={0.5} sx={{ display: "flex", alignItems: "center" }}>
                                  <IconButton
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    <RemoveCircleIcon
                                      sx={{ color: theme.palette.colors.text.rejected }}
                                    />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </WhiteBgCardContainer>
              </Grid>
            </>
          )}
        </Formik>

        <Grid item xs={12}>
          <WhiteBgCardContainer>
            <ScheduleEditOffDate />
          </WhiteBgCardContainer>
        </Grid>
      </Grid>
    );
}
