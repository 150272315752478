import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableSort from "../../../components/table/table-sort.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  createScheduleOffDates,
  deleteScheduleOffDates,
  getScheduleOffDates,
  scheduleOffDateSelector,
  updateScheduleOffDates,
} from "../../../services/schedule/off-date/off-date-slice.service";
import ScheduleOffDateTableRowLoader from "../loader/schedule-off-date-table-row-loader.component";
import ScheduleOffDateModal from "./schedule-off-date-modal.component";
import ScheduleOffDateTableRow from "./schedule-off-date-table-row.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const createValidationSchema = Yup.object().shape({
  date: Yup.date().label("Date").nullable().required(),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
});

export default function ScheduleEditOffDate() {
  const theme = useTheme();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOffDateId, setSelectedOffDateId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedScheduleId = new URLSearchParams(location.search).get("scheduleId");
  const createSnackBar = useContext(SnackbarContext);
  const { getScheduleOffDatesObj, deleteScheduleOffDatesObj } =
    useSelector(scheduleOffDateSelector);

  const columnMapping = {
    ID: "id",
    Date: "date",
    "Start Time": "startTime",
    "End Time": "endTime",
  };

  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshScheduleOffDateList = (newPage) => {
    setPage(newPage);
    dispatch(
      getScheduleOffDates({
        scheduleId: selectedScheduleId,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    onRefreshScheduleOffDateList(newPage);
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getScheduleOffDatesObj.data && getScheduleOffDatesObj.status === "succeeded") {
      records = { ...getScheduleOffDatesObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const onHandleDelete = (offDateId) => {
    setSelectedOffDateId(offDateId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(
      deleteScheduleOffDates({ scheduleId: selectedScheduleId, offDateId: selectedOffDateId }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshScheduleOffDateList(page);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onCreateOffDay = (values, { resetForm }) => {
    const startTimeFormatted = dayjs(values.startTime).format("HH:mm:00");
    const endTimeFormatted = dayjs(values.endTime).format("HH:mm:00");
    const dateFormatted = format(values.date, "yyyy-MM-dd");

    dispatch(
      createScheduleOffDates({
        scheduleId: selectedScheduleId,
        date: dateFormatted,
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        onRefreshScheduleOffDateList(page);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        setShowCreateModal(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onUpdateOffDay = (values, { resetForm }) => {
    const startTimeFormatted = dayjs(values.startTime).format("HH:mm:00");
    const endTimeFormatted = dayjs(values.endTime).format("HH:mm:00");
    const dateFormatted = format(values.date, "yyyy-MM-dd");

    dispatch(
      updateScheduleOffDates({
        scheduleId: selectedScheduleId,
        offDateId: values.offDateId,
        date: dateFormatted,
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        onRefreshScheduleOffDateList(page);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderTableBody = () => {
    if (getScheduleOffDatesObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getScheduleOffDatesObj.data?.items?.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={9}>
              <TableEmptyBox>
                <Text type="TableText">No items</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records.items.map((item) => (
        <ScheduleOffDateTableRow
          offDate={item}
          key={item.id}
          onHandleDelete={onHandleDelete}
          onUpdateOffDay={onUpdateOffDay}
        />
      ));
    }
    return <ScheduleOffDateTableRowLoader />;
  };

  useEffect(() => {
    onRefreshScheduleOffDateList(1);
  }, []);

  return (
    <>
      <BackdropLoading isLoading={isLoading} />
      <Form
        initialValues={{
          date: new Date(),
          startTime: null,
          endTime: null,
        }}
        validationSchema={createValidationSchema}
        onSubmit={onCreateOffDay}
      >
        <ScheduleOffDateModal showModal={showCreateModal} setShowModal={setShowCreateModal} />
      </Form>
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Off Date"
        label="Are you sure you wish to remove this Off Date?"
        isLoading={deleteScheduleOffDatesObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Grid item container sx={{ justifyContent: "space-between" }}>
        <Grid item xs={2}>
          <Text variant="screenLabel">Off Dates</Text>
        </Grid>
        <Grid item xs={2}>
          <CtaButton
            onClickButton={() => {
              setShowCreateModal(true);
            }}
          >
            <Text type="WhiteColor">Create</Text>
          </CtaButton>
        </Grid>
      </Grid>

      <Grid container sx={{ paddingY: "20px" }} spacing={3}>
        <Grid item xs={12}>
          <TableWrapper>
            <Table>
              <TableHeader
                headerCells={["ID", "Date", "Start Time", "End Time", "Action"]}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                columnMapping={columnMapping}
              />
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableWrapper>
        </Grid>

        <Grid item xs={12}>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              shape="rounded"
              onChange={onPageChange}
              count={getScheduleOffDatesObj?.data?.pagination.totalPages || 1}
              variant="outlined"
            />
          </CustomFooter>
        </Grid>
      </Grid>
    </>
  );
}
