import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import BankDetail from "../components/bank-detail.component";

export default function BankDetailScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <Grid item sx={{ display: "flex", flex: 1 }}>
        <BankDetail />
      </Grid>
    </Grid>
  );
}
