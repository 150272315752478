import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PackageRevenueChart from "../components/package-revenue-chart.component";
import SubscriptionRevenueChart from "../components/subscription-revenue-chart.component";
import FitnessClassRevenueChart from "../components/fitness-class-revenue-chart.component";
import HourlyBookingRevenueChart from "../components/hourly-booking-revenue-chart.component";
import DialogModal from "../../../components/notification/dialog-modal.component";
import { getUserPermission, isMerchantStaff } from "../../../infrastructure/utils";
import { resetUser, staffLogout } from "../../../services/staff/auth/auth.slice.services";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import routes from "../../../navigation/routes";
import EventRevenueChart from "../components/event-revenue-chart.component";
import TotalRevenueChart from "../components/total-revenue-chart.component";

function RevenueSummaryScreen() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const history = useHistory();

  const handleLogout = () => {
    dispatch(staffLogout()).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ message: payload.message, type: "success" });
        history.push(routes.LOGIN_STAFF);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        createSnackBar({ message: "Successfully logged out", type: "success" });
        history.push(routes.LOGIN_STAFF);
      }
    });
  };

  useEffect(() => {
    if (isMerchantStaff() && getUserPermission().length === 0) {
      setShowModal(true);
    }
  }, []);

  return (
    <>
      <DialogModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="You do not have any permissions to access the portal. Please contact the owner. Thank you."
        buttonText="OK"
        onConfirm={handleLogout}
      />
      <Grid container spacing={2} paddingBottom="20px">
        <Grid item md={12} lg={6}>
          <TotalRevenueChart />
        </Grid>
        <Grid item md={12} lg={6}>
          <FitnessClassRevenueChart />
        </Grid>
        <Grid item md={12} lg={6}>
          <HourlyBookingRevenueChart />
        </Grid>
        <Grid item md={12} lg={6}>
          <SubscriptionRevenueChart />
        </Grid>
        <Grid item md={12} lg={6}>
          <PackageRevenueChart />
        </Grid>
        <Grid item md={12} lg={6}>
          <EventRevenueChart />
        </Grid>
      </Grid>
    </>
  );
}

export default RevenueSummaryScreen;
