import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api-services";

export const searchBooking = createAsyncThunk("merchants/booking/search", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings?${payload}`,
  );
  return response;
});

export const getBookingDetail = createAsyncThunk("merchants/booking/detail", async (bookingId) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/${bookingId}`,
  );
  return response;
});

export const getBookingsCSV = createAsyncThunk("merchants/booking/csv", async (payload) => {
  const { businessId, classId, startAt, endAt, status } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/csv?status=${status}${
      businessId ? "&businessId=".concat(businessId) : ""
    }${classId ? "&classId=".concat(classId) : ""}${startAt ? "&startAt=".concat(startAt) : ""}${
      endAt ? "&endAt=".concat(endAt) : ""
    }`,
  );
  return response;
});

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    searchBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBookingDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBookingsCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [searchBooking.pending]: (state) => {
      state.searchBookingObj.status = "pending";
    },
    [searchBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchBookingObj.status = "succeeded";
      state.searchBookingObj.data = data;
      state.searchBookingObj.successMessage = message;
    },
    [searchBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchBookingObj.status = "failed";
      state.searchBookingObj.errorMessage = message;
    },
    [getBookingDetail.pending]: (state) => {
      state.getBookingDetailObj.status = "pending";
    },
    [getBookingDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBookingDetailObj.status = "succeeded";
      state.getBookingDetailObj.data = data;
      state.getBookingDetailObj.successMessage = message;
    },
    [getBookingDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBookingDetailObj.status = "failed";
      state.getBookingDetailObj.errorMessage = message;
    },
    [getBookingsCSV.pending]: (state) => {
      state.getBookingsCSVObj.status = "pending";
    },
    [getBookingsCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBookingsCSVObj.status = "succeeded";
      state.getBookingsCSVObj.data = data;
      state.getBookingsCSVObj.successMessage = message;
    },
    [getBookingsCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBookingsCSVObj.status = "failed";
      state.getBookingsCSVObj.errorMessage = message;
    },
  },
});

export default bookingSlice.reducer;

// state
export const bookingSelector = (state) => state.booking;
