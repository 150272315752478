import { setAxiosToken } from "../../services/api-services";

export const isLogin = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const authToken = searchParams.get("authToken");

  if (authToken) {
    setAxiosToken(authToken);
    localStorage.setItem("engageMerchantAuthToken", authToken);
    localStorage.setItem(
      "userDetail",
      JSON.stringify({ role: ["merchant-owner"], permissions: [] }),
    );
    localStorage.setItem("loginType", "owner");

    window.history.replaceState({}, "", window.location.href.split("?")[0]);
  }

  const storedAuthToken = localStorage.getItem("engageMerchantAuthToken");
  if (storedAuthToken) {
    setAxiosToken(storedAuthToken);
    return true;
  }
  return false;
};

export const isMerchantStaff = () => localStorage.getItem("loginType") === "staff";

export const getUserRole = () => {
  // set the role and permission first into local storage if is shadow login
  isLogin();

  const userDetail = localStorage.getItem("userDetail");

  if (userDetail) {
    return JSON.parse(userDetail).role;
  }
  return [];
};

export const getUserPermission = () => {
  const userDetail = localStorage.getItem("userDetail");

  if (userDetail) {
    return JSON.parse(userDetail).permissions;
  }
  return [];
};
