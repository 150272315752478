import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KatchLogo from "../../../images/katch-logo.png";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import Form from "../../../components/forms/form.component";
import FormOTP from "../../../components/forms/form-otp.component";
import {
  authSelector,
  resendVerificationCode,
  verifyVerificationCode,
} from "../../../services/auth/auth.slice.services";
import routes from "../../../navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import ResendCountdownButton from "../../../components/button/resend-countdown-button.component";

const MainContainer = styled(Box)({
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  width: "90%",
  maxWidth: "500px",
  backgroundColor: theme.palette.colors.bg.secondary,
  display: "flex",
  flexDirection: "row",
}));

const CenteredBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  verificationCode: Yup.string().min(6).required().label("Verification Code"),
  type: Yup.string().required(),
});

export default function EmailVerificationScreen() {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isConfirmPressed, setIsConfirmPressed] = useState(false);
  const { verifyVerificationCodeObj } = useSelector(authSelector);

  const onResendVerificationCode = () => {
    dispatch(
      resendVerificationCode({
        type: "email",
        infoType: location.state?.email,
        functionType: "updateEmail",
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onResendVerificationCode();
  }, []);

  const onConfirmVerificationCode = (values) => {
    dispatch(verifyVerificationCode(values)).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.PROFILE_DETAIL);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Form
      initialValues={{ verificationCode: "", type: "email" }}
      validationSchema={validationSchema}
      onSubmit={onConfirmVerificationCode}
    >
      <MainContainer>
        <CardContainer>
          <CenteredBox sx={{ padding: isMobile ? "20px" : "50px" }}>
            <img src={KatchLogo} width="150" alt="katch-logo" />
            <Spacer position="top" size="m" />
            <Text
              sx={{
                fontSize: theme.fonts.fontSizes.size30,
                fontWeight: theme.fonts.fontWeights.bold,
              }}
            >
              Enter Verification Code
            </Text>
            <Spacer size="s" position="top" />

            <Text type="GreyColor">
              A verification code has been sent to {location.state?.email}
            </Text>

            <Spacer size="m" position="top" />
            <FormOTP
              name="verificationCode"
              width={isMobile ? "30px" : "50px"}
              setIsConfirmPressed={setIsConfirmPressed}
              isConfirmPressed={isConfirmPressed}
            />
            <Spacer size="s" position="top" />

            <ResendCountdownButton onClickButton={onResendVerificationCode} variant="body1" />
            <Spacer size="m" position="top" />
            <Box sx={{ width: "100%" }}>
              <FormSubmitButton
                onClickButton={() => setIsConfirmPressed(true)}
                isLoading={verifyVerificationCodeObj.status === "pending"}
              >
                <Text type="WhiteColor">Verify</Text>
              </FormSubmitButton>
            </Box>
            <Spacer size="m" position="top" />
          </CenteredBox>
        </CardContainer>
      </MainContainer>
    </Form>
  );
}
