import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useFormikContext } from "formik";
import { Box, FormHelperText, useTheme } from "@mui/material";

export default function FormTimePicker({
  name,
  isTransparent,
  width,
  disabled,
  maxWidth,
  placeholder,
}) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (time) => {
    setFieldValue(name, time);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          "& .MuiTextField-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            width,
            maxWidth,
          },
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
          },
        }}
      >
        <TimePicker
          disabled={disabled}
          value={values[name]}
          onChange={handleChange}
          InputProps={{ error: showError }}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              onBlur={() => setFieldTouched(name)}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder,
              }}
            />
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}
FormTimePicker.defaultProps = {
  width: null,
  isTransparent: false,
  maxWidth: null,
  disabled: false,
  placeholder: "hh/mm",
};

FormTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
  maxWidth: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};
