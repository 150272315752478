import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, patchReq, postReq, putReq } from "../api-services";

export const searchPackage = createAsyncThunk("merchants/packages/plans/index", async (payload) => {
  const { q, page, categoryTypes } = payload;

  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans?${q ? "q=".concat(q) : ""}${
      categoryTypes ? "&categoryTypes=".concat(categoryTypes) : ""
    }&page=${page}`,
  );
  return response;
});

export const searchPackagePurchases = createAsyncThunk(
  "merchants/packages/index",
  async (payload) => {
    const { q, page, statuses } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages?${q ? "q=".concat(q) : ""}${
        statuses ? "&statuses=".concat(statuses) : ""
      }&page=${page}`,
    );
    return response;
  },
);

export const getPackagePurchasesCSV = createAsyncThunk(
  "merchants/packages/csv",
  async (payload) => {
    const { q, statuses } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/csv?${q ? "q=".concat(q) : ""}${
        statuses ? "&statuses=".concat(statuses) : ""
      }`,
    );
    return response;
  },
);

export const searchReport = createAsyncThunk(
  "merchants/packages/plans/{plan_id}/report",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}/report?q=${payload.q}&page=${payload.page}`,
    );
    return response;
  },
);

export const getPackage = createAsyncThunk(
  "merchants/packages/plans/get/{plan_id}",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}`,
    );
    return response;
  },
);

export const searchCategories = createAsyncThunk(
  "merchants/packages/plans/search_categories",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/search_categories`,
      payload,
    );
    return response;
  },
);

export const createPackage = createAsyncThunk("merchants/packages/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans`,
    payload,
  );
  return response;
});

export const deletePackage = createAsyncThunk(
  "merchants/packages/plans/delete/{plan_id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}`,
    );
    return response;
  },
);

export const updatePackage = createAsyncThunk(
  "merchants/packages/plans/update/{plan_id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}`,
      payload,
    );
    return response;
  },
);

export const updatePackageImage = createAsyncThunk(
  "merchants/packages/plans/update/image/{plan_id}",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}/image`,
      payload,
    );
    return response;
  },
);

export const updatePackageStatus = createAsyncThunk(
  "merchants/packages/plans/update/status/{plan_id}",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}/status`,
      payload,
    );
    return response;
  },
);

export const updatePackageItem = createAsyncThunk(
  "merchants/packages/plans/{plan_id}/update/item",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}/items`,
      payload,
    );
    return response;
  },
);

export const deletePackageDetail = createAsyncThunk(
  "merchants/packages/plans/delete/{plan_id}/detail",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}/details/${payload.detailId}`,
    );
    return response;
  },
);

export const updatePackageDetail = createAsyncThunk(
  "merchants/packages/plans/update/{plan_id}/detail",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}/details/${payload.detailId}`,
      { description: payload.description },
    );
    return response;
  },
);

export const createPackageDetail = createAsyncThunk(
  "merchants/packages/plans/create/{plan_id}/detail",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/${payload.planId}/details`,
      { description: payload.description },
    );
    return response;
  },
);

export const getPackageAutoCompleteSuggestion = createAsyncThunk(
  "merchants/packages/plans/autocomplete",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/packages/plans/auto_complete?q=${payload.keyword}&page=${payload.page}`,
    );
    return response;
  },
);

const packageSlice = createSlice({
  name: "package",
  initialState: {
    reduxStorePackageDetails: [],
    searchPackageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchPackagePurchasesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPackagePurchasesCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchReportObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPackageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchCategoriesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createPackageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deletePackageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePackageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePackageImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePackageStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePackageItemObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deletePackageDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePackageDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createPackageDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPackageAutoCompleteSuggestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    setReduxStorePackageDetails: (state, action) => {
      const { payload } = action;
      state.reduxStorePackageDetails = payload;
    },
  },
  extraReducers: {
    [searchPackage.pending]: (state) => {
      state.searchPackageObj.status = "pending";
    },
    [searchPackage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchPackageObj.status = "succeeded";
      state.searchPackageObj.data = data;
      state.searchPackageObj.successMessage = message;
    },
    [searchPackage.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchPackageObj.status = "failed";
      state.searchPackageObj.errorMessage = message;
    },

    [searchPackagePurchases.pending]: (state) => {
      state.searchPackagePurchasesObj.status = "pending";
    },
    [searchPackagePurchases.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchPackagePurchasesObj.status = "succeeded";
      state.searchPackagePurchasesObj.data = data;
      state.searchPackagePurchasesObj.successMessage = message;
    },
    [searchPackagePurchases.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchPackagePurchasesObj.status = "failed";
      state.searchPackagePurchasesObj.errorMessage = message;
    },
    [getPackagePurchasesCSV.pending]: (state) => {
      state.getPackagePurchasesCSVObj.status = "pending";
    },
    [getPackagePurchasesCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPackagePurchasesCSVObj.status = "succeeded";
      state.getPackagePurchasesCSVObj.data = data;
      state.getPackagePurchasesCSVObj.successMessage = message;
    },
    [getPackagePurchasesCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPackagePurchasesCSVObj.status = "failed";
      state.getPackagePurchasesCSVObj.errorMessage = message;
    },
    [searchReport.pending]: (state) => {
      state.searchReportObj.status = "pending";
    },
    [searchReport.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchReportObj.status = "succeeded";
      state.searchReportObj.data = data;
      state.searchReportObj.successMessage = message;
    },
    [searchReport.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchReportObj.status = "failed";
      state.searchReportObj.errorMessage = message;
    },
    [getPackage.pending]: (state) => {
      state.getPackageObj.status = "pending";
    },
    [getPackage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPackageObj.status = "succeeded";
      state.getPackageObj.data = data;
      state.getPackageObj.successMessage = message;
    },
    [getPackage.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPackageObj.status = "failed";
      state.getPackageObj.errorMessage = message;
    },
    [searchCategories.pending]: (state) => {
      state.searchCategoriesObj.status = "pending";
    },
    [searchCategories.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchCategoriesObj.status = "succeeded";
      state.searchCategoriesObj.data = data;
      state.searchCategoriesObj.successMessage = message;
    },
    [searchCategories.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchCategoriesObj.status = "failed";
      state.searchCategoriesObj.errorMessage = message;
    },
    [createPackage.pending]: (state) => {
      state.createPackageObj.status = "pending";
    },
    [createPackage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createPackageObj.status = "succeeded";
      state.createPackageObj.data = data;
      state.createPackageObj.successMessage = message;
    },
    [createPackage.rejected]: (state, action) => {
      const { message } = action.error;

      state.createPackageObj.status = "failed";
      state.createPackageObj.errorMessage = message;
    },
    [deletePackage.pending]: (state) => {
      state.deletePackageObj.status = "pending";
    },
    [deletePackage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deletePackageObj.status = "succeeded";
      state.deletePackageObj.data = data;
      state.deletePackageObj.successMessage = message;
    },
    [deletePackage.rejected]: (state, action) => {
      const { message } = action.error;

      state.deletePackageObj.status = "failed";
      state.deletePackageObj.errorMessage = message;
    },
    [updatePackage.pending]: (state) => {
      state.updatePackageObj.status = "pending";
    },
    [updatePackage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePackageObj.status = "succeeded";
      state.updatePackageObj.data = data;
      state.updatePackageObj.successMessage = message;
    },
    [updatePackage.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePackageObj.status = "failed";
      state.updatePackageObj.errorMessage = message;
    },
    [updatePackageImage.pending]: (state) => {
      state.updatePackageImageObj.status = "pending";
    },
    [updatePackageImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePackageImageObj.status = "succeeded";
      state.updatePackageImageObj.data = data;
      state.updatePackageImageObj.successMessage = message;
    },
    [updatePackageImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePackageImageObj.status = "failed";
      state.updatePackageImageObj.errorMessage = message;
    },
    [updatePackageStatus.pending]: (state) => {
      state.updatePackageStatusObj.status = "pending";
    },
    [updatePackageStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePackageStatusObj.status = "succeeded";
      state.updatePackageStatusObj.data = data;
      state.getPackageObj.data = data;
      state.updatePackageStatusObj.successMessage = message;
    },
    [updatePackageStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePackageStatusObj.status = "failed";
      state.updatePackageStatusObj.errorMessage = message;
    },
    [updatePackageItem.pending]: (state) => {
      state.updatePackageItemObj.status = "pending";
    },
    [updatePackageItem.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePackageItemObj.status = "succeeded";
      state.updatePackageItemObj.data = data;
      state.updatePackageItemObj.successMessage = message;
    },
    [updatePackageItem.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePackageItemObj.status = "failed";
      state.updatePackageItemObj.errorMessage = message;
    },
    [deletePackageDetail.pending]: (state) => {
      state.deletePackageDetailObj.status = "pending";
    },
    [deletePackageDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deletePackageDetailObj.status = "succeeded";
      state.deletePackageDetailObj.data = data;
      state.deletePackageDetailObj.successMessage = message;
    },
    [deletePackageDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.deletePackageDetailObj.status = "failed";
      state.deletePackageDetailObj.errorMessage = message;
    },
    [updatePackageDetail.pending]: (state) => {
      state.updatePackageDetailObj.status = "pending";
    },
    [updatePackageDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePackageDetailObj.status = "succeeded";
      state.updatePackageDetailObj.data = data;
      state.updatePackageDetailObj.successMessage = message;
    },
    [updatePackageDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePackageDetailObj.status = "failed";
      state.updatePackageDetailObj.errorMessage = message;
    },
    [createPackageDetail.pending]: (state) => {
      state.createPackageDetailObj.status = "pending";
    },
    [createPackageDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createPackageDetailObj.status = "succeeded";
      state.createPackageDetailObj.data = data;
      state.createPackageDetailObj.successMessage = message;
    },
    [createPackageDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.createPackageDetailObj.status = "failed";
      state.createPackageDetailObj.errorMessage = message;
    },
    [getPackageAutoCompleteSuggestion.pending]: (state) => {
      state.getPackageAutoCompleteSuggestionObj.status = "pending";
    },
    [getPackageAutoCompleteSuggestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPackageAutoCompleteSuggestionObj.status = "succeeded";
      state.getPackageAutoCompleteSuggestionObj.data = data;
      state.getPackageAutoCompleteSuggestionObj.successMessage = message;
    },
    [getPackageAutoCompleteSuggestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPackageAutoCompleteSuggestionObj.status = "failed";
      state.getPackageAutoCompleteSuggestionObj.errorMessage = message;
    },
  },
});

export default packageSlice.reducer;

// state
export const packageSelector = (state) => state.package;

export const { setReduxStorePackageDetails } = packageSlice.actions;
