import { Box, IconButton, TableCell, TableRow, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";

export default function SubscriberTableRow({ subscriber }) {
  const theme = useTheme();
  const [showDetail, setShowDetail] = useState(false);

  const handleOpenDetail = () => {
    setShowDetail(!showDetail);
  };
  return (
    <>
      <TableRow
        key={subscriber.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {subscriber.user.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {subscriber.user.phone}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {subscriber.plan.title}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "150px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM${subscriber.price}`}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {subscriber.start_at}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {subscriber.end_at}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
            type={subscriber.status === "active" ? "BrandColor" : "YellowColor"}
          >
            {subscriber.status.replace("_", " ")}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenDetail}>
              {showDetail ? (
                <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.text.table }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.text.table }} />
              )}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>

      {showDetail && (
        <>
          <TableRow>
            <TableCell colSpan={8}>
              <Text marginLeft="2%">Description</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={8}>
              <Text type="TableText" marginLeft="2%">
                {subscriber.plan.shortDescription}
              </Text>
            </TableCell>
          </TableRow>
          {subscriber.businesses.length !== 0 && (
            <>
              <TableRow>
                <TableCell colSpan={8}>
                  <Text marginLeft="2%">Businesses Name</Text>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={8}>
                  {subscriber.businesses.map((business) => (
                    <Box key={business.name}>
                      <Text type="TableText" marginLeft="2%">
                        {business.name}
                      </Text>
                      <Spacer />
                    </Box>
                  ))}
                </TableCell>
              </TableRow>
            </>
          )}
        </>
      )}
    </>
  );
}

SubscriberTableRow.propTypes = {
  subscriber: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.string,
    }),
    plan: PropTypes.shape({
      title: PropTypes.string,
      shortDescription: PropTypes.string,
      numberOfDays: PropTypes.number,
    }),
    price: PropTypes.string,
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
};
