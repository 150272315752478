import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api-services";

export const getHourlyBookings = createAsyncThunk(
  "merchants/hourly_bookings/index",
  async (payload) => {
    const { page, q, businessesId, startAt, endAt, statuses } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/hourly_bookings?${
        page ? "page=".concat(page) : "page=1"
      }${q ? "&q=".concat(q) : ""}${businessesId ? "&businessesId=".concat(businessesId) : ""}${
        startAt ? "&startAt=".concat(startAt) : ""
      }${endAt ? "&endAt=".concat(endAt) : ""}${statuses ? "&statuses=".concat(statuses) : ""}`,
    );
    return response;
  },
);

export const getHourlyBookingsCSV = createAsyncThunk(
  "merchants/hourly_bookings/csv",
  async (payload) => {
    const { businessesId, q, startAt, endAt, statuses } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/hourly_bookings/csv?${
        statuses ? "statuses=".concat(statuses) : ""
      }${businessesId ? "&businessesId=".concat(businessesId) : ""}${q ? "&q=".concat(q) : ""}${
        startAt ? "&startAt=".concat(startAt) : ""
      }${endAt ? "&endAt=".concat(endAt) : ""}`,
    );
    return response;
  },
);

const hourlyBookingSlice = createSlice({
  name: "hourlyBooking",
  initialState: {
    getHourlyBookingsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getHourlyBookingsCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getHourlyBookings.pending]: (state) => {
      state.getHourlyBookingsObj.status = "pending";
    },
    [getHourlyBookings.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getHourlyBookingsObj.status = "succeeded";
      state.getHourlyBookingsObj.data = data;
      state.getHourlyBookingsObj.successMessage = message;
    },
    [getHourlyBookings.rejected]: (state, action) => {
      const { message } = action.error;

      state.getHourlyBookingsObj.status = "failed";
      state.getHourlyBookingsObj.errorMessage = message;
    },
    [getHourlyBookingsCSV.pending]: (state) => {
      state.getHourlyBookingsCSVObj.status = "pending";
    },
    [getHourlyBookingsCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getHourlyBookingsCSVObj.status = "succeeded";
      state.getHourlyBookingsCSVObj.data = data;
      state.getHourlyBookingsCSVObj.successMessage = message;
    },
    [getHourlyBookingsCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getHourlyBookingsCSVObj.status = "failed";
      state.getHourlyBookingsCSVObj.errorMessage = message;
    },
  },
});

export default hourlyBookingSlice.reducer;

// state
export const hourlyBookingSelector = (state) => state.hourlyBooking;
