import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import routes from "../../../navigation/routes";
import Text from "../../../components/text.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import SearchBar from "../../../components/table/search-bar.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import { searchReport, packageSelector } from "../../../services/package/package-slice.service";
import PackageReportTableRowLoader from "../loader/package-report-table-row-loader.component";
import PackageReportTableRow from "../components/package-report-table-row.component";
import TableSort from "../../../components/table/table-sort.component";
import TableHeader from "../../../components/table/table-header.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function PackageReportScreen() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedPlanId = location.state.id;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const { searchReportObj } = useSelector(packageSelector);
  const [totalPages, setTotalPages] = useState(1);
  const columnMapping = {
    Name: "user.firstName",
    Status: "session.claimedCount",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshPackageReport = (newPage, keyword) => {
    setPage(newPage);
    setQ(keyword);
    dispatch(searchReport({ planId: selectedPlanId, q: keyword, page: newPage })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setTotalPages(payload.data.pagination.totalPages);
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const onChangeSearch = (keyword) => {
    onRefreshPackageReport(1, keyword);
  };

  const onPageChange = (e, newPage) => {
    onRefreshPackageReport(newPage, q);
  };

  useEffect(() => {
    onRefreshPackageReport(page, q);
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const getProcessedRecord = () => {
    let records = null;

    if (searchReportObj.data && searchReportObj.status === "succeeded") {
      records = { ...searchReportObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (searchReportObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (searchReportObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={2}>
              <TableEmptyBox>
                <Text type="TableText">No Packages</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records.items.map((item) => (
        <PackageReportTableRow packagePlanReport={item} key={uuidv4()} />
      ));
    }
    return <PackageReportTableRowLoader />;
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text variant="screenLabel">Package Plan Report</Text>
          <Button
            onClick={() => history.push(routes.PACKAGE_LIST)}
            sx={{
              textTransform: "none",
              padding: "0px",
              ":hover": { backgroundColor: "transparent" },
            }}
          >
            <Text>Back</Text>
          </Button>
        </SpaceBetweenBox>
      </Grid>
      <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
        <WhiteBgCardContainer>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <SearchBar
              searchKeyword={q}
              setSearchKeyword={onChangeSearch}
              placeholder="Search here"
              isTransparent
              width="300px"
            />
          </Box>

          <Grid container sx={{ paddingY: "20px" }} spacing={3}>
            <Grid item xs={12}>
              <TableWrapper>
                <Table>
                  <TableHeader
                    headerCells={["Name", "Status"]}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={onSortChange}
                    columnMapping={columnMapping}
                  />
                  <TableBody>{renderTableBody()}</TableBody>
                </Table>
              </TableWrapper>
            </Grid>
            <Grid item xs={12}>
              <CustomFooter>
                <Pagination
                  sx={{
                    "&& .Mui-selected": {
                      backgroundColor: theme.palette.colors.brand.primary,
                      color: theme.palette.colors.text.white,
                    },
                  }}
                  page={page}
                  shape="rounded"
                  onChange={onPageChange}
                  count={searchReportObj?.data?.pagination.totalPages || totalPages}
                  variant="outlined"
                />
              </CustomFooter>
            </Grid>
          </Grid>
        </WhiteBgCardContainer>
      </Grid>
    </Grid>
  );
}
