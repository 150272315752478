import React from "react";
import PropTypes from "prop-types";
import { Box, styled, useTheme } from "@mui/material";
import Text from "../../../components/text.component";
import FormPhoneNumber from "../../../components/forms/form-phone-number.component";
import FormFieldText from "../../../components/forms/form-field-text.component";

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function StaffLabelFormField({ name, label, placeholder }) {
  const theme = useTheme();

  const renderField = () => {
    if (name === "phone") {
      return <FormPhoneNumber name={name} placeholder={placeholder} />;
    }
    return <FormFieldText name={name} placeholder={placeholder} />;
  };

  return (
    <Box>
      <LabelContainer>
        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
      </LabelContainer>
      <Box sx={{ width: "100%" }}>{renderField()}</Box>
    </Box>
  );
}

StaffLabelFormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};
