import React from "react";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/spacer.component";
import { statementSelector } from "../../../services/statement/statement-slice.service";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.ui.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  padding: "0px",
}));

const CenterRowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  color: theme.palette.colors.ui.notification,
}));

export default function EmailFieldModal({ showModal, setShowModal, name }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { sendStatementEmailObj } = useSelector(statementSelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ padding: isMobile ? "30px" : "40px", width: isMobile ? "350px" : "450px" }}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Box>
            <Spacer position="top" size="m" />
            <Text variant="h6" textAlign="start">
              Send to:
            </Text>
            <Spacer position="top" size="s" />
            <FormFieldText name={name} placeholder="Email" />
            <Spacer position="top" size="s" />
            <CenterRowBox>
              <WarningAmberRoundedIcon />
              <Spacer position="left" size="xs" />
              <Text variant="body2" sx={{ color: theme.palette.colors.ui.notification }}>
                Please ensure the email address is correct.
              </Text>
            </CenterRowBox>
          </Grid>

          <Grid item xs={12}>
            <FormSubmitButton isLoading={sendStatementEmailObj.status === "pending"}>
              <Text type="WhiteColor">Send</Text>
            </FormSubmitButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

EmailFieldModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
