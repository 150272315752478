import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import FormPrice from "../../../components/forms/form-price.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  additionalFeesSelector,
  getAdditionalFees,
  updateAdditionalFees,
  updateAdditionalFeesStatus,
} from "../../../services/additional-fees/additional-fees-slice.service";
import StatusSingleSelect from "../components/status-single-select.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function AdditionalFeesEditScreen() {
  const theme = useTheme();
  const location = useLocation();
  const selectedFeeId = location.state.id;
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getAdditionalFeesObj } = useSelector(additionalFeesSelector);
  const [allowEditDetail, setAllowEditDetail] = useState(false);
  const [allowEditStatus, setAllowEditStatus] = useState(false);

  const validationSchema = Yup.object().shape({
    description: Yup.string().required().label("Description"),
    priceRM: Yup.number().required().label("Price"),
  });

  const validationSchemaStatus = Yup.object().shape({
    active: Yup.boolean().required().label("Status"),
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAdditionalFees({ feeId: selectedFeeId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onUpdateAdditionalFees = (values) => {
    const amountCents = values.priceRM * 100;
    setIsLoading(true);
    dispatch(updateAdditionalFees({ feeId: selectedFeeId, amountCents, ...values })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        setIsLoading(false);
      },
    );
    setAllowEditDetail(false);
  };

  const onUpdateAdditionalFeesStatus = (values) => {
    setIsLoading(true);
    dispatch(updateAdditionalFeesStatus({ feeId: selectedFeeId, ...values })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        setIsLoading(false);
      },
    );
    setAllowEditStatus(false);
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      {getAdditionalFeesObj.status === "succeeded" && (
        <>
          <Formik
            validationSchema={validationSchemaStatus}
            onSubmit={onUpdateAdditionalFeesStatus}
            initialValues={{
              active: getAdditionalFeesObj.data.active,
            }}
          >
            {({ handleSubmit }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                    paddingTop: theme.dimensions.ScreenPaddingY,
                  }}
                >
                  <Grid item xs={12}>
                    <SpaceBetweenBox>
                      <Text variant="screenLabel">Edit Additional Fees</Text>
                      <Button
                        onClick={() => history.push(routes.ADDITIONAL_FEES_LIST)}
                        sx={{
                          textTransform: "none",
                          padding: "0px",
                          ":hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <Text>Back</Text>
                      </Button>
                    </SpaceBetweenBox>
                  </Grid>
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Status</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditStatus ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditStatus(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditStatus(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Status</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Status
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <StatusSingleSelect
                                  name="active"
                                  placeholder="Choose status"
                                  label="Status"
                                  disabled={!allowEditStatus}
                                  itemList={[
                                    { id: 1, label: "Active", value: true },
                                    { id: 2, label: "Inactive", value: false },
                                  ]}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Formik
            validationSchema={validationSchema}
            onSubmit={onUpdateAdditionalFees}
            initialValues={{
              description: getAdditionalFeesObj.data.description,
              // eslint-disable-next-line no-useless-escape
              priceRM: Number(getAdditionalFeesObj.data.price.replace(/[^0-9\.]+/g, "")).toFixed(2),
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid
                        container
                        columnSpacing={isMobile ? 1 : 10}
                        rowSpacing={isMobile ? 1 : 3}
                      >
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Detail</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditDetail ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditDetail(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditDetail(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Description
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Description
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormFieldTextMultiline
                                  placeholder="Enter description"
                                  name="description"
                                  rows={5}
                                  disabled={!allowEditDetail}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormPrice
                                  placeholder="00.00"
                                  name="priceRM"
                                  disabled={!allowEditDetail}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Grid>
  );
}
