import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { FieldArray, Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import { packageSelector, searchPackage } from "../../../services/package/package-slice.service";
import { createSchedule } from "../../../services/schedule/schedule-slice.service";
import FormMultipleToggleButton from "../components/form-multiple-toggle-button.component";
import FormSelect from "../components/form-select.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const validationSchema = Yup.object().shape({
  title: Yup.string().nullable().required().label("Title"),
  categoryType: Yup.string().nullable().required().label("Category"),
  planId: Yup.number().nullable().required().label("Package"),
  businessId: Yup.number().nullable().required().label("Business"),
  operationalHours: Yup.string().nullable().required().label("Operational Hours"),
  days: Yup.array().when("operationalHours", {
    is: "bulk",
    then: Yup.array().of(Yup.string()).min(1).label("Days"),
    otherwise: Yup.array().of(Yup.string()).nullable(),
  }),
  slots: Yup.array(
    Yup.object()
      .shape({
        day: Yup.string().when("operationalHours", {
          is: "custom",
          then: Yup.string()
            .required("Day is required when operational hours are custom")
            .nullable()
            .label("Day"),
          otherwise: Yup.string().nullable(),
        }),
        startTime: Yup.string()
          .nullable()
          .required()
          .label("Start time")
          .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
            if (value) {
              const selectedTimeFormatted = moment
                .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
                .utcOffset(8 * 60);
              const selectedTimeWithoutDate = moment(
                selectedTimeFormatted.format("HH:mm:ss"),
                "HH:mm:ss",
              );
              const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
              const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
              return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
            }
            return true;
          }),
        endTime: Yup.string()
          .nullable()
          .required()
          .label("End time")
          .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
            if (value) {
              const selectedTimeFormatted = moment
                .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
                .utcOffset(8 * 60);
              const selectedTimeWithoutDate = moment(
                selectedTimeFormatted.format("HH:mm:ss"),
                "HH:mm:ss",
              );
              const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
              const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
              return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
            }
            return true;
          }),
      })
      .required("Must have at least 1"),
  )
    .min(1)
    .label("Timeslots"),
});

export default function ScheduleCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [createType, setCreateType] = useState("bulk");
  const [categoryType, setCategoryType] = useState("");
  const { searchPackageObj } = useSelector(packageSelector);

  const onCreateSchedule = (values, { resetForm }) => {
    const slotsBulkFormatted = values.slots.map((slot) => ({
      startTime: dayjs(slot.startTime).format("HH:mm:00"),
      endTime: dayjs(slot.endTime).format("HH:mm:00"),
    }));
    const slotsCustomFormatted = values.slots.map((slot) => ({
      day: slot.day,
      startTime: dayjs(slot.startTime).format("HH:mm:00"),
      endTime: dayjs(slot.endTime).format("HH:mm:00"),
    }));

    const payloadParams = {
      title: values.title,
      categoryType: values.categoryType,
      planId: values.planId,
      businessId: values.businessId,
      operationalHours: values.operationalHours,
      days: values.operationalHours === "bulk" ? values.days : [],
      slots: values.operationalHours === "bulk" ? slotsBulkFormatted : slotsCustomFormatted,
    };

    setIsLoading(true);
    dispatch(createSchedule(payloadParams)).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.SCHEDULE_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    dispatch(searchPackage({ q: "", page: 1, categoryTypes: categoryType })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  }, [categoryType]);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <BackdropLoading isLoading={isLoading} />
      <Formik
        validationSchema={validationSchema}
        onSubmit={onCreateSchedule}
        initialValues={{
          title: "",
          categoryType: null,
          planId: null,
          businessId: null,
          operationalHours: createType,
          days: [],
          slots: [{ startTime: "", endTime: "" }],
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <>
            <Grid item xs={12}>
              <SpaceBetweenBox>
                <Text variant="screenLabel">Create Schedule</Text>
                <Button
                  onClick={() => history.goBack()}
                  sx={{
                    textTransform: "none",
                    padding: "0px",
                    ":hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Text>Back</Text>
                </Button>
              </SpaceBetweenBox>
            </Grid>
            <Grid item xs={12}>
              <WhiteBgCardContainer>
                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={12}>
                    <Text variant="screenLabel">Schedule Details</Text>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Title</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Title</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormFieldText name="title" placeholder="Title" />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Category</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Category</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormSelect
                            itemList={[
                              { id: 1, value: "wellness", label: "Wellness" },
                              { id: 2, value: "health", label: "Health" },
                            ]}
                            name="categoryType"
                            placeholder="Select Category"
                            setCategoryType={setCategoryType}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Package</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Package</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormSelect
                            itemList={
                              searchPackageObj.data?.items
                                ? searchPackageObj.data.items?.map((item) => ({
                                    id: item.id,
                                    value: item.id,
                                    label: item.name,
                                  }))
                                : []
                            }
                            name="planId"
                            placeholder="Select Category First"
                            disabled={!values.categoryType}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormSelect
                            itemList={
                              values.planId
                                ? searchPackageObj.data.items
                                    ?.filter((item) => item.id === values.planId)
                                    ?.flatMap((item) => item.businesses)
                                    ?.map((business) => ({
                                      id: business.id,
                                      value: business.id,
                                      label: business.name,
                                    }))
                                : []
                            }
                            name="businessId"
                            placeholder="Select Package First"
                            disabled={!values.planId}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 4}>
                    <Spacer size="l" />
                    <ToggleButtonGroup
                      value={createType}
                      exclusive
                      onChange={(e) => {
                        setCreateType(e.target.value);
                        setFieldValue("operationalHours", e.target.value);
                        if (e.target.value === "bulk") {
                          setFieldValue("days", []);
                          setFieldValue("slots", [{ startTime: "", endTime: "" }]);
                        } else if (e.target.value === "custom") {
                          setFieldValue("days", []);
                          setFieldValue("slots", [{ day: "", startTime: "", endTime: "" }]);
                        }
                      }}
                    >
                      <ToggleButton value="bulk" sx={{ whiteSpace: "nowrap" }}>
                        Bulk
                      </ToggleButton>
                      <ToggleButton value="custom" sx={{ whiteSpace: "nowrap" }}>
                        Custom
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <Spacer />
                  </Grid>

                  {createType === "bulk" && (
                    <>
                      <Grid item xs={12}>
                        <Text variant="h5" sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                          Days
                        </Text>
                        <Spacer />
                        <FormMultipleToggleButton
                          name="days"
                          size={isMobile ? "small" : "large"}
                          itemList={[
                            { id: 0, value: "sunday", label: "Sun" },
                            { id: 1, value: "monday", label: "Mon" },
                            { id: 2, value: "tuesday", label: "Tue" },
                            { id: 3, value: "wednesday", label: "Wed" },
                            { id: 4, value: "thursday", label: "Thu" },
                            { id: 5, value: "friday", label: "Fri" },
                            { id: 6, value: "saturday", label: "Sat" },
                          ]}
                        />
                      </Grid>

                      <FieldArray name="slots">
                        {({ push, remove }) => (
                          <>
                            <Grid item xs={12} marginTop="20px">
                              <SpaceBetweenBox>
                                <Text variant="h5" fontWeight="bold">
                                  Timeslots
                                </Text>
                                <Box>
                                  <CtaButton
                                    onClickButton={() => push({ startTime: "", endTime: "" })}
                                  >
                                    Add
                                  </CtaButton>
                                </Box>
                              </SpaceBetweenBox>
                            </Grid>

                            {values.slots.map((_, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Grid container item key={index}>
                                <Grid
                                  item
                                  container
                                  xs={11}
                                  columnSpacing={isMobile ? 0 : 3}
                                  bgcolor={theme.palette.colors.bg.grey}
                                  padding="15px 10px"
                                  margin="1px"
                                  borderRadius="5px"
                                >
                                  <Grid item xs={isMobile ? 12 : 6}>
                                    {isMobile && (
                                      <LabelContainer>
                                        <Text fontWeight="bold">Start Time</Text>
                                      </LabelContainer>
                                    )}
                                    <FormContainer>
                                      {!isMobile && (
                                        <LabelContainer>
                                          <Text fontWeight="bold">Start Time</Text>
                                        </LabelContainer>
                                      )}
                                      <Box
                                        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
                                      >
                                        <FormTimePicker
                                          name={`slots[${index}].startTime`}
                                          width="100%"
                                          setEndTimeAnHourLater
                                        />

                                        {errors.slots && (
                                          <>
                                            <FormHelperText error sx={{ marginLeft: "10px" }}>
                                              {errors?.slots[index]?.startTime}
                                            </FormHelperText>
                                          </>
                                        )}
                                      </Box>
                                    </FormContainer>
                                  </Grid>

                                  <Grid item xs={isMobile ? 12 : 6}>
                                    {isMobile && (
                                      <LabelContainer>
                                        <Text fontWeight="bold">End Time</Text>
                                      </LabelContainer>
                                    )}
                                    <FormContainer>
                                      {!isMobile && (
                                        <LabelContainer>
                                          <Text fontWeight="bold">End Time</Text>
                                        </LabelContainer>
                                      )}
                                      <Box
                                        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
                                      >
                                        <FormTimePicker
                                          name={`slots[${index}].endTime`}
                                          width="100%"
                                        />

                                        {errors.slots && (
                                          <>
                                            <FormHelperText error sx={{ marginLeft: "10px" }}>
                                              {errors?.slots[index]?.endTime}
                                            </FormHelperText>
                                          </>
                                        )}
                                      </Box>
                                    </FormContainer>
                                  </Grid>
                                </Grid>

                                <Grid item xs={0.5} sx={{ display: "flex", alignItems: "center" }}>
                                  <IconButton
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    <RemoveCircleIcon
                                      sx={{ color: theme.palette.colors.text.rejected }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </>
                  )}

                  {createType === "custom" && (
                    <>
                      <FieldArray name="slots">
                        {({ push, remove }) => (
                          <>
                            <Grid item xs={12} marginTop="20px">
                              <SpaceBetweenBox>
                                <Text variant="h5" fontWeight="bold">
                                  Timeslots
                                </Text>
                                <Box>
                                  <CtaButton
                                    onClickButton={() =>
                                      push({ day: "", startTime: "", endTime: "" })
                                    }
                                  >
                                    Add
                                  </CtaButton>
                                </Box>
                              </SpaceBetweenBox>
                            </Grid>

                            {values.slots.map((_, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Grid container item key={index}>
                                <Grid
                                  item
                                  container
                                  xs={11}
                                  columnSpacing={isMobile ? 0 : 3}
                                  bgcolor={theme.palette.colors.bg.grey}
                                  padding="15px 10px"
                                  margin="1px"
                                  borderRadius="5px"
                                >
                                  <Grid item xs={isMobile ? 12 : 2}>
                                    {isMobile && (
                                      <LabelContainer>
                                        <Text fontWeight="bold">Day</Text>
                                      </LabelContainer>
                                    )}
                                    <FormContainer>
                                      {!isMobile && (
                                        <LabelContainer>
                                          <Text fontWeight="bold">Day</Text>
                                        </LabelContainer>
                                      )}
                                      <Box
                                        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
                                      >
                                        <FormSelect
                                          name={`slots[${index}].day`}
                                          itemList={[
                                            { id: 0, value: "sunday", label: "Sun" },
                                            { id: 1, value: "monday", label: "Mon" },
                                            { id: 2, value: "tuesday", label: "Tue" },
                                            { id: 3, value: "wednesday", label: "Wed" },
                                            { id: 4, value: "thursday", label: "Thu" },
                                            { id: 5, value: "friday", label: "Fri" },
                                            { id: 6, value: "saturday", label: "Sat" },
                                          ]}
                                          placeholder="Day"
                                        />

                                        {errors.slots && (
                                          <>
                                            <FormHelperText error sx={{ marginLeft: "10px" }}>
                                              {errors?.slots[index]?.day}
                                            </FormHelperText>
                                          </>
                                        )}
                                      </Box>
                                    </FormContainer>
                                  </Grid>
                                  <Grid item xs={isMobile ? 12 : 5}>
                                    {isMobile && (
                                      <LabelContainer>
                                        <Text fontWeight="bold">Start Time</Text>
                                      </LabelContainer>
                                    )}
                                    <FormContainer>
                                      {!isMobile && (
                                        <LabelContainer>
                                          <Text fontWeight="bold">Start Time</Text>
                                        </LabelContainer>
                                      )}
                                      <Box
                                        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
                                      >
                                        <FormTimePicker
                                          name={`slots[${index}].startTime`}
                                          width="100%"
                                          setEndTimeAnHourLater
                                        />

                                        {errors.slots && (
                                          <>
                                            <FormHelperText error sx={{ marginLeft: "10px" }}>
                                              {errors?.slots[index]?.startTime}
                                            </FormHelperText>
                                          </>
                                        )}
                                      </Box>
                                    </FormContainer>
                                  </Grid>

                                  <Grid item xs={isMobile ? 12 : 5}>
                                    {isMobile && (
                                      <LabelContainer>
                                        <Text fontWeight="bold">End Time</Text>
                                      </LabelContainer>
                                    )}
                                    <FormContainer>
                                      {!isMobile && (
                                        <LabelContainer>
                                          <Text fontWeight="bold">End Time</Text>
                                        </LabelContainer>
                                      )}
                                      <Box
                                        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
                                      >
                                        <FormTimePicker
                                          name={`slots[${index}].endTime`}
                                          width="100%"
                                        />

                                        {errors.slots && (
                                          <>
                                            <FormHelperText error sx={{ marginLeft: "10px" }}>
                                              {errors?.slots[index]?.endTime}
                                            </FormHelperText>
                                          </>
                                        )}
                                      </Box>
                                    </FormContainer>
                                  </Grid>
                                </Grid>

                                <Grid item xs={0.5} sx={{ display: "flex", alignItems: "center" }}>
                                  <IconButton
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    <RemoveCircleIcon
                                      sx={{ color: theme.palette.colors.text.rejected }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </>
                  )}
                </Grid>
              </WhiteBgCardContainer>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
              <FormSubmitButton width="200px">
                <Text type="WhiteColor">Create</Text>
              </FormSubmitButton>
            </Grid>
          </>
        )}
      </Formik>
    </Grid>
  );
}
