import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import CtaButton from "../../../components/button/cta-button.component";
import Form from "../../../components/forms/form.component";
import AddOffDayModal from "./add-off-day-modal.component";
import { offDaySelector, updateOffDay } from "../../../services/off-day/off-day.slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxWidth: "1100px",
  width: "90%",
  maxHeight: "90%",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const TopBarContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  justifyContent: "space-between",
});

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  ":hover": { backgroundColor: "transparent" },
  color: theme.palette.colors.text.primary,
  padding: "0px",
}));

const EventContainer = styled(Box)(({ theme }) => ({
  padding: "15px",
  height: "100%",
  backgroundColor: theme.palette.colors.brand.secondary,
  borderRadius: theme.shape.borderRadius[0],
}));

const OffDayContainer = styled(Box)(({ theme }) => ({
  padding: "15px",
  height: "100%",
  backgroundColor: theme.palette.colors.ui.errorBox,
  borderRadius: theme.shape.borderRadius[0],
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  businessIds: Yup.array().label("Businesses").nullable(),
});

export default function DateEventModal({
  showModal,
  setShowModal,
  events,
  selectedDate,
  refreshCalendar,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("760"));
  const createSnackBar = useContext(SnackbarContext);
  const [showSetOffDayModal, setShowSetOffDayModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { updateOffDayObj } = useSelector(offDaySelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  const renderContent = () => {
    if (events && events.length > 0) {
      return (
        <Grid container spacing={1}>
          {events.map((item) => (
            <Grid item xs={isMobile ? 12 : 4} key={uuidv4()}>
              {item.title === "Off Day" ? (
                <OffDayContainer
                  onClick={() => setShowDeleteModal(true)}
                  sx={{ cursor: "pointer" }}
                >
                  <Text type="WhiteColor">{item.title}</Text>
                  {item.businesses.map((business, index) => (
                    <Text key={business.businessId} type="WhiteColor">
                      {`${index + 1}. ${business.businessName}`}
                    </Text>
                  ))}
                </OffDayContainer>
              ) : (
                <EventContainer>
                  <Text>{item.title}</Text>
                </EventContainer>
              )}
            </Grid>
          ))}
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "130px",
            alignItems: "center",
          }}
        >
          <Text>No Events</Text>
        </Box>
      </Grid>
    );
  };

  const onDeleteOffDay = () => {
    dispatch(updateOffDay({ date: selectedDate, businessIds: [] })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setShowDeleteModal(false);
          handleCancel();
          refreshCalendar();
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const onUpdateOffDay = (values) => {
    const businessIds = values.businessIds.map((item) => item.id);
    dispatch(updateOffDay({ date: selectedDate, businessIds })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowSetOffDayModal(false);
        handleCancel();
        refreshCalendar();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <>
      <Form
        validationSchema={validationSchema}
        onSubmit={onUpdateOffDay}
        initialValues={{
          businessIds: events
            .filter((item) => item?.businesses)[0]
            ?.businesses.map((business) => ({
              id: business.businessId,
              option: business.businessName,
            })),
        }}
      >
        <AddOffDayModal
          title="Add Off Day"
          showModal={showSetOffDayModal}
          setShowModal={setShowSetOffDayModal}
        />
      </Form>
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Off Day"
        label="Are you sure you wish to remove the off day?"
        isLoading={updateOffDayObj.status === "pending"}
        onConfirmClicked={onDeleteOffDay}
      />

      <Modal open={showModal} onClose={handleCancel}>
        <ModalBox sx={{ padding: isMobile ? "20px" : "40px" }}>
          <TopBarContainer>
            <Text variant="screenLabel">Events</Text>
            <SpaceBetweenRowBox>
              {new Date(selectedDate) > new Date() && (
                <>
                  <CtaButton onClickButton={() => setShowSetOffDayModal(true)}>
                    Set Off Day
                  </CtaButton>
                  <Spacer position="left" size="l" />
                </>
              )}
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon
                  sx={{
                    stroke: theme.palette.colors.text.primary,
                    strokeWidth: 2,
                  }}
                />
              </CloseIconButton>
            </SpaceBetweenRowBox>
          </TopBarContainer>
          <Spacer position="top" size="l" />
          <Text variant="h6" fontWeight={600}>
            {format(new Date(selectedDate), "dd MMM yyyy, eee")}
          </Text>
          <Spacer position="top" size="l" />
          <Box
            sx={{
              height: isMobile ? "60%" : "400px",
              overflowY: "auto",
              width: "100%",
            }}
          >
            {renderContent()}
          </Box>
        </ModalBox>
      </Modal>
    </>
  );
}

DateEventModal.propTypes = {
  refreshCalendar: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      class: PropTypes.shape({
        name: PropTypes.string,
      }),
      session: PropTypes.shape({
        id: PropTypes.number,
        currentCapacity: PropTypes.number,
        maxCapacity: PropTypes.number,
        startAt: PropTypes.string,
        endAt: PropTypes.string,
      }),
      bookings: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          user: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
    }),
  ).isRequired,
};
