import { TableCell, TableRow } from "@mui/material";
import React from "react";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";
import Text from "../../../components/text.component";

export default function PromoCodeTableRowLoader() {
  return (
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>
      <TableCell sx={{ width: "150px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>
      <TableCell sx={{ width: "350px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          <SkeletonLoader width="100%" />
        </Text>
      </TableCell>
    </TableRow>
  );
}
