import React from "react";
import PropTypes from "prop-types";
import { Box, FormHelperText, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function FormPickerMonthyCalendar({ name, disabled }) {
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (eventName, date) => {
    if (date === null) {
      setFieldValue(eventName, "");
      return;
    }
    setFieldValue(eventName, date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <DatePicker
          disabled={disabled}
          maxDate={new Date()}
          openTo="month"
          views={["year", "month"]}
          value={values[name] || ""}
          onChange={(date) => {
            handleChange(name, date);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={showError}
              onBlur={() => setFieldTouched(name)}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderTopLeftRadius: theme.shape.borderRadius[1],
                  borderTopRightRadius: theme.shape.borderRadius[1],
                  borderBottomLeftRadius: theme.shape.borderRadius[1],
                  borderBottomRightRadius: theme.shape.borderRadius[1],
                  backgroundColor: theme.palette.colors.bg.primary,
                },
              }}
            />
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}

FormPickerMonthyCalendar.defaultProps = {
  disabled: false,
};

FormPickerMonthyCalendar.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default FormPickerMonthyCalendar;
