import React from "react";
import { Box, Button, Grid, styled } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import { useHistory } from "react-router-dom";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

const ButtonContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "10px",
  color: theme.palette.colors.brand.primary,
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  textTransform: "none",
  width: "100%",
  overflow: "hidden",
  padding: "0px",
  ":hover": {
    backgroundColor: "none",
  },
}));

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.secondary,
  height: "89vh",
}));

const BorderLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.lightBorder,
}));

export default function MobileAccountMenu() {
  const history = useHistory();

  return (
    <Container>
      <Grid container sx={{ paddingY: "15px", paddingX: "30px" }} rowSpacing={1}>
        <Grid item xs={12}>
          <StyledButton onClick={() => history.push(routes.PROFILE_DETAIL)}>
            <ButtonContainer>
              <PersonIcon />
              <Spacer size="m" position="left" />
              <Text type="BrandColor">Profile</Text>
            </ButtonContainer>
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <BorderLine />
        </Grid>
        <Grid item xs={12}>
          <StyledButton onClick={() => history.push(routes.BANK_DETAIL)}>
            <ButtonContainer>
              <AccountBalanceIcon />
              <Spacer size="m" position="left" />
              <Text type="BrandColor">Bank Details</Text>
            </ButtonContainer>
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <BorderLine />
        </Grid>
        <Grid item xs={12}>
          <StyledButton onClick={() => history.push(routes.STAFF_LIST)}>
            <ButtonContainer>
              <GroupAddRoundedIcon />
              <Spacer size="m" position="left" />
              <Text type="BrandColor">Staff List</Text>
            </ButtonContainer>
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <BorderLine />
        </Grid>
      </Grid>
    </Container>
  );
}
