import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import Text from "../../../components/text.component";

export default function AttendeeTableRow({ id, user }) {
  return (
    <>
      <TableRow
        key={id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.name}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.phone}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

AttendeeTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};
