import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function AdditionalFeesTableRow({ additionalFee, onHandleDelete }) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);
  const history = useHistory();

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const onToggleDelete = () => {
    onHandleDelete(additionalFee.id);
    handleCloseMenu();
  };

  const onHandleEdit = () => {
    history.push({
      pathname: routes.ADDITIONAL_FEES_EDIT,
      search: `${additionalFee.id}`,
      state: additionalFee,
    });
    handleCloseMenu();
  };

  const getStatusColor = (status) => {
    if (!status) {
      return "RedColor";
    }
    return "BrandColor";
  };

  return (
    <>
      <TableRow
        key={additionalFee.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "100px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {additionalFee.id}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "250px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {additionalFee.description}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "150px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM${additionalFee.price}`}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {additionalFee.createdAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Text
            variant="body2"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
            type={getStatusColor(additionalFee.active)}
          >
            {additionalFee.active ? "Active" : "Inactive"}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon sx={{ color: theme.palette.colors.text.table }} />
            </IconButton>
            <StyledMenu
              sx={{ mt: "20px" }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem key={1} onClick={onHandleEdit}>
                <Text textAlign="center">Edit</Text>
              </MenuItem>

              <MenuItem key={2} onClick={onToggleDelete}>
                <Text textAlign="center">Remove</Text>
              </MenuItem>
            </StyledMenu>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

AdditionalFeesTableRow.propTypes = {
  onHandleDelete: PropTypes.func.isRequired,
  additionalFee: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    price: PropTypes.string,
    active: PropTypes.bool,
    createdAt: PropTypes.string,
  }).isRequired,
};
