import { Box, IconButton, TableCell, TableRow, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";

export default function PackagePurchasesTableRow({ packagePlan }) {
  const theme = useTheme();
  const [showDetail, setShowDetail] = useState(false);

  const handleOpenDetail = () => {
    setShowDetail(!showDetail);
  };

  return (
    <>
      <TableRow
        key={packagePlan.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {packagePlan.plan.title}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {packagePlan.user.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {packagePlan.user.phone}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {packagePlan.code}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM${packagePlan.price}`}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {packagePlan.sessions.claimedCount}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {packagePlan.sessions.noOfSessions}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {packagePlan.createdAt}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
            type={packagePlan.status === "completed" ? "BrandColor" : "YellowColor"}
          >
            {packagePlan.status}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <IconButton onClick={handleOpenDetail}>
            {showDetail ? (
              <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.text.table }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.text.table }} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>

      {showDetail && (
        <>
          <TableRow>
            <TableCell colSpan={10}>
              <Text marginLeft="2%">Businesses</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={10}>
              {packagePlan.plan.businesses.map((business) => (
                <div key={business.name}>
                  <Text type="TableText" marginLeft="2%">
                    {business.name}
                  </Text>
                  <Spacer />
                </div>
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={10}>
              <Text marginLeft="2%">Items</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            {packagePlan.items.length === 0 ? (
              <TableCell colSpan={10}>
                <Box>
                  <Text type="TableText" marginLeft="2%">
                    No items
                  </Text>
                  <Spacer />
                </Box>
              </TableCell>
            ) : (
              <TableCell colSpan={9}>
                {packagePlan.items.map((item) => (
                  <Box key={item.id}>
                    <Text type="TableText" marginLeft="2%">
                      {item.name}
                    </Text>
                    <Spacer />
                  </Box>
                ))}
              </TableCell>
            )}
          </TableRow>
        </>
      )}
    </>
  );
}

PackagePurchasesTableRow.propTypes = {
  packagePlan: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    code: PropTypes.string,
    createdAt: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        start_at: PropTypes.string,
        end_at: PropTypes.string,
      }),
    ).isRequired,
    user: PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.string,
    }),
    plan: PropTypes.shape({
      title: PropTypes.string,
      businesses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
    }),
    sessions: PropTypes.shape({
      claimedCount: PropTypes.number,
      noOfSessions: PropTypes.number,
    }),
    price: PropTypes.string,
  }).isRequired,
};
