import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";

export default function FormSelect({
  itemList,
  name,
  placeholder,
  width,
  isTransparent,
  setFormVariable,
  disabled,
}) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext("");
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
    if (setFormVariable) {
      setFormVariable(event.target.value);
    }
  };

  return (
    <FormControl sx={{ width }}>
      <Select
        disabled={disabled}
        onBlur={() => setFieldTouched(name)}
        value={values[name]}
        onChange={handleChange}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
          padding: "0px",
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        }}
        error={showError}
        displayEmpty
      >
        <MenuItem disabled value="">
          <Text type="GreyColor">{placeholder}</Text>
        </MenuItem>
        {itemList.map((item) => (
          <MenuItem key={item.id} value={item.value}>
            <Text sx={{ textTransform: "capitalize" }}>{item.label}</Text>
          </MenuItem>
        ))}
      </Select>
      {showError && (
        <FormHelperText error sx={{ marginLeft: "0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormSelect.defaultProps = {
  width: "100%",
  isTransparent: false,
  setFormVariable: null,
  disabled: false,
};

FormSelect.propTypes = {
  setFormVariable: PropTypes.func,
  width: PropTypes.string,
  itemList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
  disabled: PropTypes.bool,
};
