import React from "react";
import PropTypes from "prop-types";
import { Box, FormHelperText, styled } from "@mui/material";
import OtpInput from "react-otp-input";
import { useFormikContext } from "formik";

const Container = styled(Box)(({ theme }) => ({
  "&& :focus-visible": { outline: "none", borderBottomColor: theme.palette.colors.brand.primary },
}));

export default function FormOTP({ name, width, setIsConfirmPressed, isConfirmPressed }) {
  const { values, setFieldValue, errors } = useFormikContext();

  return (
    <Container>
      <OtpInput
        value={values[name]}
        onChange={(i) => {
          if (setIsConfirmPressed() !== null) {
            setIsConfirmPressed(false);
          }

          setFieldValue(name, i);
        }}
        numInputs={6}
        isInputNum
        separator={<div style={{ width: "10px" }} />}
        inputStyle={{
          width,
          height: width,
          borderWidth: "0px",
          borderBottomWidth: "1px",
        }}
      />
      {isConfirmPressed && errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </Container>
  );
}

FormOTP.defaultProps = {
  width: "100%",
  setIsConfirmPressed: null,
  isConfirmPressed: false,
};

FormOTP.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  setIsConfirmPressed: PropTypes.func,
  isConfirmPressed: PropTypes.bool,
};
