import React from "react";
import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import { referralSelector } from "../../../services/exclusive-referral/exclusive-referral-slice.service";
import UserListPhoneNumberAutoComplete from "./user-autocomplete-single-select.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxHeight: "90%",
  overflowY: "scroll",
}));

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function ExclusiveReferralFormModal({ showModal, setShowModal, title }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm } = useFormikContext();
  const { createReferralObj } = useSelector(referralSelector);

  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "600px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text variant="screenLabel">{title}</Text>
          </Grid>

          <Grid item xs={12}>
            <LabelContainer>
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>User</Text>
            </LabelContainer>
            <UserListPhoneNumberAutoComplete
              name="userId"
              placeholder="User phone number (e.g. 60123456789)"
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <CtaBorderButton
                onClickButton={handleCancel}
                width="100px"
                isLoading={createReferralObj.status === "pending"}
              >
                <Text type="BrandColor">Cancel</Text>
              </CtaBorderButton>
              <Spacer size="m" position="left" />
              <FormSubmitButton width="100px" isLoading={createReferralObj.status === "pending"}>
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

ExclusiveReferralFormModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
