import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getClassCategoryList = createAsyncThunk("merchants/class/category/list", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/class_categories`,
  );
  return response;
});

export const createClass = createAsyncThunk("merchants/fitness_classes/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes`,
    payload,
  );
  return response;
});

export const updateClass = createAsyncThunk("merchants/fitness_classes/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/${payload.classId}`,
    payload,
  );
  return response;
});

export const searchClass = createAsyncThunk(
  "merchants/fitness_classes/full_list",
  async (payload) => {
    const { q, categoryIds, businessIds, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/?page=${page}${
        q ? "&q=".concat(q) : ""
      }${categoryIds ? "&categoryIds=".concat(categoryIds) : ""}${
        businessIds ? "&businessIds=".concat(businessIds) : ""
      }`,
    );
    return response;
  },
);

export const getClassDetail = createAsyncThunk(
  "merchants/fitness_classes/detail",
  async (payload) => {
    const { classId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/${classId}`,
    );
    return response;
  },
);

export const deleteClass = createAsyncThunk(
  "merchants/fitness_classes/delete/{class_id}",
  async (payload) => {
    const { classId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/${classId}`,
    );
    return response;
  },
);

const fitnessClassSlice = createSlice({
  name: "fitnessClass",
  initialState: {
    getClassCategoryListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createClassObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchClassObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateClassObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },

    getClassDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteClassObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getClassCategoryList.pending]: (state) => {
      state.getClassCategoryListObj.status = "pending";
    },
    [getClassCategoryList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getClassCategoryListObj.status = "succeeded";
      state.getClassCategoryListObj.data = data;
      state.getClassCategoryListObj.successMessage = message;
    },
    [getClassCategoryList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getClassCategoryListObj.status = "failed";
      state.getClassCategoryListObj.errorMessage = message;
    },
    [createClass.pending]: (state) => {
      state.createClassObj.status = "pending";
    },
    [createClass.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createClassObj.status = "succeeded";
      state.createClassObj.data = data;
      state.createClassObj.successMessage = message;

      state.searchClassObj.data = data;
    },
    [createClass.rejected]: (state, action) => {
      const { message } = action.error;

      state.createClassObj.status = "failed";
      state.createClassObj.errorMessage = message;
    },
    [searchClass.pending]: (state) => {
      state.searchClassObj.status = "pending";
    },
    [searchClass.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchClassObj.status = "succeeded";
      state.searchClassObj.data = data;
      state.searchClassObj.successMessage = message;
    },
    [searchClass.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchClassObj.status = "failed";
      state.searchClassObj.errorMessage = message;
    },
    [updateClass.pending]: (state) => {
      state.updateClassObj.status = "pending";
    },
    [updateClass.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateClassObj.status = "succeeded";
      state.updateClassObj.data = data;
      state.updateClassObj.successMessage = message;
    },
    [updateClass.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateClassObj.status = "failed";
      state.updateClassObj.errorMessage = message;
    },

    [getClassDetail.pending]: (state) => {
      state.getClassDetailObj.status = "pending";
    },
    [getClassDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getClassDetailObj.status = "succeeded";
      state.getClassDetailObj.data = data;
      state.getClassDetailObj.successMessage = message;
    },
    [getClassDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getClassDetailObj.status = "failed";
      state.getClassDetailObj.errorMessage = message;
    },
    [deleteClass.pending]: (state) => {
      state.deleteClassObj.status = "pending";
    },
    [deleteClass.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteClassObj.status = "succeeded";
      state.deleteClassObj.data = data;
      state.deleteClassObj.successMessage = message;
    },
    [deleteClass.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteClassObj.status = "failed";
      state.deleteClassObj.errorMessage = message;
    },
  },
});

export default fitnessClassSlice.reducer;

// state
export const fitnessClassSelector = (state) => state.fitnessClass;
