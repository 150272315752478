import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, Grid, IconButton, Tooltip, styled, useTheme } from "@mui/material";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import {
  deleteEventQuestion,
  updateEventQuestion,
} from "../../../services/event/question/question.slice.services";
import TextFieldMultiple from "./text-field-multiple.component";

const DetailTextField = styled(Field)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius[0],
  padding: "10px",
  font: "inherit",
  border: "2px solid #D0D0D0",
  backgroundColor: `${theme.palette.colors.bg.primary}`,
}));

const EventQuestion = ({ item, eventId, allowEditDetail }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const [eventQuestion, setEventQuestion] = useState(item.question);
  const [deleted, setDeleted] = useState(false);
  const { setFieldValue, values, dirty, resetForm, submitForm } = useFormikContext();

  const handleChange = (e) => {
    setEventQuestion(e.target.value);
    setFieldValue("eventQuestion", e.target.value);
  };

  const onUpdateEventQuestion = () => {
    submitForm();
    dispatch(
      updateEventQuestion({
        questionId: values.questionId,
        eventId,
        question: values.eventQuestion,
        openEnded: values.eventQuestionResponses.length === 0,
        responses: values.eventQuestionResponses.map((responses) => ({ option: responses })),
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        resetForm({ values });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const handleDelete = () => {
    setDeleted(true);
    dispatch(deleteEventQuestion({ eventId, questionId: values.questionId })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  return (
    <>
      {!deleted && (
        <Grid container item marginBottom="10px">
          <Grid item xs={allowEditDetail ? 10 : 12}>
            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <DetailTextField
                placeholder="Enter question"
                type="text"
                value={eventQuestion}
                name="eventQuestion"
                onChange={handleChange}
                disabled={!allowEditDetail}
                style={{ color: !allowEditDetail && theme.palette.colors.text.disabled }}
              />
              <Spacer position="top" />
              <TextFieldMultiple
                name="eventQuestionResponses"
                placeholder="Enter responses (Leave empty for open ended question)"
                disabled={!allowEditDetail}
              />
            </Box>
          </Grid>
          {allowEditDetail && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Grid item xs={1}>
                <IconButton onClick={onUpdateEventQuestion} disabled={!dirty}>
                  <Tooltip title="Update" arrow>
                    <CheckCircleIcon
                      style={{ color: dirty && theme.palette.colors.brand.primary }}
                    />
                  </Tooltip>
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={handleDelete}>
                  <Tooltip title="Delete" arrow>
                    <RemoveCircleIcon style={{ color: theme.palette.colors.ui.errorBox }} />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Box>
          )}
        </Grid>
      )}
    </>
  );
};

EventQuestion.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    question: PropTypes.string,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        option: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  eventId: PropTypes.string.isRequired,
  allowEditDetail: PropTypes.bool.isRequired,
};

export default EventQuestion;
