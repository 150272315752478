import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, TextField, Paper, LinearProgress, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import PaddedView from "../padded-view.component";
import Spacer from "../spacer.component";

const searchOptions = {
  componentRestrictions: { country: "MY" },
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    padding: "0px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
}));

function FormAddressAutoComplete({
  name,
  latName,
  longName,
  stateName,
  cityName,
  countryName,
  postCodeName,
  placeholder,
  isTransparent,
}) {
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
  const [address, setAddress] = useState(values[name]);
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleSelect = async (value, placeID) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    const [place] = await geocodeByPlaceId(placeID);

    const { long_name: postcode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) || {};
    const { long_name: city = "" } =
      place.address_components.find(
        (c) => c.types.includes("sublocality_level_1") || c.types.includes("locality"),
      ) || {};
    const { long_name: state = "" } =
      place.address_components.find((c) => c.types.includes("administrative_area_level_1")) || {};
    const { long_name: country = "" } =
      place.address_components.find((c) => c.types.includes("country")) || {};

    setAddress(value);
    setFieldValue(name, value);
    setFieldValue(latName, latLng.lat.toString());
    setFieldValue(longName, latLng.lng.toString());
    setFieldValue(stateName, state);
    setFieldValue(cityName, city);
    setFieldValue(countryName, country);
    setFieldValue(postCodeName, postcode);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={(text) => {
        setAddress(text);
        setFieldValue(name, "");
        setFieldValue(latName, "");
        setFieldValue(longName, "");
        setFieldValue(stateName, "");
        setFieldValue(cityName, "");
        setFieldValue(countryName, "");
        setFieldValue(postCodeName, "");
      }}
      searchOptions={searchOptions}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box>
          <StyledTextField
            multiline
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
              },
            }}
            {...getInputProps()}
            placeholder={placeholder}
            value={address}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            helperText={showError ? errors[name] : null}
          />

          {loading && (
            <>
              <Spacer />
              <LinearProgress />
            </>
          )}

          {suggestions.map((suggestion) => {
            const style = {
              backgroundColor: suggestion.active
                ? theme.palette.colors.brand.secondary
                : theme.palette.colors.bg.primary,
              cursor: "pointer",
            };
            return (
              <Paper
                {...getSuggestionItemProps(suggestion, { style })}
                key={`${suggestion.description}`}
              >
                <PaddedView>
                  <Typography>{suggestion.description}</Typography>
                </PaddedView>
              </Paper>
            );
          })}
        </Box>
      )}
    </PlacesAutocomplete>
  );
}
FormAddressAutoComplete.defaultProps = {
  width: "100%",
  placeholder: "Address",
  isTransparent: false,
};

FormAddressAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  latName: PropTypes.string.isRequired,
  longName: PropTypes.string.isRequired,
  stateName: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
  postCodeName: PropTypes.string.isRequired,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  isTransparent: PropTypes.bool,
};
export default FormAddressAutoComplete;
