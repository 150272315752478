import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  additionalFeesSelector,
  createAdditionalFees,
} from "../../../services/additional-fees/additional-fees-slice.service";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function AdditionalFeesCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { createAdditionalFeesObj } = useSelector(additionalFeesSelector);

  const validationSchema = Yup.object().shape({
    description: Yup.string().required().label("Description"),
    priceRM: Yup.number().required().label("Price"),
  });

  const onCreateAdditionalFees = (values) => {
    const amountCents = values.priceRM * 100;
    setIsLoading(true);
    dispatch(createAdditionalFees({ amountCents, ...values })).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.ADDITIONAL_FEES_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      <Formik
        validationSchema={validationSchema}
        onSubmit={onCreateAdditionalFees}
        initialValues={{
          description: "",
          priceRM: "",
        }}
      >
        <Form>
          <Grid
            container
            spacing={5}
            sx={{
              padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
              paddingY: theme.dimensions.ScreenPaddingY,
            }}
          >
            <Grid item xs={12}>
              <SpaceBetweenBox>
                <Text variant="screenLabel">Create Additional Fees</Text>
                <Button
                  onClick={() => history.push(routes.ADDITIONAL_FEES_LIST)}
                  sx={{
                    textTransform: "none",
                    padding: "0px",
                    ":hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Text>Back</Text>
                </Button>
              </SpaceBetweenBox>
            </Grid>

            <Grid item xs={12}>
              <WhiteBgCardContainer>
                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={12}>
                    <Text variant="screenLabel">Additional Fees</Text>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Description</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Description</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormFieldTextMultiline
                            placeholder="Enter description"
                            name="description"
                            rows={5}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormPrice placeholder="00.00" name="priceRM" />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>
                </Grid>
              </WhiteBgCardContainer>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
              <FormSubmitButton
                width="200px"
                isLoading={createAdditionalFeesObj.status === "pending"}
              >
                <Text type="WhiteColor">Create</Text>
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Grid>
  );
}
