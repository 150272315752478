import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import SearchBar from "../../../components/table/search-bar.component";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import TableSort from "../../../components/table/table-sort.component";
import {
  createReferral,
  deleteReferral,
  getReferrals,
  referralSelector,
} from "../../../services/exclusive-referral/exclusive-referral-slice.service";
import ExclusiveReferralTableRow from "../components/exclusive-referral-table-row.component";
import ExclusiveReferralTableRowLoader from "../loader/exclusive-referral-table-row-loader.component";
import ExclusiveReferralFormModal from "../components/exclusive-referral-form-modal.component";

const formValidationSchema = Yup.object().shape({
  userId: Yup.object().required().label("User Phone Number").nullable(),
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function ExclusiveReferralListScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const { getReferralsObj, deleteReferralObj } = useSelector(referralSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedReferralId, setSelectedReferralId] = useState("");
  const columnMapping = {
    "Code": "code",
    "User Name": "user.name",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshReferralList = (newPage, keyword) => {
    setPage(newPage);
    setQ(keyword);
    dispatch(getReferrals({ page: newPage, q: keyword })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onCreateReferral = (values, { resetForm }) => {
    const formData = {
      userId: values.userId.id,
    };

    setIsLoading(true);
    dispatch(createReferral(formData)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        setShowAddModal(false);
        onRefreshReferralList(1, "");
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeSearch = (keyword) => {
    onRefreshReferralList(1, keyword);
  };

  const onPageChange = (e, newPage) => {
    onRefreshReferralList(newPage, q);
  };

  const onHandleDelete = (referralId) => {
    setSelectedReferralId(referralId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteReferral({ referralId: selectedReferralId })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowDeleteModal(false);
          onRefreshReferralList(page, q);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  useEffect(() => {
    onRefreshReferralList(page, q);
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getReferralsObj.data && getReferralsObj.status === "succeeded") {
      records = { ...getReferralsObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (getReferralsObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getReferralsObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={6}>
              <TableEmptyBox>
                <Text type="TableText">No items</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <ExclusiveReferralTableRow referral={item} key={item.id} onHandleDelete={onHandleDelete} />
      ));
    }
    return <ExclusiveReferralTableRowLoader />;
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Referral"
        label="Are you sure you wish to remove this referral?"
        isLoading={deleteReferralObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Form
        validationSchema={formValidationSchema}
        onSubmit={onCreateReferral}
        initialValues={{
          userId: null,
        }}
      >
        <ExclusiveReferralFormModal
          title="Add Exclusive Referral"
          showModal={showAddModal}
          setShowModal={setShowAddModal}
        />
      </Form>

      <BackdropLoading isLoading={isLoading} />
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Text variant="screenLabel">Exclusive Referral List</Text>
          <IconButton onClick={() => setShowAddModal(true)}>
            <AddCircleIcon sx={{ color: theme.palette.colors.brand.primary }} />
          </IconButton>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <SearchBar
          searchKeyword={q}
          setSearchKeyword={onChangeSearch}
          placeholder="Search here"
          isTransparent
          width="300px"
        />
      </Grid>

      <Grid item xs={12}>
        <TableWrapper>
          <Table>
            <TableHeader
              headerCells={["Code", "User Name", "Action"]}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
              columnMapping={columnMapping}
            />
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      <Grid item xs={12}>
        <CustomFooter>
          <Pagination
            sx={{
              "&& .Mui-selected": {
                backgroundColor: theme.palette.colors.brand.primary,
                color: theme.palette.colors.text.white,
              },
            }}
            page={page}
            shape="rounded"
            onChange={onPageChange}
            count={getReferralsObj?.data?.pagination.totalPages || totalPages}
            variant="outlined"
          />
        </CustomFooter>
      </Grid>
    </Grid>
  );
}
