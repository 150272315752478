import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function UserPackagesDetailTableRow({ userPackage }) {
  return (
    <>
      <TableRow
        key={userPackage.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {userPackage.plan.title}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {userPackage.createdAt ? userPackage.createdAt : "-"}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {userPackage.code}
          </Text>
        </TableCell>
        <TableCell>
          <Text
            variant="body2"
            type="TableText"
            noWrap={true}
            sx={{ textTransform: "capitalize", textAlign: "center" }}
          >
            {userPackage.status.replaceAll("_", " ")}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

UserPackagesDetailTableRow.propTypes = {
  userPackage: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    code: PropTypes.string,
    createdAt: PropTypes.string,
    plan: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
};
