import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api-services";

export const getScheduleOffDates = createAsyncThunk(
  "merchants/schedules/{schedule_id}/off_dates/index",
  async (payload) => {
    const { page, scheduleId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/schedules/${scheduleId}/off_dates?${
        page ? "page=".concat(page) : "page=1"
      }`,
    );
    return response;
  },
);

export const createScheduleOffDates = createAsyncThunk(
  "merchants/schedules/{schedule_id}/off_dates/create",
  async (payload) => {
    const { scheduleId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/schedules/${scheduleId}/off_dates`,
      payload,
    );
    return response;
  },
);

export const updateScheduleOffDates = createAsyncThunk(
  "merchants/schedules/{schedule_id}/off_dates/{off_date_id}/update",
  async (payload) => {
    const { scheduleId, offDateId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/schedules/${scheduleId}/off_dates/${offDateId}`,
      payload,
    );
    return response;
  },
);

export const deleteScheduleOffDates = createAsyncThunk(
  "merchants/schedules/{schedule_id}/off_dates/{off_date_id}/delete",
  async (payload) => {
    const { scheduleId, offDateId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/schedules/${scheduleId}/off_dates/${offDateId}`,
      payload,
    );
    return response;
  },
);

const scheduleOffDateSlice = createSlice({
  name: "scheduleOffDate",
  initialState: {
    getScheduleOffDatesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createScheduleOffDatesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateScheduleOffDatesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteScheduleOffDatesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getScheduleOffDates.pending]: (state) => {
      state.getScheduleOffDatesObj.status = "pending";
    },
    [getScheduleOffDates.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getScheduleOffDatesObj.status = "succeeded";
      state.getScheduleOffDatesObj.data = data;
      state.getScheduleOffDatesObj.successMessage = message;
    },
    [getScheduleOffDates.rejected]: (state, action) => {
      const { message } = action.error;

      state.getScheduleOffDatesObj.status = "failed";
      state.getScheduleOffDatesObj.errorMessage = message;
    },

    [createScheduleOffDates.pending]: (state) => {
      state.createScheduleOffDatesObj.status = "pending";
    },
    [createScheduleOffDates.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createScheduleOffDatesObj.status = "succeeded";
      state.createScheduleOffDatesObj.data = data;
      state.createScheduleOffDatesObj.successMessage = message;
    },
    [createScheduleOffDates.rejected]: (state, action) => {
      const { message } = action.error;

      state.createScheduleOffDatesObj.status = "failed";
      state.createScheduleOffDatesObj.errorMessage = message;
    },
    [updateScheduleOffDates.pending]: (state) => {
      state.updateScheduleOffDatesObj.status = "pending";
    },
    [updateScheduleOffDates.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateScheduleOffDatesObj.status = "succeeded";
      state.updateScheduleOffDatesObj.data = data;
      state.updateScheduleOffDatesObj.successMessage = message;
    },
    [updateScheduleOffDates.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateScheduleOffDatesObj.status = "failed";
      state.updateScheduleOffDatesObj.errorMessage = message;
    },
    [deleteScheduleOffDates.pending]: (state) => {
      state.deleteScheduleOffDatesObj.status = "pending";
    },
    [deleteScheduleOffDates.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteScheduleOffDatesObj.status = "succeeded";
      state.deleteScheduleOffDatesObj.data = data;
      // state.getScheduleOffDatesObj.data = data;
      state.deleteScheduleOffDatesObj.successMessage = message;
    },
    [deleteScheduleOffDates.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteScheduleOffDatesObj.status = "failed";
      state.deleteScheduleOffDatesObj.errorMessage = message;
    },
  },
});

export default scheduleOffDateSlice.reducer;

// state
export const scheduleOffDateSelector = (state) => state.scheduleOffDate;
