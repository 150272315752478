import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, InputAdornment, FormHelperText } from "@mui/material";

function FormFieldTextWithCustomisedAdornments({
  isDisableEnter,
  startdAdornment,
  endAdornment,
  width,
  isTransparent,
  placeholder,
  disabled,
  name,
  type,
}) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const showError = touched[name] && typeof errors[name] === "string";

  const keyPress = (e) => {
    if (e.keyCode === 13 && !isDisableEnter) {
      handleSubmit();
    }
  };

  return (
    <>
      <TextField
        disabled={disabled}
        onKeyDown={keyPress}
        type={type}
        onBlur={() => setFieldTouched(name)}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        }}
        placeholder={placeholder}
        InputProps={{
          inputProps: { min: 0 },
          startAdornment: <InputAdornment position="start">{startdAdornment}</InputAdornment>,
          endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
        }}
        value={values[name] === null ? "" : values[name]}
      />
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
    </>
  );
}

FormFieldTextWithCustomisedAdornments.defaultProps = {
  isDisableEnter: false,
  startdAdornment: <></>,
  endAdornment: <></>,
  width: "100%",
  isTransparent: false,
  disabled: false,
  type: null,
};

FormFieldTextWithCustomisedAdornments.propTypes = {
  isDisableEnter: PropTypes.bool,
  startdAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default FormFieldTextWithCustomisedAdornments;
