import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Text from "../../../components/text.component";
import TableHeader from "../../../components/table/table-header.component";

export default function BookingTableRow({ booking, onHandleCancel }) {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <TableRow
        key={booking.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.user.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.isGuest ? "Yes" : "No"}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.user.phone}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {booking.status.replaceAll("_", " ")}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.startAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.endAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.event.title}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM ${booking.price}`}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.event.business.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onHandleCancel(booking.id)}
              disabled={
                (booking.status !== "active" && booking.status !== "merchant_request") ||
                booking.session.isGuest
              }
            >
              <Tooltip title="Cancel" arrow>
                <DoDisturbAltIcon />
              </Tooltip>
            </IconButton>

            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Table size="small">
              <TableHeader
                headerCells={["Category Label", "Start At", "End At"]}
                nonSortableColumns={["Category Label", "Start At", "End At"]}
              />
              <TableBody>
                {booking.session.sessionCategories.length > 0 ? (
                  booking.session.sessionCategories.map((sessionCategory) => (
                    <TableRow key={sessionCategory.id}>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {sessionCategory.label}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {sessionCategory.startAt}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {sessionCategory.endAt}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

BookingTableRow.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    price: PropTypes.string,
    payment: PropTypes.shape({
      method: PropTypes.string,
    }),
    session: PropTypes.shape({
      id: PropTypes.number,
      startAt: PropTypes.string,
      endAt: PropTypes.string,
      sessionCategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          startAt: PropTypes.string,
          endAt: PropTypes.string,
        }),
      ),
      event: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        category: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
        ),
        business: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      }),
      user: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
      isGuest: PropTypes.bool,
    }),
  }).isRequired,
  onHandleCancel: PropTypes.func.isRequired,
};
