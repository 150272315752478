import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableSort from "../../../components/table/table-sort.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  eventResponseSelector,
  getEventResponses,
} from "../../../services/event/response/response.slice.services";
import EventListAutoComplete from "../components/event-list-autocomplete.component";
import ResponsesTableRow from "../components/responses-table-row.component";
import SearchBar from "../components/search-bar.component";
import ResponsesTableRowLoader from "../loader/responses-table-row-loader.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  eventIds: Yup.array().label("Events").nullable(),
  userIds: Yup.array().label("Users").nullable(),
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function EventResponsesScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const formRef = useRef();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [events, setEvents] = useState(null);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getEventResponsesObj } = useSelector(eventResponseSelector);

  const columnMapping = {
    ID: "id",
    Event: "title",
    "Total Responses": "totalResponses",
  };

  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshResponsesList = (keyword, eventIds, userIds, newPage) => {
    setQ(keyword);
    setEvents(eventIds);
    setUsers(userIds);
    setPage(newPage);

    dispatch(
      getEventResponses({
        q: keyword,
        eventIds,
        userIds,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const submitFilter = (values) => {
    const eventIds = values.eventIds.length !== 0 ? values.eventIds.map((item) => item.id) : null;
    const userIds = values.userIds.length !== 0 ? values.userIds.map((item) => item.id) : null;
    onRefreshResponsesList(q, eventIds, userIds, 1);
  };

  const clearFilter = () => {
    formRef.current.setFieldValue("eventIds", []);
    formRef.current.setFieldValue("userIds", []);
  };

  const onPageChange = (e, newPage) => {
    onRefreshResponsesList(q, events, users, newPage);
  };

  const onChangeSearch = (keyword) => {
    onRefreshResponsesList(keyword, events, users, 1);
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getEventResponsesObj.data && getEventResponsesObj.status === "succeeded") {
      records = { ...getEventResponsesObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (getEventResponsesObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getEventResponsesObj.data?.items?.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={9}>
              <TableEmptyBox>
                <Text type="TableText">No items</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records.items.map((item) => <ResponsesTableRow response={item} key={item.id} />);
    }
    return <ResponsesTableRowLoader />;
  };

  useEffect(() => {
    onRefreshResponsesList(q, events, users, 1);
  }, []);

  return (
    <>
      <BackdropLoading isLoading={isLoading} />

      <Formik
        innerRef={formRef}
        initialValues={{
          eventIds: [],
          userIds: [],
        }}
        onSubmit={submitFilter}
        validationSchema={validationSchema}
      >
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
            paddingY: theme.dimensions.ScreenPaddingY,
          }}
        >
          <Grid item container>
            <Text variant="screenLabel">Event Responses</Text>
          </Grid>
          <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
            <WhiteBgCardContainer>
              <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={isSmallScreen ? 12 : 6}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Events</Text>
                          </Grid>

                          <Grid item xs={9}>
                            <EventListAutoComplete name="eventIds" placeholder="Events" />
                          </Grid>
                        </FormContainer>
                      </Grid>

                      <Grid item xs={isSmallScreen ? 12 : 6}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Users</Text>
                          </Grid>

                          <Grid item xs={9}>
                            <SearchBar
                              placeholder="Search by user info"
                              name="q"
                              searchKeyword={q}
                              setSearchKeyword={onChangeSearch}
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={2}>
                        <CtaButton whiteBg onClickButton={clearFilter}>
                          <Text type="BrandColor">Clear All</Text>
                        </CtaButton>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormSubmitButton>
                          <Text type="WhiteColor">Search</Text>
                        </FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>

              <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                <Grid item xs={12}>
                  <TableWrapper>
                    <Table>
                      <TableHeader
                        headerCells={["ID", "Event", "Total Responses", "Action"]}
                        sortColumn={sortColumn}
                        sortOrder={sortOrder}
                        onSortChange={onSortChange}
                        columnMapping={columnMapping}
                      />
                      <TableBody>{renderTableBody()}</TableBody>
                    </Table>
                  </TableWrapper>
                </Grid>

                <Grid item xs={12}>
                  <CustomFooter>
                    <Pagination
                      sx={{
                        "&& .Mui-selected": {
                          backgroundColor: theme.palette.colors.brand.primary,
                          color: theme.palette.colors.text.white,
                        },
                      }}
                      page={page}
                      shape="rounded"
                      onChange={onPageChange}
                      count={getEventResponsesObj?.data?.pagination.totalPages || 1}
                      variant="outlined"
                    />
                  </CustomFooter>
                </Grid>
              </Grid>
            </WhiteBgCardContainer>
          </Grid>
        </Grid>
      </Formik>
    </>
  );
}
