import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Chip, FormHelperText, IconButton, styled, useTheme } from "@mui/material";
import { DesktopDatePicker, PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { startOfDay } from "date-fns";
import { useFormikContext } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Text from "../text.component";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  padding: "10px",
  backgroundColor: theme.palette.colors.bg.primary,
  border: `1px solid ${theme.palette.colors.ui.border}`,
  "&:hover": {
    border: "1px solid black",
  },
}));

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.colors.ui.blue,
    color: theme.palette.colors.ui.white,
    "&:hover": {
      backgroundColor: theme.palette.colors.ui.border,
    },
    "&:focus": {
      backgroundColor: theme.palette.colors.ui.blue,
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

export default function FormDatePickerMultiple({ name, width, disabled, minDays, maxDays }) {
  const theme = useTheme();
  const { values, setFieldValue, errors } = useFormikContext();
  const showError = typeof errors[name] === "string";
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const currentDate = new Date();

  const findDate = (dates, date) => {
    const dateTime = date.getTime();
    return dates.find((item) => item.getTime() === dateTime);
  };

  const findIndexDate = (dates, date) => {
    const dateTime = date.getTime();
    return dates.findIndex((item) => item.getTime() === dateTime);
  };

  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!values[name]) {
      return <PickersDay {...pickersDayProps} />;
    }

    const selected = findDate(values[name], date);

    return <CustomPickersDay {...pickersDayProps} disableMargin selected={selected} />;
  };

  const handleChange = (date) => {
    // Check if the date is already in the array
    const array = [...values[name]];
    const index = findIndexDate(array, startOfDay(date));
    if (index === -1) {
      // If not, add it to the array
      array.push(date);
      setFieldValue(name, array);
    } else {
      // If yes, remove it from the array
      array.splice(index, 1);
      setFieldValue(name, array);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClear = () => {
    setFieldValue(name, []);
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          disabled={disabled}
          value={values[name]}
          open={open}
          onClose={() => setOpen(false)}
          closeOnSelect={false}
          onChange={handleChange}
          PopperProps={{
            placement: "bottom",
            anchorEl,
          }}
          minDate={minDays && new Date()}
          maxDate={maxDays && currentDate.setDate(currentDate.getDate() + maxDays)}
          InputProps={{
            error: showError,
          }}
          renderDay={renderPickerDay}
          renderInput={() => (
            <StyledBox onClick={handleClick}>
              {values[name].length === 0 && (
                <Text sx={{ color: theme.palette.colors.text.placeholder }}>Select dates</Text>
              )}

              <Box>
                {values[name].map((item) => (
                  <Chip
                    label={moment(item).format("D-MM-Y")}
                    sx={{ margin: "2px" }}
                    key={moment(item)}
                  />
                ))}
              </Box>

              <Box sx={{ display: "flex" }}>
                {values[name].length !== 0 && (
                  <IconButton onClick={handleClear} sx={{ padding: "3px" }}>
                    <HighlightOffIcon />
                  </IconButton>
                )}

                <IconButton onClick={() => setOpen(!open)} sx={{ padding: "3px" }}>
                  <CalendarMonthIcon />
                </IconButton>
              </Box>
            </StyledBox>
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </LocalizationProvider>
    </Box>
  );
}
FormDatePickerMultiple.defaultProps = {
  width: null,
  disabled: false,
  minDays: true,
  maxDays: 90,
};

FormDatePickerMultiple.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  minDays: PropTypes.bool,
  maxDays: PropTypes.number,
};
