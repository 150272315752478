import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq } from "../../api-services";

export const getStaffProfileDetail = createAsyncThunk(
  "merchants/staffs/profile/detail",
  async () => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/profile`,
    );
    return response;
  },
);

export const updateStaffProfileDetail = createAsyncThunk(
  "merchants/staffs/profile/detail_update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/profile`,
      payload,
    );
    return response;
  },
);

const profileStaffSlice = createSlice({
  name: "profileStaff",
  initialState: {
    getStaffProfileDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateStaffProfileDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getStaffProfileDetail.pending]: (state) => {
      state.getStaffProfileDetailObj.status = "pending";
    },
    [getStaffProfileDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffProfileDetailObj.status = "succeeded";
      state.getStaffProfileDetailObj.data = data;
      state.getStaffProfileDetailObj.successMessage = message;
    },
    [getStaffProfileDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffProfileDetailObj.status = "failed";
      state.getStaffProfileDetailObj.errorMessage = message;
    },
    [updateStaffProfileDetail.pending]: (state) => {
      state.updateStaffProfileDetailObj.status = "pending";
    },
    [updateStaffProfileDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateStaffProfileDetailObj.status = "succeeded";
      state.updateStaffProfileDetailObj.data = data;
      state.updateStaffProfileDetailObj.successMessage = message;

      state.getStaffProfileDetailObj.data = data;
    },
    [updateStaffProfileDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateStaffProfileDetailObj.status = "failed";
      state.updateStaffProfileDetailObj.errorMessage = message;
    },
  },
});

export default profileStaffSlice.reducer;

// state
export const profileStaffSelector = (state) => state.profileStaff;
