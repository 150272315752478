import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

export default function SessionTableRow({
  session,
  onHandleCancel,
  onHandleDelete,
  setDuplicateSessionInfo,
  setShowDuplicateSessionModal,
}) {
  const [collapse, setCollapse] = useState(false);
  const history = useHistory();

  const onHandleEdit = () => {
    history.push({
      pathname: routes.FITNESS_CLASS_SESSIONS_EDIT,
      search: `${session.id}`,
      state: session,
    });
  };

  return (
    <>
      <TableRow
        key={session.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {session.class.name}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          {session.staffs.length > 0 &&
            session.staffs.map((staff) => (
              <Text
                key={`${staff.id}-${staff.name}`}
                variant="body2"
                type="TableText"
                sx={{ textAlign: "center" }}
              >
                {staff.name}
              </Text>
            ))}
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {session.startAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {session.endAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {session.currentCapacity}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {session.status}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {session.status === "draft" ? (
              <IconButton aria-label="expand row" size="small" onClick={onHandleEdit}>
                <Tooltip title="Edit" arrow>
                  <EditIcon />
                </Tooltip>
              </IconButton>
            ) : (
              <IconButton aria-label="expand row" size="small" onClick={onHandleEdit}>
                <Tooltip title="View" arrow>
                  <VisibilityIcon />
                </Tooltip>
              </IconButton>
            )}

            {session.deletable ? (
              <IconButton onClick={() => onHandleDelete(session.id)} disabled={!session.deletable}>
                <Tooltip title="Delete" arrow>
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            ) : (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => onHandleCancel(session.id)}
                disabled={session.status === "cancelled" || new Date(session.endAt) < new Date()}
              >
                <Tooltip title="Cancel" arrow>
                  <DoDisturbAltIcon />
                </Tooltip>
              </IconButton>
            )}

            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setDuplicateSessionInfo(session);
                setShowDuplicateSessionModal(true);
              }}
            >
              <Tooltip title="Duplicate Session" arrow>
                <ContentCopyIcon />
              </Tooltip>
            </IconButton>

            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Table size="small">
              <TableHeader headerCells={["Attendees Name", "Phone", "Status"]} />
              <TableBody>
                {session.attendees.length > 0 ? (
                  session.attendees.map((attendee) => (
                    <TableRow key={attendee.id}>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {attendee.user.name}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          {attendee.user.phone}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {attendee.status}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

SessionTableRow.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.string,
    currentCapacity: PropTypes.number,
    maxCapacity: PropTypes.number,
    status: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    deletable: PropTypes.bool,
    class: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        imagePath: PropTypes.string,
      }),
    ),
    attendees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        user: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          phone: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  onHandleCancel: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired,
  setDuplicateSessionInfo: PropTypes.func.isRequired,
  setShowDuplicateSessionModal: PropTypes.func.isRequired,
};
