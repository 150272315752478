import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq } from "../api-services";

export const getReferrals = createAsyncThunk("merchants/exclusive_referrals", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/exclusive_referrals?q=${payload.q}&page=${payload.page}`,
  );
  return response;
});

export const createReferral = createAsyncThunk(
  "merchants/exclusive_referrals/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/exclusive_referrals`,
      payload,
    );
    return response;
  },
);

export const deleteReferral = createAsyncThunk(
  "merchants/exclusive_referrals/{referral_id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/exclusive_referrals/${payload.referralId}`,
    );
    return response;
  },
);

const referralSlice = createSlice({
  name: "exclusiveReferral",
  initialState: {
    getReferralsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createReferralObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteReferralObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getReferrals.pending]: (state) => {
      state.getReferralsObj.status = "pending";
    },
    [getReferrals.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getReferralsObj.status = "succeeded";
      state.getReferralsObj.data = data;
      state.getReferralsObj.successMessage = message;
    },
    [getReferrals.rejected]: (state, action) => {
      const { message } = action.error;

      state.getReferralsObj.status = "failed";
      state.getReferralsObj.errorMessage = message;
    },
    [createReferral.pending]: (state) => {
      state.createReferralObj.status = "pending";
    },
    [createReferral.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createReferralObj.status = "succeeded";
      state.createReferralObj.data = data;
      state.createReferralObj.successMessage = message;
    },
    [createReferral.rejected]: (state, action) => {
      const { message } = action.error;

      state.createReferralObj.status = "failed";
      state.createReferralObj.errorMessage = message;
    },
    [deleteReferral.pending]: (state) => {
      state.deleteReferralObj.status = "pending";
    },
    [deleteReferral.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteReferralObj.status = "succeeded";
      state.deleteReferralObj.data = data;
      state.deleteReferralObj.successMessage = message;
    },
    [deleteReferral.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteReferralObj.status = "failed";
      state.deleteReferralObj.errorMessage = message;
    },
  },
});

export default referralSlice.reducer;

// state
export const referralSelector = (state) => state.exclusiveReferral;
