import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, IconButton, List, ListItem, TableCell, TableRow, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

export default function ScheduleTableRow({ schedule }) {
  const theme = useTheme();
  const [showDetail, setShowDetail] = useState(false);
  const history = useHistory();

  const handleOpenDetail = () => {
    setShowDetail(!showDetail);
  };

  const onHandleEdit = () => {
    history.push({
      pathname: routes.SCHEDULE_EDIT,
      search: `scheduleId=${schedule.id}`,
      state: schedule,
    });
  };

  return (
    <>
      <TableRow
        key={schedule.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "100px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {schedule.id}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {schedule.title}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {schedule.categoryType}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {schedule.business.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {schedule.slotCounts}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box display="flex">
            <IconButton aria-label="expand row" size="small" onClick={onHandleEdit}>
              <EditIcon />
            </IconButton>

            <IconButton onClick={handleOpenDetail}>
              {showDetail ? (
                <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.text.table }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.text.table }} />
              )}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>

      {showDetail && (
        <>
          <TableRow>
            <TableCell colSpan={10}>
              <Text marginLeft="2%">Package Plan</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={10}>
              <List sx={{ listStyleType: "numeric", padding: 0, pl: 3, textAlign: "center" }}>
                {schedule.packagePlans.map((packagePlan) => (
                  <ListItem
                    key={packagePlan.id}
                    sx={{
                      display: "list-item",
                      padding: 0,
                      paddingBottom: "5px",
                      color: theme.palette.colors.text.table,
                    }}
                  >
                    {packagePlan.title}
                  </ListItem>
                ))}
              </List>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

ScheduleTableRow.propTypes = {
  schedule: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    categoryType: PropTypes.string,
    slotCounts: PropTypes.number,
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    packagePlans: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      }),
    ),
  }).isRequired,
};
