import React from "react";
import { InputAdornment, TextField, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import Text from "../text.component";

function FormPrice({ name, width, isTransparent, placeholder, disabled }) {
  const theme = useTheme();
  const { touched, errors, values, setFieldValue, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (evt) => {
    const { value } = evt.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");
      if (decimal?.length > 2) {
        return;
      }
    }
    setFieldValue(name, value);
  };

  return (
    <TextField
      sx={{
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
        width,
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        },
        "& .MuiOutlinedInput-input": {
          padding: "10px",
        },
      }}
      error={showError}
      variant="outlined"
      helperText={showError ? errors[name] : null}
      onBlur={() => setFieldTouched(name)}
      name={name}
      type="number"
      placeholder={placeholder}
      onChange={handleChange}
      value={values[name] === null ? "" : values[name]}
      disabled={disabled}
      InputProps={{
        inputProps: { min: 0 },
        startAdornment: (
          <InputAdornment position="start">
            <Text type="GreyColor">RM</Text>
          </InputAdornment>
        ),
      }}
    />
  );
}

FormPrice.defaultProps = {
  isTransparent: false,
  width: "100%",
  placeholder: "",
  disabled: false,
};

FormPrice.propTypes = {
  name: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormPrice;
