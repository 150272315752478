import {
  Box,
  Grid,
  IconButton,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  getPromoCodeList,
  promoCodeSelector,
} from "../../../services/promo-code/promo-code.slice.services";
import PromoCodeTableRow from "../components/promo-code-table-row.component";
import SearchBar from "../components/search-bar.component";
import PromoCodeTableRowLoader from "../loader/promo-code-table-row-loader.component";
import routes from "../../../navigation/routes";
import TableSort from "../../../components/table/table-sort.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  q: Yup.string().label("Code/ Title"),
  startDate: Yup.date().label("Start date").nullable(),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startDate", {
      is: (startDate) => startDate,
      then: Yup.date()
        .min(Yup.ref("startDate"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
});

export default function PromoCodeListScreen() {
  const theme = useTheme();
  const formRef = useRef();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [page, setPage] = useState(1);
  const { getPromoCodeListObj } = useSelector(promoCodeSelector);
  const [totalPages, setTotalPages] = useState(1);
  const history = useHistory();
  const columnMapping = {
    ID: "id",
    Title: "title",
    Code: "code",
    Discount: "discount",
    "Start At": "startAt",
    "End At": "endAt",
    "Redeemed Count": "redeemedCount",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshPromoCodeList = (newPage, keyword, startDate, endDate) => {
    setIsLoading(true);
    setPage(newPage);
    setQ(keyword);
    setStartAt(startDate);
    setEndAt(endDate);
    dispatch(
      getPromoCodeList({
        page: newPage,
        q: keyword,
        startAt: startDate && moment(startDate).format("Y-MM-DD 00:00:00"),
        endAt: endDate && moment(endDate).format("Y-MM-DD 23:59:59"),
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setIsLoading(false);
  };

  const submitFilter = (values) => {
    onRefreshPromoCodeList(1, values.q, values.startDate, values.endDate);
  };

  const onChangeSearch = (keyword) => {
    onRefreshPromoCodeList(1, keyword, startAt, endAt);
  };

  const onPageChange = (e, newPage) => {
    onRefreshPromoCodeList(newPage, q, startAt, endAt);
  };

  const onHandleAdd = () => {
    history.push({
      pathname: routes.PROMO_CODES_CREATE,
    });
  };

  useEffect(() => {
    onRefreshPromoCodeList(page, q);
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const getProcessedRecord = () => {
    let records = null;

    if (getPromoCodeListObj.data && getPromoCodeListObj.status === "succeeded") {
      records = { ...getPromoCodeListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (getPromoCodeListObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getPromoCodeListObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <TableEmptyBox>
                <Text type="TableText">No Promo Codes</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => <PromoCodeTableRow record={item} key={item.id} />);
    }
    return <PromoCodeTableRowLoader />;
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        q: q || "",
        startDate: null,
        endDate: null,
      }}
      onSubmit={submitFilter}
      validationSchema={validationSchema}
    >
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Text variant="screenLabel">Promo Code</Text>
            <IconButton onClick={onHandleAdd}>
              <AddCircleIcon sx={{ color: theme.palette.colors.brand.primary }} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
          <WhiteBgCardContainer>
            <BackdropLoading isLoading={isLoading} />

            <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={isSmallScreen ? 3 : 1.45}>
                          <Text>Search</Text>
                        </Grid>
                        <Grid item xs={isSmallScreen ? 9 : 10.55}>
                          <SearchBar
                            placeholder="Search by promo code"
                            name="q"
                            searchKeyword={q}
                            setSearchKeyword={onChangeSearch}
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Text>Start date</Text>
                        </Grid>
                        <Grid item xs={9}>
                          <FormDatePicker
                            name="startDate"
                            width="100%"
                            isWhiteBg
                            maxDays={null}
                            minDays={false}
                            showClear={true}
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Text>End date</Text>
                        </Grid>

                        <Grid item xs={9}>
                          <FormDatePicker
                            name="endDate"
                            width="100%"
                            isWhiteBg
                            maxDays={null}
                            minDays={false}
                            showClear={true}
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                    <Grid item xs={isSmallScreen ? 12 : 2}>
                      <FormSubmitButton>
                        <Text type="WhiteColor">Search</Text>
                      </FormSubmitButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SearchContainer>

            <Grid container sx={{ paddingY: "20px" }} spacing={3}>
              <Grid item xs={12}>
                <TableWrapper>
                  <Table>
                    <TableHeader
                      headerCells={[
                        "ID",
                        "Title",
                        "Code",
                        "Discount",
                        "Start At",
                        "End At",
                        "Redeemed Count",
                        "Action",
                      ]}
                      sortColumn={sortColumn}
                      sortOrder={sortOrder}
                      onSortChange={onSortChange}
                      columnMapping={columnMapping}
                    />
                    <TableBody>{renderTableBody()}</TableBody>
                  </Table>
                </TableWrapper>
              </Grid>
              <Grid item xs={12}>
                <CustomFooter>
                  <Pagination
                    sx={{
                      "&& .Mui-selected": {
                        backgroundColor: theme.palette.colors.brand.primary,
                        color: theme.palette.colors.text.white,
                      },
                    }}
                    page={page}
                    shape="rounded"
                    onChange={onPageChange}
                    count={getPromoCodeListObj?.data?.pagination.totalPages || totalPages}
                    variant="outlined"
                  />
                </CustomFooter>
              </Grid>
            </Grid>
          </WhiteBgCardContainer>
        </Grid>
      </Grid>
    </Formik>
  );
}
