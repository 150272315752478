import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api-services";

export const getCalendarDetail = createAsyncThunk("merchants/calendars/detail", async (payload) => {
  const { q, businessId, staffId, startDate, endDate } = payload;
  const response = await getReq(
    `${
      process.env.REACT_APP_API_PRIVATE_V1
    }/merchants/calendars/?startAt=${startDate}&endAt=${endDate}${q ? "&q=".concat(q) : ""}${
      businessId ? "&businessIds=".concat(businessId) : ""
    }${staffId ? "&staffIds=".concat(staffId) : ""}`,
  );
  return response;
});

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    getCalendarDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getCalendarDetail.pending]: (state) => {
      state.getCalendarDetailObj.status = "pending";
    },
    [getCalendarDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCalendarDetailObj.status = "succeeded";
      state.getCalendarDetailObj.data = data;
      state.getCalendarDetailObj.successMessage = message;
    },
    [getCalendarDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCalendarDetailObj.status = "failed";
      state.getCalendarDetailObj.errorMessage = message;
    },
  },
});

export default calendarSlice.reducer;

// state
export const calendarSelector = (state) => state.calendar;
