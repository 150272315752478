import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, postReq, putReq } from "../../api-services";

export const addEventImage = createAsyncThunk("merchants/events/images/add", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${payload.eventId}/images`,
    payload,
  );
  return response;
});

export const removeEventImage = createAsyncThunk(
  "merchants/events/images/remove",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${payload.eventId}/images/${payload.imageId}`,
    );
    return response;
  },
);

export const reOrderEventImage = createAsyncThunk(
  "merchants/events/images/re-order",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${payload.eventId}/images/${payload.imageId}/reorder?type=${payload.type}`,
    );
    return response;
  },
);

const eventImageSlice = createSlice({
  name: "eventImage",
  initialState: {
    addEventImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    removeEventImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    reOrderEventImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [addEventImage.pending]: (state) => {
      state.addEventImageObj.status = "pending";
    },
    [addEventImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addEventImageObj.status = "succeeded";
      state.addEventImageObj.data = data;
      state.addEventImageObj.successMessage = message;
    },
    [addEventImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.addEventImageObj.status = "failed";
      state.addEventImageObj.errorMessage = message;
    },
    [removeEventImage.pending]: (state) => {
      state.removeEventImageObj.status = "pending";
    },
    [removeEventImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeEventImageObj.status = "succeeded";
      state.removeEventImageObj.data = data;
      state.removeEventImageObj.successMessage = message;
    },
    [removeEventImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeEventImageObj.status = "failed";
      state.removeEventImageObj.errorMessage = message;
    },
    [reOrderEventImage.pending]: (state) => {
      state.reOrderEventImageObj.status = "pending";
    },
    [reOrderEventImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.reOrderEventImageObj.status = "succeeded";
      state.reOrderEventImageObj.data = data;
      state.reOrderEventImageObj.successMessage = message;
    },
    [reOrderEventImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.reOrderEventImageObj.status = "failed";
      state.reOrderEventImageObj.errorMessage = message;
    },
  },
});

export default eventImageSlice.reducer;

// state
export const eventImageSelector = (state) => state.eventImage;
