import { useFormikContext } from "formik";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { emailCheck } from "../../../services/auth/auth.slice.services";
import EmailCheckerField from "./email-checker-field.component";

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

export default function EmailForm() {
  const { setFieldValue } = useFormikContext();
  const createSnackBar = useContext(SnackbarContext);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const onChangeEmail = (values) => {
    setErrorMessage("");
    dispatch(emailCheck(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setFieldValue("email", values.email);
      }
      if (meta.requestStatus === "rejected") {
        setErrorMessage(error.message);
        setFieldValue("email", "");
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Form
      validationSchema={emailValidationSchema}
      initialValues={{ email: "" }}
      onSubmit={onChangeEmail}
    >
      <EmailCheckerField name="email" placeholder="Enter your email" errorMessage={errorMessage} />
    </Form>
  );
}
