import { Box, Grid, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import LineChart from "../../../components/chart/line-chart.component";
import FormPickerMonthyCalendar from "../../../components/forms/form-picker-monthly-calendar.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import PaddedView from "../../../components/padded-view.component";
import {
  getFitnessClassesRevenueWithCSV,
  revenueSummarySelector,
} from "../../../services/revenue/revenue-slice.services";

const validationSchema = Yup.object().shape({
  startAt: Yup.date().required().label("Start At"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End at can't be before start date")
    .label("End At")
    .required(),
});

const LINE_CHART_HEIGHT = 360;

function FitnessClassRevenueChart() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { getFitnessClassesRevenueWithCSVObj } = useSelector(revenueSummarySelector);

  useEffect(() => {
    dispatch(
      getFitnessClassesRevenueWithCSV({
        startAt: moment(new Date())
          .subtract(6, "months")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        endAt: moment(new Date()).endOf("month").format("YYYY-MM-DD 23:59:59"),
      }),
    );
  }, []);

  const onHandleSubmit = (values) => {
    const payload = {
      startAt: moment(new Date(values.startAt)).startOf("month").format("YYYY-MM-DD 00:00:00"),
      endAt: moment(new Date(values.endAt)).endOf("month").format("YYYY-MM-DD 23:59:59"),
    };
    dispatch(getFitnessClassesRevenueWithCSV(payload));
  };

  const chartLoader = () => <Skeleton height="360px" width="100%" />;

  function getRandomColor() {
    const letters = "0123456789ABCDEF".split("");
    let color = "#";
    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return (
    <>
      <PaddedView>
        <Formik
          initialValues={{
            startAt: moment(new Date())
              .subtract(6, "months")
              .startOf("month")
              .format("YYYY-MM-DD 00:00:00"),
            endAt: moment(new Date()).endOf("month").format("YYYY-MM-DD 23:59:59"),
          }}
          onSubmit={onHandleSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <>
              <Grid
                container
                sx={{
                  backgroundColor: theme.palette.colors.bg.grey,
                  borderRadius: `${theme.shape.borderRadius[1]}px`,
                }}
              >
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <PaddedView>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          Revenue - Fitness Classes
                        </Typography>
                      </PaddedView>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <PaddedView>
                        <FormPickerMonthyCalendar name="startAt" label="Start At" />
                      </PaddedView>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <PaddedView>
                        <FormPickerMonthyCalendar name="endAt" label="End At" />
                      </PaddedView>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <PaddedView>
                        <FormSubmitButton>
                          <Typography>Search</Typography>
                        </FormSubmitButton>
                      </PaddedView>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <PaddedView>
                        {getFitnessClassesRevenueWithCSVObj.status === "succeeded" ? (
                          <>
                            <CSVLink
                              data={getFitnessClassesRevenueWithCSVObj.data.csv}
                              filename={`fitness_classes_revenue_${format(
                                new Date(values.startAt),
                                "dd_MMM_yyyy",
                              )}-${format(new Date(values.endAt), "dd_MMM_yyyy")}.csv`}
                              style={{ textDecoration: "none" }}
                            >
                              <CtaBorderButton
                                disabled={getFitnessClassesRevenueWithCSVObj.status !== "succeeded"}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      getFitnessClassesRevenueWithCSVObj.status === "succeeded"
                                        ? theme.palette.colors.brand.primary
                                        : theme.palette.colors.text.disabled,
                                  }}
                                >
                                  Download CSV
                                </Typography>
                              </CtaBorderButton>
                            </CSVLink>
                          </>
                        ) : (
                          <>
                            <CtaBorderButton disabled={true}>
                              <Typography
                                sx={{
                                  color: theme.palette.colors.text.disabled,
                                }}
                              >
                                Download CSV
                              </Typography>
                            </CtaBorderButton>
                          </>
                        )}
                      </PaddedView>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <PaddedView>
                    {getFitnessClassesRevenueWithCSVObj.status === "succeeded" &&
                    getFitnessClassesRevenueWithCSVObj.data.datasets.length > 0 ? (
                      <>
                        <Box sx={{ height: LINE_CHART_HEIGHT }}>
                          <LineChart
                            datasets={getFitnessClassesRevenueWithCSVObj.data.datasets.map(
                              (datum) => {
                                const randomColor = getRandomColor();
                                return {
                                  id: datum.id,
                                  label: datum.label,
                                  data: datum.data.map((price) =>
                                    price.toString().replace(",", ""),
                                  ),
                                  borderColor:
                                    datum.label === "Total" ? datum.borderColor : randomColor,
                                  backgroundColor:
                                    datum.label === "Total" ? datum.backgroundColor : randomColor,
                                };
                              },
                            )}
                            labels={getFitnessClassesRevenueWithCSVObj.data.labels}
                            yLegend="Ringgit Malaysia (RM)"
                          />
                        </Box>
                      </>
                    ) : (
                      <>{chartLoader()}</>
                    )}
                  </PaddedView>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

export default FitnessClassRevenueChart;
