import React from "react";
import PropTypes from "prop-types";
import { Box, styled, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import Spacer from "../../../components/spacer.component";
import { profileSelector } from "../../../services/profile/profile.slice.services";
import CtaButton from "../../../components/button/cta-button.component";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const DisabledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.white,
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
}));

const SpacebetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
});

export default function ProfilePasswordEditForm({ isEditing, setIsEditing }) {
  const { handleSubmit } = useFormikContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const { updateProfileDetailObj } = useSelector(profileSelector);

  const renderButton = () => {
    if (isEditing) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {isSmallScreen && (
            <>
              <CtaButton
                width={isSmallScreen ? null : "70px"}
                padding="8px"
                onClickButton={() => handleSubmit()}
                isLoading={updateProfileDetailObj.status === "pending"}
              >
                <Text type="WhiteColor">Save</Text>
              </CtaButton>
              <Spacer position="left" size="s" />
            </>
          )}
          <CtaButton
            width={isSmallScreen ? null : "70px"}
            padding="8px"
            onClickButton={() => setIsEditing(false)}
            isLoading={updateProfileDetailObj.status === "pending"}
          >
            <Text type="WhiteColor">Cancel</Text>
          </CtaButton>
        </Box>
      );
    }
    return (
      <CtaBorderButton
        width={isSmallScreen ? null : "70px"}
        padding="8px"
        onClickButton={() => setIsEditing(true)}
      >
        <Text type="BrandColor">Edit</Text>
      </CtaBorderButton>
    );
  };

  return (
    <>
      {isSmallScreen && (
        <SpacebetweenRowBox>
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Password</Text>
          </LabelContainer>
          {renderButton()}
        </SpacebetweenRowBox>
      )}

      <FormContainer>
        {!isSmallScreen && (
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Password</Text>
          </LabelContainer>
        )}
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Box sx={{ width: "100%" }}>
            {isEditing ? (
              <>
                <FormFieldText
                  isDisableEnter
                  name="currentPassword"
                  placeholder="Enter current password"
                  width="100%"
                  type="password"
                />
                <Spacer size="s" position="top" />
                <FormFieldText
                  isDisableEnter
                  name="newPassword"
                  placeholder="Enter new password"
                  width="100%"
                  type="password"
                />
                <Spacer size="s" position="top" />
                <FormFieldText
                  isDisableEnter
                  name="confirmPassword"
                  placeholder="Enter confirm password"
                  width="100%"
                  type="password"
                />
              </>
            ) : (
              <DisabledTextField value="********" disabled variant="outlined" />
            )}
          </Box>
          {isEditing && !isSmallScreen && (
            <>
              <Spacer position="top" size="m" />
              <CtaButton
                width={isSmallScreen ? null : "70px"}
                padding="8px"
                onClickButton={() => handleSubmit()}
                isLoading={updateProfileDetailObj.status === "pending"}
              >
                <Text type="WhiteColor">Save</Text>
              </CtaButton>
            </>
          )}
        </Box>

        {!isSmallScreen && (
          <>
            <Spacer position="left" size="m" />
            {renderButton()}
          </>
        )}
      </FormContainer>
    </>
  );
}

ProfilePasswordEditForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
};
