import React, { useEffect } from "react";
import { AppBar, Box, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getProfileDetail } from "../../services/profile/profile.slice.services";
import MobileDrawer from "./mobile-drawer.component";
import DesktopDrawer from "./desktop-drawer.component";
import DesktopAvatarMenu from "./desktop-avatar-menu.component";
import { getStaffProfileDetail } from "../../services/staff/profile/profile.slice.services";
import { isMerchantStaff } from "../utils";

const FlexEndBox = styled(Box)({
  display: "flex",
  height: "100%",
  justifyContent: "flex-end",
  width: "100%",
  padding: "10px",
  paddingRight: "20px",
});

const CenterBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

const MaxWidthBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: theme.dimensions.PCMaxWidth,
  minHeight: theme.dimensions.heightWithoutAppBar,
  overflow: "auto",
}));

const FullWidthRowBox = styled(Box)({ width: "100%", display: "flex", flexDirection: "row" });
export default function AppLayout({ children }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMerchantStaff()) {
      dispatch(getProfileDetail());
    }

    if (isMerchantStaff()) {
      dispatch(getStaffProfileDetail());
    }
  }, []);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: theme.palette.colors.bg.secondary }}
        elevation={isSmallScreen ? 1 : 0}
      >
        {isSmallScreen ? (
          <MobileDrawer />
        ) : (
          <FlexEndBox>
            <DesktopAvatarMenu />
          </FlexEndBox>
        )}
      </AppBar>
      <CenterBox>
        <FullWidthRowBox>
          {!isSmallScreen && (
            <Box>
              <DesktopDrawer />
            </Box>
          )}

          <CenterBox
            sx={{
              width: "100%",
              backgroundColor: theme.palette.colors.bg.secondary,
              marginTop: "40px",
            }}
          >
            <MaxWidthBox>{children}</MaxWidthBox>
          </CenterBox>
        </FullWidthRowBox>
      </CenterBox>
    </>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
