import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { appointmentSelector } from "../../../services/appointment/appointment-slice.service";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

export default function AppointmentEditModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm, values } = useFormikContext();
  const { updateAppointmentObj } = useSelector(appointmentSelector);

  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  const compareDates = (date1, date2) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "600px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text variant="screenLabel">Edit Class</Text>
          </Grid>

          <Grid item xs={12}>
            <FormContainer>
              <Grid item xs={3}>
                <Text>Start date</Text>
              </Grid>
              <Grid item xs={9}>
                <FormDatePicker
                  name="startDate"
                  width="100%"
                  setEndDateAsSame
                  maxDays={null}
                  showClear={true}
                />
              </Grid>
            </FormContainer>
          </Grid>

          <Grid item xs={12}>
            <FormContainer>
              <Grid item xs={3}>
                <Text>Start time</Text>
              </Grid>
              <Grid item xs={9}>
                <FormTimePicker
                  name="startTime"
                  minTime={
                    compareDates(new Date(), values.startDate)
                      ? moment().format("D-MM-Y HH:mm:ss")
                      : null
                  }
                />
              </Grid>
            </FormContainer>
          </Grid>

          <Grid item xs={12}>
            <FormContainer>
              <Grid item xs={3}>
                <Text>End date</Text>
              </Grid>

              <Grid item xs={9}>
                <FormDatePicker name="endDate" width="100%" maxDays={null} showClear={true} />
              </Grid>
            </FormContainer>
          </Grid>

          <Grid item xs={12}>
            <FormContainer>
              <Grid item xs={3}>
                <Text>End time</Text>
              </Grid>
              <Grid item xs={9}>
                <FormTimePicker
                  name="endTime"
                  minTime={
                    compareDates(new Date(), values.endDate)
                      ? moment().format("D-MM-Y HH:mm:ss")
                      : null
                  }
                />
              </Grid>
            </FormContainer>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <CtaBorderButton
                onClickButton={() => setShowModal(false)}
                width="100px"
                isLoading={updateAppointmentObj.status === "pending"}
              >
                <Text type="BrandColor">Cancel</Text>
              </CtaBorderButton>
              <Spacer size="m" position="left" />
              <FormSubmitButton width="100px" isLoading={updateAppointmentObj.status === "pending"}>
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AppointmentEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
