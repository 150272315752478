import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../api-services";

export const getAppointments = createAsyncThunk("merchants/appointments/index", async (payload) => {
  const { statuses, q, startAt, endAt, page, sessionIds } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/appointments?${
      page ? "page=".concat(page) : "page=1"
    }${q ? "&q=".concat(q) : ""}${startAt ? "&startAt=".concat(startAt) : ""}${
      endAt ? "&endAt=".concat(endAt) : ""
    }${statuses ? "&statuses=".concat(statuses) : ""}${
      sessionIds ? "&sessionIds=".concat(sessionIds) : ""
    }`,
  );
  return response;
});

export const getAppointmentsSuggestion = createAsyncThunk(
  "merchants/appointments/suggestion",
  async (payload) => {
    const { packageCategoryType } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/appointments/suggestion?${
        packageCategoryType ? "packageCategoryType=".concat(packageCategoryType) : ""
      }`,
    );
    return response;
  },
);

export const createAppointment = createAsyncThunk(
  "merchants/appointments/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/appointments`,
      payload,
    );
    return response;
  },
);

export const updateAppointment = createAsyncThunk(
  "merchants/appointments/update/{appointment_id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/appointments/${payload.appointmentId}`,
      payload,
    );
    return response;
  },
);

export const cancelAppointment = createAsyncThunk(
  "merchants/appointments/cancel/{appointment_id}",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/appointments/${payload.appointmentId}/cancel`,
      payload,
    );
    return response;
  },
);

const appointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    getAppointmentsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getAppointmentsSuggestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createAppointmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateAppointmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cancelAppointmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getAppointments.pending]: (state) => {
      state.getAppointmentsObj.status = "pending";
    },
    [getAppointments.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAppointmentsObj.status = "succeeded";
      state.getAppointmentsObj.data = data;
      state.getAppointmentsObj.successMessage = message;
    },
    [getAppointments.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAppointmentsObj.status = "failed";
      state.getAppointmentsObj.errorMessage = message;
    },
    [getAppointmentsSuggestion.pending]: (state) => {
      state.getAppointmentsSuggestionObj.status = "pending";
    },
    [getAppointmentsSuggestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAppointmentsSuggestionObj.status = "succeeded";
      state.getAppointmentsSuggestionObj.data = data;
      state.getAppointmentsSuggestionObj.successMessage = message;
    },
    [getAppointmentsSuggestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAppointmentsSuggestionObj.status = "failed";
      state.getAppointmentsSuggestionObj.errorMessage = message;
    },
    [createAppointment.pending]: (state) => {
      state.createAppointmentObj.status = "pending";
    },
    [createAppointment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createAppointmentObj.status = "succeeded";
      state.createAppointmentObj.data = data;
      state.createAppointmentObj.successMessage = message;
    },
    [createAppointment.rejected]: (state, action) => {
      const { message } = action.error;

      state.createAppointmentObj.status = "failed";
      state.createAppointmentObj.errorMessage = message;
    },
    [updateAppointment.pending]: (state) => {
      state.updateAppointmentObj.status = "pending";
    },
    [updateAppointment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateAppointmentObj.status = "succeeded";
      state.updateAppointmentObj.data = data;
      state.updateAppointmentObj.successMessage = message;
    },
    [updateAppointment.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateAppointmentObj.status = "failed";
      state.updateAppointmentObj.errorMessage = message;
    },
    [cancelAppointment.pending]: (state) => {
      state.cancelAppointmentObj.status = "pending";
    },
    [cancelAppointment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelAppointmentObj.status = "succeeded";
      state.cancelAppointmentObj.data = data;
      state.cancelAppointmentObj.successMessage = message;
    },
    [cancelAppointment.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelAppointmentObj.status = "failed";
      state.cancelAppointmentObj.errorMessage = message;
    },
  },
});

export default appointmentSlice.reducer;

// state
export const appointmentSelector = (state) => state.appointment;
