import { Box, Grid, Pagination, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import {
  getClosedStatement,
  statementSelector,
} from "../../../services/statement/statement-slice.service";
import StatementCard from "../components/statement-card.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function StatementClosedListScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const { getClosedStatementObj } = useSelector(statementSelector);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const onRefreshStatementList = (newPage) => {
    setIsLoading(true);
    setPage(newPage);
    dispatch(getClosedStatement({ page: newPage })).then(({ payload, meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      } else if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      }
    });

    setIsLoading(false);
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    onRefreshStatementList(newPage);
  };

  useEffect(() => {
    onRefreshStatementList(page);
  }, []);

  return (
    <Box
      width="100%"
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text variant="screenLabel">Closed Statements</Text>
        </Grid>
        <Grid item xs={12}>
          <WhiteBgCardContainer>
            <BackdropLoading isLoading={isLoading} />

            {getClosedStatementObj.status === "succeeded" && (
              <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                {getClosedStatementObj.data.items.length === 0 && (
                  <Grid item xs={12}>
                    <CenterRowBox sx={{ justifyContent: "center" }}>
                      <Text>Currently no closed statement</Text>
                    </CenterRowBox>
                  </Grid>
                )}
                {getClosedStatementObj.data.items.length > 0 && (
                  <Grid item xs={12}>
                    {getClosedStatementObj.data.items.map((item) => (
                      <Grid item xs={12} key={item.statementId}>
                        <StatementCard
                          id={item.statementId}
                          price={item.amount}
                          date={item.transferredDate}
                          status="closed"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <CustomFooter>
                <Pagination
                  sx={{
                    "&& .Mui-selected": {
                      backgroundColor: theme.palette.colors.brand.primary,
                      color: theme.palette.colors.text.white,
                    },
                  }}
                  page={page}
                  shape="rounded"
                  onChange={onPageChange}
                  count={getClosedStatementObj?.data?.pagination.totalPages || totalPages}
                  variant="outlined"
                />
              </CustomFooter>
            </Grid>
          </WhiteBgCardContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
