import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../api-services";

export const getGuests = createAsyncThunk("merchants/guests/index", async (payload) => {
  const { q, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/guests?${
      q ? "&q=".concat(q) : ""
    }&page=${page}`,
  );
  return response;
});

const guestSlice = createSlice({
  name: "guest",
  initialState: {
    getGuestsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getGuests.pending]: (state) => {
      state.getGuestsObj.status = "pending";
    },
    [getGuests.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getGuestsObj.status = "succeeded";
      state.getGuestsObj.data = data;
      state.getGuestsObj.successMessage = message;
    },
    [getGuests.rejected]: (state, action) => {
      const { message } = action.error;

      state.getGuestsObj.status = "failed";
      state.getGuestsObj.errorMessage = message;
    },
  },
});

export default guestSlice.reducer;

// state
export const guestSelector = (state) => state.guest;
