import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { offDaySelector } from "../../../services/off-day/off-day.slice.service";
import BusinessListAutoComplete from "./business-list-autocomplete.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  minHeight: "35%",
  maxHeight: "90%",
  overflowY: "scroll",
}));

export default function AddOffDayModal({ showModal, setShowModal, title }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm } = useFormikContext();
  const { updateOffDayObj } = useSelector(offDaySelector);

  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  return (
    <Modal open={showModal}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "600px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text variant="screenLabel">{title}</Text>
          </Grid>

          <Grid item xs={12}>
            <BusinessListAutoComplete name="businessIds" placeholder="Businesses" />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <CtaBorderButton
                onClickButton={handleCancel}
                width="100px"
                isLoading={updateOffDayObj.status === "pending"}
              >
                <Text type="BrandColor">Cancel</Text>
              </CtaBorderButton>
              <Spacer size="m" position="left" />
              <FormSubmitButton width="100px" isLoading={updateOffDayObj.status === "pending"}>
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AddOffDayModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
