import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";

export default function FormSelect({
  itemList,
  name,
  placeholder,
  width,
  isTransparent,
  disabled,
  setCategoryType,
}) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext();
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);

    if (name === "categoryType") {
      setCategoryType(event.target.value);
      setFieldValue("planId", "");
      setFieldValue("businessId", "");
    }

    if (name === "planId") {
      setFieldValue("businessId", "");
    }
  };

  return (
    <FormControl
      sx={{
        width,
        "& .MuiOutlinedInput-input": {
          padding: "10px",
        },
      }}
    >
      <Select
        onBlur={() => setFieldTouched(name)}
        value={values[name] === null ? "" : values[name]}
        disabled={disabled}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
        error={showError}
        displayEmpty
        defaultValue=""
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        }}
      >
        <MenuItem value="" disabled>
          <Text type="GreyColor">{placeholder}</Text>
        </MenuItem>
        {itemList.map((item) => (
          <MenuItem key={item.id} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error={showError}>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

FormSelect.defaultProps = {
  width: "100%",
  isTransparent: false,
  disabled: false,
  setCategoryType: null,
};

FormSelect.propTypes = {
  width: PropTypes.string,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.number,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
  disabled: PropTypes.bool,
  setCategoryType: PropTypes.func,
};
