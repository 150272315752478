import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq, delReq } from "../api-services";

export const getProfileDetail = createAsyncThunk("merchants/profile/detail", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile`);
  return response;
});

export const updateProfileDetail = createAsyncThunk(
  "merchants/profile/detail_update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile`,
      payload,
    );
    return response;
  },
);

export const uploadProfileLogo = createAsyncThunk(
  "merchants/profile/logo_upload",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile/logo`,
      payload,
    );
    return response;
  },
);

export const deleteProfileLogo = createAsyncThunk(
  "merchants/profile/logo_delete",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile/logo`,
      payload,
    );
    return response;
  },
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    getProfileDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateProfileDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadProfileLogoObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteProfileLogoObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getProfileDetail.pending]: (state) => {
      state.getProfileDetailObj.status = "pending";
    },
    [getProfileDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getProfileDetailObj.status = "succeeded";
      state.getProfileDetailObj.data = data;
      state.getProfileDetailObj.successMessage = message;
    },
    [getProfileDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getProfileDetailObj.status = "failed";
      state.getProfileDetailObj.errorMessage = message;
    },
    [updateProfileDetail.pending]: (state) => {
      state.updateProfileDetailObj.status = "pending";
    },
    [updateProfileDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateProfileDetailObj.status = "succeeded";
      state.updateProfileDetailObj.data = data;
      state.updateProfileDetailObj.successMessage = message;

      state.getProfileDetailObj.data = data;
    },
    [updateProfileDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateProfileDetailObj.status = "failed";
      state.updateProfileDetailObj.errorMessage = message;
    },
    [uploadProfileLogo.pending]: (state) => {
      state.uploadProfileLogoObj.status = "pending";
    },
    [uploadProfileLogo.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadProfileLogoObj.status = "succeeded";
      state.uploadProfileLogoObj.data = data;
      state.uploadProfileLogoObj.successMessage = message;

      state.getProfileDetailObj.data = data;
    },
    [uploadProfileLogo.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadProfileLogoObj.status = "failed";
      state.uploadProfileLogoObj.errorMessage = message;
    },
    [deleteProfileLogo.pending]: (state) => {
      state.deleteProfileLogoObj.status = "pending";
    },
    [deleteProfileLogo.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteProfileLogoObj.status = "succeeded";
      state.deleteProfileLogoObj.data = data;
      state.deleteProfileLogoObj.successMessage = message;

      state.getProfileDetailObj.data = data;
    },
    [deleteProfileLogo.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteProfileLogoObj.status = "failed";
      state.deleteProfileLogoObj.errorMessage = message;
    },
  },
});

export default profileSlice.reducer;

// state
export const profileSelector = (state) => state.profile;
