import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api-services";

export const checkUserBusinessAccess = createAsyncThunk(
  "merchants/business_access/index",
  async (payload) => {
    const { userPublicId, businessesId, productType } = payload;
    const response = await getReq(
      `${
        process.env.REACT_APP_API_PRIVATE_V1
      }/merchants/business_accesses/${userPublicId}?productType=${productType}${
        businessesId ? "&businessesId=".concat(businessesId) : ""
      }`,
    );
    return response;
  },
);

const businessAccessSlice = createSlice({
  name: "businessAccess",
  initialState: {
    checkUserBusinessAccessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetBusinesAccessInfo: (state) => {
      state.checkUserBusinessAccessObj.status = "idle";
      state.checkUserBusinessAccessObj.data = null;
      state.checkUserBusinessAccessObj.successMessage = null;
      state.checkUserBusinessAccessObj.errorMessage = null;
    },
  },
  extraReducers: {
    [checkUserBusinessAccess.pending]: (state) => {
      state.checkUserBusinessAccessObj.status = "pending";
    },
    [checkUserBusinessAccess.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.checkUserBusinessAccessObj.status = "succeeded";
      state.checkUserBusinessAccessObj.data = data;
      state.checkUserBusinessAccessObj.successMessage = message;
    },
    [checkUserBusinessAccess.rejected]: (state, action) => {
      const { message } = action.error;

      state.checkUserBusinessAccessObj.status = "failed";
      state.checkUserBusinessAccessObj.errorMessage = message;
    },
  },
});

export default businessAccessSlice.reducer;

// state
export const businessAccessSelector = (state) => state.businessAccess;

export const { resetBusinesAccessInfo } = businessAccessSlice.actions;
