import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import RedCtaButton from "../../../components/button/red-cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  deletePromoCode,
  getPromoCodeDetail,
  getPromoCodeRedemptionList,
  promoCodeSelector,
  updatePromoCode,
} from "../../../services/promo-code/promo-code.slice.services";
import FormFieldLabel from "../../business/components/form-field-label.component";
import BusinessListAutoComplete from "../components/business-list-autocomplete.component";
import ClassListAutoComplete from "../components/class-list-autocomplete.component";
import FormSelect from "../components/form-select.component";
import FormTimePicker from "../components/form-time-picker.component";
import PackagePlansListAutoComplete from "../components/package-plans-list-autocomplete.component";
import PromoCodeRedemptionRow from "../components/promo-code-redemption-row.component";
import SubscriptionPlansListAutoComplete from "../components/subscription-plans-list-autocomplete.component";
import UserListPhoneNumber from "../components/user-list-phone-number.component";
import PromoCodeRedemptionTableRowLoader from "../loader/promo-code-redemption-table-row-loader.component";
import TableSort from "../../../components/table/table-sort.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.white,
  },
}));

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function PromoCodeEditScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const location = useLocation();
  const history = useHistory();
  const promoCodeId = new URLSearchParams(location.search).get("promoCodeId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getPromoCodeDetailObj, getPromoCodeRedemptionListObj, deletePromoCodeObj } =
    useSelector(promoCodeSelector);
  const [selectedType, setSelectedType] = useState("");
  const [isUserSpecific, setIsUserSpecific] = useState(false);
  const [isBusinessSpecific, setIsBusinessSpecific] = useState(false);
  const [isSubscriptionPlanSpecific, setIsSubscriptionPlanSpecific] = useState(false);
  const [isPackagePlanSpecific, setIsPackagePlanSpecific] = useState(false);
  const [isClassSpecific, setIsClassSpecific] = useState(false);
  const [allowEditTitle, setAllowEditTitle] = useState(false);
  const [allowEditDescription, setAllowEditDescription] = useState(false);
  const [allowEditPromoPeriod, setAllowEditPromoPeriod] = useState(false);
  const [allowEditPromoRules, setAllowEditPromoRules] = useState(false);
  const [page, setPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const columnMapping = {
    "Redemption ID": "id",
    "User Id": "userId",
    Username: "username",
    Status: "status",
    "Date Redeemed": "dateRedeemed",
    "Discount Amount": "discountAmount",
    "Purchase Item": "purchaseItem",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const validationSchemaTitle = Yup.object().shape({
    title: Yup.string().required().label("Title"),
  });

  const validationSchemaDescription = Yup.object().shape({
    description: Yup.string().required().label("Description"),
  });

  const validationSchemaPromoPeriod = Yup.object().shape({
    startDate: Yup.date().required().nullable().label("Start Date"),
    startTime: Yup.date().required().nullable().label("Start Time"),
    endDate: Yup.date()
      .label("End Date")
      .min(Yup.ref("startDate"), "End date can't be earlier than start date")
      .required("End date is required")
      .nullable(),
    endTime: Yup.date()
      .label("End Time")
      .required()
      .when(["startDate", "endDate"], (startDate, endDate) => {
        try {
          if (format(endDate, "yyyy-MM-dd") === format(startDate, "yyyy-MM-dd")) {
            return Yup.date()
              .label("End Time")
              .min(
                Yup.ref("startTime"),
                "End time can't be earlier than start time on the same day",
              )
              .required()
              .nullable();
          }
          return Yup.date().label("End Time").required().nullable();
        } catch (err) {
          return Yup.date().label("End Time").required().nullable();
        }
      }),
  });

  const validationSchemaPromoRules = Yup.object().shape({
    type: Yup.string().label("Type").required(),
    discount: Yup.number()
      .required()
      .when("type", {
        is: "percentage",
        then: Yup.number().integer().min(0).max(100).required(),
      })
      .when("type", {
        is: "fixed amount",
        then: Yup.number().min(1).required(),
      })
      .label("Discount"),
    maxCapAmount: Yup.number()
      .label("Maximum cap amount")
      .when("type", {
        is: "percentage",
        then: Yup.number().min(0).required(),
      }),
    maxNoClaims: Yup.number().min(0).required().label("Maximum number of claims"),
    maxNoClaimsPerUser: Yup.number().min(0).required().label("Maximum number of claims per user"),
    minSpentAmount: Yup.number().min(0).required().label("Minimum spent amount"),
    userSpecific: Yup.boolean().label("Users Specific").required(),
    businessSpecific: Yup.boolean().label("Business Specific").required(),
    hourlyBookingSpecific: Yup.boolean().label("Hourly Booking Specific").required(),
    subscriptionPlanSpecific: Yup.boolean().label("Subscription Plan Specific").required(),
    packagePlanSpecific: Yup.boolean().label("Package Plan Specific").required(),
    classSpecific: Yup.boolean().label("Fitness Class Specific").required(),
    usersList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("userSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific User List"),
    businessesList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("businessSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific Business List"),

    subscriptionPlansList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("subscriptionPlanSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific Subscription Plan List"),

    packagePlansList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("packagePlanSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific Package Plan List"),

    classesList: Yup.array()
      .of(Yup.object().shape({ id: Yup.number() }))
      .when("classSpecific", {
        is: true,
        then: Yup.array()
          .of(Yup.object().shape({ id: Yup.number() }))
          .required()
          .min(1),
      })
      .label("Specific Fitness Class List"),
  });

  const yesNoOption = [
    { id: 1, label: "No", value: false },
    { id: 2, label: "Yes", value: true },
  ];

  const onUpdatePromoCodeTitle = (values) => {
    dispatch(
      updatePromoCode({
        ...values,
        promoCodeId,
        params: "title",
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setAllowEditTitle(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onUpdatePromoCodeDescription = (values) => {
    dispatch(
      updatePromoCode({
        ...values,
        promoCodeId,
        params: "description",
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setAllowEditDescription(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onUpdatePromoCodePeriod = (values) => {
    const startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
      new Date(values.startTime),
      "HH:mm:ss",
    )}`;
    const endAt = `${format(new Date(values.endDate), "yyyy-MM-dd")} ${format(
      new Date(values.endTime),
      "HH:mm:ss",
    )}`;

    dispatch(
      updatePromoCode({
        startAt,
        endAt,
        promoCodeId,
        params: "promoPeriod",
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setAllowEditPromoPeriod(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onUpdatePromoCodeDetails = (values) => {
    let usersList = [];
    let businessesList = [];
    let subscriptionPlansList = [];
    let packagePlansList = [];
    let classesList = [];

    if (isUserSpecific) {
      usersList = values.usersList.map((item) => item.id);
    }
    if (isBusinessSpecific) {
      businessesList = values.businessesList.map((item) => item.id);
    }
    if (isSubscriptionPlanSpecific) {
      subscriptionPlansList = values.subscriptionPlansList.map((item) => item.id);
    }
    if (isPackagePlanSpecific) {
      packagePlansList = values.packagePlansList.map((item) => item.id);
    }
    if (isClassSpecific) {
      classesList = values.classesList.map((item) => item.id);
    }
    if (selectedType === "fixed amount") values.maxCapAmount = values.discount;

    dispatch(
      updatePromoCode({
        ...values,
        promoCodeId,
        usersSpecific: isUserSpecific,
        businessSpecific: isBusinessSpecific,
        subscriptionPlanSpecific: isSubscriptionPlanSpecific,
        packagePlanSpecific: isPackagePlanSpecific,
        classSpecific: isClassSpecific,
        usersList,
        businessesList,
        subscriptionPlansList,
        packagePlansList,
        classesList,
        params: "promoRules",
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setAllowEditPromoRules(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getPromoCodeRedemptionList({ promoCodeId, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deletePromoCode(promoCodeId)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.PROMO_CODES_LIST);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPromoCodeDetail(promoCodeId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setSelectedType(payload.data.type.replace("_", " "));
        setIsUserSpecific(payload.data.userSpecific);
        setIsBusinessSpecific(payload.data.businessSpecific);
        setIsSubscriptionPlanSpecific(payload.data.subscriptionPlanSpecific);
        setIsPackagePlanSpecific(payload.data.packagePlanSpecific);
        setIsClassSpecific(payload.data.classSpecific);
      }
    });
    dispatch(getPromoCodeRedemptionList({ promoCodeId, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const getProcessedRecord = () => {
    let records = null;

    if (
      getPromoCodeRedemptionListObj.data &&
      getPromoCodeRedemptionListObj.status === "succeeded"
    ) {
      records = { ...getPromoCodeRedemptionListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (getPromoCodeRedemptionListObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getPromoCodeRedemptionListObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <TableEmptyBox>
                <Text type="TableText">No redemptions</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => <PromoCodeRedemptionRow record={item} key={item.id} />);
    }
    return <PromoCodeRedemptionTableRowLoader />;
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Promo Code"
        label="Are you sure you wish to remove this promo code?"
        isLoading={deletePromoCodeObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Grid
        container
        spacing={5}
        sx={{
          paddingX: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingTop: theme.dimensions.ScreenPaddingY,
        }}
      >
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Text variant="screenLabel">Edit Promo Code</Text>
            <Box sx={{ display: "flex" }}>
              {getPromoCodeDetailObj.status === "succeeded" &&
                getPromoCodeDetailObj.data.deletable && (
                  <>
                    <RedCtaButton width="100px" onClickButton={() => setShowDeleteModal(true)}>
                      <Text type="WhiteColor">Delete</Text>
                    </RedCtaButton>
                    <Spacer position="left" size="l" />
                  </>
                )}

              <Button
                onClick={() => history.push(routes.PROMO_CODES_LIST)}
                sx={{
                  textTransform: "none",
                  padding: "0px",
                  ":hover": { backgroundColor: "transparent" },
                }}
              >
                <Text>Back</Text>
              </Button>
            </Box>
          </SpaceBetweenBox>
        </Grid>
      </Grid>

      {getPromoCodeDetailObj.status === "succeeded" && (
        <>
          <Formik
            validationSchema={validationSchemaTitle}
            onSubmit={onUpdatePromoCodeTitle}
            initialValues={{
              title: getPromoCodeDetailObj.data.title,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Code</Text>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingBottom: "50px" }}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Code</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Code</Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <StyledTextField
                                  disabled
                                  maxLength={6}
                                  value={getPromoCodeDetailObj.data.code}
                                  width="100%"
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Title</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditTitle ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditTitle(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditTitle(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <FormFieldLabel
                            name="title"
                            placeholder="Enter Promo Code title"
                            label="Promo Code Title"
                            disabled={!allowEditTitle}
                          />
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Formik
            validationSchema={validationSchemaDescription}
            onSubmit={onUpdatePromoCodeDescription}
            initialValues={{
              description: getPromoCodeDetailObj.data.description,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Description</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditDescription ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => setAllowEditDescription(false)}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditDescription(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Description
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Description
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormFieldTextMultiline
                                  placeholder="Enter promo code description"
                                  name="description"
                                  rows={5}
                                  disabled={!allowEditDescription}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Formik
            validationSchema={validationSchemaPromoPeriod}
            onSubmit={onUpdatePromoCodePeriod}
            initialValues={{
              startDate: getPromoCodeDetailObj.data.startAt,
              endDate: getPromoCodeDetailObj.data.endAt,
              startTime: getPromoCodeDetailObj.data.startAt,
              endTime: getPromoCodeDetailObj.data.endAt,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2.5}>
                          <Text variant="screenLabel">Promo Period</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditPromoPeriod ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => setAllowEditPromoPeriod(false)}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditPromoPeriod(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid container item xs={12} spacing={3}>
                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Start Date
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Start Date
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormDatePicker
                                    name="startDate"
                                    width="100%"
                                    maxDays={null}
                                    disabled={!allowEditPromoPeriod}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  End Date
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    End Date
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormDatePicker
                                    name="endDate"
                                    width="100%"
                                    maxDays={null}
                                    disabled={!allowEditPromoPeriod}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Start Time
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Start Time
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormTimePicker
                                    name="startTime"
                                    width="100%"
                                    disabled={!allowEditPromoPeriod}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  End Time
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    End Time
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormTimePicker
                                    name="endTime"
                                    width="100%"
                                    disabled={!allowEditPromoPeriod}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Formik
            validationSchema={validationSchemaPromoRules}
            onSubmit={onUpdatePromoCodeDetails}
            initialValues={{
              type: getPromoCodeDetailObj.data.type.replace("_", " "),
              discount: parseFloat(getPromoCodeDetailObj.data.discount.replace(",", "")),
              maxCapAmount: parseFloat(
                getPromoCodeDetailObj.data.maxCapAmount.replace(",", ""),
              ).toFixed(2),
              maxNoClaims: getPromoCodeDetailObj.data.maxNoClaims,
              maxNoClaimsPerUser: getPromoCodeDetailObj.data.maxNoClaimsPerUser,
              minSpentAmount: parseFloat(
                getPromoCodeDetailObj.data.minSpentAmount.replace(",", ""),
              ).toFixed(2),
              userSpecific: getPromoCodeDetailObj.data.userSpecific,
              businessSpecific: getPromoCodeDetailObj.data.businessSpecific,
              hourlyBookingSpecific: getPromoCodeDetailObj.data.hourlyBookingSpecific,
              subscriptionPlanSpecific: getPromoCodeDetailObj.data.subscriptionPlanSpecific,
              packagePlanSpecific: getPromoCodeDetailObj.data.packagePlanSpecific,
              classSpecific: getPromoCodeDetailObj.data.classSpecific,
              usersList: getPromoCodeDetailObj.data.usersList,
              businessesList: getPromoCodeDetailObj.data.businessesList,
              subscriptionPlansList: getPromoCodeDetailObj.data.subscriptionPlansList,
              packagePlansList: getPromoCodeDetailObj.data.packagePlansList,
              classesList: getPromoCodeDetailObj.data.classesList,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2.5}>
                          <Text variant="screenLabel">Promo Details</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditPromoRules ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => setAllowEditPromoRules(false)}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditPromoRules(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid container item xs={12} spacing={3}>
                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Type</Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Type
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormSelect
                                    itemList={[
                                      { label: "percentage", id: "1", value: "percentage" },
                                      { label: "fixed amount", id: "2", value: "fixed amount" },
                                    ]}
                                    name="type"
                                    placeholder="Select Type"
                                    setFormVariable={setSelectedType}
                                    disabled={!allowEditPromoRules}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Discount {selectedType === "percentage" && "%"}
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Discount {selectedType === "percentage" && "%"}
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  {selectedType === "percentage" ? (
                                    <FormFieldText
                                      name="discount"
                                      placeholder="Percentage"
                                      type="number"
                                      disabled={!allowEditPromoRules}
                                    />
                                  ) : (
                                    <FormPrice
                                      placeholder="00.00"
                                      name="discount"
                                      disabled={!allowEditPromoRules}
                                    />
                                  )}
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Max Cap Amount
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Max Cap Amount
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormPrice
                                    placeholder="00.00"
                                    name="maxCapAmount"
                                    disabled={
                                      !allowEditPromoRules || selectedType === "fixed amount"
                                    }
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Max No. Claims
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Max No. Claims
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormFieldText
                                    name="maxNoClaims"
                                    placeholder="Max no of claims"
                                    type="number"
                                    disabled={!allowEditPromoRules}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Max No. Claims Per User
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Max No. Claims Per User
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormFieldText
                                    name="maxNoClaimsPerUser"
                                    placeholder="Max no of claims per user"
                                    type="number"
                                    disabled={!allowEditPromoRules}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Min Spent Amount
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Min Spent Amount
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormPrice
                                    placeholder="Minimum spent amount"
                                    name="minSpentAmount"
                                    disabled={!allowEditPromoRules}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item container xs={12} spacing={3}>
                            <Grid item xs={3}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Hourly Booking Specific
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      Hourly Booking Specific
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <FormSelect
                                      itemList={yesNoOption}
                                      name="hourlyBookingSpecific"
                                      placeholder="For specific hourly booking"
                                      disabled={!allowEditPromoRules}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>
                          </Grid>

                          <Grid item container xs={12} spacing={3}>
                            <Grid item xs={3}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    User Specific
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      User Specific
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <FormSelect
                                      itemList={yesNoOption}
                                      name="userSpecific"
                                      placeholder="For specific user"
                                      setFormVariable={(value) => setIsUserSpecific(value)}
                                      disabled={!allowEditPromoRules}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>

                            <Grid item xs={9}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    User List
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      User List
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <UserListPhoneNumber
                                      name="usersList"
                                      placeholder="User's phone number"
                                      disabled={!isUserSpecific || !allowEditPromoRules}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>
                          </Grid>

                          <Grid item container xs={12} spacing={3}>
                            <Grid item xs={3}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Business Specific
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      Business Specific
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <FormSelect
                                      disabled={!allowEditPromoRules}
                                      itemList={yesNoOption}
                                      name="businessSpecific"
                                      placeholder="For specific business"
                                      setFormVariable={(value) => setIsBusinessSpecific(value)}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>

                            <Grid item xs={9}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Business List
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      Business List
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <BusinessListAutoComplete
                                      name="businessesList"
                                      placeholder="Businesses"
                                      disabled={!isBusinessSpecific || !allowEditPromoRules}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>
                          </Grid>

                          <Grid item container xs={12} spacing={3}>
                            <Grid item xs={3}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Subscription Plan Specific
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      Subscription Plan Specific
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <FormSelect
                                      disabled={!allowEditPromoRules}
                                      itemList={yesNoOption}
                                      name="subscriptionPlanSpecific"
                                      placeholder="For specific subscription plan"
                                      setFormVariable={(value) =>
                                        setIsSubscriptionPlanSpecific(value)
                                      }
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>

                            <Grid item xs={9}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Subscription Plan List
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      Subscription Plan List
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <SubscriptionPlansListAutoComplete
                                      name="subscriptionPlansList"
                                      placeholder="Subscription Plans"
                                      disabled={!isSubscriptionPlanSpecific || !allowEditPromoRules}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>
                          </Grid>

                          <Grid item container xs={12} spacing={3}>
                            <Grid item xs={3}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Package Plan Specific
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      Package Plan Specific
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <FormSelect
                                      disabled={!allowEditPromoRules}
                                      itemList={yesNoOption}
                                      name="packagePlanSpecific"
                                      placeholder="For specific package plan"
                                      setFormVariable={(value) => setIsPackagePlanSpecific(value)}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>

                            <Grid item xs={9}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Package Plan List
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      Package Plan List
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <PackagePlansListAutoComplete
                                      name="packagePlansList"
                                      placeholder="Package Plans"
                                      disabled={!isPackagePlanSpecific || !allowEditPromoRules}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>
                          </Grid>

                          <Grid item container xs={12} spacing={3}>
                            <Grid item xs={3}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Fitness Class Specific
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      Fitness Class Specific
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <FormSelect
                                      disabled={!allowEditPromoRules}
                                      itemList={yesNoOption}
                                      name="classSpecific"
                                      placeholder="For specific fitness class"
                                      setFormVariable={(value) => setIsClassSpecific(value)}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>

                            <Grid item xs={9}>
                              {isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Fitness Class List
                                  </Text>
                                </LabelContainer>
                              )}
                              <FormContainer>
                                {!isMobile && (
                                  <LabelContainer>
                                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                      Fitness Class List
                                    </Text>
                                  </LabelContainer>
                                )}
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <ClassListAutoComplete
                                      name="classesList"
                                      placeholder="Fitness Classes"
                                      disabled={!isClassSpecific || !allowEditPromoRules}
                                    />
                                  </Box>
                                </Box>
                              </FormContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}

      <Grid item container xs={12}>
        <WhiteBgCardContainer>
          <Grid item xs={12}>
            <Text variant="screenLabel">Redemption List</Text>
          </Grid>
          <Spacer size="l" />
          <TableWrapper>
            <Table>
              <TableHeader
                headerCells={[
                  "Redemption ID",
                  "User Id",
                  "Username",
                  "Status",
                  "Date Redeemed",
                  "Discount Amount",
                  "Purchase Item",
                ]}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                columnMapping={columnMapping}
              />
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableWrapper>

          <Grid item xs={12}>
            <CustomFooter>
              <Pagination
                sx={{
                  "&& .Mui-selected": {
                    backgroundColor: theme.palette.colors.brand.primary,
                    color: theme.palette.colors.text.white,
                  },
                }}
                page={page}
                shape="rounded"
                onChange={onPageChange}
                count={getPromoCodeRedemptionListObj?.data?.pagination.totalPages}
                variant="outlined"
              />
            </CustomFooter>
          </Grid>
        </WhiteBgCardContainer>
      </Grid>
    </Grid>
  );
}
