import { useState } from "react";

function TableSort(columnMapping) {
  const [sortConfig, setSortConfig] = useState({ column: null, order: "asc" });

  const onSortChange = (column) => {
    const dataKey = columnMapping[column];
    if (dataKey) {
      setSortConfig((prevSortConfig) => {
        if (prevSortConfig.column === dataKey) {
          return { ...prevSortConfig, order: prevSortConfig.order === "asc" ? "desc" : "asc" };
        }
        return { column: dataKey, order: "asc" };
      });
    }
  };

  return { sortColumn: sortConfig.column, sortOrder: sortConfig.order, onSortChange };
}

export default TableSort;
