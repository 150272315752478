import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../../api-services";

export const getUserHourlyBookings = createAsyncThunk(
  "merchants/users/{user_Id}/hourly_bookings",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/users/${payload.userId}/hourly_bookings?page=${payload.page}`,
    );
    return response;
  },
);

const hourlyBookingUserSlice = createSlice({
  name: "hourlyBookingUser",
  initialState: {
    getUserHourlyBookingsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getUserHourlyBookings.pending]: (state) => {
      state.getUserHourlyBookingsObj.status = "pending";
    },
    [getUserHourlyBookings.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserHourlyBookingsObj.status = "succeeded";
      state.getUserHourlyBookingsObj.data = data;
      state.getUserHourlyBookingsObj.successMessage = message;
    },
    [getUserHourlyBookings.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserHourlyBookingsObj.status = "failed";
      state.getUserHourlyBookingsObj.errorMessage = message;
    },
  },
});

export default hourlyBookingUserSlice.reducer;

// state
export const hourlyBookingUserSelector = (state) => state.hourlyBookingUser;
