import React from "react";
import PropTypes from "prop-types";
import { QrReader } from "react-qr-reader";
import { useFormikContext } from "formik";
import { Box, Button, Modal } from "@mui/material";

function QRCodeScanner({ onScanSuccess }) {
  const { setFieldValue } = useFormikContext();

  function handleScanSuccess(data) {
    if (data) {
      const userId = data.text ?? null;
      setFieldValue("q", userId);
      onScanSuccess();
    }
  }

  return (
    <QrReader
      scanDelay={1000}
      onResult={(result) => {
        if (result) {
          handleScanSuccess(result);
        }
      }}
      constraints={{ facingMode: "environment" }}
    />
  );
}

QRCodeScanner.propTypes = {
  onScanSuccess: PropTypes.func.isRequired,
};

export default function QrCodeScannerModal({ isQrScannerOpen, closeQrScannerModal }) {
  return (
    <Modal open={isQrScannerOpen} onClose={closeQrScannerModal}>
      {isQrScannerOpen ? (
        <Box
          sx={{
            width: "100%",
            maxWidth: "500px",
            margin: "auto",
            padding: "16px",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <QRCodeScanner onScanSuccess={closeQrScannerModal} />
          <Button onClick={closeQrScannerModal}>Close</Button>
        </Box>
      ) : (
        <></>
      )}
    </Modal>
  );
}

QrCodeScannerModal.propTypes = {
  isQrScannerOpen: PropTypes.bool.isRequired,
  closeQrScannerModal: PropTypes.func.isRequired,
};
