import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getClassCategoryList = createAsyncThunk("merchants/class/category/list", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/class_categories`,
  );
  return response;
});

export const createEvent = createAsyncThunk("merchants/events/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events`,
    payload,
  );
  return response;
});

export const updateEvent = createAsyncThunk("merchants/events/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${payload.eventId}`,
    payload,
  );
  return response;
});

export const searchEvent = createAsyncThunk("merchants/events/full_list", async (payload) => {
  const { q, categoryIds, businessIds, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/?page=${page}${
      q ? "&q=".concat(q) : ""
    }${categoryIds ? "&categoryIds=".concat(categoryIds) : ""}${
      businessIds ? "&businessIds=".concat(businessIds) : ""
    }`,
  );
  return response;
});

export const getEventDetail = createAsyncThunk("merchants/events/detail", async (payload) => {
  const { eventId } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${eventId}`,
  );
  return response;
});

export const deleteEvent = createAsyncThunk(
  "merchants/events/delete/{event_id}",
  async (payload) => {
    const { eventId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/${eventId}`,
    );
    return response;
  },
);

const eventSlice = createSlice({
  name: "event",
  initialState: {
    getClassCategoryListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createEventObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchEventObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateEventObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteEventObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getClassCategoryList.pending]: (state) => {
      state.getClassCategoryListObj.status = "pending";
    },
    [getClassCategoryList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getClassCategoryListObj.status = "succeeded";
      state.getClassCategoryListObj.data = data;
      state.getClassCategoryListObj.successMessage = message;
    },
    [getClassCategoryList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getClassCategoryListObj.status = "failed";
      state.getClassCategoryListObj.errorMessage = message;
    },
    [createEvent.pending]: (state) => {
      state.createEventObj.status = "pending";
    },
    [createEvent.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createEventObj.status = "succeeded";
      state.createEventObj.data = data;
      state.createEventObj.successMessage = message;

      state.searchEventObj.data = data;
    },
    [createEvent.rejected]: (state, action) => {
      const { message } = action.error;

      state.createEventObj.status = "failed";
      state.createEventObj.errorMessage = message;
    },
    [searchEvent.pending]: (state) => {
      state.searchEventObj.status = "pending";
    },
    [searchEvent.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchEventObj.status = "succeeded";
      state.searchEventObj.data = data;
      state.searchEventObj.successMessage = message;
    },
    [searchEvent.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchEventObj.status = "failed";
      state.searchEventObj.errorMessage = message;
    },
    [updateEvent.pending]: (state) => {
      state.updateEventObj.status = "pending";
    },
    [updateEvent.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateEventObj.status = "succeeded";
      state.updateEventObj.data = data;
      state.updateEventObj.successMessage = message;
    },
    [updateEvent.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateEventObj.status = "failed";
      state.updateEventObj.errorMessage = message;
    },

    [getEventDetail.pending]: (state) => {
      state.getEventDetailObj.status = "pending";
    },
    [getEventDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventDetailObj.status = "succeeded";
      state.getEventDetailObj.data = data;
      state.getEventDetailObj.successMessage = message;
    },
    [getEventDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventDetailObj.status = "failed";
      state.getEventDetailObj.errorMessage = message;
    },
    [deleteEvent.pending]: (state) => {
      state.deleteEventObj.status = "pending";
    },
    [deleteEvent.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteEventObj.status = "succeeded";
      state.deleteEventObj.data = data;
      state.deleteEventObj.successMessage = message;
    },
    [deleteEvent.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteEventObj.status = "failed";
      state.deleteEventObj.errorMessage = message;
    },
  },
});

export default eventSlice.reducer;

// state
export const eventSelector = (state) => state.event;
