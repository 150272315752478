import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import moment from "moment";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import {
  calendarSelector,
  getCalendarDetail,
} from "../../../services/calendar/calendar.slice.services";
import CalendarSessionModal from "../components/calendar-session-modal.component";
import SessionAttendeesModal from "../components/session-attendees-modal.component";
import StaffListAutoComplete from "../../profile/components/staff-list-autocomplete.component";
import BusinessListAutoComplete from "../../fitness/components/business-list-autocomplete.component";
import SearchBar from "../../business/components/search-bar.component";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  businesses: Yup.array().label("Businesses").nullable(),
  staffs: Yup.array().label("Staffs").nullable(),
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
  padding: "20px",
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const CalendarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",

  ".fc-button-primary": {
    border: `1px solid ${theme.palette.colors.brand.primary}`,
    backgroundColor: theme.palette.colors.brand.primary,
    boxShadow: "none",
    ":hover, :active, :disabled, :focus": {
      backgroundColor: theme.palette.colors.brand.primary,
      border: `1px solid ${theme.palette.colors.brand.primary}`,
      boxShadow: "none",
      ":focus": {
        backgroundColor: theme.palette.colors.brand.primary,
        border: `1px solid ${theme.palette.colors.brand.primary}`,
        boxShadow: "none",
      },
    },
  },
  ".fc-highlight": {
    backgroundColor: theme.palette.colors.brand.secondary,
    border: `1px solid ${theme.palette.colors.brand.primary}`,
  },
  "& .MuiOutlinedInput-root": {
    " &.Mui-focused fieldset": {
      borderColor: theme.palette.colors.brand.secondary,
    },
  },
  "& .MuiSelect-iconOutlined": {
    color: theme.palette.colors.brand.secondary,
  },
}));

export default function CalendarScreen() {
  const theme = useTheme();
  const formRef = useRef();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [calendarSession, setCalendarSession] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showSessionAttendeesModal, setShowSessionAttendeesModal] = useState(false);
  const [q, setQ] = useState("");
  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [selectedStaffList, setSelectedStaffList] = useState([]);
  const [currentCalendar, setCurrentCalendar] = useState({
    startDate: moment(new Date()).startOf("month").format("YYYY-MM-DD 00:00:00"),
    endDate: moment(new Date()).endOf("month").format("YYYY-MM-DD 23:59:59"),
  });
  const [selectedDateEvents, setSelectedDateEvents] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const { getCalendarDetailObj } = useSelector(calendarSelector);

  const getCalendarSession = (data) => {
    const sessions = [];

    Object.keys(data).map((date, index) =>
      data[date].slice(0, 2).map((session) => {
        let sessionInfo = session.session.identifier;
        if (session.session.identifier.length > 15) {
          sessionInfo = `${session.session.identifier.slice(0, isSmallScreen ? 4 : 15)}...`;
        }
        sessionInfo = `${sessionInfo}(${session.session.currentCapacity}/${
          session.session.maxCapacity === 0 ? "∞" : session.session.maxCapacity
        })`;
        return sessions.push({
          start: date,
          end: date,
          id: index,
          title: sessionInfo,
          type: session.type,
        });
      }),
    );

    Object.keys(data).map((date) => {
      if (data[date].length > 2) {
        return sessions.push({
          start: date,
          end: date,
          id: 99,
          title: `+${data[date].length - 2} more`,
        });
      }
      return [];
    });
    setCalendarSession(sessions);
  };

  const loadCalenderData = (start) => {
    setIsLoading(true);
    setCurrentCalendar({
      startDate: moment(new Date(start)).startOf("month").format("YYYY-MM-DD 00:00:00"),
      endDate: moment(new Date(start)).endOf("month").format("YYYY-MM-DD 23:59:59"),
    });
    dispatch(
      getCalendarDetail({
        q,
        businessId: selectedBusinessList,
        staffId: selectedStaffList,
        startDate: moment(new Date(start)).startOf("month").format("YYYY-MM-DD 00:00:00"),
        endDate: moment(new Date(start)).endOf("month").format("YYYY-MM-DD 23:59:59"),
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        getCalendarSession(payload.data);
      }

      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onChangeBusiness = (value) => {
    const username = value.q;
    const businessId = value.businesses.map((item) => item.id);
    const staffId = value.staffs.map((item) => item.id);
    setIsLoading(true);
    setQ(username);
    setSelectedBusinessList(businessId);
    setSelectedStaffList(staffId);

    dispatch(
      getCalendarDetail({
        q: username,
        businessId,
        staffId,
        ...currentCalendar,
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        getCalendarSession(payload.data);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleMonthChange = async (payload) => {
    if (currentStartDate !== payload.startStr) {
      setCurrentStartDate(payload.startStr);
      loadCalenderData(new Date(payload.startStr));
    }
  };

  const onChangeDate = (date) => {
    setSelectedDateEvents(getCalendarDetailObj.data[date]);
    setSelectedDate(date);
    setShowDetailModal(true);
  };

  const onClickSession = (session) => {
    setSelectedSession(session);
    setShowSessionAttendeesModal(true);
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        q: q || "",
        businesses: [],
        staffs: [],
      }}
      onSubmit={onChangeBusiness}
      validationSchema={validationSchema}
    >
      <Grid
        container
        spacing={3}
        sx={{
          overflowX: "auto",
          paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        {showDetailModal && (
          <CalendarSessionModal
            events={selectedDateEvents}
            selectedDate={selectedDate}
            showModal={showDetailModal}
            setShowModal={setShowDetailModal}
            onClickSession={onClickSession}
          />
        )}
        {showSessionAttendeesModal && (
          <SessionAttendeesModal
            selectedSession={selectedSession}
            showModal={showSessionAttendeesModal}
            setShowModal={setShowSessionAttendeesModal}
          />
        )}
        <BackdropLoading isLoading={isLoading} />
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Text variant="screenLabel">Calendar</Text>
          </Grid>

          <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormContainer>
                      <Grid item xs={1.45}>
                        <Text>User name</Text>
                      </Grid>
                      <Grid item xs={10.55}>
                        <SearchBar placeholder="Search by user name" name="q" />
                      </Grid>
                    </FormContainer>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <FormContainer>
                      <Grid item xs={3}>
                        <Text>Businesses</Text>
                      </Grid>

                      <Grid item xs={9}>
                        <BusinessListAutoComplete
                          name="businesses"
                          placeholder="Enter business name"
                        />
                      </Grid>
                    </FormContainer>
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <FormContainer>
                      <Grid item xs={3}>
                        <Text>Staffs</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <StaffListAutoComplete
                          name="staffs"
                          placeholder="Enter staff name"
                          isWhiteBg
                        />
                      </Grid>
                    </FormContainer>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                  <Grid item xs={12} sm={2}>
                    <FormSubmitButton>
                      <Text type="WhiteColor">Search</Text>
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SearchContainer>
        </Grid>
        <Grid item xs={12}>
          <CalendarContainer>
            <FullCalendar
              aspectRatio={isSmallScreen ? 3 / 4 : 2}
              showNonCurrentDates={false}
              headerToolbar={{ left: "title", center: "", right: "prev,next" }}
              datesSet={(i) => handleMonthChange(i)}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={calendarSession}
              eventOrder="-title"
              eventBackgroundColor="transparent"
              eventTextColor={theme.palette.colors.brand.primary}
              eventBorderColor="transparent"
              eventClick={(i) => onChangeDate(format(new Date(i.event.startStr), "yyyy-MM-dd"))}
              select={(i) => onChangeDate(format(new Date(i.startStr), "yyyy-MM-dd"))}
              selectable={true}
              validRange={{
                start: "2022-04-01",
              }}
              firstDay={1}
            />
          </CalendarContainer>
        </Grid>
      </Grid>
    </Formik>
  );
}
