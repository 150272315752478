import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { createEntries, getEntryLogs } from "../../../services/footfall/footfall-slice.service";
import { getUsers, userSelector } from "../../../services/user/user-slice.service";
import BusinessListAutoCompleteSingleSelect from "./business-list-autocomplete-single-select.component";
import { checkAttendance } from "../../../services/event/booking/booking.slice.services";
import QrCodeScannerModal from "../../../components/modal/qr-code-scanner-modal.component";
import FormFieldTextWithCustomisedAdornments from "../../../components/forms/form-field-text-with-customised-adornments.component";
import { getGuests } from "../../../services/guest/guest-slice.service";
import AvatarLogo from "../../../images/avatar_placeholder.png";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  padding: "30px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxWidth: isMobile ? "" : "1100px",
  width: isMobile ? "100%" : "90%",
  height: isMobile ? "100%" : "92%",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  overflowY: "auto",
}));

const validationSchema = Yup.object().shape({
  q: Yup.string().required().label("Keyword"),
  businesses: Yup.object().required().label("Business").nullable(),
});

export default function CheckBusinessAccessModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("760"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getUsersObj } = useSelector(userSelector);
  const [userInfo, setUserInfo] = useState(null);
  const [isGuest, setIsGuest] = useState(false);
  const [businessId, setBusinessId] = useState(null);
  const [isQrScannerOpen, setIsQrScannerOpen] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
    if (userInfo)
      dispatch(
        getEntryLogs({
          startAt: format(new Date(), "yyyy-MM-dd 00:00:00"),
          endAt: format(new Date(), "yyyy-MM-dd 23:59:59"),
          page: 1,
        }),
      );
    setUserInfo(null);
    setIsGuest(false);
  };

  const onSubmit = (values, { resetForm }) => {
    setBusinessId(values.businesses.id);
    const payloadValues = { ...values, page: 1 };

    let publicIDFromGuest = false;
    let theAPI = getUsers;

    if (payloadValues.q.startsWith("GUEST-")) {
      publicIDFromGuest = true;
      theAPI = getGuests;
    }

    setIsGuest(publicIDFromGuest);

    dispatch(theAPI(payloadValues)).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setUserInfo(payload.data.items[0]);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        resetForm({ values: { businesses: values.businesses, q: "" } });
      }

      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (userInfo) {
      return (
        <Grid
          container
          display="flex"
          justifyContent={isMobile ? "center" : "flex-start"}
          sx={{ marginTop: "10px" }}
          spacing={2}
        >
          <Grid item xs={isMobile ? 6 : 1.5}>
            <img
              alt="userImage"
              src={userInfo.user.imagePath ? userInfo.user.imagePath : AvatarLogo}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                borderRadius: theme.shape.borderRadius[1],
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 5}>
            <Box
              sx={{
                padding: "10px",
                borderRadius: `${theme.shape.borderRadius[1]}px`,
                backgroundColor: theme.palette.colors.brand.linear,
                height: "100%",
              }}
            >
              <Text type="WhiteColor" variant="h6">
                User Details
              </Text>
              <Spacer />
              <Text type="WhiteColor">
                Name: {userInfo.user.firstName} {userInfo.user.lastName}
              </Text>
              <Text type="WhiteColor">Email: {userInfo.user.email}</Text>
              <Text type="WhiteColor">Phone: {userInfo.user.phoneNumber}</Text>
            </Box>
          </Grid>
          <Grid item xs={isMobile ? 12 : 5.5}>
            <Box
              sx={{
                padding: "10px",
                borderRadius: `${theme.shape.borderRadius[1]}px`,
                backgroundColor:
                  userInfo.user.grantedBusinessAccesses.length === 0
                    ? theme.palette.colors.ui.errorBox
                    : theme.palette.colors.brand.linear,
                height: "100%",
              }}
            >
              <Text type="WhiteColor" variant="h6">
                Business Access
              </Text>
              <Spacer />
              {userInfo.user.grantedBusinessAccesses.map((item, index) => (
                <Text type="WhiteColor" key={item.id}>
                  {index + 1}. {item.name}
                </Text>
              ))}
              {userInfo.user.grantedBusinessAccesses.length === 0 && (
                <Text type="WhiteColor">No Access</Text>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Text variant="h6">Hourly Booking</Text>
            <Spacer />
            {userInfo.hourlyBookings.filter((item) => item.status === "active").length > 0 ? (
              <List sx={{ listStyleType: "numeric", padding: 0, pl: 3 }}>
                {userInfo.hourlyBookings
                  .filter((item) => item.status === "active")
                  .map((item) => (
                    <ListItem
                      key={uuidv4()}
                      sx={{ display: "list-item", padding: 0, paddingBottom: "5px" }}
                    >
                      <Text>Date: {item.date}</Text>
                      <Text>Time: {item.time}</Text>
                      <Text>Business: {item.location.name}</Text>
                    </ListItem>
                  ))}
              </List>
            ) : (
              <Grid item sx={{ pl: 1 }}>
                <Text>No Active Hourly Bookings</Text>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Text variant="h6">Classes</Text>
            <Spacer />
            {userInfo.classes.filter((item) => item.status === "active").length > 0 ? (
              <List sx={{ listStyleType: "numeric", padding: 0, pl: 3 }}>
                {userInfo.classes
                  .filter((item) => item.status === "active")
                  .map((item) => (
                    <ListItem
                      key={uuidv4()}
                      sx={{ display: "list-item", padding: 0, paddingBottom: "10px" }}
                    >
                      <Text>Date: {item.date}</Text>
                      <Text>Time: {item.time}</Text>
                      <Text>Class Name: {item.session.class.name}</Text>
                      <Text>Business: {item.location.name}</Text>
                    </ListItem>
                  ))}
              </List>
            ) : (
              <Grid item sx={{ pl: 1 }}>
                <Text>No Active Classes</Text>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Text variant="h6">Subscriptions</Text>
            <Spacer />
            {userInfo.subscriptions.filter((item) => item.status === "active").length > 0 ? (
              <List sx={{ listStyleType: "numeric", padding: 0, pl: 3 }}>
                {userInfo.subscriptions
                  .filter((item) => item.status === "active")
                  .map((item) => (
                    <ListItem
                      key={uuidv4()}
                      sx={{ display: "list-item", padding: 0, paddingBottom: "10px" }}
                    >
                      <Text>Start At: {item.start_at}</Text>
                      <Text>End At: {item.end_at}</Text>
                      <Text>Plan: {item.plan.title}</Text>
                      <Grid container>
                        <Text>Businesses: </Text>
                        <List sx={{ listStyleType: "disc", padding: 0, pl: 3 }}>
                          {item.plan.businesses.map((business) => (
                            <ListItem
                              key={uuidv4()}
                              sx={{ display: "list-item", padding: 0, paddingBottom: "5px" }}
                            >
                              {business}
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </ListItem>
                  ))}
              </List>
            ) : (
              <Grid item sx={{ pl: 1 }}>
                <Text>No Active Subscriptions</Text>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Text variant="h6">Events</Text>
            <Spacer />

            {userInfo.events.filter((item) => item.status === "active").length > 0 ? (
              <List
                sx={{
                  listStyleType: "numeric",
                  padding: 0,
                  pl: 3,
                }}
              >
                {userInfo.events
                  .filter((item) => item.status === "active")
                  .map((item) => (
                    <ListItem
                      key={uuidv4()}
                      sx={{
                        display: "list-item",
                        padding: 0,
                        paddingBottom: "10px",
                      }}
                    >
                      <Text>Date: {item.date}</Text>
                      <Text>Time: {item.time}</Text>
                      <Text>Event Name: {item.event.title}</Text>
                      <Text>Business: {item.location.name}</Text>
                      {item.sessionCategories.length !== 0 && <Text>Session Categories:</Text>}
                      {item.sessionCategories.map((category) => (
                        <List
                          key={uuidv4()}
                          sx={{
                            listStyleType: "disc",
                            padding: 0,
                            pl: 3,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <ListItem
                            key={uuidv4()}
                            sx={{
                              display: "list-item",
                              padding: 0,
                              paddingBottom: "10px",
                            }}
                          >
                            <Text>{category.label}</Text>
                            <Text>Time: {category.time}</Text>
                            <Text>Date: {category.date}</Text>
                          </ListItem>
                        </List>
                      ))}
                    </ListItem>
                  ))}
              </List>
            ) : (
              <Grid item sx={{ pl: 1 }}>
                <Text>No Active Events</Text>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    }
    if (getUsersObj.status === "pending") {
      return (
        <>
          <Spacer />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
          <SkeletonLoader height="50px" width="100%" />
        </>
      );
    }

    return false;
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(createEntries({ businessId, userId: userInfo?.user.id, isGuest })).then(
        ({ meta, payload, error }) => {
          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: error.message,
              type: "error",
            });
          }
          if (meta.requestStatus === "fulfilled") {
            createSnackBar({
              message: payload.message,
              type: "success",
            });
          }
        },
      );
      if (userInfo.user.grantedBusinessAccesses.map((item) => item.id === businessId)) {
        let eventsFiltered = userInfo.events.filter((event) => event.status === "active");
        eventsFiltered = eventsFiltered.filter((event) => event.location.id === businessId);
        eventsFiltered.map((item) =>
          dispatch(
            checkAttendance({ q: userInfo.user.public_id, sessionId: item.sessionId, isGuest }),
          ).then(({ meta, payload, error }) => {
            if (meta.requestStatus === "fulfilled") {
              createSnackBar({
                message: payload.message,
                type: "success",
              });
            }
            if (meta.requestStatus === "rejected") {
              createSnackBar({
                message: error.message,
                type: "error",
              });
            }
          }),
        );
      }
    }
  }, [userInfo]);

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Form
          initialValues={{ q: "", businesses: null }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Grid container spacing={2}>
            <Grid item container xs={12} position="relative" alignItems="center">
              <Grid item xs={12}>
                <Text variant="h5" textAlign="center">
                  Check Access
                </Text>
                <Spacer size="m" />
              </Grid>
              <Grid container item justifyContent="flex-end" position="absolute">
                <IconButton onClick={handleCancel}>
                  <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container item xs={12} display="flex" alignItems="center" spacing={2}>
              <Grid item xs={isMobile ? 12 : 2.3}>
                <Text>Business:</Text>
              </Grid>
              <Grid item xs={isMobile ? 12 : 9.7}>
                <BusinessListAutoCompleteSingleSelect name="businesses" placeholder="Business" />
              </Grid>
            </Grid>
            <Grid container item xs={12} display="flex" alignItems="center" spacing={2}>
              <Grid item xs={isMobile ? 12 : 2.3}>
                <Text>User Public Id / Phone:</Text>
              </Grid>
              <Grid item xs={isMobile ? 12 : 9.7}>
                <FormFieldTextWithCustomisedAdornments
                  name="q"
                  placeholder="Please scan user's QR code / type in phone number"
                  endAdornment={
                    <IconButton onClick={() => setIsQrScannerOpen(true)}>
                      <QrCodeScannerIcon />
                    </IconButton>
                  }
                />
              </Grid>
            </Grid>

            <Grid container item display="flex" justifyContent="end">
              <Grid item xs={isMobile ? 12 : 2}>
                <Spacer />
                <FormSubmitButton isLoading={getUsersObj.status === "pending"}>
                  <Text type="WhiteColor">Check</Text>
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Grid>
          <QrCodeScannerModal
            isQrScannerOpen={isQrScannerOpen}
            closeQrScannerModal={() => setIsQrScannerOpen(false)}
          />
        </Form>
        {renderContent()}
      </ModalBox>
    </Modal>
  );
}

CheckBusinessAccessModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
