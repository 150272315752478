import React from "react";
import { styled, Typography } from "@mui/material";
import PropTypes from "prop-types";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const YellowColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.yellow,
}));

const GreenColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.ui.greenDot,
}));

const BlueColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.ui.blue,
}));

const RedColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.rejected,
}));

const BrandColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const SecondaryColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const TableText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.table,
}));

function Text({ type, children, ...props }) {
  if (type === "BrandColor") {
    return <BrandColor {...props}>{children}</BrandColor>;
  }
  if (type === "WhiteColor") {
    return <WhiteColorText {...props}>{children}</WhiteColorText>;
  }
  if (type === "YellowColor") {
    return <YellowColorText {...props}>{children}</YellowColorText>;
  }
  if (type === "GreenColor") {
    return <GreenColorText {...props}>{children}</GreenColorText>;
  }
  if (type === "BlueColor") {
    return <BlueColorText {...props}>{children}</BlueColorText>;
  }
  if (type === "RedColor") {
    return <RedColorText {...props}>{children}</RedColorText>;
  }
  if (type === "GreyColor") {
    return <SecondaryColor {...props}>{children}</SecondaryColor>;
  }
  if (type === "TableText") {
    return <TableText {...props}>{children}</TableText>;
  }
  return <PrimaryColorText {...props}>{children}</PrimaryColorText>;
}

Text.defaultProps = {
  type: "",
  children: null,
};

Text.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};

export default Text;
