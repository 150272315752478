import React from "react";
import PropTypes from "prop-types";
import { FormGroup, FormControlLabel, useTheme } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";

function MiniCheckBox({ label, name }) {
  const theme = useTheme();
  const { setFieldValue, values } = useFormikContext();
  const handleOnChange = (event) => {
    if (event.target.checked) {
      setFieldValue(name, "true");
    } else {
      setFieldValue(name, "false");
    }
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <CheckBox
            checked={values[name] === "true"}
            onChange={handleOnChange}
            size="small"
            sx={{
              color: theme.palette.colors.ui.white,
              "&.Mui-checked": {
                color: theme.palette.colors.ui.white,
              },
            }}
          />
        }
        label={
          <Text
            type="WhiteColor"
            sx={{
              marginLeft: "-5px",
              fontWeight: theme.fonts.fontWeights.bold,
              fontSize: theme.fonts.fontSizes.size12,
            }}
          >
            {label}
          </Text>
        }
      />
    </FormGroup>
  );
}

MiniCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default MiniCheckBox;
