import BlockIcon from "@mui/icons-material/Block";
import { Box, IconButton, TableCell, TableRow, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function UserBusinessAccessTableRow({
  userAccess,
  onRemoveAccess,
}) {
  const theme = useTheme();

  const handleRemoveAccess = () => {
    onRemoveAccess(userAccess.user.id);
  };

  return (
    <TableRow
      key={userAccess.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {userAccess.user.name}
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="GreenColor" sx={{ textAlign: "center" }}>
          Allowed
        </Text>
      </TableCell>

      <TableCell sx={{ width: "100px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {userAccess.ableToRevokeAccess && (
            <IconButton onClick={handleRemoveAccess}>
              <BlockIcon sx={{ color: theme.palette.colors.ui.redDot }} />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}

UserBusinessAccessTableRow.propTypes = {
  onRemoveAccess: PropTypes.func.isRequired,
  userAccess: PropTypes.shape({
    ableToRevokeAccess: PropTypes.bool,
    id: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
};
