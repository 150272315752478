import React from "react";
import PropTypes from "prop-types";
import { Box, useMediaQuery, useTheme } from "@mui/material";

export default function WhiteBgCardContainer({ children, ...props }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      {...props}
      sx={{
        width: "100%",
        borderTopLeftRadius: theme.shape.borderRadius[2],
        borderTopRightRadius: theme.shape.borderRadius[2],
        borderBottomLeftRadius: theme.shape.borderRadius[2],
        borderBottomRightRadius: theme.shape.borderRadius[2],
        overflow: "hidden",
        backgroundColor: theme.palette.colors.bg.secondary,
        padding: isMobile ? "15px" : "50px",
      }}
    >
      {children}
    </Box>
  );
}

WhiteBgCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
