import PropTypes from "prop-types";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeletonLoader({ width, height, borderRadius }) {
  return (
    <Skeleton
      width={width}
      height={height}
      borderRadius={borderRadius}
      sx={{ padding: 0, margin: 0 }}
    />
  );
}

SkeletonLoader.defaultProps = {
  width: "100%",
  height: "100%",
  borderRadius: 5,
};

SkeletonLoader.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.number,
};

export default SkeletonLoader;
