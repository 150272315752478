import { Box, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import PropTypes from "prop-types";
import React from "react";

import Text from "../../../components/text.component";

export default function BookingTableRow({ booking, onHandleCancel }) {
  return (
    <>
      <TableRow
        key={booking.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.user.name}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.user.phone}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {booking.status.replaceAll("_", " ")}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.startAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.endAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.session.class.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM ${booking.price}`}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.payment.redeemPackageCode ? booking.payment.redeemPackageCode : "-"}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onHandleCancel(booking.id)}
              disabled={booking.status !== "active" && booking.status !== "merchant_request"}
            >
              <Tooltip title="Cancel" arrow>
                <DoDisturbAltIcon />
              </Tooltip>
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

BookingTableRow.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    price: PropTypes.string,
    payment: PropTypes.shape({
      method: PropTypes.string,
      redeemPackageCode: PropTypes.string,
    }),
    session: PropTypes.shape({
      id: PropTypes.number,
      startAt: PropTypes.string,
      endAt: PropTypes.string,
      class: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      user: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
    }),
  }).isRequired,
  onHandleCancel: PropTypes.func.isRequired,
};
