import React from "react";
import { TextField, InputAdornment, Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DebounceInput } from "react-debounce-input";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import Spacer from "../../../components/spacer.component";

function SearchBar({ placeholder, name, searchKeyword, setSearchKeyword }) {
  const theme = useTheme();
  const { setFieldValue } = useFormikContext();

  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: theme.palette.colors.bg.secondary,
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        },
      }}
    >
      <DebounceInput
        sx={{ width: "100%" }}
        debounceTimeout={500}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          setSearchKeyword(e.target.value);
        }}
        element={TextField}
        value={searchKeyword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
              <Spacer position="right" />
            </InputAdornment>
          ),
          endAdornment: searchKeyword && (
            <InputAdornment position="end">
              <IconButton
                sx={{ padding: "0px" }}
                onClick={() => {
                  setFieldValue(name, "");
                  setSearchKeyword("");
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
      />
    </Box>
  );
}

SearchBar.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  searchKeyword: PropTypes.string.isRequired,
  setSearchKeyword: PropTypes.func.isRequired,
};

export default SearchBar;
