import React, { useContext } from "react";
import * as Yup from "yup";
import { Box, Button, styled, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KatchLogo from "../../../images/katch-logo.png";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import Form from "../../../components/forms/form.component";
import {
  authSelector,
  logout,
  resetUser,
  updateMerchantDetail,
} from "../../../services/auth/auth.slice.services";
import routes from "../../../navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormFieldBankDropdown from "../../../components/forms/form-field-bank-dropdown.component";

const MainContainer = styled(Box)({
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "20px",
  paddingBottom: "20px",
});

const CardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  width: "90%",
  maxWidth: "450px",
  backgroundColor: theme.palette.colors.bg.secondary,
  display: "flex",
  flexDirection: "row",
}));

const CenteredBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  businessName: Yup.string().required().label("Business Name"),
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  accountNo: Yup.number().required().label("Account Number"),
  bankName: Yup.string().required().label("Bank Name"),
  accountHolderName: Yup.string().required().label("Account Holder Name"),
});

const TextButton = styled(Button)({
  textTransform: "none",
  ":hover": { backgroundColor: "transparent" },
});

export default function MerchantDetailScreen() {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { updateMerchantDetailObj } = useSelector(authSelector);

  const onConfirmSubmitForm = (values) => {
    dispatch(updateMerchantDetail(values)).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.HOME);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onBack = () => {
    dispatch(logout()).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        history.push(routes.LOGIN);
      }
    });
  };

  return (
    <Form
      initialValues={{
        businessName: "",
        firstName: "",
        lastName: "",
        bankName: "",
        accountNo: "",
        accountHolderName: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onConfirmSubmitForm}
    >
      <MainContainer>
        <CardContainer>
          <CenteredBox sx={{ padding: isMobile ? "20px" : "50px" }}>
            <img src={KatchLogo} width="150px" alt="katch-logo" />
            <Spacer position="top" size="m" />
            <Text
              sx={{
                fontSize: theme.fonts.fontSizes.size30,
                fontWeight: theme.fonts.fontWeights.bold,
              }}
            >
              Fill in your details
            </Text>
            <Spacer size="l" position="top" />

            <Box sx={{ width: "100%" }}>
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business Name</Text>
              <Spacer size="xs" position="top" />
              <FormFieldText name="businessName" placeholder="Enter your business name" />
            </Box>

            <Spacer size="m" position="top" />

            <Box sx={{ width: "100%" }}>
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>First name</Text>
              <Spacer size="xs" position="top" />
              <FormFieldText name="firstName" placeholder="Person in charge first name" />
            </Box>

            <Spacer size="m" position="top" />

            <Box sx={{ width: "100%" }}>
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Last Name</Text>
              <Spacer size="xs" position="top" />
              <FormFieldText name="lastName" placeholder="Person in charge last name" />
            </Box>

            <Spacer size="m" position="top" />

            <Box sx={{ width: "100%" }}>
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Bank Name</Text>
              <Spacer size="xs" position="top" />
              <FormFieldBankDropdown name="bankName" />
            </Box>

            <Spacer size="m" position="top" />

            <Box sx={{ width: "100%" }}>
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Bank Account Number</Text>
              <Spacer size="xs" position="top" />
              <FormFieldText name="accountNo" placeholder="Bank account number" />
            </Box>

            <Spacer size="m" position="top" />

            <Box sx={{ width: "100%" }}>
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Account Holder Name</Text>
              <Spacer size="xs" position="top" />
              <FormFieldText name="accountHolderName" placeholder="Account holder name" />
            </Box>

            <Spacer size="xl" position="top" />
            <Box sx={{ width: "100%" }}>
              <FormSubmitButton isLoading={updateMerchantDetailObj.status === "pending"}>
                <Text type="WhiteColor">Continue</Text>
              </FormSubmitButton>
            </Box>
            <Spacer size="m" position="top" />

            <TextButton onClick={onBack}>
              <Text>Back</Text>
            </TextButton>
          </CenteredBox>
        </CardContainer>
      </MainContainer>
    </Form>
  );
}
