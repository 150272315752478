import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, patchReq, putReq } from "../../api-services";

export const getEventBookingList = createAsyncThunk(
  "merchants/events/booking/index",
  async (payload) => {
    const {
      q,
      statuses,
      businessIds,
      categoryIds,
      eventIds,
      userIds,
      startAt,
      endAt,
      page,
      sessionCategoryIds,
      sessionIds,
    } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/bookings?${
        page ? "page=".concat(page) : "page=1"
      }${businessIds ? "&businessIds=".concat(businessIds) : ""}${q ? "&q=".concat(q) : ""}${
        categoryIds ? "&categoryIds=".concat(categoryIds) : ""
      }${eventIds ? "&eventIds=".concat(eventIds) : ""}${
        userIds ? "&userIds=".concat(userIds) : ""
      }${startAt ? "&startAt=".concat(startAt) : ""}${endAt ? "&endAt=".concat(endAt) : ""}${
        statuses ? "&statuses=".concat(statuses) : ""
      }${sessionCategoryIds ? "&sessionCategoryIds=".concat(sessionCategoryIds) : ""}${
        sessionIds ? "&sessionIds=".concat(sessionIds) : ""
      }`,
    );
    return response;
  },
);

export const getEventBookingCSV = createAsyncThunk(
  "merchants/events/booking/csv",
  async (payload) => {
    const { statuses, businessIds, categoryIds, eventIds, userIds, startAt, endAt, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/bookings/csv?${
        page ? "page=".concat(page) : "page=1"
      }${businessIds ? "&businessIds=".concat(businessIds) : ""}${
        categoryIds ? "&categoryIds=".concat(categoryIds) : ""
      }${eventIds ? "&eventIds=".concat(eventIds) : ""}${
        userIds ? "&userIds=".concat(userIds) : ""
      }${startAt ? "&startAt=".concat(startAt) : ""}${endAt ? "&endAt=".concat(endAt) : ""}${
        statuses ? "&statuses=".concat(statuses) : ""
      }`,
    );
    return response;
  },
);

export const cancelEventBooking = createAsyncThunk(
  "merchants/events/booking/cancel",
  async (payload) => {
    const { bookingIds } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/bookings/cancel`,
      { bookingIds },
    );
    return response;
  },
);

export const checkAttendance = createAsyncThunk(
  "merchants/events/booking/attendance",
  async (payload) => {
    const { sessionId, q, isGuest } = payload;
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/bookings/attendance`,
      { sessionId, q, isGuest },
    );
    return response;
  },
);

const eventBookingSlice = createSlice({
  name: "eventBooking",
  initialState: {
    getEventBookingListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventBookingCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cancelEventBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    checkAttendanceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getEventBookingList.pending]: (state) => {
      state.getEventBookingListObj.status = "pending";
    },
    [getEventBookingList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventBookingListObj.status = "succeeded";
      state.getEventBookingListObj.data = data;
      state.getEventBookingListObj.successMessage = message;
    },
    [getEventBookingList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventBookingListObj.status = "failed";
      state.getEventBookingListObj.errorMessage = message;
    },
    [getEventBookingCSV.pending]: (state) => {
      state.getEventBookingCSVObj.status = "pending";
    },
    [getEventBookingCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventBookingCSVObj.status = "succeeded";
      state.getEventBookingCSVObj.data = data;
      state.getEventBookingCSVObj.successMessage = message;
    },
    [getEventBookingCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventBookingCSVObj.status = "failed";
      state.getEventBookingCSVObj.errorMessage = message;
    },
    [cancelEventBooking.pending]: (state) => {
      state.cancelEventBookingObj.status = "pending";
    },
    [cancelEventBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelEventBookingObj.status = "succeeded";
      state.cancelEventBookingObj.data = data;
      state.cancelEventBookingObj.successMessage = message;
    },
    [cancelEventBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelEventBookingObj.status = "failed";
      state.cancelEventBookingObj.errorMessage = message;
    },
    [checkAttendance.pending]: (state) => {
      state.checkAttendanceObj.status = "pending";
    },
    [checkAttendance.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.checkAttendanceObj.status = "succeeded";
      state.checkAttendanceObj.data = data;
      state.checkAttendanceObj.successMessage = message;
    },
    [checkAttendance.rejected]: (state, action) => {
      const { message } = action.error;

      state.checkAttendanceObj.status = "failed";
      state.checkAttendanceObj.errorMessage = message;
    },
  },
});

export default eventBookingSlice.reducer;

// state
export const eventBookingSelector = (state) => state.eventBooking;
