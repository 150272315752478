import { Box, Grid, TextField, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import CtaButton from "../../../components/button/cta-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import {
  additionalFeesSelector,
  getRenewalDay,
  updateRenewalDay,
} from "../../../services/additional-fees/additional-fees-slice.service";
import RenewalDayDetailLoader from "../loader/renewal-day-detail-loader.component";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const DisabledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.white,
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
}));

const SpacebetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
});

const validationSchema = Yup.object().shape({
  renewalDay: Yup.number().required().label("Renewal Days"),
});

export default function RenewalDayDetail() {
  const theme = useTheme();
  const { getRenewalDayObj, updateRenewalDayObj } = useSelector(additionalFeesSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isEditing, setIsEditing] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getRenewalDay()).then(() => {
      setIsLoading(false);
    });
  }, []);

  const onUpdateDetail = (values) => {
    setIsLoading(true);
    dispatch(updateRenewalDay({ ...values })).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setIsEditing(false);
  };

  const renderContent = () => {
    if (getRenewalDayObj.status === "succeeded") {
      return (
        <>
          <Text variant="screenLabel">Renewal Day</Text>
          <Grid container sx={{ paddingY: "20px" }} spacing={3}>
            <Grid item xs={12}>
              <Formik
                validationSchema={validationSchema}
                onSubmit={onUpdateDetail}
                initialValues={{
                  renewalDay: getRenewalDayObj.data.renewalDays,
                }}
              >
                {({ handleSubmit, setFieldValue, values }) => (
                  <Form>
                    {isSmallScreen && (
                      <SpacebetweenRowBox>
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Renewal Days
                          </Text>
                        </LabelContainer>
                        {isEditing ? (
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {isSmallScreen && (
                              <>
                                <CtaButton
                                  width={isSmallScreen ? null : "70px"}
                                  padding="8px"
                                  onClickButton={() => handleSubmit()}
                                  isLoading={updateRenewalDayObj.status === "pending"}
                                >
                                  <Text type="WhiteColor">Save</Text>
                                </CtaButton>
                                <Spacer position="left" size="s" />
                              </>
                            )}
                            <CtaButton
                              width={isSmallScreen ? null : "70px"}
                              padding="8px"
                              onClickButton={() => {
                                setFieldValue("renewalDay", getRenewalDayObj.data.renewalDays);

                                setIsEditing(false);
                              }}
                              isLoading={updateRenewalDayObj.status === "pending"}
                            >
                              <Text type="WhiteColor">Cancel</Text>
                            </CtaButton>
                          </Box>
                        ) : (
                          isSmallScreen && (
                            <CtaBorderButton
                              width={isSmallScreen ? null : "70px"}
                              padding="8px"
                              onClickButton={() => setIsEditing(true)}
                            >
                              <Text type="BrandColor">Edit</Text>
                            </CtaBorderButton>
                          )
                        )}
                      </SpacebetweenRowBox>
                    )}

                    <FormContainer>
                      {!isSmallScreen && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Renewal Days
                          </Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          {isEditing ? (
                            <FormFieldText
                              isDisableEnter
                              name="renewalDay"
                              placeholder="Enter renewal day number"
                              width="100%"
                              type="number"
                            />
                          ) : (
                            <DisabledTextField
                              value={values.renewalDay === null ? "" : values.renewalDay}
                              disabled
                              variant="outlined"
                            />
                          )}
                        </Box>
                        {isEditing && !isSmallScreen && (
                          <>
                            <Spacer position="top" size="m" />
                            <CtaButton
                              width={isSmallScreen ? null : "70px"}
                              padding="8px"
                              onClickButton={() => handleSubmit()}
                              isLoading={updateRenewalDayObj.status === "pending"}
                            >
                              <Text type="WhiteColor">Save</Text>
                            </CtaButton>
                          </>
                        )}
                      </Box>

                      {!isSmallScreen && (
                        <>
                          <Spacer position="left" size="m" />
                          {isEditing ? (
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <CtaButton
                                width={isSmallScreen ? null : "70px"}
                                padding="8px"
                                onClickButton={() => {
                                  setFieldValue("renewalDay", getRenewalDayObj.data.renewalDays);

                                  setIsEditing(false);
                                }}
                                isLoading={updateRenewalDayObj.status === "pending"}
                              >
                                <Text type="WhiteColor">Cancel</Text>
                              </CtaButton>
                            </Box>
                          ) : (
                            <CtaBorderButton
                              width={isSmallScreen ? null : "70px"}
                              padding="8px"
                              onClickButton={() => setIsEditing(true)}
                            >
                              <Text type="BrandColor">Edit</Text>
                            </CtaBorderButton>
                          )}
                        </>
                      )}
                    </FormContainer>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </>
      );
    }
    return <RenewalDayDetailLoader />;
  };

  return (
    <Grid item xs={12}>
      <BackdropLoading isLoading={isLoading} />
      {renderContent()}
    </Grid>
  );
}
