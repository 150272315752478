/* eslint-disable no-nested-ternary */
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from "prop-types";
import { Box, TableCell, TableHead, TableRow, styled, useTheme } from "@mui/material";
import Text from "../text.component";

const TableCellContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

function TableHeader({
  headerCells,
  sortColumn,
  sortOrder,
  onSortChange,
  columnMapping,
  nonSortableColumns,
}) {
  const theme = useTheme();
  const isSortableColumn = (headCell) => !nonSortableColumns.includes(headCell);
  const renderSortIndicator = (column) => (
    <ArrowDropDownIcon
      style={{
        transform:
          sortColumn === columnMapping[column]
            ? sortOrder === "asc"
              ? "rotate(0deg)"
              : "rotate(180deg)"
            : "none",
      }}
    />
  );

  return (
    <TableHead>
      <TableRow>
        {headerCells &&
          headerCells.length > 0 &&
          headerCells.map((headCell) => (
            <TableCell
              key={`${headCell}`}
              sx={{
                textAlign: "center",
                cursor: headCell !== "Action" && isSortableColumn(headCell) && "pointer",
              }}
              onClick={() => {
                if (headCell !== "Action" && isSortableColumn(headCell)) {
                  onSortChange(headCell);
                }
              }}
            >
              {headCell.toUpperCase().includes("ACTIVE BANNER") ? (
                <>
                  <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>{headCell}</Text>
                  {renderSortIndicator(headCell)}
                </>
              ) : (
                <TableCellContainer>
                  {headCell}
                  {headCell !== "Action" &&
                    headCell !== "Staffs" &&
                    isSortableColumn(headCell) &&
                    renderSortIndicator(headCell)}
                </TableCellContainer>
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

TableHeader.defaultProps = {
  sortColumn: null,
  sortOrder: "desc",
  onSortChange: null,
  columnMapping: {},
  nonSortableColumns: [],
};

TableHeader.propTypes = {
  headerCells: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf(["asc", "desc"]),
  onSortChange: PropTypes.func,
  columnMapping: PropTypes.shape({}),
  nonSortableColumns: PropTypes.arrayOf(PropTypes.string),
};

export default TableHeader;
