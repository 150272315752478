import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableSort from "../../../components/table/table-sort.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  eventSessionSelector,
  getEventSessionAttendanceList,
} from "../../../services/event/session/session.slice.services";
import CheckAttendanceModal from "./check-attendance-modal.component";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function SessionTableRow({
  session,
  onHandleCancel,
  onHandleStopSale,
  onPageChange,
  setSessionInfo,
  setShowDuplicateSessionModal,
  setShowCreateCategoryModal,
  setShowEditCategoryModal,
  onHandleDeleteCategory,
}) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const history = useHistory();
  const { getEventSessionListObj, getEventSessionAttendanceListObj } =
    useSelector(eventSessionSelector);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const csvRef = useRef();

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const columnMapping = {
    "Attendees Name": "user.name",
    "Is Guest": "isGuest",
    Phone: "user.phone",
    Status: "status",
    Attended: "attended",
  };

  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const getProcessedRecord = () => {
    let records = null;

    if (getEventSessionListObj.data && getEventSessionListObj.status === "succeeded") {
      records = session.attendees;

      const sortedData = [...records].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records = sortedData;
    }
    return records;
  };

  const attendeeRecords = getProcessedRecord();

  const onHandleEdit = () => {
    history.push({
      pathname: routes.EVENT_SESSIONS_EDIT,
      search: `${session.id}`,
      state: session,
    });
  };

  const getSessionAttendanceList = () => {
    dispatch(getEventSessionAttendanceList({ sessionId: session.id })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          csvRef.current.link.click();
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  return (
    <>
      <CheckAttendanceModal
        sessionId={session.id}
        onPageChange={onPageChange}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <TableRow
        key={session.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {session.title ? session.title : "-"}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {session.event.title}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {session.startAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {session.endAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {typeof session.currentCapacity === "number" ? session.currentCapacity : "-"}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {session.status}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <CSVLink
            data={
              getEventSessionAttendanceListObj.data ? getEventSessionAttendanceListObj.data : []
            }
            filename={`${session.event.title}_${session.startAt}-${session.endAt}.csv`}
            target="_blank"
            ref={csvRef}
          />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon sx={{ color: theme.palette.colors.text.table }} />
            </IconButton>
            <StyledMenu
              sx={{ mt: "20px" }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem key={1} onClick={onHandleEdit}>
                <Text textAlign="center">{session.status === "draft" ? "Edit" : "View"}</Text>
              </MenuItem>
              <MenuItem key={2} onClick={() => setShowModal(true)}>
                <Text textAlign="center">Check Attendance</Text>
              </MenuItem>
              <MenuItem key={3} onClick={() => getSessionAttendanceList()}>
                <Text textAlign="center">Download Attendance</Text>
              </MenuItem>
              <MenuItem
                key={4}
                onClick={() => onHandleCancel(session.id)}
                disabled={
                  session.status === "cancelled" ||
                  new Date(session.endAt) < new Date() ||
                  session.attendees.some((attendee) => attendee.isGuest)
                }
              >
                <Text textAlign="center">Cancel</Text>
              </MenuItem>
              <MenuItem
                key={5}
                onClick={() => {
                  setSessionInfo(session);
                  setShowDuplicateSessionModal(true);
                }}
              >
                <Text textAlign="center">Duplicate</Text>
              </MenuItem>
              <MenuItem
                key={6}
                onClick={() => {
                  setSessionInfo(session);
                  setShowCreateCategoryModal(true);
                }}
                disabled={session.multipleCategories === false || session.status === "publish"}
              >
                <Text textAlign="center">Create Category</Text>
              </MenuItem>
              <MenuItem
                key={7}
                onClick={() => onHandleStopSale(session.id)}
                disabled={session.currentCapacity === session.maxCapacity}
              >
                <Text textAlign="center">Stop Sales</Text>
              </MenuItem>
            </StyledMenu>

            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Table size="small">
              <TableHeader
                headerCells={["Attendees Name", "Is Guest", "Phone", "Status", "Attended"]}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                columnMapping={columnMapping}
              />
              <TableBody>
                {session.attendees.length > 0 ? (
                  attendeeRecords.map((attendee) => (
                    <TableRow key={attendee.id}>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {attendee.user.name}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {attendee.isGuest ? "Yes" : "No"}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          {attendee.user.phone}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {attendee.status}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {attendee.attended ? "Yes" : "No"}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Spacer size="m" />
            <Table size="small">
              <TableHeader
                headerCells={[
                  "Category Label",
                  "Price",
                  "Start At",
                  "End At",
                  "Total People",
                  "Max Capacity",
                  "Action",
                ]}
                nonSortableColumns={[
                  "Category Label",
                  "Price",
                  "Start At",
                  "End At",
                  "Total People",
                  "Max Capacity",
                ]}
              />
              <TableBody>
                {session.sessionCategories.length > 0 ? (
                  session.sessionCategories.map((sessionCategory) => (
                    <TableRow key={sessionCategory.id}>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {sessionCategory.label}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          RM {sessionCategory.price}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {sessionCategory.startAt}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {sessionCategory.endAt}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {sessionCategory.currentCapacity}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {sessionCategory.maxCapacity === 0
                            ? "Unlimited"
                            : sessionCategory.maxCapacity}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setSessionInfo(sessionCategory);
                              setShowEditCategoryModal(true);
                            }}
                            disabled={session.status === "publish"}
                          >
                            <Tooltip title="Edit" arrow>
                              <EditIcon />
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => onHandleDeleteCategory(sessionCategory.id)}
                            disabled={session.status === "publish"}
                          >
                            <Tooltip title="Delete" arrow>
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

SessionTableRow.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.string,
    title: PropTypes.string,
    currentCapacity: PropTypes.number,
    maxCapacity: PropTypes.number,
    status: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    event: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    attendees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        attended: PropTypes.bool,
        status: PropTypes.string,
        user: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          phone: PropTypes.string,
        }),
      }),
    ),
    multipleCategories: PropTypes.bool,
    sessionCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        currentCapacity: PropTypes.number,
        maxCapacity: PropTypes.number,
        unlimited: PropTypes.number,
        price: PropTypes.string,
        startAt: PropTypes.string,
        endAt: PropTypes.string,
        createdAt: PropTypes.string,
      }),
    ),
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onHandleCancel: PropTypes.func.isRequired,
  onHandleStopSale: PropTypes.func.isRequired,
  setSessionInfo: PropTypes.func.isRequired,
  setShowDuplicateSessionModal: PropTypes.func.isRequired,
  setShowCreateCategoryModal: PropTypes.func.isRequired,
  setShowEditCategoryModal: PropTypes.func.isRequired,
  onHandleDeleteCategory: PropTypes.func.isRequired,
};
