import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

function PromoCodeRedemptionRow({ record }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "0px",
        },
      }}
    >
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.userId}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", textTransform: "capitalize" }}>
          {record.username}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", textTransform: "uppercase" }}>
          {record.status.replace("_", " ")}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.dateRedeemed}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          RM {record.discountAmount}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.purchaseItem}
        </Text>
      </TableCell>
    </TableRow>
  );
}

PromoCodeRedemptionRow.defaultProps = {
  record: {
    id: "",
    status: "",
    dateRedeemed: "",
    userId: "",
    username: "",
    discountAmount: "",
    purchaseItem: "",
  },
};

PromoCodeRedemptionRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    dateRedeemed: PropTypes.string,
    userId: PropTypes.number,
    username: PropTypes.string,
    discountAmount: PropTypes.number,
    purchaseItem: PropTypes.number,
  }),
};

export default PromoCodeRedemptionRow;
