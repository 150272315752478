import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { Box, Button, FormHelperText, Grid, styled, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";
import Spacer from "../../../components/spacer.component";
import { eventSelector } from "../../../services/event/event.slice.services";
import EditImageCropper from "./edit-image-cropper.component";
import EventImage from "./event-image.component";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const UploadButtonContainer = styled(Button)(({ theme }) => ({
  minHeight: "140px",
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  border: `2px dashed ${theme.palette.colors.ui.border}`,
  color: theme.palette.colors.ui.border,
  height: "100%",
  "&:hover": {
    background: "transparent",
  },
  marginTop: "-3px",
}));

function EditImageForm({ name, imageEventHandle, allowEditImage }) {
  const theme = useTheme();
  const { setFieldError, errors, setFieldTouched } = useFormikContext();
  const [image, setImage] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("1150"));
  const showError = typeof errors[name] === "string";
  const { getEventDetailObj } = useSelector(eventSelector);

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    return 4;
  };

  const inputButton = () => (
    <input
      type="file"
      name={name}
      hidden
      accept="image/*"
      onClick={(event) => {
        event.target.value = null;
      }}
      onBlur={() => setFieldTouched(name)}
      onChange={(e) => {
        const { files } = e.target;
        if (files) {
          const selectedFIles = [];
          const targetFilesObject = [...files];
          targetFilesObject.map((file) => selectedFIles.push(file));
          const allowedFileType = selectedFIles.some((item) =>
            SUPPORTED_FORMATS.includes(item.type),
          );
          if (allowedFileType) {
            setImage(URL.createObjectURL(selectedFIles[0]));
            setShowCropper(true);
          } else if (!allowedFileType) {
            setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
          }
        }
      }}
    />
  );

  const renderUploadButton = () => (
    <UploadButtonContainer onClick={() => setFieldError(name, "")} component="label" fullWidth>
      <AddCircleOutlineRoundedIcon />
      {inputButton()}
    </UploadButtonContainer>
  );

  const renderImageList = () => {
    if (getEventDetailObj.status === "succeeded") {
      return (
        <Grid container spacing={1}>
          <EditImageCropper
            name={name}
            setIsShowModal={setShowCropper}
            isShowModal={showCropper}
            imageFile={image}
          />
          {getEventDetailObj.data.images.map((item, index) => (
            <Grid item xs={getItemToShow()} key={item.id}>
              <EventImage
                image={item}
                index={index}
                imageEventHandle={imageEventHandle}
                allowEditImage={allowEditImage}
              />
            </Grid>
          ))}
          <Grid item xs={getItemToShow()}>
            {allowEditImage && renderUploadButton()}
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="130px" />
        </Grid>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="130px" />
        </Grid>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="130px" />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      <Spacer size="m" position="top" />
      {renderImageList()}
    </Box>
  );
}

EditImageForm.propTypes = {
  name: PropTypes.string.isRequired,
  imageEventHandle: PropTypes.func.isRequired,
  allowEditImage: PropTypes.bool.isRequired,
};

export default EditImageForm;
