import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../../api-services";

export const getUserSubscriptions = createAsyncThunk(
  "merchants/users/{user_Id}/subscriptions",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/users/${payload.userId}/subscriptions?page=${payload.page}`,
    );
    return response;
  },
);

const subscriptionUserSlice = createSlice({
  name: "subscriptionUser",
  initialState: {
    getUserSubscriptionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getUserSubscriptions.pending]: (state) => {
      state.getUserSubscriptionsObj.status = "pending";
    },
    [getUserSubscriptions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserSubscriptionsObj.status = "succeeded";
      state.getUserSubscriptionsObj.data = data;
      state.getUserSubscriptionsObj.successMessage = message;
    },
    [getUserSubscriptions.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserSubscriptionsObj.status = "failed";
      state.getUserSubscriptionsObj.errorMessage = message;
    },
  },
});

export default subscriptionUserSlice.reducer;

// state
export const subscriptionUserSelector = (state) => state.subscriptionUser;
