import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, patchReq, postReq, putReq } from "../api-services";

export const searchSubscriber = createAsyncThunk(
  "merchants/subscriptions/subscribers/index",
  async (payload) => {
    const { q, page, statuses, businessesId, startAt, endAt } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/subscriptions/?${
        q ? "&q=".concat(q) : ""
      }${statuses ? "&statuses=".concat(statuses) : ""}${
        businessesId ? "&businessesId=".concat(businessesId) : ""
      }${startAt ? "&startAt=".concat(startAt) : ""}${
        endAt ? "&endAt=".concat(endAt) : ""
      }&page=${page}`,
    );
    return response;
  },
);

export const getSubscriberCSV = createAsyncThunk(
  "analytics/merchant/packages/csv",
  async (payload) => {
    const { q, statuses, businessesId, startAt, endAt } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/subscriptions/csv?${
        q ? "&q=".concat(q) : ""
      }${statuses ? "&statuses=".concat(statuses) : ""}${
        businessesId ? "&businessesId=".concat(businessesId) : ""
      }${startAt ? "&startAt=".concat(startAt) : ""}${endAt ? "&endAt=".concat(endAt) : ""}`,
    );
    return response;
  },
);

export const searchSubscription = createAsyncThunk(
  "merchants/subscriptions/plans/index",
  async (payload) => {
    const { q, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/subscriptions/plans?${
        q ? "&q=".concat(q) : ""
      }&page=${page}`,
    );
    return response;
  },
);

export const createSubscription = createAsyncThunk(
  "merchants/subscriptions/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/subscriptions/plans`,
      payload,
    );
    return response;
  },
);

export const deleteSubscription = createAsyncThunk(
  "merchants/subscriptions/plans/delete/{plan_id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/subscriptions/plans/${payload.planId}`,
    );
    return response;
  },
);

export const getSubscription = createAsyncThunk(
  "merchants/subscriptions/plans/get/{plan_id}",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/subscriptions/plans/${payload.planId}`,
    );
    return response;
  },
);

export const updateSubscription = createAsyncThunk(
  "merchants/subscriptions/plans/update/{plan_id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/subscriptions/plans/${payload.planId}`,
      payload,
    );
    return response;
  },
);

export const updateSubscriptionStatus = createAsyncThunk(
  "merchants/subscriptions/plans/update/{plan_id}/status",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/subscriptions/plans/${payload.planId}/status`,
      payload,
    );
    return response;
  },
);

export const updateSubscriptionImage = createAsyncThunk(
  "merchants/subscriptions/plans/update/{plan_id}/image",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/subscriptions/plans/${payload.planId}/image`,
      payload,
    );
    return response;
  },
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    searchSubscriberObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSubscriberCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchSubscriptionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createSubscriptionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteSubscriptionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSubscriptionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSubscriptionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSubscriptionStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSubscriptionImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [searchSubscriber.pending]: (state) => {
      state.searchSubscriberObj.status = "pending";
    },
    [searchSubscriber.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchSubscriberObj.status = "succeeded";
      state.searchSubscriberObj.data = data;
      state.searchSubscriberObj.successMessage = message;
    },
    [searchSubscriber.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchSubscriberObj.status = "failed";
      state.searchSubscriberObj.errorMessage = message;
    },
    [getSubscriberCSV.pending]: (state) => {
      state.getSubscriberCSVObj.status = "pending";
    },
    [getSubscriberCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubscriberCSVObj.status = "succeeded";
      state.getSubscriberCSVObj.data = data;
      state.getSubscriberCSVObj.successMessage = message;
    },
    [getSubscriberCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubscriberCSVObj.status = "failed";
      state.getSubscriberCSVObj.errorMessage = message;
    },
    [searchSubscription.pending]: (state) => {
      state.searchSubscriptionObj.status = "pending";
    },
    [searchSubscription.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchSubscriptionObj.status = "succeeded";
      state.searchSubscriptionObj.data = data;
      state.searchSubscriptionObj.successMessage = message;
    },
    [searchSubscription.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchSubscriptionObj.status = "failed";
      state.searchSubscriptionObj.errorMessage = message;
    },
    [createSubscription.pending]: (state) => {
      state.createSubscriptionObj.status = "pending";
    },
    [createSubscription.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createSubscriptionObj.status = "succeeded";
      state.createSubscriptionObj.data = data;
      state.createSubscriptionObj.successMessage = message;
    },
    [createSubscription.rejected]: (state, action) => {
      const { message } = action.error;

      state.createSubscriptionObj.status = "failed";
      state.createSubscriptionObj.errorMessage = message;
    },
    [deleteSubscription.pending]: (state) => {
      state.deleteSubscriptionObj.status = "pending";
    },
    [deleteSubscription.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteSubscriptionObj.status = "succeeded";
      state.deleteSubscriptionObj.data = data;
      state.deleteSubscriptionObj.successMessage = message;
    },
    [deleteSubscription.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteSubscriptionObj.status = "failed";
      state.deleteSubscriptionObj.errorMessage = message;
    },
    [getSubscription.pending]: (state) => {
      state.getSubscriptionObj.status = "pending";
    },
    [getSubscription.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubscriptionObj.status = "succeeded";
      state.getSubscriptionObj.data = data;
      state.getSubscriptionObj.successMessage = message;
    },
    [getSubscription.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubscriptionObj.status = "failed";
      state.getSubscriptionObj.errorMessage = message;
    },
    [updateSubscription.pending]: (state) => {
      state.updateSubscriptionObj.status = "pending";
    },
    [updateSubscription.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSubscriptionObj.status = "succeeded";
      state.updateSubscriptionObj.data = data;
      state.updateSubscriptionObj.successMessage = message;
    },
    [updateSubscription.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSubscriptionObj.status = "failed";
      state.updateSubscriptionObj.errorMessage = message;
    },
    [updateSubscriptionStatus.pending]: (state) => {
      state.updateSubscriptionStatusObj.status = "pending";
    },
    [updateSubscriptionStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSubscriptionStatusObj.status = "succeeded";
      state.updateSubscriptionStatusObj.data = data;
      state.updateSubscriptionStatusObj.successMessage = message;
    },
    [updateSubscriptionStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSubscriptionStatusObj.status = "failed";
      state.updateSubscriptionStatusObj.errorMessage = message;
    },
    [updateSubscriptionImage.pending]: (state) => {
      state.updateSubscriptionImageObj.status = "pending";
    },
    [updateSubscriptionImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSubscriptionImageObj.status = "succeeded";
      state.updateSubscriptionImageObj.data = data;
      state.updateSubscriptionImageObj.successMessage = message;
    },
    [updateSubscriptionImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSubscriptionImageObj.status = "failed";
      state.updateSubscriptionImageObj.errorMessage = message;
    },
  },
});

export default subscriptionSlice.reducer;

// state
export const subscriptionSelector = (state) => state.subscription;
