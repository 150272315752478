import React from "react";
import PropTypes from "prop-types";
import { Box, TableCell, TableRow } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import Text from "../../../components/text.component";

const renderProgress = (claimedCount, noOfSessions) => {
  if (noOfSessions === 0) {
    return 0;
  }
  return (claimedCount / noOfSessions) * 100;
};

export default function PackageReportTableRow({ packagePlanReport }) {
  return (
    <>
      <TableRow
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "70px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`${packagePlanReport.user.firstName} ${packagePlanReport.user.lastName}`}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={renderProgress(
                  packagePlanReport.session.claimedCount,
                  packagePlanReport.session.noOfSessions,
                )}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Text variant="body2">{`${packagePlanReport.session.claimedCount} / ${packagePlanReport.session.noOfSessions}`}</Text>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

PackageReportTableRow.propTypes = {
  packagePlanReport: PropTypes.shape({
    session: PropTypes.shape({
      claimedCount: PropTypes.number,
      noOfSessions: PropTypes.number,
    }),
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
};
