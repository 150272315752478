import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import {
  getUserSubscriptions,
  subscriptionUserSelector,
} from "../../../services/user/subscription/subscription.slice.service";
import UserSubscriptionsDetailTableRow from "../components/user-subscriptions-detail-table-row.component";
import UserDetailTableRowLoader from "../loader/user-detail-table-row-loader.component";
import TableSort from "../../../components/table/table-sort.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function UserSubscriptionsDetailScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("userId");
  const { getUserSubscriptionsObj } = useSelector(subscriptionUserSelector);
  const createSnackBar = useContext(SnackbarContext);
  const columnMapping = {
    Title: "plan.title",
    Description: "plan.shortDescription",
    "Start At": "start_at",
    "End At": "end_at",
    "No. of Days": "plan.numberOfDays",
    Status: "status",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshUserSubscriptionList = (newPage) => {
    setPage(newPage);
    dispatch(getUserSubscriptions({ page: newPage, userId })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const handlePageChange = (e, newPage) => {
    onRefreshUserSubscriptionList(newPage);
  };

  useEffect(() => {
    onRefreshUserSubscriptionList(page);
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getUserSubscriptionsObj.data && getUserSubscriptionsObj.status === "succeeded") {
      records = { ...getUserSubscriptionsObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (getUserSubscriptionsObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getUserSubscriptionsObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={4}>
              <TableEmptyBox>
                <Text type="TableText">No items</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <UserSubscriptionsDetailTableRow subscription={item} key={item.id} />
      ));
    }
    return <UserDetailTableRowLoader />;
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <Grid item xs={12}>
        <Text variant="screenLabel">All Subscriptions</Text>
      </Grid>

      <Grid item xs={12}>
        <TableWrapper>
          <Table>
            <TableHeader
              headerCells={["Title", "Description", "Start At", "End At", "No. of Days", "Status"]}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
              columnMapping={columnMapping}
            />
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      <Grid item xs={12}>
        <CustomFooter>
          <Pagination
            sx={{
              "&& .Mui-selected": {
                backgroundColor: theme.palette.colors.brand.primary,
                color: theme.palette.colors.text.white,
              },
            }}
            page={page}
            shape="rounded"
            onChange={handlePageChange}
            count={getUserSubscriptionsObj?.data?.pagination.totalPages || totalPages}
            variant="outlined"
          />
        </CustomFooter>
      </Grid>
    </Grid>
  );
}
