import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import CtaButton from "../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableSort from "../../../components/table/table-sort.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  cancelEventBooking,
  eventBookingSelector,
  getEventBookingCSV,
  getEventBookingList,
} from "../../../services/event/booking/booking.slice.services";
import {
  eventCategorySelector,
  getEventCategoryList,
} from "../../../services/event/category/category.slice.services";
import BookingTableRow from "../components/booking-table-row.component";
import BusinessListAutoComplete from "../components/business-list-autocomplete.component";
import EventListAutoComplete from "../components/event-list-autocomplete.component";
import SearchBar from "../components/search-bar.component";
import SessionCategoryListAutoComplete from "../components/session-category-list-autocomplete.component";
import BookingTableRowLoader from "../loader/booking-table-row-loader.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  businessIds: Yup.array().label("Businesses").nullable(),
  categoryIds: Yup.array().label("Categories").nullable(),
  eventIds: Yup.array().label("Events").nullable(),
  userIds: Yup.array().label("Users").nullable(),
  statuses: Yup.array().label("Status").of(Yup.string()).nullable(),
  startAt: Yup.date().label("Start date").nullable(),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startAt", {
      is: (startAt) => startAt,
      then: Yup.date()
        .min(Yup.ref("startAt"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const STATUSLIST = [
  { id: "1", label: "Pending Payment", value: "pending_payment" },
  { id: "2", label: "Active", value: "active" },
  { id: "3", label: "Expired", value: "expired" },
  { id: "4", label: "Payment Cancelled", value: "payment_cancelled" },
  { id: "5", label: "Refunded", value: "refunded" },
];

export default function EventBookingScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const formRef = useRef();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState([
    "pending_payment",
    "active",
    "expired",
    "payment_cancelled",
    "refunded",
  ]);
  const [business, setBusiness] = useState(null);
  const [categories, setCategories] = useState(null);
  const [events, setEvents] = useState(null);
  const [q, setQ] = useState("");
  const [users, setUsers] = useState(null);
  const [sessionCategories, setSessionCategories] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { getEventCategoryListObj } = useSelector(eventCategorySelector);
  const { getEventBookingListObj, getEventBookingCSVObj, cancelEventBookingObj } =
    useSelector(eventBookingSelector);
  const columnMapping = {
    Name: "session.user.name",
    "Is Guest": "session.isGuest",
    Phone: "session.user.phone",
    Status: "status",
    "Start At": "session.startAt",
    "End At": "session.endAt",
    "Event Title": "session.event.title",
    Price: "price",
    Business: "session.event.business.name",
  };

  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);
  const getProcessedCategoryList = () => {
    const itemList = [];
    getEventCategoryListObj.data.map((item) =>
      itemList.push({
        label: item.label,
        id: item.id,
        value: item.id,
      }),
    );
    return itemList;
  };

  const onRefreshBookingList = (
    keyword,
    statuses,
    businessIds,
    categoryIds,
    eventIds,
    userIds,
    sessionCategoryIds,
    startAt,
    endAt,
    newPage,
  ) => {
    setQ(keyword);
    setStatus(statuses);
    setBusiness(businessIds);
    setCategories(categoryIds);
    setEvents(eventIds);
    setUsers(userIds);
    setSessionCategories(sessionCategoryIds);
    setStartDate(startAt);
    setEndDate(endAt);
    setPage(newPage);

    dispatch(
      getEventBookingList({
        q: keyword,
        statuses,
        businessIds,
        categoryIds,
        eventIds,
        userIds,
        sessionCategoryIds,
        startAt,
        endAt,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(
      getEventBookingCSV({
        statuses,
        businessIds,
        categoryIds,
        eventIds,
        userIds,
        startAt,
        endAt,
      }),
    ).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const submitFilter = (values) => {
    const businessIds = values.businessIds.map((item) => item.id);
    const eventIds = values.eventIds.map((item) => item.id);
    const userIds = values.userIds.map((item) => item.id);
    const sessionCategoryIds = values.sessionCategoryIds.map((item) => item.id);
    const startAt = values.startAt && moment(values.startAt).format("Y-MM-DD 00:00:00.000");
    const endAt = values.endAt && moment(values.endAt).format("Y-MM-DD 23:59:59.999");
    onRefreshBookingList(
      values.q,
      values.statuses,
      businessIds,
      values.categoryIds,
      eventIds,
      userIds,
      sessionCategoryIds,
      startAt,
      endAt,
      1,
    );
  };

  const clearFilter = () => {
    formRef.current.setFieldValue("q", "");
    formRef.current.setFieldValue("statuses", status);
    formRef.current.setFieldValue("businessIds", []);
    formRef.current.setFieldValue("categoryIds", []);
    formRef.current.setFieldValue("eventIds", []);
    formRef.current.setFieldValue("userIds", []);
    formRef.current.setFieldValue("sessionCategoryIds", []);
    formRef.current.setFieldValue("startAt", null);
    formRef.current.setFieldValue("endAt", null);
  };

  const onPageChange = (e, newPage) => {
    onRefreshBookingList(
      q,
      status,
      business,
      categories,
      events,
      users,
      sessionCategories,
      startDate,
      endDate,
      newPage,
    );
  };

  const onChangeSearch = (keyword) => {
    onRefreshBookingList(
      keyword,
      status,
      business,
      categories,
      events,
      users,
      sessionCategories,
      startDate,
      endDate,
      1,
    );
  };

  const onHandleCancel = (bookingId) => {
    setSelectedBookingId(bookingId);
    setShowDeleteModal(true);
  };

  const onConfirmCancel = () => {
    setIsLoading(true);
    dispatch(
      cancelEventBooking({
        bookingIds: [selectedBookingId],
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshBookingList(
          q,
          status,
          business,
          categories,
          events,
          users,
          sessionCategories,
          startDate,
          endDate,
          page,
        );
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getEventBookingListObj.data && getEventBookingListObj.status === "succeeded") {
      records = { ...getEventBookingListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (getEventBookingListObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getEventBookingListObj.data?.items?.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={9}>
              <TableEmptyBox>
                <Text type="TableText">No items</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records.items.map((item) => (
        <BookingTableRow booking={item} key={item.id} onHandleCancel={onHandleCancel} />
      ));
    }
    return <BookingTableRowLoader />;
  };

  useEffect(() => {
    dispatch(getEventCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    onRefreshBookingList(
      q,
      status,
      business,
      categories,
      events,
      users,
      sessionCategories,
      startDate,
      endDate,
      1,
    );
  }, []);

  if (getEventCategoryListObj.status === "succeeded")
    return (
      <>
        <DeleteConfirmationModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          title="Cancel"
          label="Are you sure you wish to cancel this booking?"
          isLoading={cancelEventBookingObj.status === "pending"}
          onConfirmClicked={onConfirmCancel}
        />
        <BackdropLoading isLoading={isLoading} />

        <Formik
          innerRef={formRef}
          initialValues={{
            statuses: status,
            businessIds: [],
            categoryIds: [],
            eventIds: [],
            userIds: [],
            q: "",
            sessionCategoryIds: [],
            startAt: null,
            endAt: null,
          }}
          onSubmit={submitFilter}
          validationSchema={validationSchema}
        >
          <Grid
            container
            spacing={3}
            sx={{
              paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
              paddingY: theme.dimensions.ScreenPaddingY,
            }}
          >
            <Grid item container>
              <Text variant="screenLabel">Event Bookings</Text>
            </Grid>
            <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
              <WhiteBgCardContainer>
                <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Events</Text>
                            </Grid>

                            <Grid item xs={9}>
                              <EventListAutoComplete name="eventIds" placeholder="Events" />
                            </Grid>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Businesses</Text>
                            </Grid>

                            <Grid item xs={9}>
                              <BusinessListAutoComplete
                                name="businessIds"
                                placeholder="Businesses"
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Users</Text>
                            </Grid>

                            <Grid item xs={9}>
                              <SearchBar
                                placeholder="Search by user info"
                                name="q"
                                searchKeyword={q}
                                setSearchKeyword={onChangeSearch}
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Categories</Text>
                            </Grid>
                            <Grid item xs={9}>
                              <FormMultipleSelect
                                itemList={getProcessedCategoryList()}
                                name="categoryIds"
                                placeholder="Categories"
                                isWhiteBg
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={isSmallScreen ? 12 : 6}>
                              <FormContainer>
                                <Grid item xs={3}>
                                  <Text>Start date</Text>
                                </Grid>
                                <Grid item xs={9}>
                                  <FormDatePicker
                                    name="startAt"
                                    width="100%"
                                    isWhiteBg
                                    maxDays={null}
                                    minDays={false}
                                    showClear={true}
                                  />
                                </Grid>
                              </FormContainer>
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : 6}>
                              <FormContainer>
                                <Grid item xs={3}>
                                  <Text>End date</Text>
                                </Grid>

                                <Grid item xs={9}>
                                  <FormDatePicker
                                    name="endAt"
                                    width="100%"
                                    isWhiteBg
                                    maxDays={null}
                                    minDays={false}
                                    showClear={true}
                                  />
                                </Grid>
                              </FormContainer>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={isSmallScreen ? 3 : 3.5}>
                              <Text>Status</Text>
                            </Grid>
                            <Grid item xs={isSmallScreen ? 9 : 10.5}>
                              <FormMultipleSelect
                                itemList={STATUSLIST}
                                name="statuses"
                                placeholder="Status"
                                isWhiteBg
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Session Category</Text>
                            </Grid>
                            <Grid item xs={9}>
                              <SessionCategoryListAutoComplete
                                name="sessionCategoryIds"
                                placeholder="Search by session category label"
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                        <Grid item xs={12} sm={3}>
                          {getEventBookingCSVObj.status === "succeeded" ? (
                            <>
                              <CSVLink
                                data={getEventBookingCSVObj.data}
                                filename={`event_bookings${
                                  startDate
                                    ? `_${moment(startDate).format("Y-MM-DD")}-${moment(
                                        endDate,
                                      ).format("Y-MM-DD")}`
                                    : ""
                                }.csv`}
                                style={{ textDecoration: "none" }}
                              >
                                <CtaBorderButton
                                  disabled={getEventBookingCSVObj.status !== "succeeded"}
                                >
                                  <Typography
                                    sx={{
                                      color:
                                        getEventBookingCSVObj.status === "succeeded"
                                          ? theme.palette.colors.brand.primary
                                          : theme.palette.colors.text.disabled,
                                    }}
                                  >
                                    Download CSV
                                  </Typography>
                                </CtaBorderButton>
                              </CSVLink>
                            </>
                          ) : (
                            <>
                              <CtaBorderButton disabled={true}>
                                <Typography
                                  sx={{
                                    color: theme.palette.colors.text.disabled,
                                  }}
                                >
                                  Download CSV
                                </Typography>
                              </CtaBorderButton>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <CtaButton whiteBg onClickButton={clearFilter}>
                            <Text type="BrandColor">Clear All</Text>
                          </CtaButton>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <FormSubmitButton>
                            <Text type="WhiteColor">Search</Text>
                          </FormSubmitButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </SearchContainer>

                <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                  <Grid item xs={12}>
                    <TableWrapper>
                      <Table>
                        <TableHeader
                          headerCells={[
                            "Name",
                            "Is Guest",
                            "Phone",
                            "Status",
                            "Start At",
                            "End At",
                            "Event Title",
                            "Price",
                            "Business",
                            "Action",
                          ]}
                          sortColumn={sortColumn}
                          sortOrder={sortOrder}
                          onSortChange={onSortChange}
                          columnMapping={columnMapping}
                        />
                        <TableBody>{renderTableBody()}</TableBody>
                      </Table>
                    </TableWrapper>
                  </Grid>

                  <Grid item xs={12}>
                    <CustomFooter>
                      <Pagination
                        sx={{
                          "&& .Mui-selected": {
                            backgroundColor: theme.palette.colors.brand.primary,
                            color: theme.palette.colors.text.white,
                          },
                        }}
                        page={page}
                        shape="rounded"
                        onChange={onPageChange}
                        count={getEventBookingListObj?.data?.pagination.totalPages || 1}
                        variant="outlined"
                      />
                    </CustomFooter>
                  </Grid>
                </Grid>
              </WhiteBgCardContainer>
            </Grid>
          </Grid>
        </Formik>
      </>
    );
}
