import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  profileSelector,
  updateProfileDetail,
  uploadProfileLogo,
} from "../../../services/profile/profile.slice.services";
import ProfileAccountDetailLoader from "../loader/profile-account-detail-loader.component";
import ProfileAccountDetailEditForm from "./profile-account-detail-edit-form.component";
import ProfileAvatar from "./profile-avatar.component";
import ProfileLogoEditForm from "./profile-logo-edit-form.component";
import ProfilePasswordEditForm from "./profile-password-edit-form.component";
import ProfilePhoneEditForm from "./profile-phone-edit-form.component";
import {
  profileStaffSelector,
  updateStaffProfileDetail,
} from "../../../services/staff/profile/profile.slice.services";
import { isMerchantStaff } from "../../../infrastructure/utils";

const businessNameValidationSchema = Yup.object().shape({
  businessName: Yup.string().required().label("Business Name"),
  updateType: Yup.string().required(),
});

const firstNameValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  updateType: Yup.string().required(),
});

const lastNameValidationSchema = Yup.object().shape({
  lastName: Yup.string().required().label("First Name"),
  updateType: Yup.string().required(),
});

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  updateType: Yup.string().required(),
});

const phoneValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required()
    .label("Phone number")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{8}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid.",
    ),
  updateType: Yup.string().required(),
});

const passwordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required().min(4).label("Current Password"),
  newPassword: Yup.string().required().min(4).label("New Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("newPassword"), null], "Password and confirm password must be same.")
    .label("Password confirmation"),
});

const logoValidationSchema = Yup.object().shape({
  image: Yup.string().nullable().required().label("Logo"),
  updateType: Yup.string().required(),
});

export default function ProfileAccountDetail() {
  const theme = useTheme();
  const { getProfileDetailObj } = useSelector(profileSelector);
  const { getStaffProfileDetailObj } = useSelector(profileStaffSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const history = useHistory();
  const [businessNameEditing, setBusinessNameEditing] = useState(false);
  const [firstNameEditing, setFirstNameEditing] = useState(false);
  const [lastNameEditing, setLastNameEditing] = useState(false);
  const [phoneEditing, setPhoneEditing] = useState(false);
  const [emailEditing, setEmailEditing] = useState(false);
  const [passwordEditing, setPasswordEditing] = useState(false);
  const [logoEditing, setLogoEditing] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);

  const onUpdateDetail = (values) => {
    setIsLoading(true);

    if (!isMerchantStaff()) {
      dispatch(updateProfileDetail(values)).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          if (values.updateType === "businessName") {
            setBusinessNameEditing(false);
          }
          if (values.updateType === "lastName") {
            setLastNameEditing(false);
          }
          if (values.updateType === "firstName") {
            setFirstNameEditing(false);
          }
          if (values.updateType === "phone") {
            setPhoneEditing(false);
            history.push({
              pathname: routes.PHONE_VERIFICATION,
              state: { phone: payload.data.phone, backToProfile: true },
            });
          }
          if (values.updateType === "email") {
            setEmailEditing(false);
            history.push({
              pathname: routes.EMAIL_VERIFICATION,
              state: { email: payload.data.email, backToProfile: true },
            });
          }
          if (values.updateType === "password") {
            setPasswordEditing(false);
          }
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      });
    }
    if (isMerchantStaff()) {
      dispatch(updateStaffProfileDetail(values)).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          if (values.updateType === "businessName") {
            setBusinessNameEditing(false);
          }
          if (values.updateType === "lastName") {
            setLastNameEditing(false);
          }
          if (values.updateType === "firstName") {
            setFirstNameEditing(false);
          }
          if (values.updateType === "phone") {
            setPhoneEditing(false);
          }
          if (values.updateType === "password") {
            setPasswordEditing(false);
          }
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      });
    }
  };

  const onUpdateLogo = (values) => {
    setIsLoading(true);
    dispatch(uploadProfileLogo(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (values.updateType === "image") {
          setLogoEditing(false);
        }
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (getProfileDetailObj.status === "succeeded") {
      return (
        <>
          <Text variant="screenLabel">Profile</Text>
          <Grid container sx={{ paddingY: "20px" }} spacing={3}>
            {isSmallScreen && (
              <Grid item xs={12}>
                <ProfileAvatar inverse />
              </Grid>
            )}
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "businessName",
                  businessName: getProfileDetailObj.data.businessName,
                }}
                validationSchema={businessNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfileAccountDetailEditForm
                  name="businessName"
                  placeholder="Enter business name"
                  label="Business Name"
                  isEditing={businessNameEditing}
                  setIsEditing={setBusinessNameEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "firstName",
                  firstName: getProfileDetailObj.data.firstName,
                }}
                validationSchema={firstNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfileAccountDetailEditForm
                  name="firstName"
                  placeholder="Enter first name"
                  label="First Name"
                  isEditing={firstNameEditing}
                  setIsEditing={setFirstNameEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "lastName",
                  lastName: getProfileDetailObj.data.lastName,
                }}
                validationSchema={lastNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfileAccountDetailEditForm
                  name="lastName"
                  placeholder="Enter last name"
                  label="Last Name"
                  isEditing={lastNameEditing}
                  setIsEditing={setLastNameEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "phone",
                  phone: getProfileDetailObj.data.phone,
                }}
                validationSchema={phoneValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfilePhoneEditForm
                  name="phone"
                  placeholder="Enter contact number"
                  label="Contact Number"
                  isEditing={phoneEditing}
                  setIsEditing={setPhoneEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "email",
                  email: getProfileDetailObj.data.email,
                }}
                validationSchema={emailValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfileAccountDetailEditForm
                  name="email"
                  placeholder="Enter email"
                  label="Email"
                  isEditing={emailEditing}
                  setIsEditing={setEmailEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                  updateType: "password",
                }}
                validationSchema={passwordValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfilePasswordEditForm
                  isEditing={passwordEditing}
                  setIsEditing={setPasswordEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12} />

            <Grid item xs={12}>
              <Form
                initialValues={{
                  image: getProfileDetailObj.data.logo,
                  updateType: "image",
                }}
                validationSchema={logoValidationSchema}
                onSubmit={onUpdateLogo}
              >
                <ProfileLogoEditForm
                  name="image"
                  label="Logo"
                  isEditing={logoEditing}
                  setIsEditing={setLogoEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </>
      );
    }
    if (getStaffProfileDetailObj.status === "succeeded") {
      return (
        <>
          <Text variant="screenLabel">Profile</Text>
          <Grid container sx={{ paddingY: "20px" }} spacing={3}>
            {isSmallScreen && (
              <Grid item xs={12}>
                <ProfileAvatar inverse />
              </Grid>
            )}
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "businessName",
                  businessName: getStaffProfileDetailObj?.data?.businessName,
                }}
                validationSchema={businessNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfileAccountDetailEditForm
                  name="businessName"
                  placeholder="Enter business name"
                  label="Business Name"
                  isEditing={businessNameEditing}
                  setIsEditing={setBusinessNameEditing}
                  allowEdit={false}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "firstName",
                  firstName: getStaffProfileDetailObj?.data?.firstName,
                }}
                validationSchema={firstNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfileAccountDetailEditForm
                  name="firstName"
                  placeholder="Enter first name"
                  label="First Name"
                  isEditing={firstNameEditing}
                  setIsEditing={setFirstNameEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "lastName",
                  lastName: getStaffProfileDetailObj?.data?.lastName,
                }}
                validationSchema={lastNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfileAccountDetailEditForm
                  name="lastName"
                  placeholder="Enter last name"
                  label="Last Name"
                  isEditing={lastNameEditing}
                  setIsEditing={setLastNameEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "phone",
                  phone: getStaffProfileDetailObj?.data?.phone,
                }}
                validationSchema={phoneValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfilePhoneEditForm
                  name="phone"
                  placeholder="Enter contact number"
                  label="Contact Number"
                  isEditing={phoneEditing}
                  setIsEditing={setPhoneEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  updateType: "email",
                  email: getStaffProfileDetailObj?.data?.email,
                }}
                validationSchema={emailValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfileAccountDetailEditForm
                  name="email"
                  placeholder="Enter email"
                  label="Email"
                  isEditing={emailEditing}
                  setIsEditing={setEmailEditing}
                  allowEdit={false}
                />
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                  updateType: "password",
                }}
                validationSchema={passwordValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <ProfilePasswordEditForm
                  isEditing={passwordEditing}
                  setIsEditing={setPasswordEditing}
                />
              </Form>
            </Grid>
            <Grid item xs={12} />

            <Grid item xs={12}>
              <Form
                initialValues={{
                  image: getStaffProfileDetailObj?.data?.logo,
                  updateType: "image",
                }}
                validationSchema={logoValidationSchema}
                onSubmit={onUpdateLogo}
              >
                <ProfileLogoEditForm
                  name="image"
                  label="Logo"
                  isEditing={logoEditing}
                  setIsEditing={setLogoEditing}
                  allowEdit={false}
                />
              </Form>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </>
      );
    }
    return <ProfileAccountDetailLoader />;
  };

  return (
    <Grid item xs={12}>
      <BackdropLoading isLoading={isLoading} />
      {renderContent()}
    </Grid>
  );
}
