import { TableCell, TableRow, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import Text from "../../../components/text.component";

export default function EntryLogTableRow({ record, index }) {
  const theme = useTheme();
  return (
    <>
      <TableRow
        key={uuidv4()}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "0px",
          },
        }}
      >
        <TableCell width="25px">
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {index + 1}.
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.user.name}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.business.name}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.createdAt}
          </Text>
        </TableCell>
        <TableCell>
          <Text
            variant="body2"
            sx={{
              textAlign: "center",
              color: record.valid
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.text.rejected,
            }}
          >
            {record.valid ? "Valid" : "Invalid"}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

EntryLogTableRow.propTypes = {
  record: PropTypes.shape({
    user: PropTypes.shape({ name: PropTypes.string }),
    business: PropTypes.shape({ name: PropTypes.string }),
    createdAt: PropTypes.string,
    valid: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
};
