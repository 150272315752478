import React from "react";
import { TableCell, TableRow } from "@mui/material";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";

export default function AppointmentTableRowLoader() {
  return (
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell sx={{ width: "200px" }}>
        <SkeletonLoader width="100%" />
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <SkeletonLoader width="100%" />
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <SkeletonLoader width="100%" />
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <SkeletonLoader width="100%" />
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <SkeletonLoader width="100%" />
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <SkeletonLoader width="100%" />
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <SkeletonLoader width="100%" />
      </TableCell>
    </TableRow>
  );
}
