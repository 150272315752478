import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../api-services";

export const getSchedules = createAsyncThunk("merchants/schedules/index", async (payload) => {
  const { planIds, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/schedules?${
      page ? "page=".concat(page) : "page=1"
    }${planIds ? "&planIds=".concat(planIds) : ""}`,
  );
  return response;
});

export const showSchedule = createAsyncThunk(
  "merchants/schedules/{schedule_id}",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/schedules/${payload.scheduleId}`,
      payload,
    );
    return response;
  },
);

export const createSchedule = createAsyncThunk("merchants/schedules/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/schedules`,
    payload,
  );
  return response;
});

export const updateSchedule = createAsyncThunk(
  "merchants/schedules/update/{schedule_id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/schedules/${payload.scheduleId}`,
      payload,
    );
    return response;
  },
);

export const updateScheduleSlots = createAsyncThunk(
  "merchants/schedules/update/{schedule_id}/slots",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/schedules/${payload.scheduleId}/slots`,
      payload,
    );
    return response;
  },
);

const scheduleSlice = createSlice({
  name: "schedule",
  initialState: {
    getSchedulesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    showScheduleObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createScheduleObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateScheduleObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateScheduleSlotsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getSchedules.pending]: (state) => {
      state.getSchedulesObj.status = "pending";
    },
    [getSchedules.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSchedulesObj.status = "succeeded";
      state.getSchedulesObj.data = data;
      state.getSchedulesObj.successMessage = message;
    },
    [getSchedules.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSchedulesObj.status = "failed";
      state.getSchedulesObj.errorMessage = message;
    },

    [showSchedule.pending]: (state) => {
      state.showScheduleObj.status = "pending";
    },
    [showSchedule.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.showScheduleObj.status = "succeeded";
      state.showScheduleObj.data = data;
      state.showScheduleObj.successMessage = message;
    },
    [showSchedule.rejected]: (state, action) => {
      const { message } = action.error;

      state.showScheduleObj.status = "failed";
      state.showScheduleObj.errorMessage = message;
    },
    [createSchedule.pending]: (state) => {
      state.createScheduleObj.status = "pending";
    },
    [createSchedule.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createScheduleObj.status = "succeeded";
      state.createScheduleObj.data = data;
      state.createScheduleObj.successMessage = message;
    },
    [createSchedule.rejected]: (state, action) => {
      const { message } = action.error;

      state.createScheduleObj.status = "failed";
      state.createScheduleObj.errorMessage = message;
    },
    [updateSchedule.pending]: (state) => {
      state.updateScheduleObj.status = "pending";
    },
    [updateSchedule.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateScheduleObj.status = "succeeded";
      state.updateScheduleObj.data = data;
      state.updateScheduleObj.successMessage = message;
    },
    [updateSchedule.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateScheduleObj.status = "failed";
      state.updateScheduleObj.errorMessage = message;
    },
    [updateScheduleSlots.pending]: (state) => {
      state.updateScheduleSlotsObj.status = "pending";
    },
    [updateScheduleSlots.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateScheduleSlotsObj.status = "succeeded";
      state.updateScheduleSlotsObj.data = data;
      state.updateScheduleSlotsObj.successMessage = message;
    },
    [updateScheduleSlots.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateScheduleSlotsObj.status = "failed";
      state.updateScheduleSlotsObj.errorMessage = message;
    },
  },
});

export default scheduleSlice.reducer;

// state
export const scheduleSelector = (state) => state.schedule;
