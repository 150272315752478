import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../../api-services";

export const getUserPackages = createAsyncThunk(
  "merchants/users/{user_Id}/packages",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/users/${payload.userId}/packages?page=${payload.page}`,
    );
    return response;
  },
);

const packageUserSlice = createSlice({
  name: "packageUser",
  initialState: {
    getUserPackagesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getUserPackages.pending]: (state) => {
      state.getUserPackagesObj.status = "pending";
    },
    [getUserPackages.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserPackagesObj.status = "succeeded";
      state.getUserPackagesObj.data = data;
      state.getUserPackagesObj.successMessage = message;
    },
    [getUserPackages.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserPackagesObj.status = "failed";
      state.getUserPackagesObj.errorMessage = message;
    },
  },
});

export default packageUserSlice.reducer;

// state
export const packageUserSelector = (state) => state.packageUser;
