import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../../api-services";

export const getUserFitnessClasses = createAsyncThunk(
  "merchants/users/{user_Id}/fitness_classes",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/users/${payload.userId}/fitness_classes?page=${payload.page}`,
    );
    return response;
  },
);

const fitnessClassUserSlice = createSlice({
  name: "fitnessClassUser",
  initialState: {
    getUserFitnessClassesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getUserFitnessClasses.pending]: (state) => {
      state.getUserFitnessClassesObj.status = "pending";
    },
    [getUserFitnessClasses.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserFitnessClassesObj.status = "succeeded";
      state.getUserFitnessClassesObj.data = data;
      state.getUserFitnessClassesObj.successMessage = message;
    },
    [getUserFitnessClasses.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserFitnessClassesObj.status = "failed";
      state.getUserFitnessClassesObj.errorMessage = message;
    },
  },
});

export default fitnessClassUserSlice.reducer;

// state
export const fitnessClassUserSelector = (state) => state.fitnessClassUser;
