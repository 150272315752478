import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import Text from "../../../components/text.component";
import DateEventModal from "../components/date-event-modal.component";
import { getOffDayDetail } from "../../../services/off-day/off-day.slice.service";

const CalendarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",

  ".fc-button-primary": {
    border: `1px solid ${theme.palette.colors.brand.primary}`,
    backgroundColor: theme.palette.colors.brand.primary,
    boxShadow: "none",
    ":hover, :active, :disabled, :focus": {
      backgroundColor: theme.palette.colors.brand.primary,
      border: `1px solid ${theme.palette.colors.brand.primary}`,
      boxShadow: "none",
      ":focus": {
        backgroundColor: theme.palette.colors.brand.primary,
        border: `1px solid ${theme.palette.colors.brand.primary}`,
        boxShadow: "none",
      },
    },
  },
  ".fc-highlight": {
    backgroundColor: theme.palette.colors.brand.secondary,
    border: `1px solid ${theme.palette.colors.brand.primary}`,
  },
  "& .MuiOutlinedInput-root": {
    " &.Mui-focused fieldset": {
      borderColor: theme.palette.colors.brand.secondary,
    },
  },
  "& .MuiSelect-iconOutlined": {
    color: theme.palette.colors.brand.secondary,
  },
}));

export default function OffDayCalendarScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [calendarEvent, setCalendarEvent] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const getCalendarEvent = (publicHoliday, offDay) => {
    const event = [];

    publicHoliday.map((item) =>
      event.push({
        start: item.start.date,
        end: item.end.date,
        id: item.id,
        title: item.summary,
      }),
    );

    Object.keys(offDay).map((date, index) =>
      event.push({
        start: date,
        end: date,
        id: index,
        title: "Off Day",
        textColor: "#DC3545",
        businesses: offDay[date],
      }),
    );

    setCalendarEvent(event);
  };

  const loadCalenderData = (start) => {
    setIsLoading(true);
    const BASE_CALENDAR_URL = "https://www.googleapis.com/calendar/v3/calendars";
    const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY = "holiday@group.v.calendar.google.com";
    const API_KEY = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY;
    const CALENDAR_REGION = "en.malaysia";
    const timeMin = moment(new Date(start)).startOf("month").toISOString();
    const timeMax = moment(new Date(start)).endOf("month").toISOString();

    const url = `${BASE_CALENDAR_URL}/${CALENDAR_REGION}%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?timeMin=${timeMin}&timeMax=${timeMax}&key=${API_KEY}`;

    axios.get(url).then((res) => {
      if (res.status === 200) {
        dispatch(
          getOffDayDetail({
            startDate: moment(timeMin).format("YYYY-MM-DD"),
            endDate: moment(timeMax).format("YYYY-MM-DD"),
          }),
        ).then(({ meta, payload }) => {
          if (meta.requestStatus === "fulfilled") {
            getCalendarEvent(res.data.items, payload.data);
            setIsLoading(false);
          }
        });
      }
    });
  };

  const handleMonthChange = async (payload) => {
    if (currentStartDate !== payload.startStr) {
      setCurrentStartDate(payload.startStr);
      loadCalenderData(new Date(payload.startStr));
    }
  };

  const refreshCalendar = () => {
    loadCalenderData(new Date(currentStartDate));
  };

  const onChangeDate = (date) => {
    setSelectedDateEvents(calendarEvent.filter((item) => item.start === date));
    setSelectedDate(date);
    setShowDetailModal(true);
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        overflowX: "auto",
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      {showDetailModal && (
        <DateEventModal
          events={selectedDateEvents}
          selectedDate={selectedDate}
          showModal={showDetailModal}
          setShowModal={setShowDetailModal}
          refreshCalendar={refreshCalendar}
        />
      )}
      <BackdropLoading isLoading={isLoading} />
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Text variant="screenLabel">Calendar</Text>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CalendarContainer>
          <FullCalendar
            aspectRatio={isSmallScreen ? 3 / 4 : 2}
            showNonCurrentDates={false}
            headerToolbar={{ left: "title", center: "", right: "prev,next" }}
            datesSet={(i) => handleMonthChange(i)}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={calendarEvent}
            eventOrder="-title"
            eventBackgroundColor="transparent"
            eventTextColor={theme.palette.colors.brand.primary}
            eventBorderColor="transparent"
            eventClick={(i) => onChangeDate(format(new Date(i.event.startStr), "yyyy-MM-dd"))}
            select={(i) => onChangeDate(format(new Date(i.startStr), "yyyy-MM-dd"))}
            selectable={true}
            validRange={{
              start: "2022-04-01",
            }}
            firstDay={1}
          />
        </CalendarContainer>
      </Grid>
    </Grid>
  );
}
