import { Box, FormHelperText, styled, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format, subYears } from "date-fns";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
}));

export default function FormDobPicker({ name, isWhiteBg, width }) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (date) => {
    setFieldValue(name, format(date, "yyyy-MM-dd"));
  };

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", width }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          maxDate={subYears(new Date(), 18)}
          inputFormat="dd/MM/yyyy"
          value={values[name]}
          onChange={handleChange}
          InputProps={{ error: showError }}
          renderInput={(params) => (
            <StyledTextField
              sx={{
                width,
                backgroundColor: isWhiteBg
                  ? theme.palette.colors.bg.secondary
                  : theme.palette.colors.bg.primary,
              }}
              variant="outlined"
              onBlur={() => setFieldTouched(name)}
              {...params}
            />
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </LocalizationProvider>
    </Box>
  );
}
FormDobPicker.defaultProps = {
  width: null,
  isWhiteBg: false,
};

FormDobPicker.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  isWhiteBg: PropTypes.bool,
};
