import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Checkbox, Chip, ListItemText, OutlinedInput, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";
import Text from "../text.component";

export default function FormMultipleSelect({
  itemList,
  name,
  placeholder,
  width,
  isTransparent,
  isWhiteBg,
  disabled,
}) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext("");
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (event) => {
    setFieldValue(
      name,
      typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value,
    );
  };

  return (
    <FormControl sx={{ width }}>
      <Select
        multiple
        onBlur={() => setFieldTouched(name)}
        value={values[name]}
        onChange={handleChange}
        disabled={disabled}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: isTransparent
            ? "transparent"
            : isWhiteBg
            ? theme.palette.colors.bg.secondary
            : theme.palette.colors.bg.primary,
        }}
        input={<OutlinedInput />}
        error={showError}
        displayEmpty
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return <Text type="GreyColor">{placeholder}</Text>;
          }

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected?.map((value) => (
                <Chip
                  key={value}
                  label={itemList.find((option) => option.value === value).label}
                  sx={{ textTransform: "capitalize" }}
                />
              ))}
            </Box>
          );
        }}
      >
        {itemList.map((item) => (
          <MenuItem key={uuidv4()} value={item.value}>
            <Checkbox checked={values[name]?.includes(item.value)} />
            <ListItemText primary={item.label} sx={{ textTransform: "capitalize" }} />
          </MenuItem>
        ))}
      </Select>
      {showError && (
        <FormHelperText error sx={{ marginLeft: "0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormMultipleSelect.defaultProps = {
  width: "100%",
  isTransparent: false,
  isWhiteBg: false,
  disabled: false,
};

FormMultipleSelect.propTypes = {
  width: PropTypes.string,
  itemList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
  isWhiteBg: PropTypes.bool,
  disabled: PropTypes.bool,
};
