import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function ClassTableRow({ detail, setShowEditModal, onHandleDelete }) {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <TableRow
        key={detail.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {detail.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {detail.business.name}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {detail.category.label}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => {
                setFieldValue("name", detail.name);
                setFieldValue("categoryId", detail.category.id);
                setFieldValue("classId", detail.id);
                setShowEditModal(true);
              }}
            >
              <EditIcon />
            </IconButton>

            <IconButton
              onClick={() => onHandleDelete(detail.id)}
              disabled={detail.deletable === false}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

ClassTableRow.propTypes = {
  detail: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    category: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    deletable: PropTypes.bool,
  }).isRequired,
  setShowEditModal: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired,
};
