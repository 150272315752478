import { Box, Button, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import ResendCountdownButton from "../../../components/button/resend-countdown-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormOTP from "../../../components/forms/form-otp.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import KatchLogo from "../../../images/katch-logo.png";
import routes from "../../../navigation/routes";
import {
  authStaffSelector,
  staffResendVerificationCode,
  staffResetPassword,
} from "../../../services/staff/auth/auth.slice.services";

const MainContainer = styled(Box)({
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  width: "90%",
  maxWidth: "450px",
  backgroundColor: theme.palette.colors.bg.secondary,
  display: "flex",
  flexDirection: "row",
}));

const CenteredBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  typeInfo: Yup.string().required(),
  verificationCode: Yup.string().min(6).required().label("Verification Code"),
  password: Yup.string().required().min(4).label("Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password and confirm password must be same.")
    .label("Password confirmation"),
});

const TextButton = styled(Button)({
  textTransform: "none",
  ":hover": { backgroundColor: "transparent" },
});

export default function ResetPasswordStaffScreen() {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { staffResetPasswordObj } = useSelector(authStaffSelector);
  const [isConfirmPressed, setIsConfirmPressed] = useState(false);
  const location = useLocation();
  const { type, typeInfo } = location.state;
  const [isResetSuccesss, setIsResetSuccess] = useState(false);

  const onResendVerificationCode = () => {
    dispatch(
      staffResendVerificationCode({
        type,
        infoType: typeInfo,
        functionType: "forgotPassword",
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onConfirmSubmitForm = (values) => {
    dispatch(staffResetPassword(values)).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsResetSuccess(true);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onBack = () => {
    history.push(routes.FORGOT_PASSWORD_STAFF);
  };

  const renderResetForm = () => (
    <>
      <Text
        sx={{
          fontSize: theme.fonts.fontSizes.size30,
          fontWeight: theme.fonts.fontWeights.bold,
        }}
      >
        Create new password
      </Text>
      <Spacer size="s" position="top" />
      <Text type="GreyColor">
        A verification code has been sent to {type === "phone" ? `+${typeInfo}` : typeInfo}
      </Text>
      <FormOTP
        name="verificationCode"
        width={isMobile ? "30px" : "50px"}
        setIsConfirmPressed={setIsConfirmPressed}
        isConfirmPressed={isConfirmPressed}
      />
      <Spacer size="s" position="top" />
      <ResendCountdownButton onClickButton={onResendVerificationCode} />

      <Spacer size="l" position="top" />
      <Box sx={{ width: "100%" }}>
        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>New Password</Text>
        <Spacer size="xs" position="top" />
        <FormFieldText name="password" placeholder="Enter your new password" />
      </Box>
      <Spacer size="m" position="top" />
      <Box sx={{ width: "100%" }}>
        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Confirm New Password</Text>
        <Spacer size="xs" position="top" />
        <FormFieldText
          name="confirmPassword"
          placeholder="Confirm your new password"
          type="password"
        />
      </Box>
      <Spacer size="xl" position="top" />
      <Box sx={{ width: "100%" }}>
        <FormSubmitButton
          isLoading={staffResetPasswordObj.status === "pending"}
          onClickButton={() => setIsConfirmPressed(true)}
        >
          <Text type="WhiteColor">Confirm</Text>
        </FormSubmitButton>
      </Box>
      <Spacer size="m" position="top" />

      <TextButton onClick={onBack}>
        <Text>Back</Text>
      </TextButton>
    </>
  );

  const renderResetSuccess = () => (
    <>
      <Text
        sx={{
          fontSize: theme.fonts.fontSizes.size30,
          fontWeight: theme.fonts.fontWeights.bold,
        }}
      >
        Changed Password
      </Text>
      <Spacer size="xl" position="top" />
      <Text type="GreyColor">You can now login to your account with new password.</Text>

      <Spacer size="xl" position="top" />
      <Box sx={{ width: "100%" }}>
        <CtaButton
          onClickButton={() => {
            setIsResetSuccess(false);
            history.push(routes.LOGIN_STAFF);
          }}
        >
          <Text type="WhiteColor">Login</Text>
        </CtaButton>
      </Box>
    </>
  );

  return (
    <Form
      initialValues={{ type, typeInfo, verificationCode: "", password: "", confirmPassword: "" }}
      validationSchema={validationSchema}
      onSubmit={onConfirmSubmitForm}
    >
      <MainContainer>
        <CardContainer>
          <CenteredBox sx={{ padding: isMobile ? "20px" : "50px" }}>
            <img src={KatchLogo} width="150px" alt="katch-logo" />
            <Spacer position="top" size="m" />
            {isResetSuccesss ? renderResetSuccess() : renderResetForm()}
          </CenteredBox>
        </CardContainer>
      </MainContainer>
    </Form>
  );
}
