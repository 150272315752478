import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq, setAxiosToken } from "../api-services";

export const login = createAsyncThunk("auth/merchants/login", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/login`,
    payload,
  );
  return response;
});

export const register = createAsyncThunk("auth/merchants/register", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/register`,
    payload,
  );
  return response;
});

export const resendVerificationCode = createAsyncThunk(
  "auth/merchants/resend_verification_code",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/resend_verification_code`,
      payload,
    );
    return response;
  },
);

export const verifyVerificationCode = createAsyncThunk(
  "auth/merchants/verify_verification_code",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/verify`,
      payload,
    );
    return response;
  },
);

export const emailCheck = createAsyncThunk("auth/merchants/check_email", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/check_email`,
    payload,
  );
  return response;
});

export const logout = createAsyncThunk("auth/merchants/logout", async () => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/merchants/logout`);
  return response;
});

export const forgotPassword = createAsyncThunk(
  "auth/merchants/forgot_password",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/forgot_password`,
      payload,
    );
    return response;
  },
);

export const resetPassword = createAsyncThunk("auth/merchants/reset_password", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/reset_password`,
    payload,
  );
  return response;
});

export const updateMerchantDetail = createAsyncThunk(
  "auth/merchants/update_MERCHANT_DETAIL",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/details`,
      payload,
    );
    return response;
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loginObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    registerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resendVerificationCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    verifyVerificationCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    emailCheckObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    logoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    forgotPasswordObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
    resetPasswordObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
    updateMerchantDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("engageMerchantAuthToken");
      localStorage.removeItem("userDetail");
      localStorage.removeItem("loginType");
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loginObj.status = "pending";
    },
    [login.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.loginObj.status = "succeeded";
      state.loginObj.data = data;
      state.user = data;
      localStorage.setItem("engageMerchantAuthToken", data.authToken);
      localStorage.setItem("userDetail", JSON.stringify(data.user));
      localStorage.setItem("loginType", "owner");
      setAxiosToken(data.authToken);
      state.loginObj.successMessage = message;
    },
    [login.rejected]: (state, action) => {
      const { message } = action.error;
      localStorage.removeItem("engageMerchantAuthToken");
      localStorage.removeItem("userDetail");
      localStorage.removeItem("loginType");
      state.loginObj.status = "failed";
      state.loginObj.errorMessage = message;
    },
    [register.pending]: (state) => {
      state.registerObj.status = "pending";
    },
    [register.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      localStorage.setItem("engageMerchantAuthToken", data.authToken);
      localStorage.setItem("userDetail", JSON.stringify(data.user));
      localStorage.setItem("loginType", "owner");
      setAxiosToken(data.authToken);
      state.registerObj.status = "succeeded";
      state.registerObj.data = data;
      state.registerObj.successMessage = message;
      state.user = data;
    },
    [register.rejected]: (state, action) => {
      const { message } = action.error;

      state.registerObj.status = "failed";
      state.registerObj.errorMessage = message;
    },
    [resendVerificationCode.pending]: (state) => {
      state.resendVerificationCodeObj.status = "pending";
    },
    [resendVerificationCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resendVerificationCodeObj.status = "succeeded";
      state.resendVerificationCodeObj.data = data;
      state.resendVerificationCodeObj.successMessage = message;
    },
    [resendVerificationCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.resendVerificationCodeObj.status = "failed";
      state.resendVerificationCodeObj.errorMessage = message;
    },
    [verifyVerificationCode.pending]: (state) => {
      state.verifyVerificationCodeObj.status = "pending";
    },
    [verifyVerificationCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.verifyVerificationCodeObj.status = "succeeded";
      state.verifyVerificationCodeObj.data = data;
      state.verifyVerificationCodeObj.successMessage = message;
    },
    [verifyVerificationCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.verifyVerificationCodeObj.status = "failed";
      state.verifyVerificationCodeObj.errorMessage = message;
    },
    [emailCheck.pending]: (state) => {
      state.emailCheckObj.status = "pending";
    },
    [emailCheck.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.emailCheckObj.status = "succeeded";
      state.emailCheckObj.data = data;
      state.emailCheckObj.successMessage = message;
    },
    [emailCheck.rejected]: (state, action) => {
      const { message } = action.error;

      state.emailCheckObj.status = "failed";
      state.emailCheckObj.errorMessage = message;
    },
    [logout.pending]: (state) => {
      state.logoutObj.status = "pending";
    },
    [logout.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.logoutObj.status = "succeeded";
      state.logoutObj.data = data;
      state.logoutObj.successMessage = message;

      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("engageMerchantAuthToken");
      localStorage.removeItem("userDetail");
      localStorage.removeItem("loginType");
    },
    [logout.rejected]: (state, action) => {
      const { message } = action.error;

      state.logoutObj.status = "failed";
      state.logoutObj.errorMessage = message;
    },
    [forgotPassword.pending]: (state) => {
      state.forgotPasswordObj.status = "pending";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.forgotPasswordObj.status = "succeeded";
      state.forgotPasswordObj.data = data;
      state.forgotPasswordObj.successMessage = message;
    },
    [forgotPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.forgotPasswordObj.status = "failed";
      state.forgotPasswordObj.errorMessage = message;
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordObj.status = "pending";
    },
    [resetPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resetPasswordObj.status = "succeeded";
      state.resetPasswordObj.data = data;
      state.resetPasswordObj.successMessage = message;
    },
    [resetPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.resetPasswordObj.status = "failed";
      state.resetPasswordObj.errorMessage = message;
    },
    [updateMerchantDetail.pending]: (state) => {
      state.updateMerchantDetailObj.status = "pending";
    },
    [updateMerchantDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateMerchantDetailObj.status = "succeeded";
      state.updateMerchantDetailObj.data = data;
      state.updateMerchantDetailObj.successMessage = message;
    },
    [updateMerchantDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateMerchantDetailObj.status = "failed";
      state.updateMerchantDetailObj.errorMessage = message;
    },
  },
});

export default authSlice.reducer;

// state
export const authSelector = (state) => state.auth;

export const { resetUser } = authSlice.actions;
