import { Box, styled } from "@mui/material";
import React from "react";
import KatchLogo from "../../../images/katch-logo.png";
import AuthScreenSideImage from "../../../images/auth-screen-side-image.png";
import Spacer from "../../../components/spacer.component";

const ImageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "50px",
  paddingBottom: "50px",
  width: "50%",
});

export default function SideImage() {
  return (
    <ImageContainer>
      <img src={KatchLogo} alt="logo" width="40%" />
      <Spacer position="top" size="xl" />
      <img src={AuthScreenSideImage} alt="sideImage" width="100%" />
    </ImageContainer>
  );
}
