import { Chip, FormHelperText, Popper, styled, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.bg.primary,
  },
}));
function CustomPopper(props) {
  return <Popper {...props} placement="bottom" />;
}

export default function TextFieldMultiple({ name, placeholder, disabled }) {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const listOnChange = (event, value) => {
    setFieldValue(name, typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <Autocomplete
        filterOptions={(x) => x}
        PopperComponent={CustomPopper}
        disabled={disabled}
        multiple
        freeSolo
        options={[]}
        onChange={listOnChange}
        value={values[name]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
        }
        style={{ width: "100%" }}
        renderInput={(params) => <StyledTextField {...params} placeholder={placeholder} />}
      />
      {showError && (
        <FormHelperText error sx={{ margin: "3px 14px 0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
}

TextFieldMultiple.defaultProps = {
  disabled: false,
};

TextFieldMultiple.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
