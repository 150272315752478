import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { scheduleOffDateSelector } from "../../../services/schedule/off-date/off-date-slice.service";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

export default function ScheduleOffDateModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm } = useFormikContext();
  const { updateScheduleOffDatesObj, createScheduleOffDatesObj } =
    useSelector(scheduleOffDateSelector);

  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "600px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text variant="screenLabel">Off Date</Text>
          </Grid>

          <Grid item xs={12}>
            <FormContainer>
              <Grid item xs={3}>
                <Text>Date</Text>
              </Grid>
              <Grid item xs={9}>
                <FormDatePicker
                  name="date"
                  width="100%"
                  isWhiteBg
                  maxDays={null}
                  minDays={false}
                  showClear={true}
                />
              </Grid>
            </FormContainer>
          </Grid>

          <Grid item xs={12}>
            <FormContainer>
              <Grid item xs={3}>
                <Text>Start time</Text>
              </Grid>
              <Grid item xs={9}>
                <FormTimePicker name="startTime" />
              </Grid>
            </FormContainer>
          </Grid>

          <Grid item xs={12}>
            <FormContainer>
              <Grid item xs={3}>
                <Text>End time</Text>
              </Grid>
              <Grid item xs={9}>
                <FormTimePicker name="endTime" />
              </Grid>
            </FormContainer>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <CtaBorderButton
                onClickButton={() => setShowModal(false)}
                width="100px"
                isLoading={
                  updateScheduleOffDatesObj.status === "pending" ||
                  createScheduleOffDatesObj.status === "pending"
                }
              >
                <Text type="BrandColor">Cancel</Text>
              </CtaBorderButton>
              <Spacer size="m" position="left" />
              <FormSubmitButton
                width="100px"
                isLoading={
                  updateScheduleOffDatesObj.status === "pending" ||
                  createScheduleOffDatesObj.status === "pending"
                }
              >
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

ScheduleOffDateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
