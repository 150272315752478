import React from "react";
import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import Text from "../../../components/text.component";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";
import routes from "../../../navigation/routes";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function StaffDetailLoader() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();

  const renderFieldLoader = (label) => (
    <>
      {isSmallScreen && (
        <LabelContainer>
          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
        </LabelContainer>
      )}

      <FormContainer>
        {!isSmallScreen && (
          <LabelContainer>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{label}</Text>
          </LabelContainer>
        )}
        <Box sx={{ display: "flex", flex: 1 }}>
          <Box sx={{ width: "100%" }}>
            <SkeletonLoader height="41.56px" width="100%" />
          </Box>
        </Box>
      </FormContainer>
    </>
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text variant="screenLabel">Staff Details</Text>
        <Button
          onClick={() => history.push(routes.STAFF_LIST)}
          sx={{
            textTransform: "none",
            padding: "0px",
            ":hover": { backgroundColor: "transparent" },
          }}
        >
          <Text>Back</Text>
        </Button>
      </Box>
      <Grid container sx={{ paddingY: "20px" }} spacing={3}>
        <Grid item xs={12}>
          {renderFieldLoader("First Name")}
        </Grid>
        <Grid item xs={12}>
          {renderFieldLoader("Last Name")}
        </Grid>

        <Grid item xs={12}>
          {renderFieldLoader("Email")}
        </Grid>
        <Grid item xs={12}>
          {renderFieldLoader("Phone Number")}
        </Grid>
        <Grid item xs={12}>
          {renderFieldLoader("Gender")}
        </Grid>
        <Grid item xs={12}>
          {renderFieldLoader("Date of birth")}
        </Grid>
      </Grid>
    </>
  );
}
