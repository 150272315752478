import React from "react";
import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Text from "../text.component";
import Spacer from "../spacer.component";
import CtaButton from "../button/cta-button.component";
import CtaBorderButton from "../button/cta-border-button.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: "50px",
  outline: "none",
}));

export default function DeleteConfirmationModal({
  title,
  showModal,
  setShowModal,
  label,
  onConfirmClicked,
  isLoading,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "350px" : "500px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Text
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: theme.fonts.fontWeights.bold,
              }}
            >
              {title}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <Text sx={{ textAlign: "center" }}>{label}</Text>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="l" position="top" />
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xxl" position="top" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "45%" }}>
                <CtaBorderButton isLoading={isLoading} onClickButton={handleCancel}>
                  <Text type="BrandColor">Cancel</Text>
                </CtaBorderButton>
              </Box>
              <Box sx={{ width: "45%" }}>
                <CtaButton isLoading={isLoading} onClickButton={onConfirmClicked}>
                  <Text type="WhiteColor">Confirm</Text>
                </CtaButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

DeleteConfirmationModal.defaultProps = {
  isLoading: false,
};

DeleteConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onConfirmClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
