import InfoIcon from "@mui/icons-material/Info";
import { Box, Grid, Modal, styled, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import CtaButton from "../../../components/button/cta-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { eventQuestionSelector } from "../../../services/event/question/question.slice.services";
import TextFieldMultiple from "./text-field-multiple.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: "50px",
  outline: "none",
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  color: theme.palette.colors.brand.primary,
}));

const DetailTextField = styled(Field)`
  border-radius: ${({ theme }) => theme.shape.borderRadius[0]}px;
  padding: 10px;
  font: inherit;
  border: 2px solid #d0d0d0;
  background-color: ${({ theme }) => theme.palette.colors.bg.primary};
  &:hover {
    border: 2px solid black;
  }
`;

export default function AddQuestionConfirmationModal({ title, showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [eventQuestion, setEventQuestion] = useState("");
  const { setFieldValue, submitForm } = useFormikContext();
  const { createEventQuestionsObj } = useSelector(eventQuestionSelector);

  const handleCancel = () => {
    setShowModal(false);
    setEventQuestion("");
  };

  const handleChange = (e) => {
    setEventQuestion(e.target.value);
    setFieldValue("eventQuestion", e.target.value);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "350px" : "500px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Text
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: theme.fonts.fontWeights.bold,
              }}
            >
              {title}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <Text
                variant="h6"
                sx={{
                  fontWeight: theme.fonts.fontWeights.bold,
                }}
              >
                Question
              </Text>
              <Spacer size="s" position="top" />
              <DetailTextField
                placeholder="Enter question"
                type="text"
                value={eventQuestion}
                name="eventQuestion"
                onChange={handleChange}
              />
              <Spacer size="m" position="top" />

              <Grid container alignItems="center">
                <Text
                  variant="h6"
                  sx={{
                    fontWeight: theme.fonts.fontWeights.bold,
                  }}
                >
                  Responses
                </Text>
                <Spacer position="left" />
                <Tooltip title="Leave empty for open ended question" arrow>
                  <StyledInfoIcon />
                </Tooltip>
              </Grid>
              <Spacer size="s" position="top" />

              <TextFieldMultiple
                name="eventQuestionResponses"
                placeholder="(Press enter to confirm your response)"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box width="100px">
                <CtaBorderButton onClickButton={handleCancel}>
                  <Text type="BrandColor">Cancel</Text>
                </CtaBorderButton>
              </Box>
              <Box width="100px">
                <CtaButton
                  onClickButton={() => {
                    setEventQuestion("");
                    submitForm();
                  }}
                  width="100px"
                  isLoading={createEventQuestionsObj.status === "pending"}
                >
                  Add
                </CtaButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AddQuestionConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
