import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../../api-services";

export const getEventResponses = createAsyncThunk(
  "merchants/events/responses/index",
  async (payload) => {
    const { eventIds, userIds, page, q } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/responses?page=${page}${
        eventIds ? "&eventIds=".concat(eventIds) : ""
      }${q ? "&q=".concat(q) : ""}${userIds ? "&userIds=".concat(userIds) : ""}`,
    );
    return response;
  },
);

export const getEventResponsesCSV = createAsyncThunk(
  "merchants/events/responses/csv",
  async (payload) => {
    const { eventId, userIds } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/responses/csv?${
        eventId ? "&eventId=".concat(eventId) : ""
      }${userIds ? "&userIds=".concat(userIds) : ""}`,
    );
    return response;
  },
);

const eventResponseSlice = createSlice({
  name: "eventResponse",
  initialState: {
    getEventResponsesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventResponsesCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getEventResponses.pending]: (state) => {
      state.getEventResponsesObj.status = "pending";
    },
    [getEventResponses.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventResponsesObj.status = "succeeded";
      state.getEventResponsesObj.data = data;
      state.getEventResponsesObj.successMessage = message;
    },
    [getEventResponses.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventResponsesObj.status = "failed";
      state.getEventResponsesObj.errorMessage = message;
    },
    [getEventResponsesCSV.pending]: (state) => {
      state.getEventResponsesCSVObj.status = "pending";
    },
    [getEventResponsesCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventResponsesCSVObj.status = "succeeded";
      state.getEventResponsesCSVObj.data = data;
      state.getEventResponsesCSVObj.successMessage = message;
    },
    [getEventResponsesCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventResponsesCSVObj.status = "failed";
      state.getEventResponsesCSVObj.errorMessage = message;
    },
  },
});

export default eventResponseSlice.reducer;

// state
export const eventResponseSelector = (state) => state.eventResponse;
